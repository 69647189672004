import React from 'react';

import logoImage from './images/logo.svg';
import styled from 'styled-components';

export enum LogoType {
  HEADER = 'HEADER',
  FOOTER = 'FOOTER',
}

interface Props {
  logoUrl?: string;
  logoType?: LogoType;
}

const StyledLogo = styled.img<Props>`
  height: ${(props) => {
    if (props.logoUrl) {
      return '44px';
    }
    return props.logoType === LogoType.FOOTER ? '50px' : '100px';
  }};

  @media (max-width: 767px) {
    height: ${(props) => {
      if (props.logoUrl) {
        return '44px';
      }
      return props.logoType === LogoType.FOOTER ? '45px' : '75px';
    }};
  }
`;

export const Logo: React.FC<Props> = ({ logoUrl, logoType = LogoType.HEADER }) => (
  <StyledLogo logoType={logoType} logoUrl={logoUrl} src={logoUrl || logoImage} alt="Profyle" />
);
