import React, { ReactNode } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Navigation } from './navigation';

interface Props {
  children?: ReactNode;
}

function LayoutHeader({ children }: Props) {
  return (
    <div>
      <Navigation>
        <Nav.Link to="/skillsets" as={Link}>
          Profyle Roles
        </Nav.Link>
        {/* <Nav.Link to="/background" as={Link}>Background</Nav.Link> */}
        <Nav.Link to="/faq" as={Link}>
          FAQ
        </Nav.Link>
      </Navigation>
      {children}
    </div>
  );
}

export { LayoutHeader };
