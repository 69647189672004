import React from 'react';
import { Helmet } from 'react-helmet';
import { META_TAGS } from '../shared/constants/metaTags';

import { LayoutDefault } from '../shared/ui';

function Wsa() {
  return (
    <LayoutDefault>
      <Helmet>
        <title>{META_TAGS.faq.title}</title>
        <meta name="description" content={META_TAGS.faq.description} />
      </Helmet>
      <h2>Work Style Analyser</h2>

      <p>Hello. We hope you found what you needed on Profyle?</p>

      <p>
        Just in case you needed something that goes much deeper (25 dimensions instead of 4) then you might find that
        our <a href="https://work-style-analyser.com/">Work Style Analyser</a> suits you.
      </p>

      <p>
        The Work Style Analyser is a fully validated psychometric that provides a wealth of detail and has a variety of
        uses in.
      </p>

      <h3>Leadership & Management Development</h3>
      <ul>
        <li>Identifies how an individual compares to generic leadership styles.</li>
        <li>Allows an in depth analysis of what would motivate each specific individual to be a leader.</li>
        <li>
          Facilitates bespoke development planning to move a person towards realising their potential as a leader.
        </li>
      </ul>

      <h3>Team Development</h3>
      <ul>
        <li>Explores team strengths and vulnerabilities in the context of business or strategic goals.</li>
        <li>Identifies gaps to support recruitment or succession planning decisions.</li>
        <li>Provides the basis for team goal setting and action planning discussions.</li>
        <li>Provides an objective language for addressing team conflict issues.</li>
      </ul>

      <h3>Personal Development & Coaching</h3>
      <ul>
        <li>Provides the vehicle to facilitate an in-depth conversation about current strengths and limitations.</li>
        <li>Examines the motivational drivers which underpin behaviours.</li>
        <li>Shows clearly how change can best be introduced for each individual.</li>
        <li>
          Encourages a non-judgmental tone to conversations and shows that each limitation has a commensurate strength.
        </li>
      </ul>

      <h3>Career & Talent Management</h3>
      <ul>
        <li>Explores preference, motivation and learning style in the context of career change and transition.</li>
        <li>Provides a structure for a career conversation, focusing on individual and business needs.</li>
        <li>
          Clearly identifies what a career needs to involve to appeal to the natural motivations of each individual.
        </li>
      </ul>

      <h3>Recruitment & Assessment Centres</h3>

      <p>
        Use the WSA's Recruitment Report to identify a candidate's key strengths and weaknesses against a comprehensive
        set of competence areas. These areas are Analyst-selected to accurately reflect the needs of the management role
        concerned. To aid the interview process a unique set of competency related questions is also presented.
      </p>

      <p>
        If you are interested, please visit <a href="https://work-style-analyser.com/">www.work-style-analyser.com</a>
      </p>
    </LayoutDefault>
  );
}

export { Wsa };
