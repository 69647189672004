import React, { useMemo, useCallback, ReactNode } from 'react';
import { Checkbox, ResponsiveTable } from '../../shared/ui';
import { useSkillsets } from '../../shared/skillsets/use-skillsets';
import { SelectGroup } from '../select-group';
import { SmallButton, AdminLabel, TableWrapper } from './components';
import { IProps } from './types';
import { hasRole } from '../../shared/auth/has-role';
import { RoleName } from '../../shared/auth/role-name';
import { ITableMapping } from '../../shared/ui/responsive-table/types';
import { GetAdminUsers_getAdminUsers } from '../types';
import { ModalSkillset } from '../modal-skillset';

export const UsersTable: React.FC<IProps> = ({
  data,
  selected,
  onCheckBoxChange,
  onAllCheckBoxChange,
  onChangeSkillset,
  onChangeGroup,
  send,
  changedIds,
  selectedLength,
}) => {
  const { data: dataSkillsets } = useSkillsets();
  const skillsetsOptions = dataSkillsets?.skillsetListGet || [];

  const { checked, reset } = useMemo(() => {
    const userLength = data.length;
    const reset = selectedLength && selectedLength !== userLength;
    const checked = selectedLength === userLength;

    return { checked, reset: !!reset, selectedLength };
  }, [data.length, selectedLength]);

  const onAllCheckboxChange = useCallback(() => {
    const type = reset || checked ? 'reset' : 'checked';
    onAllCheckBoxChange(type);
  }, [checked, onAllCheckBoxChange, reset]);

  const mapping: ITableMapping<GetAdminUsers_getAdminUsers>[] = [
    {
      label: <Checkbox id="all" onChange={onAllCheckboxChange} checked={checked} reset={reset} />,
      getter: (item) => {
        return <Checkbox id={item.id} onChange={onCheckBoxChange} checked={!!selected[item.id]} />;
      },
      disableOnMobile: true,
    },
    {
      label: 'Name',
      getter: (item) => {
        const isCompanyAdmin = hasRole({ roles: item.roles }, RoleName.ROLE_CORPORATE_ADMIN);
        return (
          <>
            {`${item.firstName} ${item.lastName}`} {isCompanyAdmin && <AdminLabel>admin</AdminLabel>}
          </>
        );
      },
      disableOnMobile: true,
    },
    {
      label: 'Groups',
      getter: (item, index) => {
        return (
          <SelectGroup
            onChange={(e) => onChangeGroup(e, index)}
            fieldName="groups"
            setFieldValue={(name, value) => onChangeGroup(value, index)}
            value={item.companyGroup?.groupName || ''}
            placeholder="-"
            placeholderHidden
            custom={false}
          />
        );
      },
    },
    {
      label: 'Skillset',
      getter: (item, index) => {
        return (
          <ModalSkillset
            skillsets={skillsetsOptions}
            selectedSkillsets={item.skillsets}
            onChangeSkillset={onChangeSkillset}
            index={index}
          />
        );
      },
    },
    {
      label: 'Email Address',
      getter: (item) => {
        return item.email;
      },
    },
    {
      label: 'Invite/Notice',
      getter: (item, index) => {
        const isDisabled = !!selectedLength && !selected[item.id];
        const buttonTitle = item.isConfirmed ? 'Send Notice' : 'Send Invite';
        return (
          <SmallButton onClick={() => send(index)} size="sm" disabled={!(changedIds[item.id] > -1) || isDisabled}>
            {changedIds[item.id] > -1 ? buttonTitle : 'Sent'}
          </SmallButton>
        );
      },
    },
  ];

  const mobileHeader = (
    <div className="d-flex">
      <Checkbox id="all" onChange={onAllCheckboxChange} checked={checked} reset={reset} />
      <span>Name</span>
    </div>
  );

  const mobileRowHeader = (item: GetAdminUsers_getAdminUsers): ReactNode => {
    const isCompanyAdmin = hasRole({ roles: item.roles }, RoleName.ROLE_CORPORATE_ADMIN);
    return (
      <div className="d-flex">
        <div>
          <Checkbox id={item.id} onChange={onCheckBoxChange} checked={!!selected[item.id]} />
        </div>
        <div>
          {`${item.firstName} ${item.lastName}`} {isCompanyAdmin && <AdminLabel>admin</AdminLabel>}
        </div>
      </div>
    );
  };

  return (
    <TableWrapper>
      <ResponsiveTable data={data} mapping={mapping} mobileHeader={mobileHeader} mobileRowHeader={mobileRowHeader} />
    </TableWrapper>
  );
};
