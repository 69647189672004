import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ResetDone } from './reset-done';
import { ProtectedRoute } from '../../shared/auth';

function resetDoneRoute(routeProps: RouteComponentProps) {
  return (
    <ProtectedRoute path={`${routeProps.match.url}/reset-done`}>
      <ResetDone />
    </ProtectedRoute>
  );
}

export { resetDoneRoute };
