import React from 'react';

import { LayoutDefault } from '../../shared/ui';

function InviteSending() {
  return (
    <LayoutDefault>
      <h2>Invitation Sent</h2>
      Your connection will receive their invitation soon.
    </LayoutDefault>
  );
}

export { InviteSending };
