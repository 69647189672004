import React from 'react';
import gql from 'graphql-tag';

import { AdminView } from '../../../shared/ui';
import { Html } from '../../../shared/html';

const TEXT_GET = gql`
  query AdminTextGetForView($id: Int!) {
    adminTextGet(id: $id) {
      id
      tag
      title
      body
    }
  }
`;

const BREADCRUMBS = [{ path: '/admin/texts', label: 'Texts' }, { label: 'View text' }];

const MAPPING = [
  { key: 'id' },
  { key: 'tag' },
  { key: 'title' },
  { key: 'body', getter: (value: string) => <Html code={value} /> },
];

function TextsView() {
  return <AdminView query={TEXT_GET} dataProp="adminTextGet" breadcrumbs={BREADCRUMBS} mapping={MAPPING} />;
}

export { TextsView };
