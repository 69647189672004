import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { EmailConfirmation } from './email-confirmation';
import { ProtectedRoute } from '../../shared/auth';

function emailConfirmationRoute(routeProps: RouteComponentProps) {
  return (
    <ProtectedRoute notAuth path={`${routeProps.match.url}/email-confirmation`}>
      <EmailConfirmation />
    </ProtectedRoute>
  );
}

export { emailConfirmationRoute };
