import React, { ReactNode, useCallback } from 'react';
import { Tab } from 'react-bootstrap';
import { useParams, useHistory, Redirect, useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Tabs, Loading } from '../../shared/ui';

import { ProfyleGet, ProfyleGetVariables, ProfyleGet_profyleGet } from './__generated__/ProfyleGet';

import { createHistoryPushTab } from './shared/create-history-push-tab';

import { TabWelcome } from './tab-welcome';
import { TabDiscoverYourself } from './tab-discover-yourself';
import { TabTextualReport } from './tab-textual-report';
import { TabFutureFocus } from './tab-future-focus';
import { TabInstantAction } from './tab-instant-action';
import { TabCompareYourself } from './tab-compare-yourself';
import { TabBackground } from './tab-background';
import { TabGetFeedback } from './tab-get-feedback';
import { getTabUrlGetFeedback } from '../get-tab-url-get-feedback';
import { PaymentProfyleRoleForm } from '../../plans/form-plans/payment-profyle-role-form';
import { NotificationManager } from 'react-notifications';

const PROFYLE_GET = gql`
  query ProfyleGet($id: Int!) {
    profyleGet(id: $id) {
      id
      report {
        isPaid
        text
        focusAreas
        instantActionsLow
        instantActionsHigh
        toOptimiseLowTitle
        toOptimiseLowText
        toOptimiseLowReading {
          id
          title
          author
          url
          imageUrl
        }
        toOptimiseHighTitle
        toOptimiseHighText
        toOptimiseHighReading {
          id
          title
          author
          url
          imageUrl
        }
      }
      pathway {
        items {
          id
          text
        }
      }
      result {
        quiz {
          skillsets {
            id
            name
          }
        }
        score {
          consideration
          assertion
          emotionalManagement
          extroversion
        }
      }
      createdAt
    }
  }
`;

interface Params {
  profyleId: number;
  getHeader: (profyle: ProfyleGet_profyleGet) => ReactNode;
}

function ReportTabs(params: Params) {
  const { profyleId, getHeader } = params;
  const { loading, data } = useQuery<ProfyleGet, ProfyleGetVariables>(PROFYLE_GET, {
    variables: { id: profyleId },
  });
  const profyle = data?.profyleGet;

  const routeParams = useParams<{ section: string }>();
  const tabsActiveKey = routeParams.section || '';

  const history = useHistory();
  const routeMatch = useRouteMatch();
  const historyPushTab = createHistoryPushTab(history, routeMatch);

  const toTabUrl = (key: string) => {
    let urlEnding = key.length ? key : 'welcome';
    if (key === 'get-feedback') {
      urlEnding = getTabUrlGetFeedback();
    }
    return urlEnding;
  };

  const tabsOnSelect = (key: string) => {
    historyPushTab(toTabUrl(key));
  };

  const handleCouponSuccess = useCallback(() => {
    NotificationManager.success('Payment was successful');
  }, []);

  if (!tabsActiveKey) {
    return <Redirect to={`${routeMatch.url}/welcome`} />;
  }

  if (loading) {
    return <Loading />;
  }

  const prof = profyle!;

  const { isPaid } = prof.report;

  return (
    <>
      {getHeader(prof)}
      {!isPaid && (
        <>
          <h3 className="text-center">Pay to see the full report</h3>
          <PaymentProfyleRoleForm
            skillsetIds={[prof.result.quiz.skillsets[0].id]}
            redirect={window.location.pathname}
            onApplyCouponSuccess={handleCouponSuccess}
          />
          <br />
        </>
      )}

      <Tabs
        activeKey={tabsActiveKey}
        onSelect={tabsOnSelect}
        mountOnEnter={true}
        unmountOnExit={true}
        id="profyle-tabs"
      >
        <Tab eventKey="welcome" title="Welcome">
          <TabWelcome profyle={prof} nextUrl="discover-yourself" />
        </Tab>

        <Tab eventKey="discover-yourself" title="Discover Yourself">
          <TabDiscoverYourself profyle={prof} nextUrl={isPaid ? 'textual-report' : 'background'} />
        </Tab>

        <Tab eventKey="textual-report" title="Textual Report" disabled={!isPaid}>
          <TabTextualReport profyle={prof} nextUrl="future-focus" />
        </Tab>

        <Tab eventKey="future-focus" title="Future Focus" disabled={!isPaid}>
          <TabFutureFocus
            profyle={prof}
            nextUrl={
              prof.report.instantActionsLow || prof.report.instantActionsHigh
                ? 'instant-action'
                : getTabUrlGetFeedback()
            }
          />
        </Tab>

        {(prof.report.instantActionsLow || prof.report.instantActionsHigh) && (
          <Tab eventKey="instant-action" title="Instant Actions" disabled={!isPaid}>
            <TabInstantAction profyle={prof} nextUrl={getTabUrlGetFeedback()} />
          </Tab>
        )}

        <Tab eventKey="get-feedback" title="Feedback" disabled={!isPaid}>
          <TabGetFeedback profyle={prof} nextUrl="compare-yourself" />
        </Tab>

        <Tab eventKey="compare-yourself" title="Compare Yourself" disabled={!isPaid}>
          <TabCompareYourself profyle={prof} nextUrl="background" />
        </Tab>

        <Tab eventKey="background" title="Background">
          <TabBackground profyle={prof} />
        </Tab>
      </Tabs>
    </>
  );
}

export { ReportTabs };
