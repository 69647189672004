import React from 'react';

import { LayoutDefault, AdminBreadcrumbs } from '../../../../shared/ui';

import { useQuizId } from '../use-quiz-id';

import { QuizzesEditForm } from './quizzes-edit-form';

const BREADCRUMBS = [
  { path: '/admin/quizzes', label: 'Quizzes & Questions' },
  { path: '/admin/quizzes/quizzes', label: 'Quizzes' },
  { label: 'Edit quiz' },
];

function QuizzesEdit() {
  const quizId = useQuizId();

  return (
    <LayoutDefault>
      <h2>Edit quiz with id {quizId}</h2>
      <AdminBreadcrumbs breadcrumbs={BREADCRUMBS} />
      <QuizzesEditForm />
    </LayoutDefault>
  );
}

export { QuizzesEdit };
