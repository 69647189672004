import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  GetCompanyOrderDetailsById,
  GetCompanyOrderDetailsByIdVariables,
} from './__generated__/GetCompanyOrderDetailsById';

const GET_COMPANY_ORDER_DETAILS_BY_ID = gql`
  query GetCompanyOrderDetailsById($id: Int!) {
    getCompanyOrderDetailsById(id: $id) {
      orderDetailList {
        firstName
        lastName
        skillset
        email
      }
    }
  }
`;

export const useCompanyOrderDetailsById = (orderId: number) => {
  return useQuery<GetCompanyOrderDetailsById, GetCompanyOrderDetailsByIdVariables>(GET_COMPANY_ORDER_DETAILS_BY_ID, {
    variables: { id: orderId },
  });
};
