import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../../../../shared/auth';

import { Status } from './status';

function statusRoute(routeProps: RouteComponentProps) {
  return (
    <ProtectedRoute path={`${routeProps.match.url}/status`}>
      <Status />
    </ProtectedRoute>
  );
}

export { statusRoute };
