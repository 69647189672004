import React from 'react';

import { LayoutDefault } from '../shared/ui';

import imageRating from './images/rating.png';

function Rating() {
  return (
    <LayoutDefault>
      <h2>How do I Rank?</h2>
      <img src={imageRating} alt="Rating" />
    </LayoutDefault>
  );
}

export { Rating };
