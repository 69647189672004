import React, { useCallback, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/named
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { FormActions, FormButton, LayoutContainer, Button } from '../../shared/ui';
import { FormMode } from '../../shared/from';
import { useCurrentUser } from '../../shared/auth';
import { Form, Col } from 'react-bootstrap';
import { SelectControl } from '../../shared/user/my-info-form/my-info-form-layout/my-info-form-layout';
import { useFormik } from 'formik';
import { BlockFigureMain } from '../../shared/block-figure-main';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { PaymentStripePublishableKeyGet } from '../../plans/form-plans/payment-profyle-role-form/__generated__/PaymentStripePublishableKeyGet';
import { PUBLISHABLE_KEY_GET } from '../../plans/form-plans/payment-profyle-role-form/payment-profyle-role-form';
import {
  PaymentStripeCheckoutSessionCreateCompany,
  PaymentStripeCheckoutSessionCreateCompanyVariables,
  PaymentStripeCheckoutSessionCreateCompany_paymentStripeCheckoutSessionCreateCompany,
} from './__generated__/PaymentStripeCheckoutSessionCreateCompany';
import { GetOrderById_getCompanyOrderById } from '../../company/hooks/__generated__/GetOrderById';
import classNames from 'classnames';
import {
  PaymentCouponCheckoutCompany,
  PaymentCouponCheckoutCompanyVariables,
} from './__generated__/PaymentCouponCheckoutCompany';

const StyledBlockFigureMain = styled(BlockFigureMain)`
  padding: 14px;

  @media (max-width: 767px) {
    padding: 0;
  }
`;

const FormWrapper = styled.div`
  background: var(--white);
  border: 1px solid var(--gray);
  padding: 24px;

  & .form-group:not(:first-child) {
    border-bottom: 1px solid var(--gray);
  }
`;

const MobileCol = styled(Col)<{ hasError?: boolean }>`
  @media (min-width: 768px) {
    margin-top: ${(props) => (props.hasError ? '40px' : '20px')};
  }
  @media (max-width: 767px) {
    display: flex;
    width: 100%;
    margin-top: 10px;
    flex-direction: column;
  }
`;

const PAYMENT_METHODS = {
  PREPAID: 'PREPAID',
  POSTPAID: 'POSTPAID',
};

const paymentMethodOptions = [
  {
    name: 'Online card',
    value: PAYMENT_METHODS.PREPAID,
  },
  {
    name: 'By Invoice',
    value: PAYMENT_METHODS.POSTPAID,
  },
];

const CHECKOUT_SESSION_CREATE_COMPANY = gql`
  mutation PaymentStripeCheckoutSessionCreateCompany($input: PaymentStripeCheckoutSessionCreateCompanyInput!) {
    paymentStripeCheckoutSessionCreateCompany(input: $input) {
      id
      amountTotal
      currency
    }
  }
`;

const COUPON_CHECKOUT_COMPANY = gql`
  mutation PaymentCouponCheckoutCompany($input: PaymentCouponCheckoutCompanyInput!) {
    paymentCouponCheckoutCompany(input: $input) {
      id
      isPaid
    }
  }
`;

interface IOrderFormValues {
  id: number;
  userAmount: number;
  skillsetAmount: number;
  orderAmount: number;
  isPaid: boolean;
  paymentMethod: string;
}

const PATH_MY_ORDERS = '/company/billing-information';

const toValues = (order: GetOrderById_getCompanyOrderById): IOrderFormValues => {
  const { __typename, ...values } = order;
  return { ...values, paymentMethod: paymentMethodOptions[0].value };
};
interface IProps {
  order: GetOrderById_getCompanyOrderById;
  initialFormMode?: FormMode;
}

export const MyOrderForm: React.FC<IProps> = ({ order }) => {
  const { data: user } = useCurrentUser();
  const history = useHistory();
  const [
    stripeCheckoutSession,
    setStripeCheckoutSession,
  ] = useState<PaymentStripeCheckoutSessionCreateCompany_paymentStripeCheckoutSessionCreateCompany>();
  const initialValues = toValues(order);

  const form = useFormik({
    initialValues,
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
    },
    validateOnBlur: true,
    validateOnMount: true,
  });

  // Coupon
  const [paymentCouponCheckoutCompany] = useMutation<
    PaymentCouponCheckoutCompany,
    PaymentCouponCheckoutCompanyVariables
  >(COUPON_CHECKOUT_COMPANY);

  const [coupon, setCoupon] = useState('');
  const [couponErr, setCouponErr] = useState('');
  const handleCouponChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCoupon(e.target.value);
      if (couponErr) {
        setCouponErr('');
      }
    },
    [couponErr]
  );

  const handleApplyCoupon = useCallback(() => {
    paymentCouponCheckoutCompany({ variables: { input: { orderId: order.id, coupon: coupon } } })
      .then((result) => {
        if (!result.data?.paymentCouponCheckoutCompany.isPaid) {
          throw new Error('Invalid Coupon');
        }
        history.push(PATH_MY_ORDERS);
      })
      .catch((e) => {
        setCouponErr('Invalid Coupon');
      });
  }, [coupon, history, order, paymentCouponCheckoutCompany]);

  const handleCouponKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleApplyCoupon();
      }
    },
    [handleApplyCoupon]
  );
  // Load publishable key
  const { loading: publishableKeyLoading, data: publishableKeyData } = useQuery<PaymentStripePublishableKeyGet>(
    PUBLISHABLE_KEY_GET
  );

  // Load stripe
  const stripeRef = useRef<Stripe | null>();
  useEffect(() => {
    if (!publishableKeyData) {
      return;
    }
    const publishableKey = publishableKeyData.paymentStripePublishableKeyGet.publishableKey;
    loadStripe(publishableKey).then((s) => {
      stripeRef.current = s;
    });
  }, [publishableKeyData]);

  // Create checkout session
  // const stripeCheckoutSessionRef = useRef<PaymentStripeCheckoutSessionCreate_paymentStripeCheckoutSessionCreate>();
  const [paymentStripeCheckoutSessionCreateCompany] = useMutation<
    PaymentStripeCheckoutSessionCreateCompany,
    PaymentStripeCheckoutSessionCreateCompanyVariables
  >(CHECKOUT_SESSION_CREATE_COMPANY);

  useEffect(() => {
    paymentStripeCheckoutSessionCreateCompany({
      variables: { input: { orderId: order.id, redirect: PATH_MY_ORDERS } },
    }).then((result) => {
      console.log({ result });
      setStripeCheckoutSession(result.data?.paymentStripeCheckoutSessionCreateCompany);
      //
      //
      //
      //
      //
      //
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickPay = useCallback(() => {
    if (form.values.paymentMethod === PAYMENT_METHODS.PREPAID) {
      stripeRef.current!.redirectToCheckout({ sessionId: stripeCheckoutSession!.id });
    } else if (form.values.paymentMethod === PAYMENT_METHODS.POSTPAID) {
      window.open(`/company-order-invoice/${order.id}`, '_blank')?.focus();
    }
  }, [form, stripeCheckoutSession, order]);

  return (
    <LayoutContainer>
      <h1>
        My <span className="emphasis">order</span>
      </h1>
      <StyledBlockFigureMain>
        <FormWrapper>
          <h2 className="text-left">Invoice</h2>
          <Form noValidate onSubmit={form.handleSubmit}>
            <Form.Group controlId="paymentMethod">
              <Form.Label>Payment method</Form.Label>
              <SelectControl
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.paymentMethod}
                isInvalid={!!(form.touched.paymentMethod && form.errors.paymentMethod)}
                disabled={form.isSubmitting}
                custom
                required
              >
                {paymentMethodOptions.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.name}
                  </option>
                ))}
              </SelectControl>
              <Form.Control.Feedback type="invalid">{form.errors.paymentMethod}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Customer</Form.Label>
              <Form.Control
                type="text"
                value={user?.userCurrentGet.firstName}
                disabled={form.isSubmitting}
                plaintext
                readOnly
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Number of users</Form.Label>
              <Form.Control
                type="text"
                value={form.values.userAmount}
                disabled={form.isSubmitting}
                plaintext
                readOnly
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Number of Skillsets</Form.Label>
              <Form.Control
                type="text"
                value={form.values.skillsetAmount}
                disabled={form.isSubmitting}
                plaintext
                readOnly
              />
            </Form.Group>
            <h4>
              Total:{' '}
              {stripeCheckoutSession ? (
                <>
                  {stripeCheckoutSession.amountTotal / 100} {stripeCheckoutSession.currency.toUpperCase()}
                </>
              ) : null}
            </h4>
            <Form.Group>
              <Form.Row className="justify-content-sm-center align-items-sm-center mb-3">
                <MobileCol sm="auto" xs={12}>
                  <div>Coupon Code:</div>
                </MobileCol>
                <MobileCol lg={3} md={4} sm={12} xs={12} hasError={!!couponErr}>
                  <Form.Control
                    isInvalid={!!couponErr}
                    type="text"
                    placeholder="Enter Coupon Code"
                    onChange={handleCouponChange}
                    onKeyDown={handleCouponKeyDown}
                  />
                  <Form.Control.Feedback type="invalid" className={classNames({ 'd-block': couponErr })}>
                    {couponErr}
                  </Form.Control.Feedback>
                </MobileCol>
                <MobileCol xs="auto">
                  <Button disabled={!coupon} onClick={handleApplyCoupon}>
                    Apply Coupon
                  </Button>
                </MobileCol>
              </Form.Row>
            </Form.Group>
            <FormActions>
              <FormButton
                type="submit"
                form={form}
                disabled={!stripeRef.current || !stripeCheckoutSession}
                onClick={handleClickPay}
              >
                {form.values.paymentMethod === PAYMENT_METHODS.PREPAID ? 'Go to Payment' : 'Show Invoice'}
              </FormButton>
            </FormActions>
          </Form>
        </FormWrapper>
      </StyledBlockFigureMain>
    </LayoutContainer>
  );
};
