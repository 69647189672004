import React from 'react';
import { useFormik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { UserCoreSignUp, UserCoreSignUpVariables } from './__generated__/UserCoreSignUp';
import { UserCoreSignUpInput } from '../../../../__generated__/globalTypes';
import { ButtonSpinner } from '../../shared/ui';
import { getApiResponseMessage } from '../../shared/graphql';
import { error } from '../../shared/error';

const PATH_SIGN_UP_LANDING = '/sign-up/email-confirmation';

const USER_CORE_SIGN_UP = gql`
  mutation UserCoreSignUp($input: UserCoreSignUpInput!) {
    userCoreSignUp(input: $input) {
      id
    }
  }
`;

error.registerIgnoreOperation('UserCoreSignUp');

const initialValues: UserCoreSignUpInput = {
  firstName: '',
  lastName: '',
  email: '',
};

const validationSchema = yup.object().shape<UserCoreSignUpInput>({
  firstName: yup.string().required('First Name is a required field'),
  lastName: yup.string().required('Last Name is a required field'),
  email: yup.string().email('Email must be a valid email').required('Email is a required field'),
});

function SignUpForm() {
  const history = useHistory();
  const [userCoreSignUp] = useMutation<UserCoreSignUp, UserCoreSignUpVariables>(USER_CORE_SIGN_UP);
  const onSubmit = (values: UserCoreSignUpInput, formikHelpers: FormikHelpers<UserCoreSignUpInput>) => {
    userCoreSignUp({ variables: { input: values } })
      .then(() => {
        history.push(PATH_SIGN_UP_LANDING);
      })
      .catch((e) => {
        formikHelpers.setErrors({ email: getApiResponseMessage(e) });
        formikHelpers.setSubmitting(false);
      });
  };
  const form = useFormik<UserCoreSignUpInput>({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <div className="w-50-resp m-auto">
      <h2>Getting to Know You</h2>
      <Form noValidate onSubmit={form.handleSubmit}>
        <Form.Group controlId="firstName">
          <Form.Label>First name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter first name"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.firstName}
            isInvalid={!!(form.touched.firstName && form.errors.firstName)}
            disabled={form.isSubmitting}
          />
          <Form.Control.Feedback type="invalid">{form.errors.firstName}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="lastName">
          <Form.Label>Last name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter last name"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.lastName}
            isInvalid={!!(form.touched.lastName && form.errors.lastName)}
            disabled={form.isSubmitting}
          />
          <Form.Control.Feedback type="invalid">{form.errors.lastName}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.email}
            isInvalid={!!(form.touched.email && form.errors.email)}
            disabled={form.isSubmitting}
          />
          <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
          <Form.Control.Feedback type="invalid">{form.errors.email}</Form.Control.Feedback>
        </Form.Group>
        <Button type="submit" disabled={form.isSubmitting}>
          I’m in
          {form.isSubmitting && <ButtonSpinner />}
        </Button>
      </Form>
    </div>
  );
}

export { SignUpForm };
