import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { LayoutDefault, Loading } from '../shared/ui';
import { Html } from '../shared/html';

import { TextGetContactsForContacts } from './__generated__/TextGetContactsForContacts';

import { ContactsForm } from './contacts-form';
import { Helmet } from 'react-helmet';
import { META_TAGS } from '../shared/constants/metaTags';

const CONTACTS_TEXT_GET = gql`
  query TextGetContactsForContacts {
    textGet(tag: "contacts") {
      title
      body
    }
  }
`;

function Contacts() {
  const { loading, data } = useQuery<TextGetContactsForContacts>(CONTACTS_TEXT_GET);
  const text = data?.textGet;

  return (
    <LayoutDefault>
      <Helmet>
        <title>{META_TAGS.contacts.title}</title>
        <meta name="description" content={META_TAGS.contacts.description} />
      </Helmet>
      {loading ? (
        <Loading />
      ) : (
        (() => {
          return (
            !!text && (
              <>
                <h2>{text.title}</h2>
                <div className="w-50-resp m-auto">
                  <Html code={text.body} />
                </div>
                <br />

                <ContactsForm />
              </>
            )
          );
        })()
      )}
    </LayoutDefault>
  );
}

export { Contacts };
