import React from 'react';
import styled from 'styled-components';

interface LoadingWrapperProps {
  center?: boolean;
}

const LoadingWrapper = styled.span<LoadingWrapperProps>`
  text-align: ${(props) => (props.center ? 'center' : 'unset')};
  display: ${(props) => (props.center ? 'block' : 'unset')};
`;

interface Props {
  center?: boolean;
}

function Loading(props: Props) {
  return <LoadingWrapper center={props.center}>Loading...</LoadingWrapper>;
}

export { Loading };
