import React from 'react';
import styled from 'styled-components';
import { Button, Modal, Col, Row, Container } from 'react-bootstrap';

import privateAccountImage from './images/private-account.svg';
import corporateAccountImage from './images/corporate-account.svg';

const CreateAccountModalHeader = styled(Modal.Header)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  border: 0;
`;

const CreateAccountContainer = styled(Container)`
  margin: 32px 15px;

  @media (max-width: 991px) {
    margin: 32px 0;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 15%;
    left: 50%;
    width: 1px;
    height: 69%;
    background-color: var(--light);
  }
`;

const CreateAccountCol = styled(Col)`
  text-align: center;
`;

const CreateAccountImage = styled.img`
  margin-bottom: 24px;
`;

const CreateAccountHeader = styled.h5`
  margin-bottom: 16px;
`;

interface Props {
  show: boolean;
  onHide: () => void;
  onCreatePrivateAccount: () => void;
  onCreateCorporateAccount: () => void;
}

function ModalCreateAccountLayout(props: Props) {
  return (
    <Modal show={props.show} onHide={props.onHide} size="lg" centered>
      <CreateAccountModalHeader closeButton />
      <Modal.Body>
        <CreateAccountContainer>
          <Row>
            <CreateAccountCol>
              <CreateAccountImage src={privateAccountImage} width="238" height="100" alt="Private Account" />
              <CreateAccountHeader>Just for Me</CreateAccountHeader>
              <Button onClick={props.onCreatePrivateAccount}>Create</Button>
            </CreateAccountCol>
            <CreateAccountCol>
              <CreateAccountImage src={corporateAccountImage} width="238" height="100" alt="Corporate Account" />
              <CreateAccountHeader>For my Business</CreateAccountHeader>
              <Button onClick={props.onCreateCorporateAccount}>Create</Button>
            </CreateAccountCol>
          </Row>
        </CreateAccountContainer>
      </Modal.Body>
    </Modal>
  );
}

export { ModalCreateAccountLayout };
