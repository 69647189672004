import React, { ReactNode, useMemo } from 'react';
import { LayoutDefault, Loading, ResponsiveTable } from '../../shared/ui';
import { ITableMapping } from '../../shared/ui/responsive-table/types';
import { useCompanyOrderDetailsById } from '../hooks/useCompanyOrderDetailsById';
import { GetCompanyOrderDetailsById_getCompanyOrderDetailsById_orderDetailList } from '../hooks/__generated__/GetCompanyOrderDetailsById';

const mapping: ITableMapping<GetCompanyOrderDetailsById_getCompanyOrderDetailsById_orderDetailList>[] = [
  {
    label: 'First Name',
    getter: (item) => {
      return item.firstName;
    },
  },
  {
    label: 'Last Name',
    getter: (item) => {
      return item.lastName;
    },
  },
  {
    label: 'Email',
    getter: (item) => {
      return item.email;
    },
    disableOnMobile: true,
  },
  {
    label: 'Skillset',
    getter: (item) => {
      return item.skillset;
    },
  },
];

const mobileRowHeader = (item: GetCompanyOrderDetailsById_getCompanyOrderDetailsById_orderDetailList): ReactNode => {
  return item.email;
};

export const CompanyOrderDetails: React.FC<{
  orderId: number;
}> = ({ orderId }) => {
  const { loading, data } = useCompanyOrderDetailsById(orderId);
  const orderDetailList = useMemo(() => {
    if (!loading && data?.getCompanyOrderDetailsById.orderDetailList) {
      return data?.getCompanyOrderDetailsById.orderDetailList.map((item, id) => ({ ...item, id }));
    }
    return [];
  }, [loading, data]);

  return (
    <LayoutDefault>
      <h1>
        Order <span className="emphasis text-primary">#{orderId}</span>
      </h1>
      {loading ? (
        <Loading />
      ) : (
        <ResponsiveTable
          data={orderDetailList}
          mapping={mapping}
          mobileHeader="Employee Email"
          mobileRowHeader={mobileRowHeader}
        />
      )}
    </LayoutDefault>
  );
};
