import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { QuizzesAdd } from './quizzes-add';

function quizzesAddRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/add`}
      render={() => (
        <>
          <QuizzesAdd />
        </>
      )}
    />
  );
}

export { quizzesAddRoute };
