import React from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { useParamId } from '../../../../shared/router';

import { AdminTextGet, AdminTextGetVariables, AdminTextGet_adminTextGet } from './__generated__/AdminTextGet';
import { AdminTextUpdate, AdminTextUpdateVariables } from './__generated__/AdminTextUpdate';

import { TextsFormLayout } from '../../texts-form-layout';
import { Loading } from '../../../../shared/ui';

const toInitialValues = (text: AdminTextGet_adminTextGet): { title: string; body: string } => ({
  title: text.title,
  body: text.body,
});

const PATH_UPDATE_LANDING = '/admin/texts';

const TEXT_GET = gql`
  query AdminTextGet($id: Int!) {
    adminTextGet(id: $id) {
      id
      title
      body
    }
  }
`;

const TEXT_UPDATE = gql`
  mutation AdminTextUpdate($input: AdminTextUpdateInput!) {
    adminTextUpdate(input: $input) {
      id
    }
  }
`;

function TextsEditForm() {
  const textId = useParamId();
  const history = useHistory();
  const { loading, data } = useQuery<AdminTextGet, AdminTextGetVariables>(TEXT_GET, {
    variables: { id: textId },
  });
  const text = data?.adminTextGet;
  const [adminTextUpdate] = useMutation<AdminTextUpdate, AdminTextUpdateVariables>(TEXT_UPDATE);

  const onSubmit = (values: { title: string; body: string }) => {
    const input = { id: textId, ...values };
    adminTextUpdate({ variables: { input } }).then(() => {
      history.push(PATH_UPDATE_LANDING);
    });
  };

  if (loading) {
    return <Loading />;
  }

  return <TextsFormLayout initialValues={toInitialValues(text!)} onSubmit={onSubmit} />;
}

export { TextsEditForm };
