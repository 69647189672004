import React from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { UseFormikReturnType } from '../../../shared/formik';

import { FormSlider } from '../../../shared/ui';

import './review-item.scss';

import { GRADE_LABELS } from '../../../shared/grade';

const ReviewItemWrapper = styled.div.attrs(() => ({
  className: 'review-item',
}))``;

const FeedbackFormGroup = styled(Form.Group)`
  margin-bottom: 2rem;
`;

const CommentFormGroup = styled(Form.Group)`
  margin-bottom: 3rem;
`;

const ReportTextParagraph = styled.div`
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
`;

interface Props {
  reportTextParagraph: string;
  reportTextIndex: number;
  form: UseFormikReturnType;
  onNext: () => void;
  last: boolean;
}

function ReviewItem({ reportTextParagraph, reportTextIndex, form, onNext, last }: Props) {
  const submitButonText = last ? 'Complete your Feedback' : 'Send your Feedback';

  const gradeField = `${reportTextIndex}.grade`;
  const commentField = `${reportTextIndex}.comment`;

  const onChangeGrade = (value: string) => {
    form.setFieldValue(gradeField, value);
  };

  const onBlurGrade = () => {
    form.handleBlur(gradeField);
  };

  return (
    <ReviewItemWrapper>
      <Row>
        <Col>
          <ReportTextParagraph>{reportTextParagraph}</ReportTextParagraph>
        </Col>
      </Row>
      <FeedbackFormGroup>
        <FormSlider options={GRADE_LABELS} onChange={onChangeGrade} onBlur={onBlurGrade} />
      </FeedbackFormGroup>
      <CommentFormGroup>
        <Form.Control
          type="text"
          as="textarea"
          name={commentField}
          placeholder="Comment..."
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values[reportTextIndex]?.comment}
          isInvalid={
            !!((form.touched[reportTextIndex] as any)?.comment && (form.errors[reportTextIndex] as any)?.comment)
          }
        />
        <Form.Control.Feedback type="invalid">{(form.errors[reportTextIndex] as any)?.comment}</Form.Control.Feedback>
      </CommentFormGroup>
      <Button
        disabled={!form.values[reportTextIndex] || (form.errors[reportTextIndex] as any)?.comment}
        onClick={onNext}
      >
        {submitButonText}
      </Button>
    </ReviewItemWrapper>
  );
}

export { ReviewItem };
