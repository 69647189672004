import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { QuizzesEdit } from './quizzes-edit';

function quizzesEditRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/edit/:id`}
      render={() => (
        <>
          <QuizzesEdit />
        </>
      )}
    />
  );
}

export { quizzesEditRoute };
