import React, { ChangeEvent, useMemo } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { FormButton, Loading } from '../../../../../shared/ui';

import { AdminQuizAdd, AdminQuizAddVariables } from './__generated__/AdminQuizAdd';
import classNames from 'classnames';
import { useSkillsets } from '../../../../../shared/skillsets/use-skillsets';

const PATH_ADD_LANDING = '/admin/quizzes/quizzes';

const ADMIN_QUIZ_ADD = gql`
  mutation AdminQuizAdd($input: AdminQuizAddInput!) {
    adminQuizAdd(input: $input) {
      id
    }
  }
`;

const initialValues = {
  skillsetId: '',
  spreadsheetFile: undefined,
};

const validationSchema = yup.object().shape({
  skillsetId: yup.string().required('Skillset is a required field'),
  spreadsheetFile: yup.mixed().required('Document is a required field'),
});

function QuizzesAddForm() {
  const history = useHistory();
  const { loading, data } = useSkillsets();
  const skillsets = data?.skillsetListGet || [];
  const [adminQuizAdd] = useMutation<AdminQuizAdd, AdminQuizAddVariables>(ADMIN_QUIZ_ADD);

  const onSubmit = (values: { skillsetId: string; spreadsheetFile: File | undefined }) => {
    const input = { skillsetIds: [parseInt(values.skillsetId)], spreadsheetFile: values.spreadsheetFile! };
    adminQuizAdd({ variables: { input } }).then(() => {
      history.push(PATH_ADD_LANDING);
    });
  };

  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const createOnChangeFile = (fieldName: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const el = e.currentTarget;
    form.setFieldValue(fieldName, el.files ? el.files[0] : null);
  };

  const isFormValid = useMemo(() => {
    const { spreadsheetFile, skillsetId } = form.values;
    return !!spreadsheetFile && !!skillsetId;
  }, [form]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Form noValidate onSubmit={form.handleSubmit}>
      <Form.Group controlId="skillsetId">
        <Form.Label>Skillsets</Form.Label>
        <Form.Control
          as="select"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.skillsetId}
          isInvalid={!!(form.touched.skillsetId && form.errors.skillsetId)}
          disabled={form.isSubmitting}
          custom
          required
        >
          <option disabled value="" key="placeholder">
            Select skillset
          </option>
          {skillsets.map((skillset) => (
            <option value={skillset.id} key={skillset.id}>
              {skillset.name}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{form.errors.skillsetId}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="spreadsheetFile">
        <Form.Label>Spreedsheet with question statements and score</Form.Label>
        <Form.File
          placeholder="Browse spreadsheet"
          onChange={createOnChangeFile('spreadsheetFile')}
          onBlur={form.handleBlur}
          isInvalid={!!(form.touched.spreadsheetFile && form.errors.spreadsheetFile)}
          disabled={form.isSubmitting}
          accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        />
        <Form.Control.Feedback type="invalid" className={classNames({ 'd-block': form.touched.spreadsheetFile })}>
          {form.errors.spreadsheetFile}
        </Form.Control.Feedback>
      </Form.Group>

      <FormButton type="submit" form={form} disabled={!isFormValid}>
        Submit
      </FormButton>
    </Form>
  );
}

export { QuizzesAddForm };
