import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface Params {
  breadcrumbs: { path?: string; label: string }[];
}

function AdminBreadcrumbs(params: Params) {
  const lastBreadcrumbsIndex = params.breadcrumbs.length - 1;

  return (
    <Breadcrumb>
      <Breadcrumb.Item linkAs={'span'} key="/admin">
        <Link to="/admin">Admin Console</Link>
      </Breadcrumb.Item>
      {params.breadcrumbs.map((breadcrumb, i) => (
        <Breadcrumb.Item linkAs={'span'} active={i === lastBreadcrumbsIndex} key={i}>
          {breadcrumb.path ? <Link to={breadcrumb.path}>{breadcrumb.label}</Link> : <>{breadcrumb.label}</>}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export { AdminBreadcrumbs };
