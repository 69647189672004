import { range, pick, omit } from 'ramda';
import React, { useRef, ChangeEvent } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { FormButton, FormGroupText, Loading } from '../../../../../shared/ui';

import { AdminProfyleReportUpdateInput } from '../../../../../../../__generated__/globalTypes';
import {
  AdminProfyleReportGetEdit,
  AdminProfyleReportGetEditVariables,
} from './__generated__/AdminProfyleReportGetEdit';
import { AdminProfyleReportUpdate, AdminProfyleReportUpdateVariables } from './__generated__/AdminProfyleReportUpdate';
import { IReading } from '../../../../../profyle/profyle-chosen/tab-instant-action/tab-instant-action';
import { ReadingForm } from './reading-form';
import {
  AdminProfyleReportUpdateWithDocument,
  AdminProfyleReportUpdateWithDocumentVariables,
} from './__generated__/AdminProfyleReportUpdateWithDocument';
import { useSkillsets } from '../../../../../shared/skillsets/use-skillsets';

const FormWrapper = styled.div`
  textarea.form-control {
    min-height: 300px;
  }
`;

const PATH_UPDATE_LANDING = '/admin/profyles/reports';

const REPORT_GET = gql`
  query AdminProfyleReportGetEdit($id: Int!) {
    adminProfyleReportGet(id: $id) {
      id
      name
      text
      focusAreas
      instantActionsLow
      instantActionsHigh
      scoreRules {
        rule
      }
      skillsets {
        id
      }
      toOptimiseHighReading {
        id
        author
        imageUrl
        title
        url
      }
      toOptimiseHighText
      toOptimiseHighTitle
      toOptimiseLowReading {
        id
        author
        imageUrl
        title
        url
      }
      toOptimiseLowText
      toOptimiseLowTitle
    }
  }
`;

const REPORT_UPDATE = gql`
  mutation AdminProfyleReportUpdate($input: AdminProfyleReportUpdateInput!) {
    adminProfyleReportUpdate(input: $input) {
      id
    }
  }
`;

const REPORT_UPDATE_WITH_DOCUMENT = gql`
  mutation AdminProfyleReportUpdateWithDocument($input: AdminProfyleReportUpdateWithDocumentInput!) {
    adminProfyleReportUpdateWithDocument(input: $input) {
      id
    }
  }
`;

type AdminProfyleReportUpdateValues = Omit<AdminProfyleReportUpdateInput, 'id' | 'skillsetIds'> & {
  skillsetId: string;
  documentFile?: File;
};

const initialValues: AdminProfyleReportUpdateValues = {
  skillsetId: '',
  name: '',
  scoreRules: [],
  text: '',
  focusAreas: '',
  instantActionsLow: '',
  instantActionsHigh: '',
  toOptimiseHighText: '',
  toOptimiseHighTitle: '',
  toOptimiseHighReading: [],
  toOptimiseLowText: '',
  toOptimiseLowTitle: '',
  toOptimiseLowReading: [],
  documentFile: undefined,
};

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  scoreRules: yup.array().of(yup.string().required()).required(),
  text: yup.string().required(),
  focusAreas: yup.string().required(),
});

interface RouteParams {
  id: string;
}

function ReportsEditForm() {
  const refOnce = useRef<boolean>();
  const history = useHistory();
  const routeParams = useParams<RouteParams>();
  const id = parseInt(routeParams.id);
  const { loading: loadingSkillsets, data: dataSkillsets } = useSkillsets();
  const skillsets = dataSkillsets?.skillsetListGet || [];
  const { loading: loadingReport, data: dataReport } = useQuery<
    AdminProfyleReportGetEdit,
    AdminProfyleReportGetEditVariables
  >(REPORT_GET, {
    variables: { id },
  });
  const [adminProfyleReportUpdate] = useMutation<AdminProfyleReportUpdate, AdminProfyleReportUpdateVariables>(
    REPORT_UPDATE
  );

  const [adminProfyleReportUpdateWithDocument] = useMutation<
    AdminProfyleReportUpdateWithDocument,
    AdminProfyleReportUpdateWithDocumentVariables
  >(REPORT_UPDATE_WITH_DOCUMENT);

  const onSubmit = (values: AdminProfyleReportUpdateValues) => {
    const { documentFile } = values;
    if (documentFile) {
      const input = { id, skillsetIds: [parseInt(values.skillsetId)], documentFile };
      adminProfyleReportUpdateWithDocument({ variables: { input } }).then(() => {
        history.push(PATH_UPDATE_LANDING);
      });
    } else {
      const input = { id, ...omit(['skillsetId'], values), skillsetIds: [parseInt(values.skillsetId)] };
      adminProfyleReportUpdate({ variables: { input } }).then(() => {
        history.push(PATH_UPDATE_LANDING);
      });
    }
  };

  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const createOnChangeFile = (fieldName: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const el = e.currentTarget;
    form.setFieldValue(fieldName, el.files ? el.files[0] : null);
  };

  if (loadingSkillsets || loadingReport) {
    return <Loading />;
  }

  // Run only on first render after loading
  if (!refOnce.current) {
    refOnce.current = true;
    const values: AdminProfyleReportUpdateValues = {
      ...pick(Object.keys(initialValues), dataReport!.adminProfyleReportGet),
      skillsetId: String(dataReport!.adminProfyleReportGet.skillsets?.[0]?.id) || '',
    };
    values.scoreRules = dataReport!.adminProfyleReportGet.scoreRules.map((sr) => pick(['rule'], sr));
    values.toOptimiseLowReading =
      values.toOptimiseLowReading?.map((r) => ({
        id: r.id,
        title: r.title,
        author: r.author,
        url: r.url,
        imageUrl: r.imageUrl,
      })) || [];

    values.toOptimiseHighReading =
      values.toOptimiseHighReading?.map((r) => ({
        id: r.id,
        title: r.title,
        author: r.author,
        url: r.url,
        imageUrl: r.imageUrl,
      })) || [];
    form.setValues(values);
  }

  return (
    <FormWrapper>
      <Form noValidate onSubmit={form.handleSubmit}>
        <Form.Group controlId="skillsetId">
          <Form.Label>Skillsets</Form.Label>
          <Form.Control
            as="select"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.skillsetId || ''}
            isInvalid={!!(form.touched.skillsetId && form.errors.skillsetId)}
            disabled={form.isSubmitting}
            custom
            required
          >
            <option disabled value="" key="placeholder">
              Enter skillset
            </option>
            {skillsets.map((skillset) => (
              <option value={skillset.id} key={skillset.id}>
                {skillset.name}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">{form.errors.skillsetId}</Form.Control.Feedback>
        </Form.Group>

        <FormGroupText field="name" form={form} />

        {range(0, 5).map((i) => (
          <FormGroupText
            key={i}
            field={`scoreRules[${i}].rule`}
            valueGetter={(info: { [field: string]: any }) => info.scoreRules[i]?.rule}
            infoGetter={(info: { [field: string]: any }) => info.scoreRules?.[i]}
            label="Score rule"
            form={form}
          />
        ))}

        <FormGroupText field="text" as="textarea" form={form} />

        <FormGroupText field="focusAreas" as="textarea" form={form} />

        <FormGroupText field="instantActionsHigh" as="textarea" form={form} />

        <FormGroupText field="toOptimiseHighTitle" form={form} />

        <FormGroupText field="toOptimiseHighText" as="textarea" form={form} />

        {(dataReport as any)?.adminProfyleReportGet.toOptimiseHighReading.map((r: IReading, index: number) => (
          <ReadingForm index={index} form={form} type={'High'} key={r.id} />
        ))}

        {/* New High book */}
        <ReadingForm
          index={dataReport?.adminProfyleReportGet.toOptimiseHighReading.length || 0}
          form={form}
          type={'High'}
        />

        <FormGroupText field="instantActionsLow" as="textarea" form={form} />

        <FormGroupText field="toOptimiseLowTitle" form={form} />

        <FormGroupText field="toOptimiseLowText" as="textarea" form={form} />

        {dataReport?.adminProfyleReportGet.toOptimiseLowReading.map((r: IReading, index: number) => (
          <ReadingForm index={index} form={form} type={'Low'} key={r.id} />
        ))}

        {/* New Low book */}
        <ReadingForm
          index={dataReport?.adminProfyleReportGet.toOptimiseLowReading.length || 0}
          form={form}
          type={'Low'}
        />

        <Form.Group controlId="documentFile">
          <Form.Label>Document with text report, focus areas and score rules</Form.Label>
          <Form.File
            placeholder="Browse spreadsheet"
            onChange={createOnChangeFile('documentFile')}
            onBlur={form.handleBlur}
            isInvalid={!!(form.touched.documentFile && form.errors.documentFile)}
            disabled={form.isSubmitting}
            accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
          <Form.Control.Feedback type="invalid">{form.errors.documentFile}</Form.Control.Feedback>
        </Form.Group>

        <FormButton type="submit" form={form}>
          Submit
        </FormButton>
      </Form>
    </FormWrapper>
  );
}

export { ReportsEditForm };
