import React from 'react';
import { Form } from 'react-bootstrap';
import { FormikValues } from 'formik';

import { UseFormikReturnType } from '../../formik';
import { toLabel } from '../../helper';

interface Props<FormValues> {
  field: string;
  form: UseFormikReturnType<FormValues>;
  options: string[];
  valueGetter?: (values: FormValues) => any;
  infoGetter?: (info: any) => any;
  label?: string;
  toOptionLabel?: (option: string | undefined) => string;
}

function FormGroupSelect<FormValues extends FormikValues = FormikValues>(props: Props<FormValues>) {
  const { field, form, valueGetter, infoGetter, label, toOptionLabel, options } = {
    valueGetter: (values: FormValues) => values[props.field],
    infoGetter: (info: any) => info[props.field],
    label: toLabel(props.field),
    toOptionLabel: toLabel,
    ...props,
  };

  return (
    <Form.Group controlId={field}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="select"
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        value={valueGetter(form.values)}
        isInvalid={!!(infoGetter(form.touched) && infoGetter(form.errors))}
        disabled={form.isSubmitting}
        custom
        required
      >
        <option disabled value="" key="placeholder">
          Select {label.toLocaleLowerCase()}
        </option>
        {options.map((o) => (
          <option value={o} key={o}>
            {toOptionLabel(o)}
          </option>
        ))}
      </Form.Control>

      <Form.Control.Feedback type="invalid">{infoGetter(form.errors)}</Form.Control.Feedback>
    </Form.Group>
  );
}

export { FormGroupSelect };
