import { useCurrentUser } from './use-current-user';
import { hasRole } from './has-role';
import { RoleName } from './role-name';

const useHasRole = (role: RoleName | RoleName[]) => {
  const { data: user } = useCurrentUser();

  if (!user) {
    return false;
  }

  return hasRole(user.userCurrentGet, role);
};

export { useHasRole };
