import { Route } from 'react-router-dom';
import React from 'react';

import { ProtectedRoute } from '../shared/auth';

import { MyInfo } from './my-info';
import { passwordRoute } from './password';

function myInfoRoute() {
  return (
    <ProtectedRoute
      path="/my-info"
      render={(routeProps) => (
        <>
          <Route path={routeProps.match.url} exact>
            <MyInfo />
          </Route>
          {passwordRoute(routeProps)}
        </>
      )}
    />
  );
}

export { myInfoRoute };
