import React from 'react';
import gql from 'graphql-tag';
import { splitEvery } from 'ramda';

import { toLines, toParagraphs } from '../../../../shared/helper';

import { AdminProfyleReportGet_adminProfyleReportGet_scoreRules } from './__generated__/AdminProfyleReportGet';
import { AdminView } from '../../../../shared/ui';
import {
  IReading,
  Reading,
  ReadingRow,
} from '../../../../profyle/profyle-chosen/tab-instant-action/tab-instant-action';
import { Col } from 'react-bootstrap';

const REPORT_GET = gql`
  query AdminProfyleReportGet($id: Int!) {
    adminProfyleReportGet(id: $id) {
      id
      name
      text
      focusAreas
      instantActionsLow
      instantActionsHigh
      toOptimiseHighReading {
        id
        author
        imageUrl
        title
        url
      }
      toOptimiseHighText
      toOptimiseHighTitle
      toOptimiseLowReading {
        id
        author
        imageUrl
        title
        url
      }
      toOptimiseLowText
      toOptimiseLowTitle
      scoreRules {
        rule
      }
    }
  }
`;

const BREADCRUMBS = [
  { path: '/admin/profyles', label: 'Profyles' },
  { path: '/admin/profyles/reports', label: 'Reports' },
  { label: 'View report' },
];

const ToOptimiseTextGetter = (value: string) => <ul>{toParagraphs(value, 'li')}</ul>;

const ToOptimiseReadingGetter = (readingsValue: IReading[]) =>
  splitEvery(2, readingsValue).map((readings, i) => (
    <ReadingRow key={i}>
      {readings.map((r: IReading) => (
        <Col key={r.id}>
          <Reading reading={r} />
        </Col>
      ))}
    </ReadingRow>
  ));

const MAPPING = [
  { key: 'id' },
  { key: 'name' },
  {
    key: 'scoreRules',
    getter: (value: AdminProfyleReportGet_adminProfyleReportGet_scoreRules[]) =>
      value.map((scoreRule) => <div key={scoreRule.rule}>{scoreRule.rule}</div>),
  },
  { key: 'text', getter: (value: string) => toLines(value).map((line, i) => <div key={i}>{line}</div>) },
  { key: 'focusAreas', getter: (value: any) => toLines(value).map((line, i) => <div key={i}>{line}</div>) },
  { key: 'instantActionsHigh' },
  { key: 'toOptimiseHighTitle' },
  { key: 'toOptimiseHighText', getter: ToOptimiseTextGetter },
  { key: 'toOptimiseHighReading', getter: ToOptimiseReadingGetter },
  { key: 'instantActionsLow' },
  { key: 'toOptimiseLowTitle' },
  { key: 'toOptimiseLowText', getter: ToOptimiseTextGetter },
  { key: 'toOptimiseLowReading', getter: ToOptimiseReadingGetter },
];

function ReportsView() {
  return <AdminView query={REPORT_GET} dataProp="adminProfyleReportGet" breadcrumbs={BREADCRUMBS} mapping={MAPPING} />;
}

export { ReportsView };
