import React from 'react';
import { Route } from 'react-router-dom';

import { ProtectedRoute } from '../shared/auth';
import { Referral } from './referral';
import { inviteRoute } from './invite';
import { inviteSendingRoute } from './invite-sending';

function referralRoute() {
  return (
    <Route
      path="/share"
      render={(routeProps) => (
        <>
          <ProtectedRoute path={routeProps.match.url} exact>
            <Referral />
          </ProtectedRoute>
          {inviteRoute(routeProps)}
          {inviteSendingRoute(routeProps)}
        </>
      )}
    />
  );
}

export { referralRoute };
