import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';

import { Profyles } from './profyles';
import { reportsRoute } from './reports';

function profylesRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/profyles`}
      render={(routeProps) => (
        <>
          <ProtectedRoute path={routeProps.match.url} exact>
            <Profyles />
          </ProtectedRoute>
          {reportsRoute(routeProps)}
        </>
      )}
    />
  );
}

export { profylesRoute };
