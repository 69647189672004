import React from 'react';

import { LayoutDefault } from '../../shared/ui';
import { PasswordForm } from '../../shared/user';
import { useQueryParams } from '../../shared/router';
import { RoleName } from '../../shared/auth/role-name';
import { useHasRole } from '../../shared/auth/use-has-role';

function Password() {
  const queryParams = useQueryParams();
  const corporateAdmin = useHasRole(RoleName.ROLE_CORPORATE_ADMIN);
  const redirectUrl = corporateAdmin ? '/company-info' : '/profyle';
  const landing = queryParams.get('redirect') || redirectUrl;

  return (
    <LayoutDefault>
      <PasswordForm title="Setup Password" landing={landing} />
    </LayoutDefault>
  );
}

export { Password };
