import React, { useCallback, useMemo, useState } from 'react';
import { GlobalLoading, LayoutDefault, Loading } from '../shared/ui';
import { UsersTable } from './users-table';
import { GetAdminUsers_getAdminUsers, IObject } from './types';
import { ButtonsWrapper, ButtonWarning, LargeButton, SmallButton } from './components';
import { COMPANY_USERS_ADD_PATH, useProps } from './props';
import { useHistory } from 'react-router';
import { ModalRemoveUsers } from './modal-remove-users';
import { ActiveTabEnum, CompanyUsersTabs } from './company-users-tabs';
import { hasRole } from '../shared/auth/has-role';
import { RoleName } from '../shared/auth/role-name';
import { SkillsetListGet_skillsetListGet } from '../shared/skillsets/__generated__/SkillsetListGet';

const ORDER_PATH = 'my-order-enterprise';

export const CompanyUsersList: React.FC<any> = () => {
  const {
    selected,
    setSelected,
    users,
    setUsers,
    loading,
    data,
    refetch,
    changeGroup,
    updateSkillsets,
    skillsetLoading,
    notifySkillsets,
    notifyLoading,
    selectedLength,
    isSendButtonActive,
    changedIds,
  } = useProps();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const history = useHistory();

  const onCheckBoxChange = useCallback(
    (id, checked) => {
      setSelected((state: {}) => ({
        ...state,
        [id]: !checked,
      }));
    },
    [setSelected]
  );

  const onChangeSkillset = useCallback(
    (skillset: SkillsetListGet_skillsetListGet, index: number) => {
      const { id: value, name } = skillset;

      setUsers((state) => {
        const skillsetIndex = state[index]?.skillsets?.findIndex(({ id }) => id === value);

        if (typeof skillsetIndex === 'number' && skillsetIndex > -1) {
          state[index]?.skillsets?.splice(skillsetIndex, 1);
        } else {
          state[index].skillsets = state[index].skillsets || [];
          state[index].skillsets?.push({ id: value, name, __typename: 'Skillset' });
        }
        return [...state];
      });
    },
    [setUsers]
  );

  const onChangeGroup = useCallback(
    (e, index) => {
      const value = typeof e === 'string' ? e : e.target.value;

      setUsers((state) => {
        state[index].companyGroup = value ? { groupName: String(value), __typename: 'CompanyGroup' } : null;

        return [...state];
      });

      const input = {
        users: [
          {
            email: users[index].email || '',
            newGroup: value,
          },
        ],
      };

      const currentData = JSON.parse(JSON.stringify(data || {}));

      changeGroup({ variables: { input } }).catch(() => {
        setUsers(currentData?.getAdminUsers || []);
      });
    },
    [changeGroup, data, setUsers, users]
  );

  const onAllCheckBoxChange = useCallback(
    (type) => {
      if (type === 'reset') setSelected({});
      if (type === 'checked') {
        const state =
          users.reduce(
            (newSelected: IObject, { id }: any) => ({
              ...newSelected,
              [id]: true,
            }),
            {}
          ) || {};
        setSelected(state);
      }
    },
    [setSelected, users]
  );

  const updateUsersSkillsets = useCallback(
    async (indexes) => {
      const input = {
        users: indexes.map((index: any) => {
          const { email, skillsets = [] } = users[index] || {};
          return {
            email,
            skillsets: skillsets ? skillsets.map(({ id }) => id) : [],
          };
        }),
      };

      try {
        await updateSkillsets({ variables: { input } });
        const users = input.users.map(({ email }: any) => ({ email }));
        await notifySkillsets({ variables: { input: { users } } });
      } catch (e) {
        console.log(e);
      }

      const savedUsers = JSON.parse(JSON.stringify(users));

      const { data: newDate = { getAdminUsers: [] } } = await refetch();

      indexes.forEach((index: number) => {
        const { id } = savedUsers[index];
        const updatedUser =
          newDate.getAdminUsers.find(({ id: userId }) => id === userId) || ({} as GetAdminUsers_getAdminUsers);
        savedUsers[index] = JSON.parse(JSON.stringify(updatedUser));
      });

      setUsers([...savedUsers]);
    },
    [notifySkillsets, refetch, setUsers, updateSkillsets, users]
  );

  const send = useCallback(
    (index) => {
      updateUsersSkillsets([index]);
    },
    [updateUsersSkillsets]
  );

  const sendAll = useCallback(() => {
    let indexes;

    if (selectedLength) {
      indexes = Object.keys(selected)
        .map((key) => changedIds[Number(key)])
        .filter((i) => typeof i === 'number');
    } else {
      indexes = Object.values(changedIds);
    }
    updateUsersSkillsets(indexes);
  }, [changedIds, selected, selectedLength, updateUsersSkillsets]);

  const handleDeleteSelectedUsers = useCallback(() => {
    refetch();
    setSelected({});
  }, [refetch, setSelected]);

  const handleContinueClick = useCallback(() => {
    history.push(ORDER_PATH);
  }, [history]);

  const handleDeleteSelectedClick = useCallback(() => {
    setShowDeleteModal(true);
  }, []);

  const handleModalHide = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const selectedUsers = useMemo(() => {
    return Object.entries(selected)
      .filter(([_, value]) => value)
      .map(([id]) => users.find((user) => user.id === Number(id))!);
  }, [selected, users]);

  const isAdminSelected = useMemo(
    () => selectedUsers.some((user) => user?.roles && hasRole(user, RoleName.ROLE_CORPORATE_ADMIN)),
    [selectedUsers]
  );

  return (
    <LayoutDefault>
      {(loading || !users) && <Loading />}
      {!loading && (
        <>
          <h1>
            User <span className="emphasis">Management</span>
          </h1>
          <CompanyUsersTabs activeTab={ActiveTabEnum.USERS} />
          <UsersTable
            data={users}
            onCheckBoxChange={onCheckBoxChange}
            onAllCheckBoxChange={onAllCheckBoxChange}
            onChangeSkillset={onChangeSkillset}
            onChangeGroup={onChangeGroup}
            send={send}
            changedIds={changedIds}
            selected={selected}
            selectedLength={selectedLength}
          />
          <ButtonsWrapper isAdminSelected={isAdminSelected}>
            {!!selectedLength && (
              <SmallButton
                size="sm"
                variant="outline-dark"
                onClick={handleDeleteSelectedClick}
                disabled={isAdminSelected}
              >
                Delete selected
                {isAdminSelected && <ButtonWarning>Admin cannot be deleted</ButtonWarning>}
              </SmallButton>
            )}
            <SmallButton onClick={sendAll} size="sm" disabled={!isSendButtonActive}>
              {!selectedLength ? 'Send to all' : 'Send to selected'}
            </SmallButton>
          </ButtonsWrapper>
          <div className="d-flex justify-content-end">
            <LargeButton onClick={handleContinueClick}>Continue</LargeButton>
          </div>
        </>
      )}
      <ModalRemoveUsers
        show={showDeleteModal}
        onHide={handleModalHide}
        selectedUsers={selectedUsers}
        onRemove={handleDeleteSelectedUsers}
      />
      <GlobalLoading loading={notifyLoading || skillsetLoading || loading} />
    </LayoutDefault>
  );
};
