import styled from 'styled-components';

const TabBody = styled.div`
  padding: 20px 20px;

  border: 1px solid var(--profyle-gray-300);
  background-color: var(--profyle-profyle-color-bg);
`;

export { TabBody };
