import React, { ReactText } from 'react';
import { LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip, Dot, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import classNames from 'classnames';
import useMediaQuery from '../../../../shared/ui/use-media-query/use-media-query';

const hereColor = '#c40000';

const WrappedDot = (p: { className?: string }) => (
  <g className={p.className}>
    <Dot {...p} />
  </g>
);
const CompareDot = styled<any>(WrappedDot).attrs((props) => ({
  className: classNames(
    props.className,
    {
      youAreHere: props.payload?.scoreValue === props.userScoreValue,
    },
    props.property
  ),
}))`
  & circle.youAreHere {
    r: 5;
  }

  & circle.youAreHere.stroke {
    stroke: ${hereColor};
  }

  & circle.youAreHere.fill {
    fill: ${hereColor};
  }
`;

const YouAreHere = styled.span`
  display: block;
  color: ${hereColor};
`;

interface LabelProps {
  label: ReactText;
  userLabel: ReactText;
}

const Label = (props: LabelProps) => (
  <>
    {props.label === props.userLabel && (
      <>
        <YouAreHere>You Are Here</YouAreHere>
      </>
    )}
    {props.label} {props.label === 1 ? 'point' : 'points'}
  </>
);

interface Props {
  analyticsHistoryAggreagation: { scoreValue: number; userPercent: number }[];
  userScoreValue: number;
}

function GraphicCompareYourself(props: Props) {
  const isSmall = useMediaQuery('(max-width: 767px)');
  const width = isSmall ? '100%' : 500;

  return (
    <ResponsiveContainer width={width} height={300}>
      <LineChart data={props.analyticsHistoryAggreagation}>
        <XAxis dataKey="scoreValue" name="Score Value" />
        <YAxis unit="%" />
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <Tooltip labelFormatter={(l) => <Label label={l} userLabel={props.userScoreValue} />} />
        <Line
          dataKey="userPercent"
          name="User Percent"
          unit="%"
          dot={(p) => <CompareDot {...p} userScoreValue={props.userScoreValue} property="stroke" />}
          activeDot={(p) => <CompareDot {...p} userScoreValue={props.userScoreValue} property="fill" />}
          type="monotone"
          stroke="#ffbe17"
          strokeWidth={1}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

export { GraphicCompareYourself };
