import React from 'react';
import gql from 'graphql-tag';

import { AdminList } from '../../../shared/ui';

import { AdminProfyleReportListGet_adminProfyleReportListGet } from './__generated__/AdminProfyleReportListGet';
import { toSkillsetNames } from '../../../shared/profyle';

const REPORT_LIST_GET = gql`
  query AdminProfyleReportListGet {
    adminProfyleReportListGet {
      id
      name
      skillsets {
        name
      }
      scoreRules {
        rule
      }
    }
  }
`;

const REPORT_DELETE = gql`
  mutation AdminProfyleReportDelete($id: Int!) {
    adminProfyleReportDelete(id: $id) {
      id
    }
  }
`;

const BREADCRUMBS = [{ path: '/admin/profyles', label: 'Profyles' }, { label: 'Reports' }];

const MAPPING = [
  { label: 'Name', getter: (value: AdminProfyleReportListGet_adminProfyleReportListGet) => value.name },
  {
    label: 'Profyle Role',
    getter: (value: AdminProfyleReportListGet_adminProfyleReportListGet) => toSkillsetNames(value.skillsets),
  },
  {
    label: 'Score Rules',
    getter: (value: AdminProfyleReportListGet_adminProfyleReportListGet) =>
      value.scoreRules.map((scoreRule) => <div key={scoreRule.rule}>{scoreRule.rule}</div>),
  },
];

function Reports() {
  return (
    <AdminList
      listQuery={REPORT_LIST_GET}
      deleteMutation={REPORT_DELETE}
      dataProp="adminProfyleReportListGet"
      breadcrumbs={BREADCRUMBS}
      mapping={MAPPING}
    />
  );
}

export { Reports };
