import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../shared/ui';

export const SmallButton = styled(Button)`
  width: 147px;
  font-weight: normal;
  margin-right: 16px;
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 160%;
  color: rgba(101, 105, 126, 0.5);
  margin: 0;
`;

export const CustomLink = styled(Link)`
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
`;
