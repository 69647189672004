import { Parser } from '../../../with-types/html-to-react';

interface Props {
  code: string;
}

function Html(props: Props) {
  const parser = new Parser();
  return parser.parse(props.code);
}

export { Html };
