import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import { FileUpload } from '../../shared/ui';
import uploadIcon from '../../shared/icons/upload-icon.svg';
import { NotificationManager } from 'react-notifications';

interface IProps {
  mutate: (options?: any) => Promise<any>;
  onEnd: () => void;
  loading: boolean;
}

export const ImportUsers: React.FC<IProps> = ({ mutate, onEnd, loading }) => {
  const [droppedFile, setDroppedFile] = useState<File>();
  const [progress, setProgress] = useState<number>(0);

  const handleFileDrop = useCallback(
    (item) => {
      const file = item?.files?.[0];
      if (file) {
        setDroppedFile(file);
      }
    },
    [setDroppedFile]
  );

  const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget?.files?.[0];
    if (file) {
      setDroppedFile(file);
    }
  }, []);

  useEffect(() => {
    if (!droppedFile) return;

    let abort: any;
    mutate({
      variables: { input: { documentFile: droppedFile } },
      context: {
        fetchOptions: {
          onProgress: (ev: ProgressEvent) => {
            setProgress(Math.round((ev.loaded / ev.total) * 100));
          },
          onAbortPossible: (abortHandler: any) => {
            abort = abortHandler;
          },
        },
      },
    })
      .then((e) => {
        console.log(e);
        NotificationManager.success('Users added successfully');
        // onEnd();
      })
      .catch(() => {
        NotificationManager.error('There was a failure during upload');
        setProgress(0);
      });

    return () => {
      if (abort) {
        abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [droppedFile]);

  return (
    <div>
      <FileUpload
        onDrop={handleFileDrop}
        onChange={handleFileChange}
        buttonText="Add a file"
        supportedText="Files are supported: zip, 7-zip, excel"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        progress={progress}
        loading={loading}
      />
      <a
        href="/files/Profyle_invite_corp_core_V2.xlsx"
        target="_blank"
        rel="noopener noreferrer"
        className="d-block mt-3 mb-5"
      >
        <img src={uploadIcon} />
        <span className="ml-3">Download the template for the file</span>
      </a>
    </div>
  );
};
