import React from 'react';
import gql from 'graphql-tag';
import { Link, useRouteMatch } from 'react-router-dom';

import { AdminList } from '../../../shared/ui';
import { trimUrlLastPart } from '../../../shared/helper';

import { AdminQuizListGet_adminQuizListGet } from './__generated__/AdminQuizListGet';

const QUIZ_LIST_GET = gql`
  query AdminQuizListGet {
    adminQuizListGet {
      id
      skillsets {
        name
      }
    }
  }
`;

const QUIZ_DELETE = gql`
  mutation AdminQuizDelete($id: Int!) {
    adminQuizDelete(id: $id) {
      id
    }
  }
`;

const MAPPING = [
  { label: 'Quiz Id', getter: (value: AdminQuizListGet_adminQuizListGet) => value.id },
  {
    label: 'Skillset',
    getter: (value: AdminQuizListGet_adminQuizListGet) => value.skillsets.map((skillset) => skillset.name).join(', '),
  },
];

const BREADCRUMBS = [{ path: '/admin/quizzes', label: 'Quizzes & Questions' }, { label: 'Quizzes' }];

function QuizzesQuizzes() {
  const routeMatch = useRouteMatch();

  return (
    <AdminList
      listQuery={QUIZ_LIST_GET}
      deleteMutation={QUIZ_DELETE}
      dataProp="adminQuizListGet"
      breadcrumbs={BREADCRUMBS}
      mapping={MAPPING}
      additionalActions={(value) => (
        <>
          <div>
            <Link to={`${trimUrlLastPart(routeMatch.path)}/questions?quizId=${value.id}`}>Questions</Link>
          </div>
        </>
      )}
      viewLinkDisable
    />
  );
}

export { QuizzesQuizzes };
