import { splitEvery } from 'ramda';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';

import { TabBody, TabActions } from '../../../shared/ui';
import { toProfyleSkillsetNames } from '../../../shared/profyle';
import { toParagraphs } from '../../../shared/helper';

const ReadingContainer = styled(Container)`
  margin: 25px 0 40px;
`;

export const ReadingRow = styled(Row)`
  margin-bottom: 20px;
`;

const ReadingContent = styled.div`
  overflow: hidden;
`;

const ReadingImage = styled.img`
  float: left;
  max-width: 150px;
  max-height: 200px;
  margin-right: 20px;
`;

const ReadingDesc = styled.div``;

export interface IReading {
  id: number;
  title: string;
  author: string;
  url: string | null;
  imageUrl: string | null;
}

interface Params {
  profyle: {
    report: {
      instantActionsLow: string | null;
      instantActionsHigh: string | null;
      toOptimiseLowTitle: string | null;
      toOptimiseLowText: string | null;
      toOptimiseLowReading: IReading[] | null;
      toOptimiseHighTitle: string | null;
      toOptimiseHighText: string | null;
      toOptimiseHighReading: IReading[] | null;
    };
    result: {
      quiz: {
        skillsets: {
          name: string;
        }[];
      };
    };
  };
  nextUrl: string;
}

function ReadingLink(params: { url: string | null | undefined; children: ReactNode }) {
  if (!params.url) {
    return <>{params.children}</>;
  }

  return (
    <a href={params.url} target="_blank" rel="noopener noreferrer">
      {params.children}
    </a>
  );
}

export function Reading(params: { reading: IReading }) {
  return (
    <ReadingLink url={params.reading.url}>
      <ReadingContent>
        {params.reading.imageUrl && (
          <ReadingImage alt={`${params.reading.title} Picture`} src={params.reading.imageUrl} />
        )}
        <ReadingDesc>
          {params.reading.title}
          <br />
          {params.reading.author}
        </ReadingDesc>
      </ReadingContent>
    </ReadingLink>
  );
}

function Readings(params: { readings: IReading[] }) {
  if (!params.readings.length) {
    return null;
  }

  return (
    <>
      <h4>Reading</h4>
      If reading is your thing, here are some books that Profyle has found that might be helpful.
      <ReadingContainer>
        {splitEvery(2, params.readings).map((readings, i) => (
          <ReadingRow key={i}>
            {readings.map((r) => (
              <Col key={r.title}>
                <Reading reading={r} />
              </Col>
            ))}
          </ReadingRow>
        ))}
      </ReadingContainer>
    </>
  );
}

function ToOptimise(params: { title: string; text: string }) {
  return (
    <>
      <h4>{params.title}</h4>
      <ul>{toParagraphs(params.text, 'li')}</ul>
    </>
  );
}

function TabInstantAction(params: Params) {
  const skillsetNames = toProfyleSkillsetNames(params.profyle);
  const report = params.profyle.report;

  return (
    <TabBody>
      <p>
        Profyle has worked out that there are some simple but practical things you can start doing right now to become a
        more effective {skillsetNames}. Pick and chose from following list the items that most appeal to you.
      </p>

      {report.instantActionsHigh && (
        <>
          <h4>Your Profyle Instant Actions</h4>
          {toParagraphs(report.instantActionsHigh)}
        </>
      )}

      {report.toOptimiseHighTitle && (
        <>
          <ToOptimise title={report.toOptimiseHighTitle} text={report.toOptimiseHighText!} />
          <Readings readings={report.toOptimiseHighReading!} />
        </>
      )}

      {report.instantActionsLow && (
        <>
          <h4>Your Profyle Instant Actions</h4>
          {toParagraphs(report.instantActionsLow)}
        </>
      )}

      {report.toOptimiseLowTitle && (
        <>
          <ToOptimise title={report.toOptimiseLowTitle} text={report.toOptimiseLowText!} />
          <Readings readings={report.toOptimiseLowReading!} />
        </>
      )}

      <TabActions>
        <Link to={params.nextUrl}>Next</Link>
      </TabActions>
    </TabBody>
  );
}

export { TabInstantAction };
