import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../button';

const COOKIE_STORAGE_KEY = 'acceptCookie';
const COOKIE_POLICY_URL = 'https://www.dropbox.com/s/6stz17fbb8yi845/Cookie%20Policy.pdf?dl=0';

const getCookie = (): boolean => {
  const cookie = localStorage.getItem(COOKIE_STORAGE_KEY);
  if (cookie === 'true') {
    return true;
  }

  return false;
};

const setCookie = (cookie: string): void => {
  localStorage.setItem(COOKIE_STORAGE_KEY, cookie);
};

const Banner = styled('div')`
  padding: 24px;
  position: sticky;
  bottom: 0;
  border: 1px solid var(--gray);
  background: var(--white);
  z-index: 1;
  display: flex;
  justify-content: space-between;
`;

const AcceptButton = styled(Button)`
  margin-left: 24px;
`;

function CookieBanner() {
  const [isCookieAccepted, setIsCookieAccepted] = useState(getCookie);

  const handleCloseClick = useCallback(() => {
    setCookie('true');
    setIsCookieAccepted(true);
  }, []);

  if (isCookieAccepted) {
    return null;
  }

  return (
    <Banner>
      <div>
        This site uses cookies to improve our site and provide content and ads that might interest you. By using this
        site, you consent to our use of cookies as described in our{' '}
        <a href={COOKIE_POLICY_URL} target="_blank" rel="noopener noreferrer">
          COOKIE POLICY
        </a>
        .
      </div>
      <AcceptButton onClick={handleCloseClick}>Accept &amp; Close</AcceptButton>
    </Banner>
  );
}

export { CookieBanner };
