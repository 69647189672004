import React from 'react';

import { LayoutDefault } from '../shared/ui';
import { FormSignIn } from '../shared/sign-in-up';

function SignIn() {
  return (
    <LayoutDefault>
      <div className="w-50-resp m-auto">
        <h2>Sign In</h2>
        <FormSignIn />
      </div>
    </LayoutDefault>
  );
}

export { SignIn };
