import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { Button } from '../../shared/ui';

const StyledModal = styled(Modal)`
  & > div {
    width: 420px;
    max-width: 420px;
    @media (max-width: 767px) {
      width: auto;
    }
  }
`;

const StyledModalBody = styled(Modal.Body)`
  padding: 48px 64px 64px 64px;
  @media (max-width: 767px) {
    padding: 40px 20px;
  }
`;

const StyledModalHeader = styled(Modal.Header)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  border: 0;
`;

const BodyMainText = styled.p`
  font-size: 20px;
  margin-bottom: 24px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 48px;

  & > button {
    flex: 1;
  }

  & > button:first-child {
    margin-right: 8px;
  }

  & > button:last-child {
    margin-left: 8px;
  }
`;

interface IProps {
  show: boolean;
  headerText: string;
  bodyText: ReactNode;
  confirmText?: string;
  hideNegativeButton?: boolean;
  onHide?: () => void;
  onConfirm: (e: React.MouseEvent<HTMLElement>) => void;
}

const DEFAULT_CONFIRM_TEXT = 'Yes';

export const ConfirmModal: React.FC<IProps> = ({
  show,
  headerText,
  bodyText,
  confirmText,
  hideNegativeButton,
  onHide,
  onConfirm,
}) => {
  return (
    <StyledModal show={show} onHide={onHide} centered>
      <StyledModalHeader />
      <StyledModalBody>
        <h2>{headerText}</h2>
        <BodyMainText>{bodyText}</BodyMainText>
        <ButtonsWrapper>
          {!hideNegativeButton && <Button onClick={onHide}>No</Button>}
          <Button onClick={onConfirm} variant={hideNegativeButton ? 'primary' : 'secondary'}>
            {confirmText || DEFAULT_CONFIRM_TEXT}
          </Button>
        </ButtonsWrapper>
      </StyledModalBody>
    </StyledModal>
  );
};
