import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';

import { EmailConfirmed } from './email-confirmed';

function emailConfirmedRoute(routeProps: RouteComponentProps) {
  return (
    <ProtectedRoute notAuth path={`${routeProps.match.url}/email-confirmed`}>
      <EmailConfirmed />
    </ProtectedRoute>
  );
}

export { emailConfirmedRoute };
