import gql from 'graphql-tag';

export const CONSOLIDATED_ORDER = gql`
  query ConsolidatedOrder {
    consolidatedOrder {
      id
      userAmount
      skillsetAmount
      orderAmount
      isPaid
    }
  }
`;
