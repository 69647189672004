import React, { useRef, useEffect } from 'react';
import { select, arc, interpolateString, easeElasticOut } from 'd3';
import styled from 'styled-components';
import { prop } from 'ramda';

import { calcMaxScoreDiff } from '../../shared/calc-max-score-diff';

import { GraphicFilterInnerShadow } from '../graphic-filter-inner-shadow';

const GRAPHIC_SIZE_DEGREES = 180;
const ARC_WIDTH = 30;
const ARC_INNER_RADIUS = 130;
const ARC_OUTER_RADIUS = ARC_INNER_RADIUS + ARC_WIDTH;
const SECTOR_SEPARATOR_DEGREES = 2;
const SECTORS = [
  {
    parts: 2,
    color: '#ffdb80',
    textLabelX: 60,
    textLabelY: 12,
  },
  {
    parts: 9,
    color: '#ffd56b',
    textLabelX: 159,
    textLabelY: 227,
  },
  {
    parts: 15,
    color: '#ffbe17',
    textLabelX: 0,
    textLabelY: 358,
  },
];
const SECTOR_MAX = SECTORS[SECTORS.length - 1];
const SECTOR_ONE_DEGREES = GRAPHIC_SIZE_DEGREES / SECTOR_MAX.parts;

const degreesToRadians = (degrees: number): number => (degrees * Math.PI) / 180;

const ArcG = styled.g``;

const LegendG = styled.g`
  & text {
    font-family: var(--profyle-headings-font-family);
    font-size: var(--profyle-h3-font-size);
    font-weight: var(--profyle-headings-font-weight);
    fill: var(--dark);
  }
`;

interface Props {
  assertion: number;
  consideration: number;
  emotionalManagement: number;
  extroversion: number;
  className?: string;
}

function GraphicScoreDiff(props: Props) {
  const maxDistanceScore = calcMaxScoreDiff(
    props.assertion,
    props.consideration,
    props.emotionalManagement,
    props.extroversion
  );
  const rotateDegrees = SECTOR_ONE_DEGREES * maxDistanceScore;

  const refArcGroup = useRef<SVGSVGElement>(null);
  const refLegendGroup = useRef<SVGSVGElement>(null);
  const refArrowGroup = useRef<SVGSVGElement>(null);

  useEffect(() => {
    // Arcs

    const arcGenerator = arc().innerRadius(ARC_INNER_RADIUS).outerRadius(ARC_OUTER_RADIUS);

    const arcData = SECTORS.map((sector, i) => {
      const startAngleDegrees = (i === 0 ? 0 : SECTORS[i - 1].parts) * SECTOR_ONE_DEGREES;
      const endAngleDegrees = sector.parts * SECTOR_ONE_DEGREES;
      return {
        color: sector.color,
        padAngle: degreesToRadians(SECTOR_SEPARATOR_DEGREES),
        startAngle: degreesToRadians(startAngleDegrees),
        endAngle: degreesToRadians(endAngleDegrees),
      };
    });

    select(refArcGroup.current)
      .selectAll('path')
      .data(arcData)
      .enter()
      .append('path')
      .attr('d', arcGenerator as any)
      .attr('fill', prop('color'))
      .attr('style', 'filter:url(#graphic-score-diff-inner-shadow)');

    // Legend

    const legendData = [
      {
        label: '0',
        x: 0,
        y: 0,
      },
      ...SECTORS.map((sector) => {
        return {
          label: String(sector.parts),
          x: sector.textLabelX,
          y: sector.textLabelY,
        };
      }),
    ];

    select(refLegendGroup.current)
      .selectAll('text')
      .data(legendData)
      .enter()
      .append('text')
      .attr('x', prop('x'))
      .attr('y', prop('y'))
      .text(prop('label'));
  }, []);

  useEffect(() => {
    const transformTween = () => {
      return (d: any, index: number, groups: ArrayLike<SVGSVGElement | null>) => {
        return interpolateString(
          groups[index]?.getAttribute('transform') || 'rotate(0)',
          `rotate(${rotateDegrees}, 21, 114)`
        );
      };
    };

    setTimeout(() => {
      select(refArrowGroup.current)
        .transition()
        .ease(easeElasticOut.amplitude(1).period(0.5))
        .duration(1500)
        .attrTween('transform', transformTween());
    }, 1000);
  });

  return (
    <div className={props.className}>
      <svg viewBox="0 0 220 385">
        <ArcG ref={refArcGroup} transform="translate(30, 190)" />
        <LegendG ref={refLegendGroup} transform="translate(35, 21)" />

        <g transform="translate(13, 80)">
          <g ref={refArrowGroup}>
            <svg height="135" width="44" fill="none">
              <g filter="url(#filter0_ii)">
                <mask id="a" fill="#fff">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M43.206 107.512c.403 1.653.616 3.38.616 5.157 0 11.979-9.71 21.69-21.69 21.69-11.979 0-21.69-9.711-21.69-21.69 0-1.777.214-3.504.617-5.157L21.149.97c.205-1.086 1.761-1.086 1.966 0l20.09 106.542zm-21.074 16.001c-5.99 0-10.845-4.855-10.845-10.845 0-5.989 4.856-10.845 10.845-10.845 5.99 0 10.845 4.856 10.845 10.845 0 5.99-4.855 10.845-10.845 10.845z"
                  />
                </mask>
                <path
                  d="M43.822 97.668c8.284 0 15 6.716 15 15.001 0 8.284-6.716 15-15 15v-30zM32.977 127.67c-8.284 0-15-6.716-15-15 0-8.285 6.716-15 15-15v30zm-21.69-30c8.284 0 15 6.715 15 15 0 8.284-6.715 15-15 15v-30zm-10.845 30c-8.284 0-15-6.716-15-15 0-8.285 6.716-15 15-15v30zm43.38 0H32.977v-30h10.845v30zm-32.535 0H.442v-30h10.845v30zm31.919-20.157L14.06 114.62a30.126 30.126 0 01-.335-1.548l29.48-5.559zm-42.147 0l29.48 5.559a30.02 30.02 0 01-.334 1.548l-29.146-7.107zM21.149.97L-8.33-4.59 21.149.97zm1.966 0l29.48-5.56L23.115.97zm-9.293 111.699c0 .636.078 1.294.238 1.95l58.292-14.213a51.742 51.742 0 011.47 12.263h-60zm8.31-8.31a8.31 8.31 0 00-8.31 8.31h60c0 28.547-23.142 51.69-51.69 51.69v-60zm8.31 8.31a8.31 8.31 0 00-8.31-8.31v60c-28.548 0-51.69-23.143-51.69-51.69h60zm-.237 1.95a8.25 8.25 0 00.237-1.95h-60c0-4.19.505-8.302 1.47-12.264l58.293 14.214zM50.63 6.529L30.539 113.071l-58.96-11.118L-8.332-4.589l58.96 11.118zm-56.996 0c5.94 31.502 51.056 31.502 56.996 0L-8.331-4.589c6.35-33.674 54.576-33.674 60.926 0L-6.365 6.529zm20.091 106.542L-6.365 6.529l58.96-11.118 20.091 106.542-58.96 11.118zm27.562-.403c0-10.579-8.576-19.155-19.155-19.155v60c-22.558 0-40.845-18.287-40.845-40.845h60zm-19.155 19.155c10.58 0 19.155-8.576 19.155-19.155h-60c0-22.558 18.287-40.845 40.845-40.845v60zM2.977 112.668c0 10.579 8.576 19.155 19.155 19.155v-60c22.558 0 40.845 18.287 40.845 40.845h-60zm19.155-19.155c-10.579 0-19.155 8.576-19.155 19.155h60c0 22.558-18.287 40.845-40.845 40.845v-60z"
                  fill="#212121"
                  mask="url(#a)"
                />
              </g>
              <defs>
                <filter
                  id="filter0_ii"
                  x="-3.558"
                  y="-3.845"
                  width="51.38"
                  height="142.204"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="4" dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                  <feColorMatrix values="0 0 0 0 0.23 0 0 0 0 0.23 0 0 0 0 0.23 0 0 0 1 0" />
                  <feBlend in2="shape" result="effect1_innerShadow" />
                  <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="-4" dy="-4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                  <feColorMatrix values="0 0 0 0 0.03 0 0 0 0 0.03 0 0 0 0 0.03 0 0 0 1 0" />
                  <feBlend in2="effect1_innerShadow" result="effect2_innerShadow" />
                </filter>
              </defs>
            </svg>
          </g>
        </g>

        <defs>
          <GraphicFilterInnerShadow id="graphic-score-diff-inner-shadow" />
        </defs>
      </svg>
    </div>
  );
}

export { GraphicScoreDiff };
