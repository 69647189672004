import React from 'react';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { UserInviteInput } from '../../../../__generated__/globalTypes';
import { User360Invite, User360InviteVariables } from './__generated__/User360Invite';

import { InviteFormLayout } from '../../shared/invite';

const PATH_USER_360_INVITE_LANDING = '/profyle-360/invite-sending';

const USER_360_INVITE = gql`
  mutation User360Invite($input: UserInviteInput!) {
    user360Invite(input: $input) {
      id
    }
  }
`;

function Profyle360Form() {
  const history = useHistory();

  const [user360Invite] = useMutation<User360Invite, User360InviteVariables>(USER_360_INVITE);

  const onSubmit = (values: UserInviteInput) => {
    user360Invite({ variables: { input: values } }).then(() => {
      history.push(PATH_USER_360_INVITE_LANDING);
    });
  };

  return (
    <>
      <h2>Profyle 360</h2>

      <p>
        This is the place where you can share your Profyle with people who know you well and get their feedback. All you
        need to do is enter their name and email details below and Profyle will invite them.
      </p>
      <p>
        You can add as many as you like, but typically people choose between 3 to 5 people. Most people chose a few
        colleagues, sometimes their boss and often their partner or best friend to get that valuable external view.
      </p>
      <p>Each time one of your chosen people responds, Profyle will let you know.</p>

      <InviteFormLayout onSubmit={onSubmit} />
    </>
  );
}

export { Profyle360Form };
