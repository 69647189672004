import React from 'react';
import { Link } from 'react-router-dom';

import { LayoutDefault } from '../../shared/ui';

function ReviewFinish() {
  return (
    <LayoutDefault>
      <h2>Review is finished</h2>
      <p>
        Thank you for your review. You can find more Profyles to review <Link to="/feedback">here</Link>.
      </p>
    </LayoutDefault>
  );
}

export { ReviewFinish };
