import React from 'react';
import { LayoutDefault, Loading } from '../../shared/ui';
import { MyOrderForm } from '../../my-order-enterprise/my-order-form';
import { useCompanyOrderById } from '../hooks/useCompanyOrderById';

export const CompanyOrder: React.FC<{
  orderId: number;
}> = ({ orderId }) => {
  const { loading, data } = useCompanyOrderById(orderId);
  return <LayoutDefault>{loading ? <Loading /> : <MyOrderForm order={data!.getCompanyOrderById} />}</LayoutDefault>;
};
