import React from 'react';

import { ProtectedRoute } from '../shared/auth';

import { Admin } from './admin';
import { quizzesRoute } from './quizzes';
import { profylesRoute } from './profyles';
import { companiesRoute } from './companies';
import { skillsetsRoute } from './skillsets';
import { usersRoute } from './users';
import { textsRoute } from './texts';
import { paymentLogsRoute } from './payment-logs';
import { ordersRoute } from './orders';
import { settingsRoute } from './settings';
import { salesRoute } from './sales-report';
import { RoleName } from '../shared/auth/role-name';

function adminRoute() {
  return (
    <ProtectedRoute
      role={RoleName.ROLE_ADMIN}
      path="/admin"
      render={(routeProps) => (
        <>
          <ProtectedRoute path={routeProps.match.url} exact>
            <Admin />
          </ProtectedRoute>
          {quizzesRoute(routeProps)}
          {profylesRoute(routeProps)}
          {skillsetsRoute(routeProps)}
          {usersRoute(routeProps)}
          {companiesRoute(routeProps)}
          {textsRoute(routeProps)}
          {settingsRoute(routeProps)}
          {paymentLogsRoute(routeProps)}
          {ordersRoute(routeProps)}
          {salesRoute(routeProps)}
        </>
      )}
    />
  );
}

export { adminRoute };
