import React from 'react';

import { LayoutDefault, AdminBreadcrumbs } from '../../shared/ui';
import { Link } from 'react-router-dom';

const BREADCRUMBS = [{ label: 'Profyles' }];

function Profyles() {
  return (
    <LayoutDefault>
      <h2>Profyles</h2>
      <AdminBreadcrumbs breadcrumbs={BREADCRUMBS} />
      <ul>
        <li>
          <Link to="/admin/profyles/reports">Reports</Link>
        </li>
      </ul>
    </LayoutDefault>
  );
}

export { Profyles };
