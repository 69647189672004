import React, { useCallback } from 'react';
import { Table } from 'react-bootstrap';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Link, useHistory } from 'react-router-dom';

import { Loading } from '../shared/ui';
import { toProfyleSkillsetNames } from '../shared/profyle';
import { toLabel } from '../shared/helper';

import {
  Profyle360ReviewRequestAssignedListGet,
  Profyle360ReviewRequestAssignedListGet_profyle360ReviewRequestAssignedListGet,
  Profyle360ReviewRequestAssignedListGet_profyle360ReviewRequestAssignedListGet_requestProfyles,
} from './__generated__/Profyle360ReviewRequestAssignedListGet';

import { RequestProfyleWithUser, toRequestProfylesWithRequester } from '../shared/request';
import { ReviewRequestStatus } from '../../../__generated__/globalTypes';

type RequestProfyleWithRequester = RequestProfyleWithUser<
  'requester',
  Profyle360ReviewRequestAssignedListGet_profyle360ReviewRequestAssignedListGet,
  Profyle360ReviewRequestAssignedListGet_profyle360ReviewRequestAssignedListGet_requestProfyles
>;

const REQUEST_ASSIGNED_LIST_GET = gql`
  query Profyle360ReviewRequestAssignedListGet {
    profyle360ReviewRequestAssignedListGet {
      id
      requester {
        id
        firstName
        lastName
      }
      requestProfyles {
        id
        status
        profyle {
          id
          result {
            quiz {
              skillsets {
                name
              }
            }
          }
        }
      }
    }
  }
`;

function YourFeedbackAboutOthers() {
  const history = useHistory();

  const { loading, data } = useQuery<Profyle360ReviewRequestAssignedListGet>(REQUEST_ASSIGNED_LIST_GET);

  const reviewRequests = data?.profyle360ReviewRequestAssignedListGet || [];
  const reviewRequestProfyles = toRequestProfylesWithRequester<RequestProfyleWithRequester>(reviewRequests);

  const handleRowViewClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const reviewId = e.currentTarget.dataset.reviewId;
      if (reviewId) {
        history.push(`/review-360/${reviewId}`);
      }
    },
    [history]
  );

  return (
    <>
      <h2>Your Feedback About Others</h2>

      {loading && <Loading />}
      {!loading && !reviewRequestProfyles.length && <p>You haven't been asked to provide any Feedback yet.</p>}
      {!loading && !!reviewRequestProfyles.length && (
        <>
          <p>Click on the Profyle you want to provide Feedback on.</p>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Profyle Role</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {reviewRequestProfyles.map((request) => {
                return (
                  <tr key={request.id}>
                    <td>
                      {request.requester.firstName} {request.requester.lastName}
                    </td>
                    <td>{toProfyleSkillsetNames(request.profyle)}</td>
                    <td>{toLabel(request.status)}</td>
                    <td>
                      {request.status !== ReviewRequestStatus.REQUEST_COMPLETED && (
                        <Link to={`/profyle-360/review/${request.id}`}>Review</Link>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
}

export { YourFeedbackAboutOthers };
