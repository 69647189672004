import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { Button } from '../shared/ui';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { DeleteCompanyUsers, DeleteCompanyUsersVariables } from './__generated__/DeleteCompanyUsers';
import { NotificationManager } from 'react-notifications';
import { GetAdminUsers_getAdminUsers } from './types';

const USERS_DELETE = gql`
  mutation DeleteCompanyUsers($input: DeleteCompanyUsersInput!) {
    deleteCompanyUsers(input: $input) {
      id
    }
  }
`;

const StyledModal = styled(Modal)`
  & > div {
    width: 420px;
    max-width: 420px;
    @media (max-width: 767px) {
      width: auto;
    }
  }
`;

const StyledModalBody = styled(Modal.Body)`
  padding: 48px 64px 64px 64px;
  @media (max-width: 767px) {
    padding: 40px 20px;
  }
`;

const StyledModalHeader = styled(Modal.Header)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  border: 0;
`;

const BodyMainText = styled.p`
  font-size: 20px;
  margin-bottom: 24px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 48px;

  & > button {
    flex: 1;
  }

  & > button:first-child {
    margin-right: 8px;
  }

  & > button:last-child {
    margin-left: 8px;
  }
`;

interface IProps {
  show: boolean;
  selectedUsers: GetAdminUsers_getAdminUsers[];
  onHide: () => void;
  onRemove: () => void;
}

export const ModalRemoveUsers: React.FC<IProps> = ({ show, onHide, onRemove, selectedUsers }) => {
  const [deleteUsers] = useMutation<DeleteCompanyUsers, DeleteCompanyUsersVariables>(USERS_DELETE);
  const [loading, setLoading] = useState(false);
  const handleRemove = useCallback(() => {
    setLoading(true);
    deleteUsers({
      variables: {
        input: {
          users: selectedUsers.map(({ email }) => ({
            email,
          })),
        },
      },
    })
      .then(() => {
        onRemove();
        NotificationManager.success('Selected users deleted successfully');
      })
      .finally(() => {
        setLoading(false);
        onHide();
      });
  }, [deleteUsers, onRemove, onHide, selectedUsers]);

  return (
    <StyledModal show={show} onHide={onHide} centered>
      <StyledModalHeader />
      <StyledModalBody>
        <h2>Delete selected</h2>
        <BodyMainText>Are you sure you want to remove these users from the company?</BodyMainText>
        <ButtonsWrapper>
          <Button onClick={handleRemove} variant="secondary" disabled={loading}>
            Yes
          </Button>
          <Button onClick={onHide} disabled={loading}>
            No
          </Button>
        </ButtonsWrapper>
      </StyledModalBody>
    </StyledModal>
  );
};
