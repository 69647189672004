import React from 'react';
import { Link } from 'react-router-dom';

import { TabBody, TabActions } from '../../../shared/ui';
import { toProfyleSkillsetNames } from '../../../shared/profyle';
import { getTabUrlGetFeedback } from '../../get-tab-url-get-feedback';

interface Params {
  profyle: {
    report: { instantActionsLow: string | null; instantActionsHigh: string | null };
    result: { quiz: { skillsets: { name: string }[] } };
  };
  nextUrl: string;
}

function TabWelcome(params: Params) {
  const skillsetNames = toProfyleSkillsetNames(params.profyle);

  return (
    <TabBody>
      <p>
        Your report has been compiled based on the 30 choices you made. It’s likely that you probably found some of the
        question choices quite challenging, perhaps even frustrating. If you found that you agreed with both choices for
        some of them and agreed with neither for others, then relax - this is all part of the process and it suggests
        that all is well.
      </p>
      <p>
        Profyle provides you with a rich array of reports, analyses and actions that are all derived from your choices.
      </p>
      <p>
        Firstly, you can view your Profyle results as a graphic representation using four measures and a further diagram
        called your Span Score. You can see these by clicking on the{' '}
        <Link to="discover-yourself">Discover Yourself</Link> tab.
      </p>
      <p>
        Next you can review your report which describes your preferred style as a {skillsetNames} - both your strengths
        and some probable limitations. It is the style that is most likely to flow from you easily and naturally. You
        can see this by clicking on the <Link to="textual-report">Textual Report</Link> tab.
      </p>
      <p>
        After that you can consider some focus areas for future development. These are some simple suggestions to help
        you develop into a more effective {skillsetNames}. You can see this by clicking on the{' '}
        <Link to="future-focus">Future Focus</Link> tab.
      </p>
      {(params.profyle.report.instantActionsLow || params.profyle.report.instantActionsHigh) && (
        <p>
          If you are ready to start work right now you can click on the <Link to="instant-action">Instant Actions</Link>{' '}
          tab where you can review some activities that you can work on today as well as some books and articles to
          read.
        </p>
      )}
      <p>
        The next tab provides you with the opportunity to get structured feedback on your {skillsetNames} Profyle from
        others that you trust in a safe and secure way. You can do this by clicking on the{' '}
        <Link to={getTabUrlGetFeedback()}>Feedback</Link> tab.
      </p>
      <p>
        The last tab offers you a simple statistical view of how you and your Profyle compare to others who have already
        completed their Profyles. You can see this by clicking on the{' '}
        <Link to="compare-yourself">Compare Yourself</Link> tab.
      </p>

      <TabActions>
        <Link to={params.nextUrl}>Next</Link>
      </TabActions>
    </TabBody>
  );
}

export { TabWelcome };
