import React from 'react';

import { LayoutDefault, AdminBreadcrumbs } from '../../../shared/ui';

import { useParamId } from '../../../shared/router';

import { SettingsEditForm } from './settings-edit-form';

const BREADCRUMBS = [{ path: '/admin/settings', label: 'Settings' }, { label: 'Edit setting' }];

function SettingsEdit() {
  const settingId = useParamId();

  return (
    <LayoutDefault>
      <h2>Edit setting with id {settingId}</h2>
      <AdminBreadcrumbs breadcrumbs={BREADCRUMBS} />
      <SettingsEditForm />
    </LayoutDefault>
  );
}

export { SettingsEdit };
