import React from 'react';
import { Route } from 'react-router-dom';

import { NotFound } from './not-found';

function notFoundRoute() {
  return (
    <Route path="*">
      <NotFound />
    </Route>
  );
}

export { notFoundRoute };
