import React, { useCallback, useState } from 'react';
import { useParams, Redirect, useRouteMatch, Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { LayoutDefault, Loading } from '../../shared/ui';
import { toProfyleSkillsetNames } from '../../shared/profyle';
import { ReportTabs } from './report-tabs';
import { GetCompanyUserDataById, GetCompanyUserDataByIdVariables } from './__generated__/GetCompanyUserDataById';
import { SelectControl } from '../../shared/user/my-info-form/my-info-form-layout/my-info-form-layout';
import { ProfyleGet_profyleGet } from './__generated__/ProfyleGet';

const USER_GET = gql`
  query GetCompanyUserDataById($id: Int!) {
    getCompanyUserDataById(id: $id) {
      id
      firstName
      lastName
      email
      profyles {
        id
        report {
          name
        }
      }
    }
  }
`;

interface Params {
  id: number;
}

const BackLink = () => <Link to="/reporting">Back to Reporting list</Link>;

function ProfyleChosenCompany(params: Params) {
  const userId = params.id;

  const { loading, data } = useQuery<GetCompanyUserDataById, GetCompanyUserDataByIdVariables>(USER_GET, {
    variables: { id: userId },
  });

  const companyUser = data?.getCompanyUserDataById!;

  const [selectedProfyleId, setSelectedProfyleId] = useState<number>();
  const defaultProfyleId = companyUser?.profyles?.[0]?.id;

  const routeParams = useParams<{ section: string }>();
  const tabsActiveKey = routeParams.section || '';

  const routeMatch = useRouteMatch();

  const handleSelectChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProfyleId(Number(e.target.value));
  }, []);

  const getHeader = useCallback(
    (profyle: ProfyleGet_profyleGet) => {
      const skillsetNames = toProfyleSkillsetNames(profyle);
      return (
        <>
          <h2>
            {companyUser.firstName} {companyUser.lastName} - <span className="emphasis">Report {skillsetNames}</span>
          </h2>
          <BackLink />
          <div className="mt-2">Skillset</div>
          <SelectControl onChange={handleSelectChange} value={selectedProfyleId || defaultProfyleId} className="mb-4">
            {companyUser.profyles.map((profyle) => (
              <option key={profyle.id} value={profyle.id}>
                {profyle.report.name}
              </option>
            ))}
          </SelectControl>
        </>
      );
    },
    [companyUser, defaultProfyleId, handleSelectChange, selectedProfyleId]
  );

  if (!tabsActiveKey) {
    return <Redirect to={`${routeMatch.url}/welcome`} />;
  }

  const profyleUserName = companyUser ? `${companyUser.firstName} ${companyUser.lastName}` : `#${userId}`;

  return (
    <LayoutDefault>
      {loading && <Loading />}
      {!loading && !defaultProfyleId && (
        <>
          <h2>No profyles for {profyleUserName}</h2>
          <BackLink />
        </>
      )}
      {!loading && defaultProfyleId && (
        <>
          <ReportTabs profyleId={selectedProfyleId || defaultProfyleId} getHeader={getHeader} />
        </>
      )}
    </LayoutDefault>
  );
}

export { ProfyleChosenCompany };
