import React, { ReactNode } from 'react';

import { Loading } from '../../ui';
import { UserCurrent } from '../user-current';
import { useCurrentUser } from '../use-current-user';

interface Props {
  children: (user: UserCurrent) => ReactNode;
  loading?: boolean;
}

function CurrentUser(props: Props) {
  const { loading, data } = useCurrentUser();

  if (props.loading && loading) {
    return <Loading />;
  }

  return <>{!loading && data ? props.children(data!.userCurrentGet) : null}</>;
}

export { CurrentUser };
