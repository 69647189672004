import { path, compose, isEmpty, either, isNil, pick, values, complement, all, any } from 'ramda';
import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TestOptions } from 'yup';
import styled from 'styled-components';
import classNames from 'classnames';
import { useCurrentUser } from '../../auth';

import { FormActions, Button, FormButton } from '../../ui';

import { UserInviteInput } from '../../../../../__generated__/globalTypes';
import useMediaQuery from '../../ui/use-media-query/use-media-query';
import { useLocation } from 'react-router-dom';

const USER_INPUT_COUNT_ON_START = 3;

const FormRow = styled(Row).attrs(() => ({
  className: classNames('mx-sm-n1'),
}))``;

const FormCol = styled(Col).attrs(() => ({
  className: classNames('px-sm-1'),
  sm: '4',
}))``;

const MAX_REVIEWERS_PER_TIME = 25;

const initialValues = {
  // Formik will set fields toudched on submit only when we provide initial values
  users: Array(MAX_REVIEWERS_PER_TIME).fill({ email: '', firstName: '', lastName: '' }),
};

const createExists = (fn: typeof any | typeof all) => (fields: string[], value: { [f: string]: unknown }): boolean =>
  fn(complement(either(isNil, isEmpty)))(values(pick(fields)(value)));

const anyFieldExists = createExists(any);
const allFieldExists = createExists(all);
const allExists = (o: { [f: string]: unknown }) => allFieldExists(Object.keys(o), o);

const createLineValidationTest = (fields: string[], message?: string): TestOptions => ({
  test: function (value: unknown) {
    const exists = anyFieldExists(fields, this.parent);
    if (!exists) {
      return true;
    }

    return !!value;
  },
  message,
});

const allFields = ['email', 'firstName', 'lastName'];

interface Params {
  onSubmit: (values: any) => void;
  className?: string;
}

function InviteFormLayout(params: Params) {
  const location = useLocation();
  // @TODO: use context or query instead
  const profyleId = location.pathname.split('/')[2];

  const { data: user } = useCurrentUser();
  const currentUserEmail = user?.userCurrentGet.email;

  const form = useFormik<UserInviteInput>({
    initialValues,
    validationSchema: yup.object().shape({
      users: yup.array().of(
        yup.object().shape({
          email: yup
            .string()
            .email('Email must be a valid email')
            .test(createLineValidationTest(allFields, 'Email is a required field'))
            .test({
              message: 'You are requesting feedback from yourself',
              test: (value: string): boolean => {
                if (!value || !currentUserEmail) {
                  return true;
                }
                return value.toLowerCase() !== currentUserEmail?.toLowerCase();
              },
            }),
          firstName: yup.string().test(createLineValidationTest(allFields, 'First name is a required field')),
          lastName: yup.string().test(createLineValidationTest(allFields, 'Last name is a required field')),
        })
      ),
    }),
    onSubmit: (values) => {
      const cleanedValues = {
        ...values,
        users: (values.users || [])
          .filter((u) => allExists((u as unknown) as { [f: string]: unknown }))
          .map((item) => ({ ...item, profyleIds: profyleId })),
      };
      params.onSubmit(cleanedValues);
    },
  });
  const [userInputCount, setUserInputCount] = useState<number>(USER_INPUT_COUNT_ON_START);

  const onAdd = () => {
    setUserInputCount(userInputCount + 1);
  };

  const isSmall = useMediaQuery('(max-width: 575px)');

  return (
    <>
      <Form noValidate onSubmit={form.handleSubmit} className={params.className}>
        {Array.from(Array(userInputCount).keys()).map((i, index) => {
          const controlIdPrefix = `users[${i}]`;
          const controlIdFirstName = `${controlIdPrefix}.firstName`;
          const controlIdLastName = `${controlIdPrefix}.lastName`;
          const controlIdEmail = `${controlIdPrefix}.email`;
          const getControlPrefix = path<{ [field: string]: string | undefined | null }>(['users', i]);
          const getFirstName = compose(
            path<string>(['firstName']),
            getControlPrefix
          );
          const getLastName = compose(
            path<string>(['lastName']),
            getControlPrefix
          );
          const getEmail = compose(
            path<string>(['email']),
            getControlPrefix
          );

          return (
            <React.Fragment key={i}>
              <FormRow>
                <FormCol>
                  <Form.Group controlId={controlIdFirstName}>
                    <Form.Control
                      type="text"
                      placeholder="First name"
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      isInvalid={!!(getFirstName(form.touched) && getFirstName(form.errors))}
                      disabled={form.isSubmitting}
                    />

                    <Form.Control.Feedback type="invalid">{getFirstName(form.errors)}</Form.Control.Feedback>
                  </Form.Group>
                </FormCol>

                <FormCol>
                  <Form.Group controlId={controlIdLastName}>
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      isInvalid={!!(getLastName(form.touched) && getLastName(form.errors))}
                      disabled={form.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">{getLastName(form.errors)}</Form.Control.Feedback>
                  </Form.Group>
                </FormCol>

                <FormCol>
                  <Form.Group controlId={controlIdEmail}>
                    <Form.Control
                      type="text"
                      placeholder="E-mail"
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      isInvalid={!!(getEmail(form.touched) && getEmail(form.errors))}
                      disabled={form.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">{getEmail(form.errors)}</Form.Control.Feedback>
                  </Form.Group>
                </FormCol>
              </FormRow>
              {isSmall && userInputCount > index + 1 && <hr />}
            </React.Fragment>
          );
        })}
        {userInputCount < MAX_REVIEWERS_PER_TIME ? (
          <Button onClick={onAdd} size="sm">
            Add someone else
          </Button>
        ) : (
          <span>25 reviewers is max per time</span>
        )}
        <FormActions>
          <FormButton type="submit" form={form}>
            Get Feedback
          </FormButton>
        </FormActions>
      </Form>
    </>
  );
}

export { InviteFormLayout };
