import React from 'react';

import { LayoutDefault } from '../../shared/ui';

function EmailConfirmation() {
  return (
    <LayoutDefault>
      <h2>Email Confirmation</h2>
      Almost done. Profyle has sent you reset password email. Hint. It sometimes lands in junk or spam.
    </LayoutDefault>
  );
}

export { EmailConfirmation };
