import React from 'react';
import { Route } from 'react-router-dom';

import { ProtectedRoute } from '../shared/auth';

import { Profyle } from './profyle';
import { profyleChosenRoute, profyleChosenCompanyRoute } from './profyle-chosen';

function profyleRoute() {
  return (
    <ProtectedRoute
      path="/profyle"
      render={(routeProps) => (
        <>
          <Route path={`${routeProps.match.url}`} exact>
            <Profyle />
          </Route>
          {profyleChosenRoute(routeProps)}
        </>
      )}
    />
  );
}

export { profyleRoute };
