import { toPairs } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Form, ProgressBar } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Wizard, Steps, Step, WizardContext } from 'react-albus';
import { useFormik } from 'formik';
import * as yup from 'yup';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { QuizResultAdd, QuizResultAddVariables } from './__generated__/QuizResultAdd';
import { QuizGet, QuizGetVariables } from './__generated__/QuizGet';
import { QuizResultAddInput, QuizResultAnswerIdsInput } from '../../../../__generated__/globalTypes';

import {
  LayoutDefault,
  getProgressPercents,
  createValidationSchema,
  createInitialValues,
  isLastStep,
  Loading,
} from '../../shared/ui';
import { useResetCurrentUserAndHistoryPush } from '../../shared/user';

import { QuestionItem } from './question-item';
import { questionToHTMLId } from './data';

const QuestionProgress = styled(ProgressBar)`
  margin-bottom: 3rem;
`;

const PATH_COLLECTION_QUIZ_LANDING = '/collection/finish';

const QUIZ_GET = gql`
  query QuizGet($id: Int!) {
    quizGet(id: $id) {
      id
      questions {
        id
        text
        options {
          id
          text
        }
      }
    }
  }
`;

const QUIZ_RESULT_ADD = gql`
  mutation QuizResultAdd($input: QuizResultAddInput!) {
    quizResultAdd(input: $input) {
      id
    }
  }
`;

type questionOptionId = string;
interface QuestionAnswerIds {
  [questionId: string]: questionOptionId;
}

const questionAnswerIdsToAnswerIdsInput = (questionAnswerIds: QuestionAnswerIds): QuizResultAnswerIdsInput[] => {
  return toPairs<string>(questionAnswerIds).map<QuizResultAnswerIdsInput>(([questionId, optionId]) => ({
    questionId: parseInt(questionId),
    optionId: parseInt(optionId),
  }));
};

interface RouteParams {
  id: string;
}

function CollectionQuiz() {
  const routeParams = useParams<RouteParams>();
  const resetCurrentUserAndHistoryPush = useResetCurrentUserAndHistoryPush();

  const { loading, data } = useQuery<QuizGet, QuizGetVariables>(QUIZ_GET, {
    variables: { id: parseInt(routeParams.id) },
  });
  const questions = data?.quizGet.questions || [];
  const [quizResultAdd] = useMutation<QuizResultAdd, QuizResultAddVariables>(QUIZ_RESULT_ADD);

  const onSubmit = (values: QuestionAnswerIds) => {
    const input: QuizResultAddInput = {
      quizId: data!.quizGet.id,
      answerIds: questionAnswerIdsToAnswerIdsInput(values),
    };
    quizResultAdd({ variables: { input } }).then(() => {
      resetCurrentUserAndHistoryPush(PATH_COLLECTION_QUIZ_LANDING);
    });
  };

  const form = useFormik({
    initialValues: createInitialValues(questions, ''),
    validationSchema: createValidationSchema(questions, yup.string().required()),
    onSubmit,
  });

  const onFinish = () => {
    form.submitForm();
  };

  const onNext = (wizard: WizardContext) => {
    if (isLastStep(wizard)) {
      onFinish();
      return;
    }

    wizard.push();
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <LayoutDefault>
      <h2>Generate Your Profyle</h2>
      <Form noValidate onSubmit={form.handleSubmit}>
        <Wizard
          onNext={onNext}
          render={(wizard) => (
            <>
              <QuestionProgress now={getProgressPercents(wizard)} />

              <Steps>
                {questions.map((question, i) => {
                  const questionStepId = `${questionToHTMLId(question)}-step` + i;
                  const last = i === questions.length - 1;
                  return (
                    <Step
                      render={(wizard) => (
                        <QuestionItem question={question} form={form} onNext={wizard.next} last={last} />
                      )}
                      id={questionStepId}
                      key={questionStepId}
                    />
                  );
                })}
              </Steps>
            </>
          )}
        />
      </Form>
    </LayoutDefault>
  );
}

export { CollectionQuiz };
