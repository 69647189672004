import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../../../shared/auth';

import { Feedback360 } from './feedback-360';
import { statusRoute } from './status';
import { inviteSendingRoute } from './invite-sending';

function feedback360Route(parentRouteProps: RouteComponentProps) {
  return (
    <Route
      render={() => (
        <>
          <ProtectedRoute path={`${parentRouteProps.match.url}/home`}>
            <Feedback360 />
          </ProtectedRoute>
          {statusRoute(parentRouteProps)}
          {inviteSendingRoute(parentRouteProps)}
        </>
      )}
    />
  );
}

export { feedback360Route };
