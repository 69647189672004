import React, { ReactNode, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  useAuth,
  ShowForAuth,
  ShowForNotAuth,
  ShowForUser,
  CurrentUser,
  HideForUser,
  AuthContextConsumer,
} from '../../../auth';
import { Logo } from '../../logo';
import { Button } from '../../button';
import { Avatar } from '../../avatar';
import { ModalSignIn, ModalCreateAccount } from '../../../sign-in-up';
import { useResetCurrentUserAndHistoryPush } from '../../../user';
import { RoleName } from '../../../auth/role-name';
import { CompanyContext } from '../../../company/company-context';
import { DesktopNavbar } from './navbar';

const { origin } = location;
const query = origin.includes('localhost') || origin.includes('dev.') ? `?host=${origin}` : '';

const NavigationBar = styled(Navbar)`
  margin-top: 25px;
  .navbar-nav {
    align-items: center;
    @media (max-width: 991px) {
      align-items: flex-start;
      a {
        display: block;
        width: 100%;
      }
      & > div,
      button {
        width: 100%;
      }
    }
  }
`;

const AvatarLink = styled(Link)`
  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
  }
`;

const StyledBrand = styled(Navbar.Brand)`
  display: flex;
  align-items: center;
  height: 44px;
`;

const MediaQuery = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`;

const AvatarWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

const Name = styled.div`
  flex: 1 1 auto;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 160%;
  color: #65697e;
  margin-bottom: 0;
  margin-right: 16px;
`;

interface ModalSignInHandlerProps {
  isAuthenticated: boolean;
  isExpired: boolean;
  showModal: () => void;
}

const ModalSignInHandler = (props: ModalSignInHandlerProps) => {
  useEffect(() => {
    if (!props.isAuthenticated && props.isExpired) {
      const timeoutId = setTimeout(() => {
        props.showModal();
      }, 0);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [props]);

  return (
    <Button onClick={props.showModal} variant="secondary">
      Sign In
    </Button>
  );
};

const PATH_UNAUTH_LANDING = '/';

interface Props {
  children?: ReactNode;
}

function Navigation(props: Props) {
  const auth = useAuth();
  const resetCurrentUserAndHistoryPush = useResetCurrentUserAndHistoryPush();

  const { data } = React.useContext(CompanyContext);
  const company = data?.getCompanyInfo;

  const signOut = () => {
    auth.unauthenticate().then(() => {
      resetCurrentUserAndHistoryPush(PATH_UNAUTH_LANDING);
    });
  };

  return (
    <NavigationBar expand="lg">
      <Container>
        <StyledBrand to="/" as={Link}>
          <Logo logoUrl={company?.companyLanding?.logoUrl} />
        </StyledBrand>
        <Navbar.Toggle aria-controls="main-navbar-nav" />
        <Navbar.Collapse id="main-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav>
            <ShowForAuth>
              <MediaQuery>
                <CurrentUser>
                  {(user) => (
                    <ShowForUser
                      role={[RoleName.ROLE_CORE, RoleName.ROLE_REVIEW_360, RoleName.ROLE_REVIEW_PLAIN]}
                      else={() => <Avatar firstName={user.firstName} lastName={user.lastName} />}
                    >
                      <AvatarLink to="/my-info">
                        <AvatarWrapper>
                          <Name>
                            <Text>{user.firstName}</Text>
                            <Text>{user.lastName}</Text>
                          </Name>
                          <Avatar firstName={user.firstName} lastName={user.lastName} />
                        </AvatarWrapper>
                      </AvatarLink>
                    </ShowForUser>
                  )}
                </CurrentUser>
              </MediaQuery>
            </ShowForAuth>
            <Nav.Link to="/" as={Link}>
              Home
            </Nav.Link>
            <a href={`https://blog.profyle.me/${query}`} className="nav-link">
              Blog
            </a>
            <ShowForAuth>
              <ShowForUser role={RoleName.ROLE_ADMIN}>
                <Nav.Link to="/admin" as={Link}>
                  Admin
                </Nav.Link>
              </ShowForUser>
              <HideForUser role={RoleName.ROLE_CORPORATE_ADMIN}>
                <ShowForUser role={[RoleName.ROLE_CORE, RoleName.ROLE_CORPORATE_CORE]}>
                  <Nav.Link to="/profyle" as={Link}>
                    My Profyles
                  </Nav.Link>
                </ShowForUser>
              </HideForUser>
              <ShowForUser role={RoleName.ROLE_CORPORATE_ADMIN}>
                <>
                  <Nav.Link to="/company-info" as={Link}>
                    My Company
                  </Nav.Link>
                  <Nav.Link to="/company-users" as={Link}>
                    Users
                  </Nav.Link>
                  <Nav.Link to="/reporting" as={Link}>
                    Reporting
                  </Nav.Link>
                </>
              </ShowForUser>
              {/* <ShowForUser role={RoleName.ROLE_CORE}> */}
              {/* <Nav.Link to="/profyle-360?withStatus" as={Link}>Profyle 360</Nav.Link> */}
              {/* <Nav.Link to="/profyle-review?withStatus" as={Link}>Review</Nav.Link> */}
              {/* </ShowForUser> */}
              <HideForUser role={RoleName.ROLE_CORPORATE_ADMIN}>
                <ShowForUser role={RoleName.ROLE_REVIEW_360}>
                  <Nav.Link to="/feedback" as={Link}>
                    Feedback
                  </Nav.Link>
                </ShowForUser>
              </HideForUser>
              {/* <ShowForUser role={RoleName.ROLE_CORE}> */}
              {/* <Nav.Link to="/rating" as={Link}>Rating</Nav.Link> */}
              {/* <Nav.Link to="/collection" as={Link}>Collection</Nav.Link> */}
              {/* </ShowForUser> */}
              <HideForUser role={[RoleName.ROLE_REVIEW_PLAIN, RoleName.ROLE_ADMIN, RoleName.ROLE_CORPORATE_ADMIN]}>
                <Nav.Link to="/share" as={Link}>
                  Share
                </Nav.Link>
              </HideForUser>
            </ShowForAuth>

            {props.children}

            <Nav.Link to="/wsa" as={Link}>
              WSA
            </Nav.Link>
            <Nav.Link to="/contacts" as={Link}>
              Contacts
            </Nav.Link>
            <ShowForUser role={RoleName.ROLE_CORPORATE_ADMIN}>
              <MediaQuery>
                <Nav.Link to="/company/billing-information" as={Link}>
                  Billing Information
                </Nav.Link>
              </MediaQuery>
            </ShowForUser>
            <ShowForAuth>
              <MediaQuery>
                <Button onClick={signOut}>Sign Out</Button>
              </MediaQuery>
              <DesktopNavbar />
            </ShowForAuth>
            <ShowForNotAuth>
              <ModalSignIn
                render={({ showModal }) => (
                  <AuthContextConsumer>
                    {({ isAuthenticated, isExpired }) => (
                      <ModalSignInHandler
                        isAuthenticated={isAuthenticated}
                        isExpired={isExpired}
                        showModal={showModal}
                      />
                    )}
                  </AuthContextConsumer>
                )}
              />
              <ModalCreateAccount render={({ showModal }) => <Button onClick={showModal}>Sign Up</Button>} />
            </ShowForNotAuth>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </NavigationBar>
  );
}

export { Navigation };
