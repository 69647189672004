import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { Form, Modal } from 'react-bootstrap';
import { Button } from '../../shared/ui';

const StyledModal = styled(Modal)`
  & > div {
    width: 420px;
    max-width: 420px;
    @media (max-width: 767px) {
      width: auto;
    }
  }
`;

const StyledModalBody = styled(Modal.Body)`
  padding: 48px 64px 64px 64px;
  @media (max-width: 767px) {
    padding: 40px 20px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 48px;

  & > button {
    flex: 1;
  }

  & > button:first-child {
    margin-right: 8px;
  }

  & > button:last-child {
    margin-left: 8px;
  }
`;

interface IProps {
  show: boolean;
  onHide: () => void;
  onCreate: (value: string) => void;
}

export const ModalAddGroup: React.FC<IProps> = ({ show, onHide, onCreate }) => {
  const [value, setValue] = useState('');

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const handleCreate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onCreate(value);
    },
    [onCreate, value]
  );

  return (
    <StyledModal show={show} onHide={onHide} centered>
      <StyledModalBody>
        <h2>Add new group</h2>
        <div className="mb-2">Group name</div>
        <Form.Control onChange={handleChange} type="text" placeholder="Enter a group name" />
        <ButtonsWrapper>
          <Button onClick={onHide} variant="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreate} disabled={!value}>
            Create
          </Button>
        </ButtonsWrapper>
      </StyledModalBody>
    </StyledModal>
  );
};
