const toTaggedTexts = <T extends { tag: string }>(texts: T[]): { [tag: string]: T } =>
  texts.reduce(
    (acc, text) => ({
      ...acc,
      [text.tag]: text,
    }),
    {}
  );

export { toTaggedTexts };
