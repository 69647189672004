import React from 'react';
import { Switch } from 'react-router-dom';

import { homeRoute } from '../home';
import { signUpRoute } from '../sign-up';
import { signUpEnterpriseRoute } from '../sign-up-enterprise';
import { signInRoute } from '../sign-in';
import { signOutRoute } from '../sign-out';
import { signInByConfirmRoute } from '../sign-in-by-confirm';
import { onBoardingRoute } from '../on-boarding';
import { collectionRoute } from '../collection';
import { profyleRoute } from '../profyle';
import { skillsetsRoute } from '../skillsets';
import { backgroundRoute } from '../background';
import { faqRoute } from '../faq';
import { wsaRoute } from '../wsa';
import { profyle360Route } from '../profyle-360';
import { feedbackRoute } from '../feedback';
import { profyleReviewRoute } from '../profyle-review';
import { ratingRoute } from '../rating';
import { referralRoute } from '../referral';
import { paymentRoute } from '../payment';
import { termsRoute } from '../terms';
import { contactsRoute } from '../contacts';
import { myInfoRoute } from '../my-info';
import { companyInfoRoute } from '../company-info';
import { companyUsersRoute } from '../company-users';
import { adminRoute } from '../admin';
import { myOrderEnterpriseRoute } from '../my-order-enterprise';
import { consolidatedOrderRoute } from '../consolidated-order';
import { forgotPasswordRoute } from '../forgot-password';
import { review360Route } from '../review-360';
import { brandingRoute } from '../branding';
import { companyBillingInfoRoute } from '../company-billing-info';
import { companyOrderRoute } from '../company/company-order';
import { companyOrderInvoiceRoute } from '../company/company-order-invoice';
import { companyOrderDetailsRoute } from '../company/company-order-details';
import { reportingRoute } from '../reporting';
import { notFoundRoute } from '../not-found';
import { profyleCompanyRoute } from '../profyle/profyle-company.route';

function Routing() {
  return (
    <Switch>
      {homeRoute()}
      {signUpRoute()}
      {signUpEnterpriseRoute()}
      {signInRoute()}
      {signOutRoute()}
      {signInByConfirmRoute()}
      {onBoardingRoute()}
      {collectionRoute()}
      {profyleRoute()}
      {profyleCompanyRoute()}
      {skillsetsRoute()}
      {backgroundRoute()}
      {faqRoute()}
      {wsaRoute()}
      {profyle360Route()}
      {feedbackRoute()}
      {profyleReviewRoute()}
      {ratingRoute()}
      {referralRoute()}
      {paymentRoute()}
      {termsRoute()}
      {contactsRoute()}
      {myInfoRoute()}
      {companyInfoRoute()}
      {companyUsersRoute()}
      {adminRoute()}
      {myOrderEnterpriseRoute()}
      {consolidatedOrderRoute()}
      {forgotPasswordRoute()}
      {review360Route()}
      {brandingRoute()}
      {companyBillingInfoRoute()}
      {companyOrderRoute()}
      {companyOrderInvoiceRoute()}
      {companyOrderDetailsRoute()}
      {reportingRoute()}
      {notFoundRoute()}
    </Switch>
  );
}

export { Routing };
