import { useHistory } from 'react-router-dom';
import React from 'react';

import { LayoutDefault, Loading } from '../shared/ui';
import { MyInfoForm } from '../shared/user';
import { FormMode } from '../shared/from';
import { useQueryParams } from '../shared/router';
import { hasRole } from '../shared/auth/has-role';
import { RoleName } from '../shared/auth/role-name';
import { USER_INFO_GET, toValues } from '../my-info/my-info';
import { UserInfoCurrentGet } from '../my-info/__generated__/UserInfoCurrentGet';
import { useQuery } from '@apollo/react-hooks';

const PATH_ON_BOARDING_LANDING = '/collection';

function OnBoarding() {
  const queryParams = useQueryParams();
  const redirect = queryParams.get('redirect') || PATH_ON_BOARDING_LANDING;
  const history = useHistory();
  const { loading, data } = useQuery<UserInfoCurrentGet>(USER_INFO_GET);

  const onSave = () => {
    history.push(redirect);
  };
  const getTitle = () => 'More About You';
  const getDescription = (mode: FormMode) =>
    mode === FormMode.Edit
      ? 'We collect this extra data about you so you can compare your Profyle results to other people. Most people that use Profyle are curious to see where they fit.'
      : 'Does this look right? If you want to change something, just click edit.';
  const getLabelSubmit = () => 'See What’s Next';

  return (
    <LayoutDefault>
      {loading ? (
        <Loading />
      ) : (
        (() => {
          const user = data!.userInfoCurrentGet;
          const isCorporateUser = hasRole(user, RoleName.ROLE_CORPORATE_CORE);
          const initialValues = toValues(user);

          return (
            <MyInfoForm
              getTitle={getTitle}
              getDescription={getDescription}
              getLabelSubmit={getLabelSubmit}
              onSave={onSave}
              isCorporateUser={isCorporateUser}
              initialValues={initialValues}
            />
          );
        })()
      )}
    </LayoutDefault>
  );
}

export { OnBoarding };
