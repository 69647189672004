import React from 'react';
import { Route } from 'react-router-dom';
import { Feedback } from './feedback';

import { ProtectedRoute } from '../shared/auth';

function feedbackRoute() {
  return (
    <Route
      path="/feedback"
      render={(routeProps) => (
        <>
          <ProtectedRoute path={routeProps.match.url} exact>
            <Feedback />
          </ProtectedRoute>
        </>
      )}
    />
  );
}

export { feedbackRoute };
