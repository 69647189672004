import { Route } from 'react-router-dom';
import React from 'react';

import { ProtectedRoute } from '../shared/auth';

import { CompanyInfo } from './company-info';
import { ListOfAdmins } from './list-of-admins';
import { RoleName } from '../shared/auth/role-name';
import { AddAdmins } from './add-admins';

function companyInfoRoute() {
  return (
    <ProtectedRoute
      path="/company-info"
      role={RoleName.ROLE_CORPORATE_ADMIN}
      render={(routeProps) => (
        <>
          <Route path={routeProps.match.url} exact>
            <CompanyInfo />
          </Route>
          <Route path={`${routeProps.match.url}/list-of-admins`} exact>
            <ListOfAdmins />
          </Route>
          <Route path={`${routeProps.match.url}/add-admins`} exact>
            <AddAdmins />
          </Route>
        </>
      )}
    />
  );
}

export { companyInfoRoute };
