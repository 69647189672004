import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { UserCoreSignUpConfirm, UserCoreSignUpConfirmVariables } from './__generated__/UserCoreSignUpConfirm';

import { LayoutDefault, Loading } from '../../shared/ui';
import { useQueryParams } from '../../shared/router';
import { auth } from '../../shared/auth';
import { useResetCurrentUserAndHistoryPush } from '../../shared/user';

const PATH_SIGN_UP_CONFIRM_LANDING = '/sign-up/password';

const USER_CORE_SIGN_UP_CONFIRM = gql`
  mutation UserCoreSignUpConfirm($input: UserCoreSignUpConfirmInput!) {
    userCoreSignUpConfirm(input: $input) {
      token
    }
  }
`;

function EmailConfirmed() {
  const queryParams = useQueryParams();
  const code = queryParams.get('code');
  if (!code) {
    throw new Error('Code is not found in query params');
  }
  const resetCurrentUserAndHistoryPush = useResetCurrentUserAndHistoryPush();
  const [userCoreSignUpConfirm] = useMutation<UserCoreSignUpConfirm, UserCoreSignUpConfirmVariables>(
    USER_CORE_SIGN_UP_CONFIRM
  );
  const ref = useRef<boolean>();

  // Run only on first render
  if (!ref.current) {
    ref.current = true;

    const input = { code };
    userCoreSignUpConfirm({ variables: { input: input } }).then((result) => {
      return auth.authenticate(result.data!.userCoreSignUpConfirm!.token).then(() => {
        resetCurrentUserAndHistoryPush(PATH_SIGN_UP_CONFIRM_LANDING);
      });
    });
  }

  return (
    <LayoutDefault>
      <h2>Email confirm</h2>
      <Loading />
    </LayoutDefault>
  );
}

export { EmailConfirmed };
