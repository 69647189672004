import React from 'react';

import { LayoutDefault, AdminBreadcrumbs } from '../../../shared/ui';

import { useParamId } from '../../../shared/router';

import { SkillsetsEditForm } from './skillsets-edit-form';

const BREADCRUMBS = [{ path: '/admin/skillsets', label: 'Skillsets' }, { label: 'Edit skillset' }];

function SkillsetsEdit() {
  const skillsetId = useParamId();

  return (
    <LayoutDefault>
      <h2>Edit skillset with id {skillsetId}</h2>
      <AdminBreadcrumbs breadcrumbs={BREADCRUMBS} />
      <SkillsetsEditForm />
    </LayoutDefault>
  );
}

export { SkillsetsEdit };
