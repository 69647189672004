import React, { useState, useCallback, useEffect } from 'react';
import { addMonths } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';

import calendarIcon from '../../shared/icons/calendar.svg';
import { Picker } from './components';
import { IPicker } from './types';

export const MonthsPicker: React.FC<IPicker> = ({ onChange }) => {
  const [startDate, setStartDate] = useState(addMonths(new Date(), -2));
  const [endDate, setEndDate] = useState(new Date());

  const onDateChange = useCallback(
    (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);

      if (start && end) {
        onChange(start, end);
      }
    },
    [onChange]
  );

  return (
    <Picker>
      <DatePicker
        placeholderText="Select months"
        selected={startDate}
        onChange={onDateChange}
        startDate={startDate}
        endDate={endDate}
        maxDate={startDate && !endDate ? addMonths(startDate, 2) : null}
        popperPlacement="bottom"
        dateFormat="MM/yyyy"
        showMonthYearPicker
        selectsRange
      />
      <img src={calendarIcon} />
    </Picker>
  );
};
