import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { CollectionBaseOnResult } from './collection-base-on-result';

function collectionBaseOnResultRoute(routeProps: RouteComponentProps) {
  return (
    <Route path={`${routeProps.match.url}/base-on-result/:quizId`}>
      <CollectionBaseOnResult />
    </Route>
  );
}

export { collectionBaseOnResultRoute };
