import React from 'react';
import { Route } from 'react-router-dom';

import { ProtectedRoute } from '../shared/auth';

import { ForgotPassword } from './forgot-password';
import { resetPasswordRoute } from './reset-password';
import { emailConfirmationRoute } from './email-confirmation';
import { emailConfirmedRoute } from './email-confirmed';
import { resetDoneRoute } from './reset-done';

function forgotPasswordRoute() {
  return (
    <Route
      path="/forgot-password"
      render={(routeProps) => (
        <>
          <ProtectedRoute notAuth path={routeProps.match.url} exact>
            <ForgotPassword />
          </ProtectedRoute>
          {resetPasswordRoute(routeProps)}
          {resetDoneRoute(routeProps)}
          {emailConfirmationRoute(routeProps)}
          {emailConfirmedRoute(routeProps)}
        </>
      )}
    />
  );
}

export { forgotPasswordRoute };
