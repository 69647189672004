import React, { useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { Button, LayoutDefault, Loading } from '../shared/ui';
import { useQuery } from '@apollo/react-hooks';
import { CreateOrder } from './__generated__/CreateOrder';
import { useHistory } from 'react-router';

const COMPANY_ORDER_PATH = '/company-order/';

const CREATE_ORDER = gql`
  query CreateOrder {
    createOrder {
      id
    }
  }
`;

function MyOrderEnterprise() {
  const { loading, data } = useQuery<CreateOrder>(CREATE_ORDER);
  const history = useHistory();
  const handleGoBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    const orderId = data?.createOrder?.id;
    if (!loading && orderId) {
      history.push(`${COMPANY_ORDER_PATH}${orderId}`);
    }
  }, [data, loading, history]);

  return (
    <LayoutDefault>
      {loading && <Loading />}
      {!loading && !data && (
        <div>
          <p>You have nothing to pay</p>
          <Button onClick={handleGoBackClick}>Go back</Button>
        </div>
      )}
    </LayoutDefault>
  );
}

export { MyOrderEnterprise };
