import { auth } from './auth';
import { authValueDefault, AuthValue } from './auth-value';

function useAuth(): AuthValue {
  return {
    ...authValueDefault,
    isAuthenticated: auth.isAuthenticated(),
    isExpired: auth.isExpired(),
  };
}

export { useAuth };
