import React, { ReactNode } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { UserInfoCurrentUpdateInput } from '../../../../../__generated__/globalTypes';

import { FormMode } from '../../from';

import { UserInfoCurrentUpdate, UserInfoCurrentUpdateVariables } from './__generated__/UserInfoCurrentUpdate';

import { MyInfoFormLayout } from './my-info-form-layout';
import { MyInfoFormValuesInitial } from './my-info-form-values';

const INFO_UPDATE = gql`
  mutation UserInfoCurrentUpdate($input: UserInfoCurrentUpdateInput!) {
    userInfoCurrentUpdate(input: $input) {
      id
    }
  }
`;

interface Props {
  initialStatus?: FormMode;
  initialValues?: MyInfoFormValuesInitial;
  submitOnSave?: boolean;
  isCorporateUser?: boolean;
  getTitle: (mode: FormMode) => string;
  getDescription: (mode: FormMode) => string;
  getLabelSubmit?: (mode: FormMode) => string;
  getAdditionalActions?: () => ReactNode;
  onSave: () => void;
}

function MyInfoForm(props: Props) {
  const [userInfoCurrentUpdate] = useMutation<UserInfoCurrentUpdate, UserInfoCurrentUpdateVariables>(INFO_UPDATE);
  const onSubmit = (values: UserInfoCurrentUpdateInput) => {
    userInfoCurrentUpdate({ variables: { input: values } }).then(() => {
      props.onSave();
    });
  };

  return (
    <MyInfoFormLayout
      initialStatus={props.initialStatus}
      initialValues={props.initialValues}
      submitOnSave={props.submitOnSave}
      isCorporateUser={props.isCorporateUser}
      getTitle={props.getTitle}
      getDescription={props.getDescription}
      getLabelSubmit={props.getLabelSubmit}
      getAdditionalActions={props.getAdditionalActions}
      onSubmit={onSubmit}
    />
  );
}

export { MyInfoForm };
