/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum OrderByDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ReviewRequestStatus {
  REQUEST_ACCEPTED = "REQUEST_ACCEPTED",
  REQUEST_COMPLETED = "REQUEST_COMPLETED",
  REQUEST_REMINDED = "REQUEST_REMINDED",
  REQUEST_SENT = "REQUEST_SENT",
}

export interface AddBulkCompanyUsersInput {
  documentFile: any;
}

export interface AddCompanyAdminsInput {
  users: CompanyAdminInput[];
}

export interface AddCompanyGroup {
  groupName: string;
}

export interface AddCompanyGroupsInput {
  groups: AddCompanyGroup[];
}

export interface AddCompanyUser {
  firstName: string;
  lastName: string;
  email: string;
  companyDepartment: string;
  skillsetIds: number[];
  jobTitle: string;
  groupName: string;
}

export interface AddCompanyUsersInput {
  users: AddCompanyUser[];
}

export interface AdminPaymentLogPageAndSizeInput {
  paymentStatus?: string | null;
  page: number;
  size: number;
}

export interface AdminProfyleReportAddInput {
  skillsetIds: number[];
  documentFile: any;
}

export interface AdminProfyleReportUpdateInput {
  id: number;
  name: string;
  text: string;
  focusAreas: string;
  scoreRules: ProfyleReportScoreRuleInput[];
  instantActionsLow?: string | null;
  toOptimiseLowTitle?: string | null;
  toOptimiseLowText?: string | null;
  toOptimiseLowReading: ProfyleReportReadingInput[];
  instantActionsHigh?: string | null;
  toOptimiseHighTitle?: string | null;
  toOptimiseHighText?: string | null;
  toOptimiseHighReading: ProfyleReportReadingInput[];
  skillsetIds: number[];
}

export interface AdminProfyleReportUpdateWithDocumentInput {
  id: number;
  skillsetIds: number[];
  documentFile: any;
}

export interface AdminQuizAddInput {
  skillsetIds: number[];
  spreadsheetFile: any;
}

export interface AdminQuizQuestionAddInput {
  quizId: number;
  text?: string | null;
  options: AdminQuizQuestionOptionAddInput[];
}

export interface AdminQuizQuestionOptionAddInput {
  text: string;
  score: AdminQuizQuestionOptionScoreInput;
}

export interface AdminQuizQuestionOptionScoreInput {
  consideration: number;
  assertion: number;
  emotionalManagement: number;
  extroversion: number;
}

export interface AdminQuizQuestionOptionUpdateInput {
  id: number;
  text: string;
  score: AdminQuizQuestionOptionScoreInput;
}

export interface AdminQuizQuestionUpdateInput {
  id: number;
  text?: string | null;
  options: AdminQuizQuestionOptionUpdateInput[];
}

export interface AdminQuizUpdateInput {
  id: number;
  skillsetIds: number[];
}

export interface AdminRoleInput {
  role: string;
}

export interface AdminSettingUpdateInput {
  id: number;
  title: string;
  value: string;
}

export interface AdminSkillsetAddInput {
  name: string;
  description: string;
}

export interface AdminSkillsetUpdateInput {
  id: number;
  name: string;
  description: string;
}

export interface AdminTextUpdateInput {
  id: number;
  title: string;
  body: string;
}

export interface AdminUserAddInput {
  email: string;
  firstName: string;
  lastName: string;
  roles: AdminRoleInput[];
}

export interface AdminUserUpdateInput {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  roles: AdminRoleInput[];
}

export interface AnalysisHistoryAggregationGetInput {
  profyleId: number;
  area: string;
  gender: string;
}

export interface BlockCompanyInput {
  id: number;
}

export interface ChangeGroupUser {
  email: string;
  newGroup: string;
}

export interface ChangeUserSkillsets {
  email: string;
  skillsets: (number | null)[];
}

export interface ChangeUsersGroupInput {
  users: ChangeGroupUser[];
}

export interface CompanyAdminInput {
  email: string;
}

export interface ConfirmInput {
  code: string;
}

export interface ContactMessageSendInput {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

export interface DeleteCompanyAdminsInput {
  users: DeleteUserInput[];
}

export interface DeleteCompanyOrderByIdInput {
  orderId: number;
}

export interface DeleteCompanyUsersInput {
  users: CompanyAdminInput[];
}

export interface DeleteUserInput {
  email: string;
  substituteAdminEmail: string;
}

export interface MonthListInput {
  monthList: string[];
}

export interface OrderByInput {
  field: string;
  direction?: OrderByDirection | null;
}

export interface PaymentCouponCheckoutCompanyInput {
  orderId: number;
  coupon: string;
}

export interface PaymentStripeCheckoutSessionCreateCompanyInput {
  orderId: number;
  redirect: string;
}

export interface PaymentStripeCheckoutSessionCreateInput {
  skillsetIds: number[];
  redirect: string;
  coupon?: string | null;
}

export interface Profyle360ReviewAddFeedbackInput {
  paragraphIndex: number;
  grade: string;
  comment: string;
}

export interface Profyle360ReviewAddInput {
  reviewRequestProfyleId: number;
  feedbacks: Profyle360ReviewAddFeedbackInput[];
}

export interface ProfyleReportReadingInput {
  id?: number | null;
  title: string;
  author: string;
  url?: string | null;
  imageUrl?: string | null;
}

export interface ProfyleReportScoreRuleInput {
  rule: string;
}

export interface QuizResultAddBaseOnExistentInput {
  quizId: number;
}

export interface QuizResultAddInput {
  quizId: number;
  answerIds: QuizResultAnswerIdsInput[];
}

export interface QuizResultAnswerIdsInput {
  questionId: number;
  optionId: number;
}

export interface SendOrderByEmailInput {
  orderId: number;
}

export interface SendUsersSkillsetsNotificationInput {
  users: UserSkillsetsNotify[];
}

export interface UpdateCompanyInfoInput {
  id?: number | null;
  name: string;
  companyIndustry: string;
  country: string;
  city: string;
  companySize: string;
  paymentMethod: string;
  companyLanding?: UpdateCompanyLandingInfoInput | null;
}

export interface UpdateCompanyLandingInfoInput {
  logoUrl: string;
  intro?: string | null;
}

export interface UpdateUsersSkillsetsInput {
  users: ChangeUserSkillsets[];
}

export interface UserCoreSignUpConfirmInput {
  code: string;
}

export interface UserCoreSignUpInput {
  email: string;
  firstName: string;
  lastName: string;
}

export interface UserCorporateSignUpConfirmInput {
  code: string;
}

export interface UserCorporateSignUpInput {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
}

export interface UserForgotPasswordInput {
  email: string;
}

export interface UserInfoCurrentUpdateInput {
  gender: string;
  jobTitle: string;
  companyName: string;
  companySize: string;
  companyDepartment: string;
  companyIndustry: string;
  city: string;
  country: string;
}

export interface UserInviteConfirmInput {
  code: string;
}

export interface UserInviteInput {
  users: UserInviteUserInput[];
}

export interface UserInviteUserInput {
  email: string;
  firstName: string;
  lastName: string;
  profyleIds?: string | null;
}

export interface UserSetupPasswordInput {
  password: string;
}

export interface UserSignInInput {
  email: string;
  password: string;
}

export interface UserSkillsetsNotify {
  email: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
