import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import {
  GetAdminUsers,
  ChangeUsersGroup,
  ChangeUsersGroupVariables,
  UpdateUsersSkillsets,
  UpdateUsersSkillsetsVariables,
  SendUsersSkillsetsNotification,
  SendUsersSkillsetsNotificationVariables,
  IObject,
  GetAdminUsers_getAdminUsers,
} from './types';
import { ADMIN_USERS_GET, CHANGE_GROUP, CHANGE_SKILLSETS, NOTIFY_SKILLSETS } from '../shared/document-node/users';

export const COMPANY_USERS_ADD_PATH = '/company-users/add';

export const useProps = () => {
  const history = useHistory();
  const [selected, setSelected] = useState<IObject>({});
  const [users, setUsers] = useState<GetAdminUsers_getAdminUsers[]>([]);
  const { loading, data, refetch } = useQuery<GetAdminUsers>(ADMIN_USERS_GET, {
    fetchPolicy: 'no-cache',
  });
  const [changeGroup] = useMutation<ChangeUsersGroup, ChangeUsersGroupVariables>(CHANGE_GROUP);
  const [updateSkillsets, { loading: skillsetLoading }] = useMutation<
    UpdateUsersSkillsets,
    UpdateUsersSkillsetsVariables
  >(CHANGE_SKILLSETS);
  const [notifySkillsets, { loading: notifyLoading }] = useMutation<
    SendUsersSkillsetsNotification,
    SendUsersSkillsetsNotificationVariables
  >(NOTIFY_SKILLSETS);

  const selectedLength = useMemo(() => Object.keys(selected).filter((key) => selected[key]).length, [selected]);

  const { isSendButtonActive, changedIds } = useMemo(() => {
    let isChanged = false;
    let isSelected = !selectedLength;
    const changedIds: IObject = {};

    users.forEach((user, i) => {
      const userId = user.id;
      const skills = user.skillsets || [];
      const initSkills = data?.getAdminUsers?.[i]?.skillsets || [];
      const sameLength = skills.length === initSkills.length;
      const isIdentical = skills.every(({ id }) => initSkills.some(({ id: initId }) => id === initId));

      if (!sameLength || !isIdentical) {
        isChanged = true;
        changedIds[userId] = i;
        if (selected[userId]) isSelected = true;
      }
    });

    return { isSendButtonActive: isChanged && isSelected, changedIds };
  }, [data, selected, selectedLength, users]);

  useEffect(() => {
    if (!users?.length) {
      const newUsers: GetAdminUsers = JSON.parse(JSON.stringify(data || {}));
      setUsers(newUsers.getAdminUsers || []);
    }
  }, [data, users]);

  useEffect(() => {
    if (!loading && !data) {
      history.push(COMPANY_USERS_ADD_PATH, { listTitle: true });
    }
  }, [data, history, loading]);

  return {
    selected,
    setSelected,
    users,
    setUsers,
    loading,
    data,
    refetch,
    changeGroup,
    updateSkillsets,
    skillsetLoading,
    notifySkillsets,
    notifyLoading,
    selectedLength,
    isSendButtonActive,
    changedIds,
  };
};
