import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

import { LayoutDefault, AdminBreadcrumbs, Loading } from '../../shared/ui';
import { CompanyInfoForm } from '../../company-info/company-info-form';
import { FormMode } from '../../shared/from';

const BREADCRUMBS = [{ path: '/admin/companies', label: 'Companies' }, { label: 'View Company' }];

const COMPANY_GET = gql`
  query AdminCompanyViewGet($id: Int!) {
    getCompanyInfoById(id: $id) {
      id
      name
      companyIndustry
      country
      city
      companySize
      paymentMethod
      companyLanding {
        logoUrl
      }
      companyGroups {
        groupName
      }
      isBlocked
    }
  }
`;

interface RouteParams {
  id: string;
}

function CompaniesView() {
  const routeParams = useParams<RouteParams>();
  const companyId = parseInt(routeParams.id);

  const { loading, data, refetch } = useQuery<any, any>(COMPANY_GET, {
    variables: { id: companyId },
  });

  const company = data?.getCompanyInfoById;

  return (
    <LayoutDefault>
      <h2>Company view</h2>
      <AdminBreadcrumbs breadcrumbs={BREADCRUMBS} />
      {loading ? (
        <Loading />
      ) : (
        <CompanyInfoForm
          headerText={company!.name || `Company #${company!.id}`}
          company={company!}
          initialFormMode={FormMode.Preview}
          hideTabs
          hideActions
        />
      )}
    </LayoutDefault>
  );
}

export { CompaniesView };
