import React from 'react';

import { Link, Redirect } from 'react-router-dom';

import { LayoutDefault, Button } from '../shared/ui';
import { CurrentUser } from '../shared/auth';

const PATH_NO_SKLLSETS = '/plans?redirect=/collection';

function Collection() {
  return (
    <LayoutDefault>
      <CurrentUser loading>
        {(user) =>
          (user.skillsets || []).length > 0 ? (
            <>
              <h2>Generate Your Profyle</h2>
              <p>
                To get your Profyle you firstly have to make some choices. Relax. It is not a test. There are only 30
                choices to make so it is quick and simple to do. You will be presented with two statements and all you
                have to do is to choose the one that feels most right, or most like you.
              </p>
              <p>
                Most choices will be simple but be ready for a few frustrating ones that might make you scratch your
                head a little. There is no time limit but Profyle works best if you don’t overthink your responses.
              </p>
              <p>Choose quickly using your gut feel and intuition. Be as honest as your self-awareness will allow.</p>
              <p>So that’s it. Ready? Off you go!</p>

              <Button className="btn btn-primary" as={Link} to="/profyle">
                I’m Ready
              </Button>
            </>
          ) : (
            <Redirect to={PATH_NO_SKLLSETS} />
          )
        }
      </CurrentUser>
    </LayoutDefault>
  );
}

export { Collection };
