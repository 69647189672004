import React from 'react';
import { Route } from 'react-router-dom';

import { ProtectedRoute } from '../shared/auth';

import { Profyle360 } from './profyle-360';
import { statusRoute } from './status';
import { inviteRoute } from './invite';
import { inviteSendingRoute } from './invite-sending';
import { reviewRoute } from './review';
import { reviewFinishRoute } from './review-finish';

function profyle360Route() {
  return (
    <Route
      path="/profyle-360"
      render={(routeProps) => (
        <>
          <ProtectedRoute path={routeProps.match.url} exact>
            <Profyle360 />
          </ProtectedRoute>
          {statusRoute(routeProps)}
          {inviteRoute(routeProps)}
          {inviteSendingRoute(routeProps)}
          {reviewRoute(routeProps)}
          {reviewFinishRoute(routeProps)}
        </>
      )}
    />
  );
}

export { profyle360Route };
