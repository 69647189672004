import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { Button, Checkbox } from '../../shared/ui';
import arrowIcon from '../../shared/icons/arrow-icon.svg';
import { GetAdminUsers_getAdminUsers_skillsets } from '../../shared/document-node/__generated__/GetAdminUsers';
import { SkillsetListGet_skillsetListGet } from '../../shared/skillsets/__generated__/SkillsetListGet';

const ModalTitle = styled.div`
  background: var(--profyle-profyle-color-input-bg);
  padding: 8px 12px;
  display: flex;
  height: 40px;

  &:focus {
    outline: none;
    border-color: #ffe297;
    box-shadow: 0 0 0 0.2rem rgb(255 190 23 / 25%);
  }
`;

const TitleText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 180px;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    margin: 1.75rem auto;
  }

  & > div {
    width: 250px;
    max-width: 350px;
    @media (max-width: 767px) {
      width: auto;
    }
  }
`;

const StyledModalBody = styled(Modal.Body)`
  padding: 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;

  & > button {
    flex: 1;
  }
`;

const SkillsetItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  & > div {
    margin-right: 5px;
  }
`;

interface IProps {
  skillsets: SkillsetListGet_skillsetListGet[];
  selectedSkillsets: GetAdminUsers_getAdminUsers_skillsets[] | null;
  onChangeSkillset: (skillset: SkillsetListGet_skillsetListGet, index: number) => void;
  index: number;
}

export const ModalSkillset: React.FC<IProps> = ({ skillsets, selectedSkillsets, index, onChangeSkillset }) => {
  const [show, setShow] = useState(false);

  const toggleShow = useCallback(() => {
    setShow(!show);
  }, [show]);

  const skillsetTitle = selectedSkillsets?.map(({ name: title }) => title).join(', ') || 'Not selected';

  const handleCheckBoxChange = useCallback(
    (id: number | string) => {
      const skillset = skillsets.find((s) => s.id === id);

      onChangeSkillset(skillset!, index);
    },
    [onChangeSkillset, index, skillsets]
  );

  return (
    <>
      <ModalTitle tabIndex={0} onClick={toggleShow} title={skillsetTitle}>
        <TitleText>{skillsetTitle}</TitleText>
        <img src={arrowIcon} />
      </ModalTitle>
      {show && (
        <StyledModal show={show} centered>
          <StyledModalBody>
            {(skillsets || []).map((skillset) => {
              const checked = selectedSkillsets?.some((selected) => selected.id === skillset.id);
              return (
                <SkillsetItem key={skillset.id}>
                  <Checkbox id={skillset.id} onChange={handleCheckBoxChange} checked={checked} />
                  <span>{skillset.name}</span>
                </SkillsetItem>
              );
            })}
            <ButtonsWrapper>
              <Button onClick={toggleShow}>Save &amp; Close</Button>
            </ButtonsWrapper>
          </StyledModalBody>
        </StyledModal>
      )}
    </>
  );
};
