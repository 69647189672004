import React, { ReactNode, useState, useEffect } from 'react';

import { AuthContext } from '../auth-context';
import { auth } from '../../auth';
import { authValueDefault } from '../../auth-value';

interface Props {
  children: ReactNode;
}

function AuthContextProvider(props: Props) {
  const [value, setValue] = useState({
    isAuthenticated: auth.isAuthenticated(),
    isExpired: auth.isExpired(),
  });

  useEffect(() => {
    function handleChange(isAuthenticated: boolean, isExpired: boolean) {
      setValue({ isAuthenticated, isExpired });
    }

    auth.subscribe(handleChange);
    return () => {
      auth.unsubscribe(handleChange);
    };
  });

  const authValue = {
    ...authValueDefault,
    ...value,
  };

  return <AuthContext.Provider {...props} value={authValue} />;
}

export { AuthContextProvider };
