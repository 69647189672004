import React, { useState, SyntheticEvent, Fragment } from 'react';
import { Table as BootstrapTable } from 'react-bootstrap';

import { GlobalLoading } from '../index';
import { IState, IProps } from './types';
import {
  TableWrapper,
  Accordion,
  AccordionWrapper,
  MobileRow,
  ExpandableRow,
  MobileRowHeader,
  MobileCell,
} from './components';

export const ResponsiveTable = <T extends { id: number; children?: Array<T> }>({
  data,
  mapping,
  mobileHeader,
  mobileRowHeader,
  loading = false,
  withHeaders = true,
  CustomTable = BootstrapTable,
}: IProps<T>) => {
  const [expanded, setExpanded] = useState<IState>({});

  const onClick = (e: SyntheticEvent) => {
    const element = e.currentTarget as HTMLInputElement;
    const key = String(element.dataset.id);
    setExpanded((state) => ({
      ...state,
      [key]: !state[key],
    }));
  };

  return (
    <>
      <TableWrapper>
        <CustomTable responsive>
          {withHeaders ? (
            <thead>
              <tr>
                {mapping.map((m, index) => (
                  <th key={index}>{m.label}</th>
                ))}
              </tr>
            </thead>
          ) : null}
          <tbody>
            {withHeaders && (
              <MobileRow>
                <td>{mobileHeader || mapping[0].label}</td>
              </MobileRow>
            )}
            {data &&
              data.map((item, rowIndex) => {
                const children = item?.children;
                return (
                  <Fragment key={item.id}>
                    <ExpandableRow expanded={expanded[item.id] && !children?.length}>
                      {mobileRowHeader && (
                        <MobileRowHeader data-id={item.id} onClick={onClick}>
                          <Accordion hasChildren={!!children?.length} expanded={expanded[item.id]}>
                            {mobileRowHeader(item)}
                          </Accordion>
                        </MobileRowHeader>
                      )}
                      {mapping.map((m, index) => {
                        return (
                          <MobileCell
                            disableOnMobile={m.disableOnMobile}
                            data-label={m.label}
                            key={`${item.id}-${index}`}
                            data-id={!index && (!mobileRowHeader || children) ? item.id : undefined}
                            onClick={!index && (!mobileRowHeader || children) ? onClick : undefined}
                          >
                            {!!children?.length && !index ? (
                              <Accordion hasChildren={!!children?.length} expanded={expanded[item.id]}>
                                {m.getter(item, rowIndex)}
                              </Accordion>
                            ) : (
                              m.getter(item, rowIndex)
                            )}
                          </MobileCell>
                        );
                      })}
                    </ExpandableRow>
                    {!!children?.length && (
                      <tr>
                        <td colSpan={mapping.length}>
                          <AccordionWrapper expanded={expanded[item.id]}>
                            <ResponsiveTable
                              data={children}
                              mapping={mapping}
                              withHeaders={false}
                              mobileRowHeader={mobileRowHeader}
                            />
                          </AccordionWrapper>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
          </tbody>
        </CustomTable>
      </TableWrapper>
      <GlobalLoading loading={loading} />
    </>
  );
};
