import React, { useMemo, useRef } from 'react';
import * as yup from 'yup';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';

import { AdminQuizGet, AdminQuizGetVariables } from './__generated__/AdminQuizGet';
import { AdminQuizUpdate, AdminQuizUpdateVariables } from './__generated__/AdminQuizUpdate';

import { useQuizId } from '../../use-quiz-id';
import { FormButton, Loading } from '../../../../../shared/ui';
import { useSkillsets } from '../../../../../shared/skillsets/use-skillsets';

const PATH_UPDATE_LANDING = '/admin/quizzes/quizzes';

const QUIZ_GET = gql`
  query AdminQuizGet($id: Int!) {
    adminQuizGet(id: $id) {
      skillsets {
        id
      }
    }
  }
`;

const QUIZ_UPDATE = gql`
  mutation AdminQuizUpdate($input: AdminQuizUpdateInput!) {
    adminQuizUpdate(input: $input) {
      id
    }
  }
`;

const validationSchema = yup.object().shape({
  skillsetId: yup.string().required(),
});

function QuizzesEditForm() {
  const refOnce = useRef<boolean>();
  const quizId = useQuizId();
  const history = useHistory();
  const { loading: loadingSkillsets, data: dataSkillsets } = useSkillsets();
  const skillsets = dataSkillsets?.skillsetListGet || [];
  const { loading: loadingQuiz, data: dataQuiz } = useQuery<AdminQuizGet, AdminQuizGetVariables>(QUIZ_GET, {
    variables: { id: quizId },
  });
  const quiz = dataQuiz?.adminQuizGet;
  const [adminQuizUpdate] = useMutation<AdminQuizUpdate, AdminQuizUpdateVariables>(QUIZ_UPDATE);

  const onSubmit = (values: { skillsetId: string }) => {
    const input = { id: quizId, skillsetIds: [parseInt(values.skillsetId)] };
    adminQuizUpdate({ variables: { input } }).then(() => {
      history.push(PATH_UPDATE_LANDING);
    });
  };

  const form = useFormik({
    initialValues: {
      skillsetId: '',
    },
    validationSchema,
    onSubmit,
  });

  const isDirty = useMemo(() => {
    const formValue = form.values.skillsetId;
    const quizValue = String(quiz?.skillsets?.[0]?.id);

    if (!formValue || !quizValue) {
      return false;
    }
    return formValue !== String(quizValue);
  }, [form, quiz]);

  if (loadingSkillsets || loadingQuiz) {
    return <Loading />;
  }

  // Run only on first render after loading
  if (!refOnce.current) {
    refOnce.current = true;
    form.setValues({
      skillsetId: String(quiz!.skillsets![0]?.id),
    });
  }

  return (
    <Form noValidate onSubmit={form.handleSubmit}>
      <Form.Group controlId="skillsetId">
        <Form.Label>Skillsets</Form.Label>
        <Form.Control
          as="select"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.skillsetId || ''}
          isInvalid={!!(form.touched.skillsetId && form.errors.skillsetId)}
          disabled={form.isSubmitting}
          custom
          required
        >
          <option disabled value="" key="placeholder">
            Select skillset
          </option>
          {skillsets.map((skillset) => (
            <option value={skillset.id} key={skillset.id}>
              {skillset.name}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{form.errors.skillsetId}</Form.Control.Feedback>
      </Form.Group>

      <FormButton type="submit" form={form} disabled={!isDirty}>
        Submit
      </FormButton>
    </Form>
  );
}

export { QuizzesEditForm };
