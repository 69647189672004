import React from 'react';
import gql from 'graphql-tag';

import { AdminView } from '../../../shared/ui';
import { Html } from '../../../shared/html';

const SKILLSET_GET = gql`
  query AdminSkillsetGetForView($id: Int!) {
    adminSkillsetGet(id: $id) {
      id
      name
      description
    }
  }
`;

const BREADCRUMBS = [{ path: '/admin/skillsets', label: 'Skillsets' }, { label: 'View skillset' }];

const MAPPING = [
  { key: 'id' },
  { key: 'name' },
  { key: 'description', getter: (value: string) => <Html code={value} /> },
];

function SkillsetsView() {
  return <AdminView query={SKILLSET_GET} dataProp="adminSkillsetGet" breadcrumbs={BREADCRUMBS} mapping={MAPPING} />;
}

export { SkillsetsView };
