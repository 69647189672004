import { eqProps, uniqWith } from 'ramda';
import { debounce } from 'debounce';
import React, { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Form } from 'react-bootstrap';

import { axios } from '../../axios';

import { City } from './city';

const API_KEY = process.env.REACT_APP_PROFYLE_HERE_API_KEY;
const API_REQUEST_QUERY_MIN_LENGTH = 2;
const API_REQUEST_DELAY = 100;

type Option = City;

interface OptionsByTitle {
  [title: string]: Option;
}

const toOptionsByTitle = (options: Option[]): OptionsByTitle =>
  options.reduce<OptionsByTitle>(
    (a, o) => ({
      ...a,
      [o.title]: a[o.title] || o,
    }),
    {}
  );

interface CitiesResponseDataSuggestion {
  address: {
    city?: string;
  };
  countryCode?: string;
}
interface CitiesResponseData {
  suggestions: CitiesResponseDataSuggestion[];
}

const toCityTitle = ({ address: { city } }: CitiesResponseDataSuggestion): string => city || '';

const toCountryCode = ({ countryCode }: CitiesResponseDataSuggestion): string => (countryCode || '').toUpperCase();

const findOptions = (query: string): Promise<Option[]> =>
  axios
    .get<CitiesResponseData>('https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json', {
      params: {
        apikey: API_KEY,
        maxresults: 10,
        query,
      },
    })
    .then((res) => {
      const cities = res.data.suggestions
        .map<Option>((s) => ({
          title: toCityTitle(s),
          countryCode: toCountryCode(s),
        }))
        .filter((c) => Boolean(c.title));

      return uniqWith(eqProps('title'))(cities);
    });

interface Props {
  id: string;
  defaultValue?: City;
  isInvalid?: boolean;
  plaintextAndReadOnly?: boolean;
  onChange?: (city: City) => void;
  onBlur?: () => void;
}

function FormInputCity(props: Props) {
  const [options, setOptions] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const optionsByTitle = toOptionsByTitle(options);

  const handleSearch = (query: string) => {
    setIsLoading(true);
    findOptions(query).then((os) => {
      setOptions(os);
      setIsLoading(false);
    });
  };

  const handleChange = (selected: Option[]) => {
    const oneCity = selected.length ? selected[0] : { title: '', countryCode: '' };
    props.onChange && props.onChange(oneCity);
  };

  const handleInputChange = debounce((text: string) => {
    let one = optionsByTitle[text];
    if (!one) {
      one = { title: text };
    }

    handleChange([one]);
  }, API_REQUEST_DELAY + 50);

  const placeholder = 'Enter city';
  const defaultSelected = props.defaultValue ? [props.defaultValue] : [];

  if (props.plaintextAndReadOnly) {
    return (
      <Form.Control
        type="text"
        placeholder={placeholder}
        value={props.defaultValue?.title}
        isInvalid={props.isInvalid}
        plaintext
        readOnly
      />
    );
  }

  return (
    <AsyncTypeahead
      id={props.id}
      options={options}
      labelKey="title"
      onChange={handleChange}
      onSearch={handleSearch}
      delay={API_REQUEST_DELAY}
      filterBy={() => true}
      minLength={API_REQUEST_QUERY_MIN_LENGTH}
      maxResults={10}
      onInputChange={handleInputChange}
      onBlur={props.onBlur}
      defaultSelected={defaultSelected}
      isInvalid={props.isInvalid}
      isLoading={isLoading}
      placeholder={placeholder}
      highlightOnlyResult
    />
  );
}

export { FormInputCity };
