import gql from 'graphql-tag';

export const SESSION_SUCCESS = gql`
  mutation PaymentStripeCheckoutSessionSuccess($sessionId: String!) {
    paymentStripeCheckoutSessionSuccess(sessionId: $sessionId) {
      id
    }
  }
`;

export const COMPANY_SESSION_SUCCESS = gql`
  mutation PaymentStripeCheckoutSessionSuccessCompany($sessionId: String!) {
    paymentStripeCheckoutSessionSuccessCompany(sessionId: $sessionId) {
      id
    }
  }
`;

export const PATH_ON_BOARDING = '/on-boarding';
export const PATH_BILLING_INFO = '/company/billing-information';
