import React, { useState, useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { addMonths, format } from 'date-fns';

import { GetSalesReport, GetSalesReportVariables } from './types';
import { SALES_REPORT_GET } from '../../shared/document-node/sales-report';
import { LayoutDefault, AdminBreadcrumbs, Loading } from '../../shared/ui';
import { SalesTable } from './sales-table';
import { MonthsPicker } from './months-picker';

const BREADCRUMBS = [{ label: 'Sales report' }];

function monthDiff(d1: Date, d2: Date) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months + 1;
}

function Sales() {
  const [months, setMonths] = useState<Date[]>([addMonths(new Date(), -2), addMonths(new Date(), -1), new Date()]);
  const [getReport, { loading, data }] = useMutation<GetSalesReport, GetSalesReportVariables>(SALES_REPORT_GET);

  const onChange = useCallback((start, end) => {
    const arrayLength = monthDiff(start, end);
    const newMonths = Array(arrayLength)
      .fill(start.getMonth())
      .map((m, i) => addMonths(start, i));

    setMonths(newMonths);
  }, []);

  useEffect(() => {
    getReport({
      variables: { input: { monthList: months.map((month) => `${format(month, 'yyyy-MM')}-01T00:00:00`) } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [months]);

  return (
    <LayoutDefault>
      <AdminBreadcrumbs breadcrumbs={BREADCRUMBS} />
      <h2>Sales Report</h2>
      <MonthsPicker onChange={onChange} />
      {loading ? <Loading /> : <SalesTable data={data?.getSalesReport} months={months} />}
    </LayoutDefault>
  );
}

export { Sales };
