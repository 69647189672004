import React, { ReactNode } from 'react';
import { Container } from 'react-bootstrap';

import { Layout } from '../layout';
import { LayoutHeader } from '../layout-header';
import { LayoutBody } from '../layout-body';
import { LayoutFooter } from '../layout-footer';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
  @media (max-width: 767px) {
    padding: 0 8px;
  }
`;

interface Props {
  children: ReactNode;
}

function LayoutDefault({ children }: Props) {
  return (
    <Layout>
      <LayoutHeader />
      <LayoutBody>
        <StyledContainer>{children}</StyledContainer>
      </LayoutBody>
      <LayoutFooter />
    </Layout>
  );
}

export { LayoutDefault };
