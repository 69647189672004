import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AuthContextConsumer } from '../auth-context';
import { RoleName } from '../role-name';
import { CurrentUser } from '../current-user';
import { hasRole } from '../has-role';

const ROUTE_PATH_SIGN_IN = '/sign-in';
const ROUTE_PATH_HOME = '/';

interface Props extends RouteProps {
  notAuth?: boolean;
  role?: RoleName | RoleName[];
}

function ProtectedRoute({ children, render, notAuth, role, ...rest }: Props) {
  const routePath = notAuth ? ROUTE_PATH_HOME : ROUTE_PATH_SIGN_IN;

  if (notAuth && role) {
    throw new Error('Please use "notAuth" or "role" prop but not both');
  }

  return (
    <AuthContextConsumer>
      {({ isAuthenticated }) => (
        <Route
          {...rest}
          render={(renderProps) => {
            const routeSearch = notAuth ? undefined : `?redirect=${renderProps.match.url}`;
            const showBaseOnAuth = notAuth ? !isAuthenticated : isAuthenticated;

            const getChildren = () => (render ? render(renderProps) : children);

            const getRedirect = () => (
              <Redirect
                to={{
                  pathname: routePath,
                  search: routeSearch,
                  state: { from: renderProps.location },
                }}
              />
            );

            return showBaseOnAuth ? (
              <>
                {role ? (
                  <CurrentUser>{(user) => (hasRole(user, role) ? getChildren() : getRedirect())}</CurrentUser>
                ) : (
                  getChildren()
                )}
              </>
            ) : (
              getRedirect()
            );
          }}
        />
      )}
    </AuthContextConsumer>
  );
}

export { ProtectedRoute };
