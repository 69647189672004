import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Accordion, Card } from 'react-bootstrap';

import { TabBody, Loading, AccordionToggle, CardHeader } from '../../../shared/ui';

import { TextListGetAreasForInfo } from '../__generated__/TextListGetAreasForInfo';
import { Html } from '../../../shared/html';

const AREA_TEXT_LIST_GET = gql`
  query TextListGetAreasForInfo {
    textListGet(tagQuery: "area") {
      title
      body
    }
  }
`;

interface Params {
  profyle: {};
}

function TabBackground(params: Params) {
  const { loading, data } = useQuery<TextListGetAreasForInfo>(AREA_TEXT_LIST_GET);
  const areaTexts = data?.textListGet || [];

  if (loading) {
    return <Loading />;
  }

  return (
    <TabBody>
      <Accordion>
        {areaTexts.map((text, i) => (
          <Card key={i}>
            <CardHeader>
              <AccordionToggle eventKey={String(i)}>{text.title}</AccordionToggle>
            </CardHeader>
            <Accordion.Collapse eventKey={String(i)}>
              <Card.Body>
                <Html code={text.body} />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </TabBody>
  );
}

export { TabBackground };
