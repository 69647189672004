export const META_TAGS = {
  home: {
    title: 'Profyle helps you develop optimised skills for specific working roles',
    description:
      'The Profyle process identifies your preferred behaviours at work and suggest simple ways to adjust them to perform better in specific work roles. It provides simple development options and offers private structured and integrated 360 feedback',
  },
  profyleRoles: {
    title: 'We all play a range of roles at work',
    description:
      "Profyle helps each person to customize their preferred behaviour to work better in specific working roles. It doesn't change you it just helps you work better",
  },
  faq: {
    title: 'Profyle works in many ways',
    description:
      'Profyle can be for curious individuals who want to develop, for HR professionals who have responsibility for staff development, or for coaches and trainers working online or face to face.',
  },
  wsa: {
    title: 'Work Style Analyser',
    description: '',
  },
  contacts: {
    title: 'Profyle puts you in control of your development',
    description:
      'Profyle uses a unique process to identify your natural strengths and your preferred style before identifying how you can be more effective in your chosen role. Profyle than proposes development actions to help you improve your role.',
  },
};
