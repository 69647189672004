import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { GetOrderById, GetOrderByIdVariables } from './__generated__/GetOrderById';

const GET_ORDER_BY_ID = gql`
  query GetOrderById($id: Int!) {
    getCompanyOrderById(id: $id) {
      id
      userAmount
      skillsetAmount
      orderAmount
      isPaid
    }
  }
`;

export const useCompanyOrderById = (orderId: number) => {
  return useQuery<GetOrderById, GetOrderByIdVariables>(GET_ORDER_BY_ID, {
    variables: { id: orderId },
  });
};
