import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { SkillsetsAdd } from './skillsets-add';

function skillsetsAddRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/add`}
      render={() => (
        <>
          <SkillsetsAdd />
        </>
      )}
    />
  );
}

export { skillsetsAddRoute };
