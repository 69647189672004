import React from 'react';

interface Props {
  id: string;
  opacity?: number;
}

function GraphicFilterInnerShadow(props: Props) {
  return (
    <filter id={props.id} x="-50%" y="-50%" width="200%" height="200%">
      <feGaussianBlur in="SourceAlpha" stdDeviation="3" result="blur"></feGaussianBlur>
      <feOffset dy="2" dx="3"></feOffset>
      <feComposite in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowDiff"></feComposite>

      <feFlood floodColor="#444444" floodOpacity="0.05"></feFlood>
      <feComposite in2="shadowDiff" operator="in"></feComposite>
      <feComposite in2="SourceGraphic" operator="over" result="firstfilter"></feComposite>

      <feGaussianBlur in="firstfilter" stdDeviation="3" result="blur2"></feGaussianBlur>
      <feOffset dy="-2" dx="-3"></feOffset>
      <feComposite in2="firstfilter" operator="arithmetic" k2="-1" k3="1" result="shadowDiff"></feComposite>

      <feFlood floodColor="#444444" floodOpacity={props.opacity || 0.3}></feFlood>
      <feComposite in2="shadowDiff" operator="in"></feComposite>
      <feComposite in2="firstfilter" operator="over"></feComposite>
    </filter>
  );
}

export { GraphicFilterInnerShadow };
