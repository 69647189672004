import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';

import { ResetPassword } from './reset-password';

function resetPasswordRoute(routeProps: RouteComponentProps) {
  return (
    <ProtectedRoute path={`${routeProps.match.url}/reset`}>
      <ResetPassword />
    </ProtectedRoute>
  );
}

export { resetPasswordRoute };
