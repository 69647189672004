import React, { ReactNode, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ModalCreateAccountLayout } from './modal-create-account-layout';
import { auth } from '../../auth';
import { useResetCurrentUserAndHistoryPush } from '../../user';

const PATH_CREATE_PRIVATE_ACCOUNT_LANDING = '/sign-up';
const PATH_CREATE_CORPORATE_ACCOUNT_LANDING = '/sign-up-enterprise';

interface RenderProps {
  showModal: () => void;
}

interface Props {
  render: (renderProps: RenderProps) => ReactNode;
}

function ModalCreateAccount(props: Props) {
  const [show, setShow] = useState(false);
  const resetCurrentUserAndHistoryPush = useResetCurrentUserAndHistoryPush();
  const hideModal = () => setShow(false);
  const showModal = () => setShow(true);

  const history = useHistory();

  const createPrivateAccount = () => {
    hideModal();
    auth.unauthenticate().then(() => {
      resetCurrentUserAndHistoryPush(PATH_CREATE_PRIVATE_ACCOUNT_LANDING);
    });
  };
  const createCorporateAccount = () => {
    hideModal();
    history.push(PATH_CREATE_CORPORATE_ACCOUNT_LANDING);
  };

  return (
    <>
      {props.render({ showModal })}
      <ModalCreateAccountLayout
        show={show}
        onHide={hideModal}
        onCreatePrivateAccount={createPrivateAccount}
        onCreateCorporateAccount={createCorporateAccount}
      />
    </>
  );
}

export { ModalCreateAccount };
