import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';

import { PaymentLogs } from './payment-logs';

function paymentLogsRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/payment-logs`}
      render={(routeProps) => (
        <>
          <ProtectedRoute path={routeProps.match.url} exact>
            <PaymentLogs />
          </ProtectedRoute>
        </>
      )}
    />
  );
}

export { paymentLogsRoute };
