import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { prop } from 'ramda';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';

import { LayoutDefault, Loading, Button, ResponsiveTable } from '../shared/ui';
import { toFormatedDateTime } from '../shared/datetime';

import { ProfyleListGetVariables, ProfyleListGet } from './__generated__/ProfyleListGet';
import { useCurrentUser } from '../shared/auth';
import checkIcon from './images/check.svg';
import { QuizListGetForProfyle } from './__generated__/QuizListGetForProfyle';
import { QuizListGetAll } from './__generated__/QuizListGetAll';
import { PaymentProfyleRoleForm } from '../plans/form-plans/payment-profyle-role-form';
import styled from 'styled-components';
import { NotificationManager } from 'react-notifications';
import { useSkillsets } from '../shared/skillsets/use-skillsets';
import { RoleName } from '../shared/auth/role-name';
import { ITableMapping } from '../shared/ui/responsive-table/types';
import { SkillsetListGet_skillsetListGet } from '../shared/skillsets/__generated__/SkillsetListGet';
import { hasRole } from '../shared/auth/has-role';

const TableButton = styled(Button)<{ remove?: boolean }>`
  width: 129px;
  ${(props) =>
    props.remove &&
    `
    &, :hover, :active, :focus {
      background: white;
      color: #FFBE17
    }
    `}
`;

export const PROFYLE_LIST_GET = gql`
  query ProfyleListGet($orderBy: OrderByInput = { field: "createdAt", direction: DESC }) {
    profyleListGet(orderBy: $orderBy) {
      id
      createdAt
      report {
        isPaid
      }
      result {
        quiz {
          skillsets {
            name
            id
          }
        }
      }
    }
  }
`;

const QUIZ_LIST_GET = gql`
  query QuizListGetForProfyle {
    quizListGet {
      id
      skillsets {
        name
        id
      }
    }
  }
`;

const QUIZ_LIST_ALL_GET = gql`
  query QuizListGetAll {
    quizListAllGet {
      id
      skillsets {
        name
        id
      }
    }
  }
`;

function Profyle() {
  const { loading, data } = useQuery<ProfyleListGet, ProfyleListGetVariables>(PROFYLE_LIST_GET);
  const { loading: userLoading, data: userData } = useCurrentUser();
  const { loading: skillsetsLoading, data: skillsetsData } = useSkillsets();
  const { loading: loadingQuiz, data: quizData } = useQuery<QuizListGetForProfyle>(QUIZ_LIST_GET);
  const { loading: loadingAllQuiz, data: quizAllData } = useQuery<QuizListGetAll>(QUIZ_LIST_ALL_GET);

  const skillsets = useMemo(() => skillsetsData?.skillsetListGet || [], [skillsetsData]);
  const quizzes = quizData?.quizListGet;
  const allQuizzes = quizAllData?.quizListAllGet;
  const user = userData?.userCurrentGet;

  const [addedToBag, setAddedToBag] = useState<number[]>([]);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const usedRoles = data?.profyleListGet?.reduce<number[]>((acc, item) => {
    const ids = item.result.quiz.skillsets.map((i) => i.id);
    return [...acc, ...ids];
  }, []);

  const profyles = data?.profyleListGet;
  const userSkillsetIds = (user?.skillsets || []).map(prop('id'));

  const handleItemPurchase = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const skillsetId = Number(e.currentTarget.dataset.skillsetId);
      const skillsetName = skillsets.find((s) => s.id === skillsetId)?.name;
      if (addedToBag.includes(skillsetId)) {
        setAddedToBag(addedToBag.filter((id) => id !== skillsetId));
        NotificationManager.info(`"${skillsetName}" removed from the purchase`);
      } else {
        setAddedToBag([...addedToBag, skillsetId]);
        NotificationManager.info(`"${skillsetName}" Successfully added to the purchase`);
      }
    },
    [addedToBag, skillsets]
  );

  const handleItemExpand = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const skillsetId = Number(e.currentTarget.dataset.skillsetId);

      if (expandedRows.includes(skillsetId)) {
        setExpandedRows(expandedRows.filter((id) => id !== skillsetId));
      } else {
        setExpandedRows([...expandedRows, skillsetId]);
      }
    },
    [expandedRows]
  );

  const handleCouponSuccess = useCallback(() => {
    NotificationManager.success('Payment was successful');
    setAddedToBag([]);
  }, []);

  const getIsPaid = useCallback(
    (item: SkillsetListGet_skillsetListGet) => {
      const profyle = profyles?.find((p) => p.result.quiz.skillsets.some((s) => s.id === item.id));
      return profyle?.report.isPaid;
    },
    [profyles]
  );

  const mapping: ITableMapping<SkillsetListGet_skillsetListGet>[] = useMemo(() => {
    return [
      {
        label: 'Profyle role',
        getter: (item) => {
          return item.name;
        },
      },
      {
        label: 'Purchased',
        getter: (item) => {
          const isPaid = getIsPaid(item);
          // const isPurchased = userSkillsetIds.includes(item.id) || usedRoles?.includes(item.id);
          return isPaid ? <img src={checkIcon} alt="Purchased" /> : null;
        },
      },
      {
        label: 'Purchase',
        getter: (item) => {
          const quiz = quizzes?.find((q) => q.skillsets.some((s) => s.id === item.id));
          const notAvailable = !!quiz;
          const profyle = profyles?.find((p) => p.result.quiz.skillsets.some((s) => s.id === item.id));

          const isPaid = getIsPaid(item);
          // const isPurchased = userSkillsetIds.includes(item.id) || usedRoles?.includes(item.id);
          const canBuyAgain = !notAvailable && isPaid;
          const canBuy = !notAvailable && !isPaid;
          const isAdded = addedToBag.includes(item.id);
          return (
            <>
              {notAvailable && <span>Not available</span>}
              {!profyle && <span>Try for free</span>}
              {!notAvailable && profyle && (
                <TableButton data-skillset-id={item.id} onClick={handleItemPurchase} size="sm" remove={isAdded}>
                  {isAdded && 'Remove'}
                  {!isAdded && canBuyAgain && 'Buy Again'}
                  {!isAdded && canBuy && 'Buy'}
                </TableButton>
              )}
            </>
          );
        },
      },
      {
        label: 'Profyle Created',
        getter: (item) => {
          const quiz = quizzes?.find((q) => q.skillsets.some((s) => s.id === item.id));
          const notAvailable = !!quiz;
          const profyle = profyles?.find((p) => p.result.quiz.skillsets.some((s) => s.id === item.id));
          const quizId = allQuizzes?.find((q) => q.skillsets.some((s) => s.id === item.id))?.id;

          return (
            <>
              {notAvailable && quiz && (
                <TableButton className="btn btn-primary btn-sm" as={Link} to={`/collection/base-on-result/${quiz.id}`}>
                  Use Now
                </TableButton>
              )}
              {!notAvailable && profyle && toFormatedDateTime(profyle.createdAt)}
              {!notAvailable && !profyle && quizId && (
                <TableButton className="btn btn-primary btn-sm" as={Link} to={`/collection/base-on-result/${quizId}`}>
                  Use Now
                </TableButton>
              )}
            </>
          );
        },
      },
      {
        label: 'View',
        getter: (item) => {
          const profyle = profyles?.find((p) => p.result.quiz.skillsets.some((s) => s.id === item.id));
          return profyle && <Link to={`/profyle/${profyle.id}`}>View</Link>;
        },
      },
    ];
  }, [addedToBag, allQuizzes, getIsPaid, handleItemPurchase, profyles, quizzes]);

  const mappingAvailable = mapping.filter((item) => {
    if (item.label === 'Purchase' && user && hasRole(user, RoleName.ROLE_CORPORATE_CORE)) {
      return false;
    }
    return true;
  });

  const mobileRowHeader = (item: SkillsetListGet_skillsetListGet): ReactNode => {
    return item.name;
  };

  return (
    <LayoutDefault>
      <h2>
        My <span className="emphasis">Profyles</span>
      </h2>

      {loading || userLoading || skillsetsLoading || loadingQuiz ? (
        <Loading />
      ) : (
        <>
          <p>
            My <span className="emphasis">Profyles</span>
          </p>
          <ResponsiveTable
            data={skillsets}
            mapping={mappingAvailable}
            mobileHeader="Profyle role"
            mobileRowHeader={mobileRowHeader}
          />
        </>
      )}
      {!!addedToBag.length && (
        <>
          <br />
          <h2>Payment</h2>
          <PaymentProfyleRoleForm
            skillsetIds={addedToBag}
            redirect="/profyle"
            onApplyCouponSuccess={handleCouponSuccess}
          />
        </>
      )}
    </LayoutDefault>
  );
}

export { Profyle };
