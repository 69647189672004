import React, { ReactNode, useState } from 'react';
import * as yup from 'yup';

import { Button, FormActions, FormButton, City, FormInputCity, FormSelectCountry, LayoutContainer } from '../shared/ui';
import { FormMode } from '../shared/from';
import { Form } from 'react-bootstrap';
import { GetCompanyInfo_getCompanyInfo } from '../shared/company/__generated__/GetCompanyInfo';
import {
  companyIndustryOptions,
  companySizeOptions,
  SelectControl,
} from '../shared/user/my-info-form/my-info-form-layout/my-info-form-layout';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { UpdateCompanyInfoInput } from '../../../__generated__/globalTypes';
import { ActiveTabEnum, CompanyTabs } from './company-tabs';
import styled from 'styled-components';

const BlockButton = styled(Button)<{ blocked: boolean }>`
  ${(props) => {
    if (props.blocked) {
      return `
        &, :hover, :active, :focus {
          background: var(--dark);
          color: var(--white);
        }
      `;
    }
  }}
`;

interface ICompanyFormValues extends Omit<UpdateCompanyInfoInput, 'city'> {
  city: City;
}

const toValues = (company?: GetCompanyInfo_getCompanyInfo): ICompanyFormValues => ({
  name: company?.name || '',
  companyIndustry: company?.companyIndustry || '',
  country: company?.country || '',
  city: { title: company?.city || '' },
  companySize: company?.companySize || '',
  paymentMethod: company?.paymentMethod || '',
});

const validationSchema = yup.object().shape<ICompanyFormValues>({
  name: yup.string().trim().required('Company name is a required field'),
  companyIndustry: yup.string().trim().required('Company industry is a required field'),
  country: yup.string().trim().required('Country is a required field'),
  city: yup.object().shape({
    title: yup.string().trim().required('City is a required field'),
    countryCode: yup.string(),
  }),
  companySize: yup.string().trim().required('Company size is a required field'),
  paymentMethod: yup.string().trim().required('Payment method is a required field'),
});

const paymentMethodOptions = [
  {
    name: 'Prepaid',
    value: 'PREPAID',
  },
  {
    name: 'Postpaid',
    value: 'POSTPAID',
  },
  // 'Online',
  // 'Invoice'
];

interface IProps {
  company: GetCompanyInfo_getCompanyInfo;
  initialFormMode?: FormMode;
  onUpdateCompany?: (data: UpdateCompanyInfoInput) => void;
  headerText?: ReactNode;
  hideTabs?: boolean;
  hideActions?: boolean;
  ableToBlock?: boolean;
  onBlockCompany?: () => void;
}

const defaultHeaderText = 'More about your Company';

export const CompanyInfoForm: React.FC<IProps> = ({
  company,
  initialFormMode,
  headerText,
  hideTabs,
  hideActions,
  ableToBlock,
  onUpdateCompany,
  onBlockCompany,
}) => {
  const initialStatus = FormMode.Edit;
  const [formMode, setFormMode] = useState<FormMode>(initialFormMode || FormMode.Preview);

  const isEdit = formMode === FormMode.Edit;
  const isPreview = !isEdit;
  const hasPaymentMethod = !!company?.paymentMethod;

  const initialValues = toValues(company);

  const form = useFormik({
    initialStatus,
    initialValues,
    validationSchema,
    onSubmit: (values, actions) => {
      setFormMode(FormMode.Preview);
      if (typeof onUpdateCompany === 'function') {
        onUpdateCompany({ ...values, city: values.city.title });
      }
      actions.setSubmitting(false);
    },
    validateOnBlur: !isPreview,
    validateOnMount: true,
  });

  const handleChangeCity = (value: City) => {
    form.setFieldValue('city', value);
    if (!form.values.country) {
      form.setFieldValue('country', value.countryCode);
    }
  };

  const onCancel = () => {
    setFormMode(FormMode.Preview);
  };

  const onEdit = () => {
    setFormMode(FormMode.Edit);
  };

  return (
    <LayoutContainer>
      <h2>{headerText || defaultHeaderText}</h2>
      {!hideTabs && <CompanyTabs activeTab={ActiveTabEnum.INFO} />}
      <Form noValidate onSubmit={form.handleSubmit}>
        <div className="row">
          <div className="col-sm-6">
            <Form.Group controlId="name">
              <Form.Label>Company name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter company name"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.name}
                isInvalid={!!(form.touched.name && form.errors.name)}
                plaintext={isPreview}
                readOnly={isPreview}
                disabled={form.isSubmitting}
              />
              <Form.Control.Feedback type="invalid">{form.errors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="companySize">
              <Form.Label>Company size</Form.Label>
              <SelectControl
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.companySize}
                isInvalid={!!(form.touched.companySize && form.errors.companySize)}
                plaintext={isPreview}
                disabled={isPreview || form.isSubmitting}
                custom
                required
              >
                <option value="" key="placeholder">
                  Enter company size
                </option>
                {Object.entries(companySizeOptions).map(([value, label]) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </SelectControl>
              <Form.Control.Feedback type="invalid">{form.errors.companySize}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="companyIndustry">
              <Form.Label>Company industry</Form.Label>
              <SelectControl
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.companyIndustry}
                isInvalid={!!(form.touched.companyIndustry && form.errors.companyIndustry)}
                plaintext={isPreview}
                disabled={isPreview || form.isSubmitting}
                custom
                required
              >
                <option value="" key="placeholder">
                  Enter company industry
                </option>
                {companyIndustryOptions.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </SelectControl>
              <Form.Control.Feedback type="invalid">{form.errors.companyIndustry}</Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col-sm-6">
            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <FormInputCity
                id="my-info-form-input-city"
                onChange={handleChangeCity}
                onBlur={() => form.setFieldTouched('city', true)}
                defaultValue={form.values.city}
                isInvalid={!!(form.touched.city && form.errors.city)}
                plaintextAndReadOnly={isPreview || form.isSubmitting}
              />
              <Form.Control.Feedback type="invalid" className={classNames({ 'd-block': form.touched.city })}>
                {form.errors.city?.title}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="country">
              <Form.Label>Country</Form.Label>
              <FormSelectCountry
                id="my-info-form-select-country"
                onChange={(value) => form.setFieldValue('country', value)}
                onBlur={() => form.setFieldTouched('country', true)}
                value={form.values.country}
                isInvalid={!!(form.touched.country && form.errors.country)}
                plaintextAndReadOnly={isPreview || form.isSubmitting}
              />
              <Form.Control.Feedback type="invalid" className={classNames({ 'd-block': form.touched.country })}>
                {form.errors.country}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="paymentMethod">
              <Form.Label>Payment method</Form.Label>
              <SelectControl
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.paymentMethod}
                isInvalid={!!(form.touched.paymentMethod && form.errors.paymentMethod)}
                plaintext={isPreview || hasPaymentMethod}
                disabled={isPreview || form.isSubmitting || hasPaymentMethod}
                custom
                required
              >
                <option value="" key="placeholder">
                  Enter payment method
                </option>
                {paymentMethodOptions.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.name}
                  </option>
                ))}
              </SelectControl>
              <Form.Control.Feedback type="invalid">{form.errors.paymentMethod}</Form.Control.Feedback>
              {hasPaymentMethod && (
                <small className={classNames({ 'd-block': hasPaymentMethod })}>
                  If you want to change the payment method, please contact us:{' '}
                  <a href="mailto: profyle@info.com">profyle@info.com</a>
                </small>
              )}
            </Form.Group>
          </div>
        </div>
        {!hideActions && (
          <FormActions>
            {ableToBlock && (
              <BlockButton onClick={onBlockCompany} variant={'secondary'} className="mr-2" blocked={company.isBlocked}>
                {company.isBlocked ? 'Unblock' : 'Block'} Company
              </BlockButton>
            )}
            {formMode === FormMode.Edit ? (
              <>
                {/* <Button onClick={onCancel} variant="secondary">
                    Cancel
                  </Button> */}
                <FormButton type="submit" form={form} disabled={!form.isValid}>
                  Save
                </FormButton>
              </>
            ) : (
              <>
                <Button onClick={onEdit} variant={'primary'}>
                  Edit
                </Button>
              </>
            )}
          </FormActions>
        )}
      </Form>
    </LayoutContainer>
  );
};
