import React from 'react';
import { Form } from 'react-bootstrap';
import { FormikValues } from 'formik';

import { UseFormikReturnType } from '../../formik';
import { toLabel } from '../../helper';
import classNames from 'classnames';

interface Props<FormValues> {
  field: string;
  valueGetter?: (values: FormValues) => any;
  infoGetter?: (info: any) => any;
  label?: string;
  form: UseFormikReturnType<FormValues>;
  as?: React.ElementType;
}

function FormGroupText<FormValues extends FormikValues = FormikValues>(props: Props<FormValues>) {
  const { field, valueGetter, infoGetter, label, form, as } = {
    valueGetter: (values: FormValues) => values[props.field],
    infoGetter: (info: any) => info[props.field],
    label: toLabel(props.field),
    ...props,
  };

  return (
    <Form.Group controlId={field}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type="text"
        as={as}
        placeholder={`Enter ${label.toLocaleLowerCase()}`}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        value={valueGetter(form.values)}
        isInvalid={!!(infoGetter(form.touched) && infoGetter(form.errors))}
        disabled={form.isSubmitting}
        form={form}
      />

      <Form.Control.Feedback type="invalid" className={classNames({ 'd-block': infoGetter(form.touched) })}>
        {infoGetter(form.errors)}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export { FormGroupText };
