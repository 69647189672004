import { map, prop, fromPairs } from 'ramda';
import * as yup from 'yup';

const createValidationSchema = <T>(
  qs: Record<'id', number>[],
  validation: yup.Schema<T>
): yup.ObjectSchema<{ [id: string]: T }> => {
  const ids = map(prop<'id', number>('id'), qs);
  const pairs = map<number, [number, yup.Schema<T>]>((id) => [id, validation], ids);

  return yup.object(fromPairs(pairs));
};

export { createValidationSchema };
