import { prop } from 'ramda';
import React from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { useParamId } from '../../../../../shared/router';

import { useQuizIdQueryParam } from '../../use-quiz-id-query-param';

import {
  AdminQuizQuestionGet,
  AdminQuizQuestionGetVariables,
  AdminQuizQuestionGet_adminQuizQuestionGet,
} from './__generated__/AdminQuizQuestionGet';
import { AdminQuizQuestionUpdate, AdminQuizQuestionUpdateVariables } from './__generated__/AdminQuizQuestionUpdate';

import { QuestionsFormLayout } from '../../questions-form-layout';
import { Loading } from '../../../../../shared/ui';

interface Score {
  consideration: number;
  assertion: number;
  emotionalManagement: number;
  extroversion: number;
}

const toScore = (scorePointCategory: string): Score => ({
  ...{
    consideration: 0,
    assertion: 0,
    emotionalManagement: 0,
    extroversion: 0,
  },
  [scorePointCategory]: 1,
});

const toOptions = (
  ids: number[],
  labels: string[],
  categories: string[]
): { id: number; text: string; score: Score }[] =>
  labels.map((label, i) => ({
    id: ids[i],
    text: label,
    score: toScore(categories[i]),
  }));

const toInitialValues = (
  question: AdminQuizQuestionGet_adminQuizQuestionGet
): { optionLabels: string[]; optionScorePointCategory: string[] } => ({
  optionLabels: question.options.map((option) => option.text),
  optionScorePointCategory: question.options.map(
    (option) => Object.entries(option.score).find(([category, value]) => value !== 0)?.[0] || ''
  ),
});

const PATH_UPDATE_LANDING = '/admin/quizzes/questions';

const QUESTION_GET = gql`
  query AdminQuizQuestionGet($id: Int!) {
    adminQuizQuestionGet(id: $id) {
      id
      options {
        id
        text
        score {
          consideration
          assertion
          emotionalManagement
          extroversion
        }
      }
    }
  }
`;

const QUESTION_UPDATE = gql`
  mutation AdminQuizQuestionUpdate($input: AdminQuizQuestionUpdateInput!) {
    adminQuizQuestionUpdate(input: $input) {
      id
    }
  }
`;

function QuestionsEditForm() {
  const quizId = useQuizIdQueryParam();
  const questionId = useParamId();
  const history = useHistory();
  const { loading, data } = useQuery<AdminQuizQuestionGet, AdminQuizQuestionGetVariables>(QUESTION_GET, {
    variables: { id: questionId },
  });
  const question = data?.adminQuizQuestionGet;
  const [adminQuizQuestionUpdate] = useMutation<AdminQuizQuestionUpdate, AdminQuizQuestionUpdateVariables>(
    QUESTION_UPDATE
  );

  const onSubmit = (values: { optionLabels: string[]; optionScorePointCategory: string[] }) => {
    const input = {
      id: questionId,
      options: toOptions(question!.options.map(prop('id')), values.optionLabels, values.optionScorePointCategory),
    };
    adminQuizQuestionUpdate({ variables: { input } }).then(() => {
      history.push(`${PATH_UPDATE_LANDING}?quizId=${quizId}`);
    });
  };

  if (loading) {
    return <Loading />;
  }

  return <QuestionsFormLayout initialValues={toInitialValues(question!)} onSubmit={onSubmit} />;
}

export { QuestionsEditForm };
