import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useCurrentUser } from '../shared/auth';
import { hasRole } from '../shared/auth/has-role';
import { RoleName } from '../shared/auth/role-name';
import { META_TAGS } from '../shared/constants/metaTags';

import { LayoutDefault, AccordionToggle, CardHeader } from '../shared/ui';
import { FAQ_CORE_USER, FAQ_CORP_USER } from './constants';

function Faq() {
  const { data: userData } = useCurrentUser();
  const user = userData?.userCurrentGet;

  const isCorpUser = user && hasRole(user, RoleName.ROLE_CORPORATE_CORE);
  const faqData = isCorpUser ? FAQ_CORP_USER : FAQ_CORE_USER;

  return (
    <LayoutDefault>
      <Helmet>
        <title>{META_TAGS.faq.title}</title>
        <meta name="description" content={META_TAGS.faq.description} />
      </Helmet>
      <h2>FAQ</h2>

      <Accordion defaultActiveKey="0">
        {faqData.map(({ title, text }, index) => {
          return (
            <Card key={index}>
              <CardHeader>
                <AccordionToggle eventKey={String(index)}>{title}</AccordionToggle>
              </CardHeader>
              <Accordion.Collapse eventKey={String(index)}>
                <Card.Body>
                  {text.map((t, index) => {
                    return <Card.Text key={index}>{t}</Card.Text>;
                  })}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          );
        })}
      </Accordion>
    </LayoutDefault>
  );
}

export { Faq };
