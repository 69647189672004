import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { LayoutDefault, Loading } from '../../shared/ui';
import { toParagraphs } from '../../shared/helper';
import { toProfyleSkillsetNames } from '../../shared/profyle';

import {
  ReviewRequestProfyleGetForReviewPlain,
  ReviewRequestProfyleGetForReviewPlainVariables,
} from './__generated__/ReviewRequestProfyleGetForReviewPlain';

const REVIEW_REQUEST_PROFYLE_GET = gql`
  query ReviewRequestProfyleGetForReviewPlain($id: Int!) {
    reviewRequestProfyleGet(id: $id) {
      profyle {
        id
        owner {
          firstName
          lastName
        }
        report {
          text
        }
        result {
          quiz {
            skillsets {
              name
            }
          }
        }
      }
    }
  }
`;

interface RouteParams {
  reviewRequestProfyleId: string;
}

function Review() {
  const routeParams = useParams<RouteParams>();
  const reviewRequestProfyleId = parseInt(routeParams.reviewRequestProfyleId);

  const { loading, data } = useQuery<
    ReviewRequestProfyleGetForReviewPlain,
    ReviewRequestProfyleGetForReviewPlainVariables
  >(REVIEW_REQUEST_PROFYLE_GET, {
    variables: { id: reviewRequestProfyleId },
  });
  const profyle = data?.reviewRequestProfyleGet?.profyle;

  if (loading) {
    return <Loading />;
  }

  const prof = profyle!;
  const owner = prof.owner;

  const ownerFullName = `${owner.firstName} ${owner.lastName}`;
  const reportText = prof.report.text;
  const skillsetNames = toProfyleSkillsetNames(prof);

  return (
    <LayoutDefault>
      <h2>
        {ownerFullName} {skillsetNames} Report
      </h2>

      {toParagraphs(reportText)}
    </LayoutDefault>
  );
}

export { Review };
