import React from 'react';
import { Link } from 'react-router-dom';

import { LayoutDefault } from '../shared/ui';

function NotFound() {
  return (
    <LayoutDefault>
      <h2>Page is not found</h2>
      Please start from <Link to="/">home</Link> page!
    </LayoutDefault>
  );
}

export { NotFound };
