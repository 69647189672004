import React from 'react';
import { Link } from 'react-router-dom';

import { TabBody, TabActions } from '../../../shared/ui';
import { toParagraphs } from '../../../shared/helper';

interface Params {
  profyle: { report: { focusAreas: string } };
  nextUrl: string;
}

function TabFutureFocus(params: Params) {
  const focusAreas = params.profyle.report.focusAreas;

  return (
    <TabBody>
      <p>
        Your Profyle has indicated a set of behaviour preferences which may not all be optimised for the context you
        work in. So below you will find a set of Future Focus approaches that your Profyle has suggested you might want
        to look at working on to make sure you understand yourself better at work.
      </p>

      <ul>{toParagraphs(focusAreas, 'li')}</ul>

      <TabActions>
        <Link to={params.nextUrl}>Next</Link>
      </TabActions>
    </TabBody>
  );
}

export { TabFutureFocus };
