import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ReportsAdd } from './reports-add';

function reportsAddRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/add`}
      render={() => (
        <>
          <ReportsAdd />
        </>
      )}
    />
  );
}

export { reportsAddRoute };
