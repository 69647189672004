type Handler = (message: string | false, error: Err) => void;

class Err {
  private message: string | null = null;
  private handler: Handler | undefined;
  private ignoreOperationList: string[] = [];

  error(message: string): Promise<string | false> {
    this.setMessage(message);
    return this.process(message);
  }

  clean(): Promise<string | false> {
    this.removeMessage();
    return this.process(false);
  }

  subscribe(handler: Handler): void {
    this.handler = handler;
  }

  unsubscribe(handler: Handler): void {
    if (this.handler !== handler) {
      return;
    }

    this.handler = undefined;
  }

  getMessage(): string | false {
    if (this.message === null) {
      return false;
    }

    return this.message;
  }

  shouldIgnore(operationName: string): boolean {
    return this.ignoreOperationList.includes(operationName);
  }

  registerIgnoreOperation(operationName: string): void {
    if (this.ignoreOperationList.includes(operationName)) {
      return;
    }

    this.ignoreOperationList.push(operationName);
  }

  private setMessage(message: string): void {
    this.message = message;
  }

  private removeMessage(): void {
    this.message = null;
  }

  private process(message: string | false): Promise<string | false> {
    return Promise.resolve(message).then((m) => {
      if (this.handler) {
        this.handler(m, this);
      }

      return m;
    });
  }
}

const error = new Err();

export { Err, error };
