import React, { useState, ReactNode, useMemo } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form, FormControlProps } from 'react-bootstrap';
import classNames from 'classnames';
import { FormActions, Button, FormSelectCountry, FormInputCity, City, FormButton } from '../../../ui';
import { FormMode } from '../../../from';

import { UserInfoCurrentUpdateInput } from '../../../../../../__generated__/globalTypes';

import { MyInfoFormValues, MyInfoFormValuesInitial } from '../my-info-form-values';

enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

type GenderOptions = {
  [G in Gender]: string;
};

const genderOptions: GenderOptions = {
  [Gender.MALE]: 'Male',
  [Gender.FEMALE]: 'Female',
  [Gender.OTHER]: 'Other',
};

enum CompanySize {
  SIZE_FROM_1_TO_10 = 'SIZE_FROM_1_TO_10',
  SIZE_FROM_11_TO_50 = 'SIZE_FROM_11_TO_50',
  SIZE_FROM_51_TO_200 = 'SIZE_FROM_51_TO_200',
  SIZE_FROM_201_TO_500 = 'SIZE_FROM_201_TO_500',
  SIZE_FROM_501_TO_1000 = 'SIZE_FROM_501_TO_1000',
  SIZE_FROM_1001 = 'SIZE_FROM_1001',
}

type CompanySizeOptions = {
  [S in CompanySize]: string;
};

export const companySizeOptions: CompanySizeOptions = {
  [CompanySize.SIZE_FROM_1_TO_10]: '1-10 employees',
  [CompanySize.SIZE_FROM_11_TO_50]: '10-50 employees',
  [CompanySize.SIZE_FROM_51_TO_200]: '50-200 employees',
  [CompanySize.SIZE_FROM_201_TO_500]: '200-500 employees',
  [CompanySize.SIZE_FROM_501_TO_1000]: '500-1000 employees',
  [CompanySize.SIZE_FROM_1001]: 'More then 1000 employees',
};

export const companyIndustryOptions = [
  'Automotive',
  'Banking and Capital Markets',
  'Business Services',
  'Capital Projects and Infrastructure',
  'Charities',
  'Chemical',
  'Education',
  'Engineering and Construction',
  'Financial Services',
  'Forest, Paper and Packaging',
  'Government and Public Services',
  'Healthcare',
  'Hospitality and Leisure',
  'Insurance',
  'Manufacturing',
  'Media and Entertainment',
  'Mining and Metals',
  'Oil and Gas',
  'Pharmaceutical and Life Sciences',
  'Power and Utilities',
  'Private Equity',
  'Real Estate',
  'Retail and Consumer',
  'Software Development',
  'Sovereign Investment Funds',
  'Technology',
  'Other',
];

const defaultInitialStatus: FormMode = FormMode.Edit;

// @TODO: use styled components instead
export const SelectControl: React.FC<
  FormControlProps & {
    onBlur?: ((event: React.FocusEvent<HTMLSelectElement>) => void) | undefined;
    required?: boolean | undefined;
    placeholder?: string;
    className?: string;
  }
> = (props) => {
  return (
    <Form.Control as="select" style={{ color: !props.value ? 'var(--profyle-color-prompt)' : undefined }} {...props} />
  );
};

const validationSchema = yup.object().shape<MyInfoFormValues>({
  gender: yup.string().trim().required('Gender is a required field'),
  jobTitle: yup.string().trim().required('Job title is a required field'),
  companyName: yup.string().trim().required('Company name is a required field'),
  companySize: yup.string().trim().required('Company size is a required field'),
  companyDepartment: yup.string().trim().required('Department is a required field'),
  companyIndustry: yup.string().trim().required('Company industry is a required field'),
  city: yup.object().shape({
    title: yup.string().trim().required('City is a required field'),
    countryCode: yup.string(),
  }),
  country: yup.string().trim().required('Country is a required field'),
});

interface Props {
  initialStatus?: FormMode;
  initialValues?: MyInfoFormValuesInitial;
  submitOnSave?: boolean;
  isCorporateUser?: boolean;
  getTitle: (mode: FormMode) => string;
  getDescription: (mode: FormMode) => string;
  getLabelSubmit?: (mode: FormMode) => string;
  getAdditionalActions?: () => ReactNode;
  onSubmit: (values: UserInfoCurrentUpdateInput) => void | Promise<any>;
}

function MyInfoFormLayout(props: Props) {
  const initialStatus = props.initialStatus || defaultInitialStatus;
  const getLabelSubmit = props.getLabelSubmit || (() => 'Submit');
  const [formMode, setFormMode] = useState<FormMode>(initialStatus);

  const initialValues = useMemo(() => {
    const { gender, jobTitle, companyName, companySize, companyDepartment, companyIndustry, city, country } =
      props.initialValues || {};

    return {
      gender: gender || '',
      jobTitle: jobTitle || '',
      companyName: companyName || '',
      companySize: companySize || '',
      companyDepartment: companyDepartment || '',
      companyIndustry: companyIndustry || '',
      city: city || { title: '' },
      country: country || '',
    };
  }, [props.initialValues]);

  const isEdit = formMode === FormMode.Edit;
  const isPreview = !isEdit;

  const form = useFormik({
    initialStatus,
    initialValues,
    validationSchema,
    onSubmit: (values, actions) => {
      setFormMode(FormMode.Preview);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      props.submitOnSave && onSubmit();
      actions.setSubmitting(false);
    },
    validateOnBlur: !isPreview,
    validateOnMount: true,
  });

  const onChangeCity = (value: City) => {
    form.setFieldValue('city', value);
    if (!form.values.country) {
      form.setFieldValue('country', value.countryCode);
    }
  };

  const onCancel = () => {
    setFormMode(FormMode.Preview);
    form.resetForm();
  };

  const onEdit = () => {
    setFormMode(FormMode.Edit);
  };

  const onSubmit = () => {
    const values = {
      ...form.values,
      city: form.values.city.title,
    };

    return props.onSubmit(values);
  };

  return (
    <div className="w-75 m-auto">
      <h2>{props.getTitle(formMode)}</h2>
      <p>{props.getDescription(formMode)}</p>
      <Form noValidate onSubmit={form.handleSubmit}>
        <div className="row">
          <div className="col-sm-6">
            <Form.Group controlId="gender">
              <Form.Label>Gender</Form.Label>
              <SelectControl
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.gender}
                isInvalid={!!(form.touched.gender && form.errors.gender)}
                plaintext={isPreview}
                disabled={isPreview || form.isSubmitting}
                custom
                required
              >
                <option value="" key="placeholder">
                  Enter gender
                </option>
                {Object.entries(genderOptions).map(([value, label]) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </SelectControl>
              <Form.Control.Feedback type="invalid">{form.errors.gender}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="jobTitle">
              <Form.Label>Job title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter job title"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.jobTitle}
                isInvalid={!!(form.touched.jobTitle && form.errors.jobTitle)}
                plaintext={isPreview}
                readOnly={isPreview}
                disabled={form.isSubmitting}
              />
              <Form.Control.Feedback type="invalid">{form.errors.jobTitle}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="companyName">
              <Form.Label>Company name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter company name"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.companyName}
                isInvalid={!!(form.touched.companyName && form.errors.companyName)}
                plaintext={props.isCorporateUser || isPreview}
                readOnly={props.isCorporateUser || isPreview}
                disabled={form.isSubmitting}
              />
              <Form.Control.Feedback type="invalid">{form.errors.companyName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="companySize">
              <Form.Label>Company size</Form.Label>
              <SelectControl
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.companySize}
                isInvalid={!!(form.touched.companySize && form.errors.companySize)}
                plaintext={props.isCorporateUser || isPreview}
                disabled={props.isCorporateUser || isPreview || form.isSubmitting}
                custom
                required
              >
                <option value="" key="placeholder">
                  Enter company size
                </option>
                {Object.entries(companySizeOptions).map(([value, label]) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </SelectControl>
              <Form.Control.Feedback type="invalid">{form.errors.companySize}</Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="col-sm-6">
            <Form.Group controlId="companyDepartment">
              <Form.Label>Department</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter department"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.companyDepartment}
                isInvalid={!!(form.touched.companyDepartment && form.errors.companyDepartment)}
                plaintext={isPreview}
                readOnly={isPreview}
                disabled={form.isSubmitting}
              />
              <Form.Control.Feedback type="invalid">{form.errors.companyDepartment}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="companyIndustry">
              <Form.Label>Company industry</Form.Label>
              <SelectControl
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.companyIndustry}
                isInvalid={!!(form.touched.companyIndustry && form.errors.companyIndustry)}
                plaintext={props.isCorporateUser || isPreview}
                disabled={props.isCorporateUser || isPreview || form.isSubmitting}
                custom
                required
              >
                <option value="" key="placeholder">
                  Enter company industry
                </option>
                {companyIndustryOptions.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </SelectControl>
              <Form.Control.Feedback type="invalid">{form.errors.companyIndustry}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <FormInputCity
                id="my-info-form-input-city"
                onChange={onChangeCity}
                onBlur={() => form.setFieldTouched('city', true)}
                defaultValue={form.values.city}
                isInvalid={!!(form.touched.city && form.errors.city)}
                plaintextAndReadOnly={props.isCorporateUser || isPreview || form.isSubmitting}
              />
              <Form.Control.Feedback type="invalid" className={classNames({ 'd-block': form.touched.city })}>
                {form.errors.city?.title}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="country">
              <Form.Label>Country</Form.Label>
              <FormSelectCountry
                id="my-info-form-select-country"
                onChange={(value) => form.setFieldValue('country', value)}
                onBlur={() => form.setFieldTouched('country', true)}
                value={form.values.country}
                isInvalid={!!(form.touched.country && form.errors.country)}
                plaintextAndReadOnly={props.isCorporateUser || isPreview || form.isSubmitting}
              />
              <Form.Control.Feedback type="invalid" className={classNames({ 'd-block': form.touched.country })}>
                {form.errors.country}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <FormActions>
          {props.getAdditionalActions && props.getAdditionalActions()}
          {formMode === FormMode.Edit ? (
            <>
              {props.submitOnSave && (
                <Button onClick={onCancel} variant="secondary">
                  Cancel
                </Button>
              )}
              <FormButton type="submit" form={form} disabled={!form.isValid}>
                Save
              </FormButton>
            </>
          ) : (
            <>
              <Button onClick={onEdit} variant={props.submitOnSave ? 'primary' : 'secondary'}>
                Edit
              </Button>
              {!props.submitOnSave && <Button onClick={onSubmit}>{getLabelSubmit(formMode)}</Button>}
            </>
          )}
        </FormActions>
      </Form>
    </div>
  );
}

export { MyInfoFormLayout };
