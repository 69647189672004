import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../../shared/auth';

import { QuizzesQuizzes } from './quizzes';
import { quizzesAddRoute } from './quizzes-add';
import { quizzesEditRoute } from './quizzes-edit';

function quizzesQuizzesRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/quizzes`}
      render={(routeProps) => (
        <>
          <ProtectedRoute path={routeProps.match.url} exact>
            <QuizzesQuizzes />
          </ProtectedRoute>
          {quizzesAddRoute(routeProps)}
          {quizzesEditRoute(routeProps)}
        </>
      )}
    />
  );
}

export { quizzesQuizzesRoute };
