import React, { ReactNode } from 'react';
import { AuthContextConsumer } from '../auth-context';

interface Props {
  children: ReactNode;
}

function ShowForAuth({ children }: Props) {
  return <AuthContextConsumer>{({ isAuthenticated }) => (isAuthenticated ? children : null)}</AuthContextConsumer>;
}

export { ShowForAuth };
