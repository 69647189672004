import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { Button } from '../shared/ui';
import { Container, Form, Row, FormCheck, FormFile } from 'react-bootstrap';

import { UpdateCompanyLandingInfoInput } from '../../../__generated__/globalTypes';
import styled from 'styled-components';

const WhiteYellowButton = styled.label`
  color: var(--primary);
  border-color: var(--primary);
`;

const DndContainer = styled(Row)`
  border: 1px dashed var(--dark);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;

  & > div {
    margin: 24px;
  }

  & > div:last-child {
    margin-top: 0;
  }
`;

interface IProps {
  onUpdate: (data: UpdateCompanyLandingInfoInput) => void;
  onClose: () => void;
}

enum YesOrNo {
  Yes = 'yes',
  No = 'no',
}

export const BrandingForm: React.FC<IProps> = ({ onUpdate, onClose }) => {
  const [wantToBrand, setWantToBrand] = useState<boolean>();
  const [file, setFile] = useState<File>();
  const [logoUrl, setLogoUrl] = useState<string>();

  const handleRadioChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === YesOrNo.Yes;
    setWantToBrand(newValue);
  }, []);

  const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget?.files?.[0];
    setFile(file);
  }, []);

  const handleLogoUrlChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLogoUrl(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (logoUrl) {
      onUpdate({ logoUrl });
    } else {
      onClose();
    }
  }, [onUpdate, onClose, logoUrl]);

  const isButtonDisabled = useMemo(() => {
    if (wantToBrand === undefined) return true;
    if (wantToBrand && !logoUrl) return true;
    return false;
  }, [wantToBrand, logoUrl]);

  return (
    <Container>
      <Row className="justify-content-center">
        <h1>
          Brand the <span className="emphasis">Profyle</span>
        </h1>
      </Row>
      <Row>Do you want to brand the Profyle experience with company's logo and customise messaging</Row>
      <Row className="my-4">
        <FormCheck
          type="radio"
          label="Yes"
          id="yes"
          checked={wantToBrand}
          value={YesOrNo.Yes}
          onChange={handleRadioChange}
          custom
        />
      </Row>
      <Row className="my-4">
        <FormCheck
          type="radio"
          label="No"
          id="no"
          checked={wantToBrand === false}
          value={YesOrNo.No}
          onChange={handleRadioChange}
          custom
        />
      </Row>
      {wantToBrand && (
        <Row className="my-4">
          <Form.Control type="text" placeholder="logo url" onChange={handleLogoUrlChange} value={logoUrl} />
        </Row>
      )}
      {wantToBrand && (
        <DndContainer>
          <div>
            <p>Drag-and-drop your logo or select from your device</p>
          </div>
          <div>
            <WhiteYellowButton className="btn btn-secondary">
              Add an logo
              <FormFile custom className="d-none" accept="image/png, image/jpeg" onChange={handleFileChange} />
            </WhiteYellowButton>
          </div>
        </DndContainer>
      )}
      <Row className="justify-content-end">
        <Button onClick={handleSubmit} disabled={isButtonDisabled}>
          Done or Finish
        </Button>
      </Row>
    </Container>
  );
};
