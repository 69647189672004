import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export enum ActiveTabEnum {
  INFO = 'INFO',
  ADMINS = 'ADMINS',
}

const COMPANY_INFO_PATH = '/company-info';
const LIST_OF_ADMINS_PATH = '/company-info/list-of-admins';

interface IProps {
  activeTab: ActiveTabEnum;
}

const TabWrapper = styled.div<{ isActive: boolean }>`
  border-bottom: ${(props) => (props.isActive ? '2px solid var(--primary)' : 'none')};

  &:hover {
    border-bottom: 2px solid var(--primary);
  }

  &:first-child {
    margin-right: 64px;
  }

  & > a {
    text-decoration: none;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    font-family: var(--profyle-headings-font-family);
    color: ${(props) => (props.isActive ? 'var(--primary)' : 'var(--dark)')};

    &:hover {
      color: var(--primary);
    }
  }
`;

export const CompanyTabs: React.FC<IProps> = ({ activeTab }) => {
  return (
    <div className="d-flex mb-4">
      <TabWrapper isActive={activeTab === ActiveTabEnum.INFO}>
        <Link to={COMPANY_INFO_PATH}>Info</Link>
      </TabWrapper>
      <TabWrapper isActive={activeTab === ActiveTabEnum.ADMINS}>
        <Link to={LIST_OF_ADMINS_PATH}>Admins</Link>
      </TabWrapper>
    </div>
  );
};
