import React, { ReactNode } from 'react';
import { toLines } from './to-lines';

const toParagraphs = (text: string | undefined, Component: React.ElementType = 'p'): ReactNode => (
  <>
    {toLines(text).map((line, i) => (
      <Component key={i}>{line}</Component>
    ))}
  </>
);

export { toParagraphs };
