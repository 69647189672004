import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';

import { LayoutDefault } from '../shared/ui';
import { useQueryParams } from '../shared/router';

import { ProfyleReviewReviewRequestRequestedListGetIds } from './__generated__/ProfyleReviewReviewRequestRequestedListGetIds';

import { ProfyleReviewInviteForm } from './profyle-review-invite-form';

const ROUTE_PATH_STATUS = '/profyle-review/status';

const REQUEST_LIST_GET_IDS = gql`
  query ProfyleReviewReviewRequestRequestedListGetIds {
    profyleReviewReviewRequestRequestedListGet {
      id
    }
  }
`;

function ProfyleReview() {
  const queryParams = useQueryParams();
  const withStatus = queryParams.get('withStatus') !== null;
  const { loading, data } = useQuery<ProfyleReviewReviewRequestRequestedListGetIds>(REQUEST_LIST_GET_IDS);
  const requestIdsAssigned = data?.profyleReviewReviewRequestRequestedListGet || [];

  if (withStatus && !loading && requestIdsAssigned.length) {
    return <Redirect to={ROUTE_PATH_STATUS} />;
  }

  return <LayoutDefault>{(!withStatus || !loading) && <ProfyleReviewInviteForm />}</LayoutDefault>;
}

export { ProfyleReview };
