import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';

import { FormGroupText, FormButton, FormEditor } from '../../../shared/ui';

const defaultInitialValues = {
  title: '',
  body: '',
};

const validationSchema = yup.object().shape({
  title: yup.string().required('Title is a required field'),
  body: yup.string().required('Body is a required field'),
});

interface Params {
  onSubmit: (values: any) => void;
  initialValues?: { title: string; body: string };
}

function TextsFormLayout(params: Params) {
  const form = useFormik({
    initialValues: params.initialValues || defaultInitialValues,
    validationSchema,
    onSubmit: params.onSubmit,
  });

  return (
    <Form noValidate onSubmit={form.handleSubmit}>
      <FormGroupText field="title" form={form} />

      <FormGroupText field="body" form={form} as={FormEditor} />

      <br />
      <FormButton type="submit" form={form} disabled={!form.dirty || !form.isValid}>
        Submit
      </FormButton>
    </Form>
  );
}

export { TextsFormLayout };
