export const FAQ_CORP_USER = [
  {
    title: 'What can Profyle be used for?',
    text: [
      'Profyle has a wide range of applications. It can be for curious individuals who want to develop, for HR professionals and talent developers who have responsibility for staff development, or for coaches and trainers looking to add something new and different to their development programmes, either online or face to face.',
    ],
  },
  {
    title: 'Can Profyle be used online?',
    text: [
      'Profyle proves especially effective when added to development programmes delivered over WebEx, MS Teams, Zoom or other internet platforms. It is quick and easy to build into a range of programmes including; Leadership and Management, Influence, Coaching, Team Building, Negotiating or Facilitation.',
    ],
  },
  {
    title: 'How does Profyle work?',
    text: [
      'Profyle uses a unique process to identify an individual’s natural strengths and their preferred style before identifying how they can be more effective in your chosen role. Profyle than proposes development actions to help them improve in their role.',
    ],
  },
  {
    title: 'How does the Feedback process work?',
    text: [
      'The Profyle Feedback process allows each person to share the results of their Profyle with others that they trust to get feedback on their report and to support them in their development. Each person that is identified as a reviewer will be able to comment and provide direct and secure feedback.',
    ],
  },
  {
    title: 'What does each person get from using Profyle? ',
    text: [
      'Profyle provides each person with a set of tools to help them learn and develop in the role they have chosen to explore. These include a written textual review of work style, both strengths and limitations, a summary of areas that might be worth focusing on, a set of immediate actions that can be taken to start to optimise style and a structured development path that can be used to ensure lasting change.',
    ],
  },
  {
    title: 'What are focus areas?',
    text: [
      'Focus areas are specific skills and behaviours that Profyle suggests an individual might want to adjust in order to help them optimise their role and to ensure that their development is invested in the right areas.',
    ],
  },
  {
    title: 'How can we use Profyle in our company?',
    text: [
      'If you use Profyle for your employees then you have a powerful new way to start developing your people that accelerates almost any learning programme. Having access to their Profyles in advance, as a coach or trainer, you get a head start in understanding who you will be working with and how they like to operate both as individuals and collectively.',
    ],
  },
  {
    title: 'How does Profyle differ from other profiling systems?',
    text: [
      'Profyle is designed so that anyone can use it without the need for an intermediary, special qualifications or licence fees. Profyle can manage a complete development journey, using trusted others as guides, to ensure that each person can understand themselves better and take control of their own development.',
    ],
  },
  {
    title: 'How will my organisation benefit from using Profyle? ',
    text: [
      'A significant saving on the Learning and Development budget. Not only does Profyle do a lot of the ground work for you but it also ensures that each individual has development ideas that are selected for them. Profyle accelerates development accurately, meaning less waste of the development budget.',
    ],
  },
  {
    title: 'How do I purchase Profyle?',
    text: [
      'Profyle can be purchased by your organisation or use within the workplace. You can see all of the pricing options on this page.',
    ],
  },
  {
    title: 'Is there any research to back up the Profyle approach? ',
    text: [
      'Yes, of course. It took us over ten years of testing and thousands of people to validate the Profyle process. The system has been through many upgrades over that time to improve accuracy and ensure that results are easier to understand and interpret.',
      'The four scales that Profyle uses as its base are all recognised psychological concepts taken from the work of Carl Jung and others. The decision pairs within the system are all unique to Profyle.',
      'The Personal Assignment ideas and Instant Actions are a catalogue of practical skill development concepts that we, as a team of coaches and trainers, have been collecting during our work over many years and have been brought together in Profyle for the first time.',
    ],
  },
  {
    title: 'How does Profyle compare to MBTI, Disc, Firo B, 16PF etc?',
    text: [
      'We are big fans of all instruments and we respect the contribution that they have made, not only to our industry, but the way they have also inspired us. But Profyle is very different.',
      'We do not have a four-box model. Neither do we classify people into one of four or sixteen different boxes. Profyle recognises multiple differences in people and variety in styles of work.',
      'Profyle is not focused on numbers, scales and boxes but provides detailed reports, focus areas and practical ideas for skills development. In addition, Profyle has included a unique step – Profyle Feedback - so that everyone can have their results validated by people who know them well. This means that Profyle’s results are not limited to your own perception but are further validated and challenged by how others experience you.',
    ],
  },
  {
    title: 'What is the theory behind Profyle? ',
    text: [
      'Inspired by both our competitors and collaborators in Learning and Development and the Psychometric world, the big idea is to go beyond numbers, boxes and theories. Profyle creates an integrated system that takes everyone on their own development path towards being the best version of themselves that they can be.',
      'Profyle provides not just insight and awareness but also 360 validation and practical, often fun, ways of developing.',
    ],
  },
  {
    title: 'How much does Profyle cost?',
    text: [
      'Profyle likes to make things as simple as possible and this includes how you pay for the service.',
      'If you are buying for a company there are discounted enterprise offers that we are able to offer, dependent on your need, so please contact us.',
    ],
  },
];
