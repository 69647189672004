import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../../shared/auth';

import { Reports } from './reports';
import { reportsAddRoute } from './reports-add';
import { reportsViewRoute } from './reports-view';
import { reportsEditRoute } from './reports-edit';

function reportsRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/reports`}
      render={(routeProps) => (
        <>
          <ProtectedRoute path={routeProps.match.url} exact>
            <Reports />
          </ProtectedRoute>
          {reportsAddRoute(routeProps)}
          {reportsViewRoute(routeProps)}
          {reportsEditRoute(routeProps)}
        </>
      )}
    />
  );
}

export { reportsRoute };
