import React from 'react';

import { ProtectedRoute } from '../shared/auth';

import { paymentSuccessRoute } from './payment-success';
import { paymentCancelRoute } from './payment-cancel';

function paymentRoute() {
  return (
    <ProtectedRoute
      path="/payment"
      render={(routeProps) => (
        <>
          {paymentSuccessRoute(routeProps)}
          {paymentCancelRoute(routeProps)}
        </>
      )}
    />
  );
}

export { paymentRoute };
