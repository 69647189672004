import React from 'react';

import { LayoutDefault, AdminBreadcrumbs } from '../../../shared/ui';

import { SkillsetsAddForm } from './skillsets-add-form';

const BREADCRUMBS = [{ path: '/admin/skillsets', label: 'Skillsets' }, { label: 'Add skillset' }];

function SkillsetsAdd() {
  return (
    <LayoutDefault>
      <h2>Add skillset</h2>
      <AdminBreadcrumbs breadcrumbs={BREADCRUMBS} />
      <SkillsetsAddForm />
    </LayoutDefault>
  );
}

export { SkillsetsAdd };
