import React from 'react';

import { LayoutDefault } from '../../shared/ui';

function PaymentCancel() {
  return (
    <LayoutDefault>
      <h2>Payment Error</h2>
      Payment was unsuccessful. Please try later.
    </LayoutDefault>
  );
}

export { PaymentCancel };
