import { equals } from 'ramda';
import React, { useRef } from 'react';
import Slider from 'rc-slider';
import styled from 'styled-components';

const SliderWrapper = styled.div`
  padding: 3rem;

  @media (max-width: 767px) {
    padding: 15px;
  }
`;

const SliderStyled = styled(Slider)`
  height: 4rem;

  & .rc-slider-rail {
    height: 1rem;
    background-color: #e9ecef;
    cursor: pointer;
  }

  & .rc-slider-dot {
    bottom: -1rem;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: -0.75rem;
    border-radius: 50%;
    color: #e9e9e9;
  }

  .rc-slider-dot-active {
    border-color: var(--primary);
  }

  & .rc-slider-track {
    height: 1rem;
    background-color: var(--primary);
    cursor: pointer;
  }

  & .rc-slider-handle {
    width: 2rem;
    height: 2rem;
    margin-top: -0.5rem;
    border-color: var(--primary);
  }

  .rc-slider-handle:active {
    border-color: var(--primary);
    box-shadow: 0 0 5px var(--primary);
  }

  & .rc-slider-mark {
    top: 2rem;
    font-size: 1rem;
  }

  @media (max-width: 767px) {
    .rc-slider-mark-text {
      max-width: 50px;
      font-size: 14px;
    }
  }
`;

interface Props<O extends Record<string, string | number> = Record<string, string | number>> {
  options: O;
  defaultValue?: keyof O;
  onChange?: (value: keyof O) => void;
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
}

const FormSlider = (props: Props) => {
  const ref = useRef<boolean>();
  const onChange = (index: number) => {
    const value = Object.keys(props.options)[index];
    if (props.onChange) {
      return props.onChange(value);
    }
  };
  const defaultValue = props.defaultValue || Object.keys(props.options)[0];
  const minIndex = 0;
  const maxIndex = Object.entries(props.options).length - 1;
  const defaultValueIndex = Object.keys(props.options).findIndex(equals(defaultValue));
  const marks = Object.values(props.options);

  // Run only on first render
  if (!ref.current) {
    ref.current = true;
    onChange(defaultValueIndex);
  }

  return (
    <SliderWrapper>
      <SliderStyled
        onChange={onChange}
        onBlur={props.onBlur}
        min={minIndex}
        max={maxIndex}
        defaultValue={defaultValueIndex}
        marks={marks}
        step={null}
      />
    </SliderWrapper>
  );
};

export { FormSlider };
