import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Tabs, Tab, Table } from 'react-bootstrap';

import { LayoutDefault, TabBody } from '../shared/ui';

function Background() {
  const history = useHistory();
  const routeParams = useParams<{ section: string }>();
  const tabsActiveKey = routeParams.section || '';
  const tabsOnSelect = (key: string) => {
    const pathEnding = key.length ? `/${key}` : '';
    history.push(`/background${pathEnding}`);
  };

  return (
    <LayoutDefault>
      <h2>Profyle Background</h2>
      <Tabs activeKey={tabsActiveKey} onSelect={tabsOnSelect} id="background-tabs">
        <Tab eventKey="" title="Background">
          <TabBody>
            <p>Here you can find background behind the Profyle process and get information on each of the areas:</p>
            <ul>
              <li>
                <Link to="/background/score-implications">Score implications</Link>
              </li>
              <li>
                <Link to="/background/consideration">Consideration</Link>
              </li>
              <li>
                <Link to="/background/assertion">Assertion</Link>
              </li>
              <li>
                <Link to="/background/emotional-management">Emotional management</Link>
              </li>
              <li>
                <Link to="/background/extroversion">Extroversion</Link>
              </li>
            </ul>
          </TabBody>
        </Tab>

        <Tab eventKey="score-implications" title="Score implications">
          <TabBody>
            <Table>
              <thead>
                <tr>
                  <th>Score</th>
                  <th>Implications</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-nowrap">0-2</td>
                  <td>
                    Extreame Low Score: Clear advantages and limitaions, but equally you are likely to be lacking in
                    flexibility on this dimention
                  </td>
                </tr>
                <tr>
                  <td className="text-nowrap">3-6</td>
                  <td>
                    Low Score: Clear preference for this end of the dimention so most of the advantages and limitations
                    are likely to be true, but you will have more flexibility than if you had a more extreme score
                  </td>
                </tr>
                <tr>
                  <td className="text-nowrap">7-9</td>
                  <td>
                    Balanced Score: A flexible style where aspects of both ends of the scale are likely to be true
                  </td>
                </tr>
                <tr>
                  <td className="text-nowrap">10-13</td>
                  <td>
                    High Score: Clear preference for this end of the dimention so most of the advantages and limitations
                    are likely to be true, but equally you will have more flexibility then if you had a more extreme
                    score
                  </td>
                </tr>
                <tr>
                  <td className="text-nowrap">14-16</td>
                  <td>
                    Extreme High Score: Clear advantages and limitaions, but you are likely to be lacking in flexibility
                    on this dimention
                  </td>
                </tr>
              </tbody>
            </Table>
          </TabBody>
        </Tab>

        <Tab eventKey="consideration" title="Consideration">
          <TabBody>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </TabBody>
        </Tab>

        <Tab eventKey="assertation" title="Assertation">
          <TabBody>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </TabBody>
        </Tab>

        <Tab eventKey="emotional-management" title="Emotional Management">
          <TabBody>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </TabBody>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </Tab>

        <Tab eventKey="extroversion" title="Extroversion">
          <TabBody>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </TabBody>
        </Tab>
      </Tabs>
    </LayoutDefault>
  );
}

export { Background };
