import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';

import { LayoutDefault } from '../shared/ui';

import { Profyle360Form } from './profyle-360-form';
import { useQueryParams } from '../shared/router';

import { Profyle360ReviewRequestRequestedListGetIds } from './__generated__/Profyle360ReviewRequestRequestedListGetIds';

const ROUTE_PATH_STATUS = '/profyle-360/status';

const REQUEST_LIST_GET_IDS = gql`
  query Profyle360ReviewRequestRequestedListGetIds {
    profyle360ReviewRequestRequestedListGet {
      id
    }
  }
`;

function Profyle360() {
  const queryParams = useQueryParams();
  const withStatus = queryParams.get('withStatus') !== null;
  const { loading, data } = useQuery<Profyle360ReviewRequestRequestedListGetIds>(REQUEST_LIST_GET_IDS);
  const requestIdsRequested = data?.profyle360ReviewRequestRequestedListGet || [];

  if (withStatus && !loading && requestIdsRequested.length) {
    return <Redirect to={ROUTE_PATH_STATUS} />;
  }

  return <LayoutDefault>{(!withStatus || !loading) && <Profyle360Form />}</LayoutDefault>;
}

export { Profyle360 };
