import React from 'react';
import { ModalWindow } from '../../shared/ui';

interface IProps {
  text: string;
  show: boolean;
  onConfirm: () => void;
}

export const ModalSent: React.FC<IProps> = (props) => (
  <ModalWindow {...props} confirmText="OK" title="Invitation sent" />
);
