import { error } from './error';

export interface ErrorValue {
  message: string | false;
  error: typeof error.error;
  clean: typeof error.clean;
}

export const errorValueDefault: ErrorValue = {
  message: error.getMessage(),
  error: (message: string) => error.error(message),
  clean: () => error.clean(),
};
