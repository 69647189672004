import { WizardContext } from 'react-albus';

const getProgressPercents = (wizard: WizardContext): number => {
  if (!wizard.step.id) {
    return 0;
  }

  let stepIndex = 0;
  for (const step of wizard.steps) {
    if (step.id === wizard.step.id) {
      break;
    }

    stepIndex++;
  }

  return (100 / wizard.steps.length) * stepIndex;
};

export { getProgressPercents };
