import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';

import { Companies } from './companies';
import { CompaniesView } from './companies-view';
import { CompaniesEdit } from './companies-edit';

function companiesRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/companies`}
      render={(routeProps) => {
        console.log(routeProps);
        return (
          <>
            <ProtectedRoute path={routeProps.match.url} exact>
              <Companies />
            </ProtectedRoute>
            <ProtectedRoute path={`${routeProps.match.url}/view/:id`} exact>
              <CompaniesView />
            </ProtectedRoute>
            <ProtectedRoute path={`${routeProps.match.url}/edit/:id`} exact>
              <CompaniesEdit />
            </ProtectedRoute>
          </>
        );
      }}
    />
  );
}

export { companiesRoute };
