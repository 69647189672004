import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';

import { TabBody, TabActions, FormGroupSelect } from '../../../shared/ui';
import { Score } from '../shared/score';

import { GraphicCompareYourselfWithHistory } from './graphic-compare-yourself-with-history';
import { calcMaxScoreDiff } from '../shared/calc-max-score-diff';
import { prop } from 'ramda';

const areas = {
  A: 'Assertion',
  C: 'Consideration',
  EM: 'Emotional Management',
  E: 'Extroversion',
  S: 'Span',
};

const areaValueGetters = {
  A: prop('assertion'),
  C: prop('consideration'),
  EM: prop('emotionalManagement'),
  E: prop('extroversion'),
  S: (s: Score) => calcMaxScoreDiff(s.assertion, s.consideration, s.emotionalManagement, s.extroversion),
};

const gender = ['MALE', 'FEMALE', 'OTHER'];

const initialValues = {
  area: 'A',
  gender: 'MALE',
};

const getArea = (v: string | undefined) => (v ? (areas as any)[v] : '');

const getScore = (score: Score, area: keyof typeof areas) => (areaValueGetters[area] as any)(score);

interface Params {
  profyle: { id: number; result: { score: Score } };
  nextUrl: string;
}

function TabCompareYourself(params: Params) {
  const form = useFormik<typeof initialValues>({
    initialValues,
    onSubmit: () => undefined,
  });

  const userScoreValue = getScore(params.profyle.result.score, form.values.area as any);
  const area = getArea(form.values.area);
  return (
    <TabBody>
      {/* <p></p> */}
      <Form noValidate>
        <FormGroupSelect field="area" label="Area" options={Object.keys(areas)} toOptionLabel={getArea} form={form} />

        <FormGroupSelect field="gender" label="Gender" options={gender} form={form} />
      </Form>
      <br />
      Your {area} is <strong>{userScoreValue}</strong>.
      <br />
      <br />
      <GraphicCompareYourselfWithHistory
        profyleId={params.profyle.id}
        area={form.values.area}
        gender={form.values.gender}
        userScoreValue={userScoreValue}
      />
      <TabActions>
        <Link to={params.nextUrl}>Next</Link>
      </TabActions>
    </TabBody>
  );
}

export { TabCompareYourself };
