import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter as Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { CompanyContextProvider } from './app/shared/company/company-context';

import './index.scss';

import * as serviceWorker from './serviceWorker';

import apolloClient from './apollo';
import { App } from './app';

function Root() {
  return (
    // react-albus uses old context api
    // <StrictMode>
    <ApolloProvider client={apolloClient}>
      <DndProvider backend={HTML5Backend}>
        <Router>
          <LastLocationProvider>
            <CompanyContextProvider>
              <App />
            </CompanyContextProvider>
          </LastLocationProvider>
        </Router>
      </DndProvider>
    </ApolloProvider>
    // </StrictMode>
  );
}

const rootElement = document.getElementById('root');
if (rootElement?.hasChildNodes()) {
  ReactDOM.hydrate(<Root />, rootElement);
} else {
  ReactDOM.render(<Root />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
