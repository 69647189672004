import React from 'react';
import gql from 'graphql-tag';

import { AdminView } from '../../../shared/ui';
import { Html } from '../../../shared/html';

const SETTING_GET = gql`
  query AdminSettingGetForView($id: Int!) {
    adminSettingGet(id: $id) {
      id
      tag
      title
      value
    }
  }
`;

const BREADCRUMBS = [{ path: '/admin/settings', label: 'Settings' }, { label: 'View setting' }];

const MAPPING = [
  { key: 'id' },
  { key: 'tag' },
  { key: 'title' },
  { key: 'value', getter: (value: string) => <Html code={value} /> },
];

function SettingsView() {
  return <AdminView query={SETTING_GET} dataProp="adminSettingGet" breadcrumbs={BREADCRUMBS} mapping={MAPPING} />;
}

export { SettingsView };
