import { omit } from 'ramda';
import React from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { AdminRoleListGetForUserAdd } from './__generated__/AdminRoleListGetForUserAdd';
import { AdminUserAdd, AdminUserAddVariables } from './__generated__/AdminUserAdd';

import { UsersFormLayout, FormValues } from '../../users-form-layout';
import { Loading } from '../../../../shared/ui';

const PATH_ADD_LANDING = '/admin/users';

const ROLE_LIST_GET = gql`
  query AdminRoleListGetForUserAdd {
    adminRoleListGet {
      role
    }
  }
`;

const USER_ADD = gql`
  mutation AdminUserAdd($input: AdminUserAddInput!) {
    adminUserAdd(input: $input) {
      id
    }
  }
`;

function UsersAddForm() {
  const history = useHistory();
  const { loading, data } = useQuery<AdminRoleListGetForUserAdd>(ROLE_LIST_GET);
  const roles = data?.adminRoleListGet || [];
  const [adminUserAdd] = useMutation<AdminUserAdd, AdminUserAddVariables>(USER_ADD);

  const onSubmit = (values: FormValues) => {
    const input = { ...omit(['role'], values), roles: [{ role: values.role }] };
    adminUserAdd({ variables: { input } }).then(() => {
      history.push(PATH_ADD_LANDING);
    });
  };

  if (loading) {
    return <Loading />;
  }

  return <UsersFormLayout onSubmit={onSubmit} roles={roles.map((r) => r.role)} />;
}

export { UsersAddForm };
