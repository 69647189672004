import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { Loading } from '../../../../shared/ui';

import { GraphicCompareYourself } from '../graphic-compare-yourself';

import {
  AnalysisHistoryAggregationGet,
  AnalysisHistoryAggregationGetVariables,
} from './__generated__/AnalysisHistoryAggregationGet';

const ANALYSIS_HISTORY_AGG_GET = gql`
  query AnalysisHistoryAggregationGet($input: AnalysisHistoryAggregationGetInput!) {
    analysisHistoryAggregationGet(input: $input) {
      scoreValue
      userPercent
    }
  }
`;

interface Props {
  profyleId: number;
  area: string;
  gender: string;
  userScoreValue: number;
}

function GraphicCompareYourselfWithHistory(props: Props) {
  const { loading, data } = useQuery<AnalysisHistoryAggregationGet, AnalysisHistoryAggregationGetVariables>(
    ANALYSIS_HISTORY_AGG_GET,
    {
      variables: {
        input: {
          profyleId: props.profyleId,
          area: props.area,
          gender: props.gender,
        },
      },
    }
  );
  const agg = data?.analysisHistoryAggregationGet || [];

  if (loading) {
    return <Loading />;
  }

  if (!agg.length) {
    return (
      <>
        <strong>No data for your selected parameters.</strong>
      </>
    );
  }

  return <GraphicCompareYourself analyticsHistoryAggreagation={agg} userScoreValue={props.userScoreValue} />;
}

export { GraphicCompareYourselfWithHistory };
