import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { QuestionsEdit } from './questions-edit';

function questionsEditRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/edit/:id`}
      render={() => (
        <>
          <QuestionsEdit />
        </>
      )}
    />
  );
}

export { questionsEditRoute };
