import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import playImage from './images/play.svg';
import pauseImage from './images/pause.svg';

interface Props {
  src: string;
  width: string | number;
  height: string | number;
  poster?: string;
}

const Container = styled.div<{ isPlaying: boolean }>`
  position: relative;
  cursor: pointer;

  button {
    display: ${(props) => (props.isPlaying ? 'none' : 'inline-block')};
  }

  &:hover {
    button {
      display: inline-block;
    }
  }
`;

const PlayPauseButton = styled.button<{ isPlaying: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 64px;
  height: 64px;
  color: black;
  border: none;
  border-radius: 100%;
  pointer-events: none;
  background-image: url(${(props) => (props.isPlaying ? pauseImage : playImage)});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &:focus {
    outline: none;
    border: 1px solid #e3a300;
  }

  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
  }
`;

const VideoPlayer: React.FC<Props> = ({ src, poster, width, height }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = useCallback(() => {
    const myVideo = videoRef.current;

    if (myVideo?.paused) {
      myVideo?.play();
      setIsPlaying(true);
    } else {
      myVideo?.pause();
      setIsPlaying(false);
    }
  }, []);

  const handleEnded = () => {
    setIsPlaying(false);
  };

  return (
    <Container isPlaying={isPlaying} onClick={handleClick}>
      <PlayPauseButton isPlaying={isPlaying} />
      <video ref={videoRef} preload="metadata" width={width} height={height} poster={poster} onEnded={handleEnded}>
        <source src={src} type="video/mp4" />
        <p>
          Your browser doesn't support HTML5 video. Here is a <a href={src}>link to the video</a> instead.
        </p>
      </video>
    </Container>
  );
};

export { VideoPlayer };
