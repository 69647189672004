import React from 'react';

import { LayoutDefault, AdminBreadcrumbs } from '../../../../shared/ui';
import { ReportsEditForm } from './reports-edit-form';

const BREADCRUMBS = [
  { path: '/admin/profyles', label: 'Profyles' },
  { path: '/admin/profyles/reports', label: 'Reports' },
  { label: 'Edit report' },
];

function ReportsEdit() {
  return (
    <LayoutDefault>
      <h2>Edit report</h2>
      <AdminBreadcrumbs breadcrumbs={BREADCRUMBS} />

      <ReportsEditForm />
    </LayoutDefault>
  );
}

export { ReportsEdit };
