import React from 'react';

import { LayoutDefault } from '../shared/ui';
import { SignUpForm } from './sign-up-form';

function SignUpEnterprise() {
  return (
    <LayoutDefault>
      <SignUpForm />
    </LayoutDefault>
  );
}

export { SignUpEnterprise };
