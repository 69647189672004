import React from 'react';
import gql from 'graphql-tag';
import { useRouteMatch } from 'react-router-dom';

import { AdminList } from '../../../shared/ui';

import { AdminQuizQuestionListGet_adminQuizQuestionListGet } from './__generated__/AdminQuizQuestionListGet';
import { useQuizIdQueryParam } from './use-quiz-id-query-param';

const QUESTION_LIST_GET = gql`
  query AdminQuizQuestionListGet($quizId: Int!) {
    adminQuizQuestionListGet(quizId: $quizId) {
      id
      options {
        id
        text
      }
    }
  }
`;

const QUESTION_DELETE = gql`
  mutation AdminQuizQuestionDelete($id: Int!) {
    adminQuizQuestionDelete(id: $id) {
      id
    }
  }
`;

const MAPPING = [
  { label: 'Question Id', getter: (value: AdminQuizQuestionListGet_adminQuizQuestionListGet) => value.id },
  {
    label: 'Options',
    getter: (value: AdminQuizQuestionListGet_adminQuizQuestionListGet) => (
      <>
        <ul>
          {value.options.map((option) => (
            <li key={option.id}>{option.text}</li>
          ))}
        </ul>
      </>
    ),
  },
];

const BREADCRUMBS = [{ path: '/admin/quizzes', label: 'Quizzes & Questions' }, { label: 'Questions' }];

function Questions() {
  const routeMatch = useRouteMatch();
  const quizId = useQuizIdQueryParam();

  return (
    <AdminList
      title={`Questions of quiz with id ${quizId}`}
      listQuery={QUESTION_LIST_GET}
      listQueryVariables={{ quizId }}
      deleteMutation={QUESTION_DELETE}
      dataProp="adminQuizQuestionListGet"
      breadcrumbs={BREADCRUMBS}
      mapping={MAPPING}
      viewLinkDisable={true}
      addLinkPath={`${routeMatch.path}/add?quizId=${quizId}`}
      editLinkPathGetter={(item) => `${routeMatch.path}/edit/${item.id}?quizId=${quizId}`}
    />
  );
}

export { Questions };
