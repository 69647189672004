import React from 'react';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { UserInviteInput } from '../../../../__generated__/globalTypes';
import { UserReviewInvite, UserReviewInviteVariables } from './__generated__/UserReviewInvite';

import { InviteFormLayout } from '../../shared/invite';

const PATH_USER_REVIEW_INVITE_LANDING = '/profyle-review/invite-sending';

const USER_REVIEW_INVITE = gql`
  mutation UserReviewInvite($input: UserInviteInput!) {
    userReviewInvite(input: $input) {
      id
    }
  }
`;

function ProfyleReviewInviteForm() {
  const history = useHistory();

  const [userReviewInvite] = useMutation<UserReviewInvite, UserReviewInviteVariables>(USER_REVIEW_INVITE);

  const onSubmit = (values: UserInviteInput) => {
    userReviewInvite({ variables: { input: values } }).then(() => {
      history.push(PATH_USER_REVIEW_INVITE_LANDING);
    });
  };

  return (
    <>
      <h2>Profyle Review</h2>
      Please input review users details to invite them to give you feedback on your Profyle.
      <br />
      <br />
      <InviteFormLayout onSubmit={onSubmit} />
    </>
  );
}

export { ProfyleReviewInviteForm };
