import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { FormSignIn } from '../../form-sign-in';

const SignInModalHeader = styled(Modal.Header)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  border: 0;
`;

const SignInModalBody = styled(Modal.Body)`
  padding: 48px 64px;
`;

interface Props {
  show: boolean;
  onHide: () => void;
  onSignIn: () => void;
}

function ModalSignInLayout(props: Props) {
  return (
    <Modal show={props.show} onHide={props.onHide} centered>
      <SignInModalHeader closeButton />
      <SignInModalBody>
        <h2>Sign In</h2>
        <FormSignIn onSignIn={props.onSignIn} modal />
      </SignInModalBody>
    </Modal>
  );
}

export { ModalSignInLayout };
