import React from 'react';

import { ProtectedRoute } from '../shared/auth';

import { Review360 } from './review-360';

function review360Route() {
  return (
    <ProtectedRoute path="/review-360/:id">
      <Review360 />
    </ProtectedRoute>
  );
}

export { review360Route };
