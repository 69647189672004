import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATH_HOME } from '../home';
import { useAuth } from '../shared/auth';

import { LayoutDefault } from '../shared/ui';

function SignOut() {
  const history = useHistory();
  const auth = useAuth();

  useEffect(() => {
    auth.unauthenticate().then(() => {
      history.push(ROUTE_PATH_HOME);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutDefault>
      <div className="w-50-resp m-auto">
        <h2>Signing out...</h2>
      </div>
    </LayoutDefault>
  );
}

export { SignOut };
