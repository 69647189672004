import { splitEvery } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Carousel, Container, Row, Col } from 'react-bootstrap';

import './client-thoughts-carousel.scss';

import { toUpperFirst } from '../../../with-types/ramda-extension';
import { Html } from '../../shared/html';

import avatarDefault from './images/avatar-default-2x.png';
import thoughtBackground1 from './images/thought-background-1.svg';
import thoughtBackground2 from './images/thought-background-2.svg';

const ThoughtContainer = styled(Container)`
  max-width: 1020px;
`;

const Thought = styled.div`
  position: relative;
  min-height: 198px;
  padding: 24px 24px 96px;
  border: 1px solid var(--light);
  background-image: url(${thoughtBackground1}), url(${thoughtBackground2});
  background-repeat: no-repeat;
  background-position: 12px 12px, bottom 24px right 24px;
`;

const ThoughtAuthor = styled.div`
  position: absolute;
  bottom: 24px;
  left: 24px;
  overflow: hidden;
  width: 250px;
  height: 48px;
`;

const ThoughtAuthorAvatar = styled.img`
  float: left;
  display: block;
  width: 48px;
  height: 48px;
  margin-right: 16px;
`;

const ThoughtAuthorInfo = styled.div``;

const ThoughtAuthorName = styled.h6`
  margin-bottom: 0;
`;

const ThoughtAuthorJob = styled.div``;

const toTaggedTextsFiltered = (startsWith: string) => <T extends any>(taggedTexts: {
  [tag: string]: T;
}): { [tag: string]: T } =>
  Object.entries(taggedTexts)
    .filter(([tag]) => tag.startsWith(startsWith))
    .reduce(
      (acc, [tag, text]) => ({
        ...acc,
        [tag]: text,
      }),
      {}
    );

interface Props {
  taggedTexts: { [tag: string]: { title: string; body: string } };
}

function ClientThoughtsCarousel(props: Props) {
  const textBodys = toTaggedTextsFiltered('homeClientThoughtsBody')(props.taggedTexts);
  const texts = toTaggedTextsFiltered('homeClientThoughts')(props.taggedTexts);
  const getText = (suffix: string, index: number, key: 'title' | 'body' = 'body'): string =>
    texts['homeClientThoughts' + toUpperFirst(suffix) + index][key];

  return (
    <Carousel className="client-thoughts-carousel">
      {splitEvery(2, Object.entries(textBodys)).map((textChunk, chunkIndex) => (
        <Carousel.Item key={textChunk[0][0]}>
          <ThoughtContainer>
            <Row>
              {textChunk.map(([tag, text], inChunkIndex) => {
                const index = chunkIndex * 2 + inChunkIndex;
                return (
                  <Col sm key={tag}>
                    <Thought>
                      <Html code={text.body} />
                      <ThoughtAuthor>
                        <ThoughtAuthorAvatar
                          src={getText('authorAvatarUrl', index, 'title') || avatarDefault}
                          alt={getText('authorName', index)}
                        />
                        <ThoughtAuthorInfo>
                          <ThoughtAuthorName>{getText('authorName', index)}</ThoughtAuthorName>
                          <ThoughtAuthorJob>{getText('authorJob', index)}</ThoughtAuthorJob>
                        </ThoughtAuthorInfo>
                      </ThoughtAuthor>
                    </Thought>
                  </Col>
                );
              })}
            </Row>
          </ThoughtContainer>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export { ClientThoughtsCarousel };
