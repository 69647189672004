import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { TabBody, TabActions } from '../../../shared/ui';
import { trimUrlLastPart } from '../../../shared/helper';
import { TabGetFeedbackRouting } from './tab-get-feedback.routing';
import styled from 'styled-components';

interface Params {
  profyle: {};
  nextUrl: string;
}

const StyledTabBody = styled(TabBody)`
  @media (max-width: 767px) {
    padding-top: 0;
  }
`;

function TabGetFeedback(params: Params) {
  const routeMatch = useRouteMatch();

  return (
    <StyledTabBody>
      <TabGetFeedbackRouting />
      <TabActions>
        <Link to={`${trimUrlLastPart(trimUrlLastPart(routeMatch.url))}/${params.nextUrl}`}>Next</Link>
      </TabActions>
    </StyledTabBody>
  );
}

export { TabGetFeedback };
