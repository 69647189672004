import React from 'react';
import { Route } from 'react-router-dom';

import { SignInByConfirm } from './sign-in-by-confirm';

function signInByConfirmRoute() {
  return (
    <Route path="/sign-in-by-confirm">
      <SignInByConfirm />
    </Route>
  );
}

export { signInByConfirmRoute };
