import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { UserSetupPassword, UserSetupPasswordVariables } from './__generated__/UserSetupPassword';
import { UserSetupPasswordInput } from '../../../../../__generated__/globalTypes';
import { FormButton } from '../../ui';
import { omit } from 'ramda';

const USER_SETUP_PASSWORD = gql`
  mutation UserSetupPassword($input: UserSetupPasswordInput!) {
    userSetupPassword(input: $input) {
      id
    }
  }
`;

type UserSetupPasswordValues = UserSetupPasswordInput & { passwordConfirm: string };

const initialValues: UserSetupPasswordValues = {
  password: '',
  passwordConfirm: '',
};

const validationSchema = yup.object().shape<UserSetupPasswordValues>({
  password: yup.string().required('Password is a required field'),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is a required field'),
});

interface Props {
  title: string;
  landing: string;
}

function PasswordForm(props: Props) {
  const history = useHistory();
  const [userSetupPassword] = useMutation<UserSetupPassword, UserSetupPasswordVariables>(USER_SETUP_PASSWORD);
  const onSubmit = (values: UserSetupPasswordValues) => {
    userSetupPassword({ variables: { input: omit(['passwordConfirm'], values) } }).then(() => {
      history.push(props.landing);
    });
  };
  const form = useFormik<UserSetupPasswordValues>({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <div className="w-50-resp m-auto">
      <h2>{props.title}</h2>
      <Form noValidate onSubmit={form.handleSubmit}>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.password}
            isInvalid={!!(form.touched.password && form.errors.password)}
            disabled={form.isSubmitting}
          />
          <Form.Control.Feedback type="invalid">{form.errors.password}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="passwordConfirm">
          <Form.Label>Confirm password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password again"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.passwordConfirm}
            isInvalid={!!(form.touched.passwordConfirm && form.errors.passwordConfirm)}
            disabled={form.isSubmitting}
          />
          <Form.Control.Feedback type="invalid">{form.errors.passwordConfirm}</Form.Control.Feedback>
        </Form.Group>
        <FormButton form={form} />
      </Form>
    </div>
  );
}

export { PasswordForm };
