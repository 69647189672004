import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import stageBackground from './images/stage-background.svg';
import { Link } from 'react-router-dom';
import { Html } from '../../shared/html';

const StageContainer = styled(Container)`
  margin-bottom: 64px;
`;

interface StageProps {
  index: number;
}

const Stage = styled.div<StageProps>`
  position: relative;
  min-height: 145px;
  padding: 45px 0 0;
  background-image: url(${stageBackground});
  background-repeat: repeat-x;
  background-position: 12px 13px;
  &::before {
    content: '${(props) => props.index}';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background-color: var(--primary);
    text-align: center;
    font-family: var(--profyle-headings-font-family);
    font-size: var(--profyle-h3-font-size);
    font-weight: var(--profyle-headings-font-weight);
    color: var(--white);
  }
`;

const StageTitle = styled.h6`
  margin-bottom: 5px;
`;

enum Stages {
  DECIDE = 'DECIDE',
  GENERATE = 'GENERATE',
  DISCOVER = 'DISCOVER',
  CHOOSE = 'CHOOSE',
  SHARE = 'SHARE',
}

interface Props {
  taggedTexts: { [tag: string]: { title: string; body: string } };
  stagesMap: { [key in keyof typeof Stages]: string };
}

function StagesOfYourJourney(props: Props) {
  const { taggedTexts, stagesMap } = props;
  return (
    <StageContainer>
      <Row>
        <Col sm>
          <Stage index={1}>
            <StageTitle>
              <Html code={taggedTexts[stagesMap.DECIDE]?.title} />
            </StageTitle>
            <Html code={taggedTexts[stagesMap.DECIDE]?.body} /> <Link to="/skillsets">here</Link>.
          </Stage>
        </Col>
        <Col sm>
          <Stage index={2}>
            <StageTitle>
              <Html code={taggedTexts[stagesMap.GENERATE]?.title} />
            </StageTitle>
            <Html code={taggedTexts[stagesMap.GENERATE]?.body} />
          </Stage>
        </Col>
        <Col sm>
          <Stage index={3}>
            <StageTitle>
              <Html code={taggedTexts[stagesMap.DISCOVER]?.title} />
            </StageTitle>
            <Html code={taggedTexts[stagesMap.DISCOVER]?.body} />
          </Stage>
        </Col>
        <Col sm>
          <Stage index={4}>
            <StageTitle>
              <Html code={taggedTexts[stagesMap.CHOOSE]?.title} />
            </StageTitle>
            <Html code={taggedTexts[stagesMap.CHOOSE]?.body} />
          </Stage>
        </Col>
        <Col sm>
          <Stage index={5}>
            <StageTitle>
              <Html code={taggedTexts[stagesMap.SHARE]?.title} />
            </StageTitle>
            <Html code={taggedTexts[stagesMap.SHARE]?.body} />
          </Stage>
        </Col>
      </Row>
    </StageContainer>
  );
}

export { StagesOfYourJourney };
