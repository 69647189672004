import React from 'react';
import { FormGroupText } from '../../../../../shared/ui';

interface IProps {
  index: number;
  form: any;
  type: 'Low' | 'High';
}

export const ReadingForm: React.FC<IProps> = ({ index, form, type }) => {
  return (
    <>
      <FormGroupText
        label={`To Optimise ${type} Reading Author (${index + 1})`}
        field={`toOptimise${type}Reading[${index}].author`}
        valueGetter={(values) => values[`toOptimise${type}Reading`][index]?.author || ''}
        form={form}
      />
      <FormGroupText
        label={`To Optimise ${type} Reading Image Url (${index + 1})`}
        field={`toOptimise${type}Reading[${index}].imageUrl`}
        valueGetter={(values) => values[`toOptimise${type}Reading`][index]?.imageUrl || ''}
        form={form}
      />
      <FormGroupText
        label={`To Optimise ${type} Reading Title (${index + 1})`}
        field={`toOptimise${type}Reading[${index}].title`}
        valueGetter={(values) => values[`toOptimise${type}Reading`][index]?.title || ''}
        form={form}
      />
      <FormGroupText
        label={`To Optimise ${type} Reading Url (${index + 1})`}
        field={`toOptimise${type}Reading[${index}].url`}
        valueGetter={(values) => values[`toOptimise${type}Reading`][index]?.url || ''}
        form={form}
      />
    </>
  );
};
