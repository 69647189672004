import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';

import { Settings } from './settings';
import { settingsEditRoute } from './settings-edit';
import { settingsViewRoute } from './settings-view';

function settingsRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/settings`}
      render={(routeProps) => (
        <>
          <ProtectedRoute path={routeProps.match.url} exact>
            <Settings />
          </ProtectedRoute>
          {settingsEditRoute(routeProps)}
          {settingsViewRoute(routeProps)}
        </>
      )}
    />
  );
}

export { settingsRoute };
