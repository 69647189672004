import { omit } from 'ramda';
import React from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { useParamId } from '../../../../shared/router';

import { AdminRoleListGetForUserEdit } from './__generated__/AdminRoleListGetForUserEdit';
import { AdminUserGet, AdminUserGetVariables, AdminUserGet_adminUserGet } from './__generated__/AdminUserGet';
import { AdminUserUpdate, AdminUserUpdateVariables } from './__generated__/AdminUserUpdate';

import { UsersFormLayout, FormValues } from '../../users-form-layout';
import { Loading } from '../../../../shared/ui';

const toInitialValues = (user: AdminUserGet_adminUserGet): FormValues => ({
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  role: user.roles[0].role,
});

const PATH_UPDATE_LANDING = '/admin/users';

const ROLE_LIST_GET = gql`
  query AdminRoleListGetForUserEdit {
    adminRoleListGet {
      role
    }
  }
`;

const USER_GET = gql`
  query AdminUserGet($id: Int!) {
    adminUserGet(id: $id) {
      id
      firstName
      lastName
      email
      roles {
        role
      }
    }
  }
`;

const USER_UPDATE = gql`
  mutation AdminUserUpdate($input: AdminUserUpdateInput!) {
    adminUserUpdate(input: $input) {
      id
    }
  }
`;

function UsersEditForm() {
  const userId = useParamId();
  const history = useHistory();
  const { loading: loadingRoles, data: dataRoles } = useQuery<AdminRoleListGetForUserEdit>(ROLE_LIST_GET);
  const roles = dataRoles?.adminRoleListGet || [];
  const { loading: loadingUser, data: dataUser } = useQuery<AdminUserGet, AdminUserGetVariables>(USER_GET, {
    variables: { id: userId },
  });
  const user = dataUser?.adminUserGet;
  const [adminUserUpdate] = useMutation<AdminUserUpdate, AdminUserUpdateVariables>(USER_UPDATE);

  const onSubmit = (values: FormValues) => {
    const input = { id: userId, ...omit(['role'], values), roles: [{ role: values.role }] };
    adminUserUpdate({ variables: { input } }).then(() => {
      history.push(PATH_UPDATE_LANDING);
    });
  };

  if (loadingUser || loadingRoles) {
    return <Loading />;
  }

  return (
    <UsersFormLayout initialValues={toInitialValues(user!)} onSubmit={onSubmit} roles={roles.map((r) => r.role)} />
  );
}

export { UsersEditForm };
