import React from 'react';
import { Route } from 'react-router-dom';

import { ProtectedRoute } from '../shared/auth';
import { RoleName } from '../shared/auth/role-name';

import { ConsolidatedOrder } from './consolidated-order';

function consolidatedOrderRoute() {
  return (
    <ProtectedRoute
      path="/consolidated-order"
      role={RoleName.ROLE_CORPORATE_CORE}
      render={(routeProps) => (
        <>
          <Route notAuth path={routeProps.match.url} exact>
            <ConsolidatedOrder />
          </Route>
        </>
      )}
    />
  );
}

export { consolidatedOrderRoute };
