import React from 'react';

import { LayoutDefault, AdminBreadcrumbs } from '../../../../shared/ui';

import { useQuizIdQueryParam } from '../use-quiz-id-query-param';
import { QuestionsAddForm } from './questions-add-form';

const BREADCRUMBS = [
  { path: '/admin/quizzes', label: 'Quizzes & Questions' },
  { path: '/admin/quizzes/quizzes', label: 'Quizzes' },
  { label: 'Add question' },
];

function QuestionsAdd() {
  const quizId = useQuizIdQueryParam();

  return (
    <LayoutDefault>
      <h2>Add question to quiz with id {quizId}</h2>
      <AdminBreadcrumbs breadcrumbs={BREADCRUMBS} />
      <QuestionsAddForm />
    </LayoutDefault>
  );
}

export { QuestionsAdd };
