import React, { useState, SyntheticEvent, ReactNode } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const Collapsable = styled.div.attrs<any>((props) => ({
  className: classNames({
    expanded: props.expanded,
  }),
}))<any>`
  position: relative;
  overflow: hidden;
  height: calc(var(--profyle-line-height-base) * ${(props) => props.lines}em);
  min-height: var(--profyle-line-height-base);

  &.expanded {
    height: auto;
  }
`;

const ShowMore = styled.a.attrs<any>(() => ({
  href: '#',
}))<any>`
  position: absolute;
  bottom: 1px;
  right: 0;

  padding: 0 0.5em;

  background: ${(props) => props.background || 'transparent'};
`;

interface Props {
  lines: number;
  showMoreBackground?: string;
  children?: ReactNode;
}

function Text(props: Props) {
  const [expanded, setExpanded] = useState(false);

  const onShowMore = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    setExpanded(true);
  };

  return (
    <Collapsable expanded={expanded} lines={props.lines}>
      {props.children}
      {expanded || (
        <ShowMore background={props.showMoreBackground} onClick={onShowMore}>
          Read more
        </ShowMore>
      )}
    </Collapsable>
  );
}

export { Text };
