import gql from 'graphql-tag';

export const ADMIN_USERS_GET = gql`
  query GetAdminUsers {
    getAdminUsers {
      id
      firstName
      lastName
      email
      isConfirmed
      roles {
        role
      }
      skillsets {
        id
        name
      }
      companyGroup {
        groupName
      }
    }
  }
`;

export const CHANGE_GROUP = gql`
  mutation ChangeUsersGroup($input: ChangeUsersGroupInput!) {
    changeUsersGroup(input: $input) {
      id
    }
  }
`;

export const CHANGE_SKILLSETS = gql`
  mutation UpdateUsersSkillsets($input: UpdateUsersSkillsetsInput!) {
    updateUsersSkillsets(input: $input) {
      id
    }
  }
`;

export const NOTIFY_SKILLSETS = gql`
  mutation SendUsersSkillsetsNotification($input: SendUsersSkillsetsNotificationInput!) {
    sendUsersSkillsetsNotification(input: $input) {
      id
    }
  }
`;
