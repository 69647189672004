import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Redirect, useRouteMatch } from 'react-router-dom';

import { Feedback360Form } from './feedback-360-form';
import { useQueryParams } from '../../../../shared/router';
import { trimUrlLastPart } from '../../../../shared/helper';

import { Profyle360ReviewRequestRequestedListGetIdsForFeedback } from './__generated__/Profyle360ReviewRequestRequestedListGetIdsForFeedback';

const ROUTE_PATH_STATUS = 'status';

const REQUEST_LIST_GET_IDS = gql`
  query Profyle360ReviewRequestRequestedListGetIdsForFeedback {
    profyle360ReviewRequestRequestedListGet {
      id
    }
  }
`;

function Feedback360() {
  const routeMatch = useRouteMatch();
  const queryParams = useQueryParams();
  const withStatus = queryParams.get('withStatus') !== null;
  const { loading, data } = useQuery<Profyle360ReviewRequestRequestedListGetIdsForFeedback>(REQUEST_LIST_GET_IDS);
  const requestIdsRequested = data?.profyle360ReviewRequestRequestedListGet || [];

  if (withStatus && !loading && requestIdsRequested.length) {
    const parentUrl = trimUrlLastPart(routeMatch.url);
    return <Redirect to={`${parentUrl}/${ROUTE_PATH_STATUS}`} />;
  }

  return !withStatus || !loading ? <Feedback360Form /> : null;
}

export { Feedback360 };
