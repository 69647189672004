import React from 'react';

import { UsersList } from './users-list';
import { ProtectedRoute } from '../shared/auth';
import { RoleName } from '../shared/auth/role-name';

function reportingRoute() {
  return <ProtectedRoute path="/reporting" role={RoleName.ROLE_CORPORATE_ADMIN} render={() => <UsersList />} />;
}

export { reportingRoute };
