import React from 'react';
import { Link } from 'react-router-dom';

import { LayoutDefault, AdminBreadcrumbs } from '../../shared/ui';

const BREADCRUMBS = [{ label: 'Quizzes & Questions' }];

function Quizzes() {
  return (
    <LayoutDefault>
      <h2>Quizzes &amp; Questions</h2>
      <AdminBreadcrumbs breadcrumbs={BREADCRUMBS} />
      <ul>
        <li>
          <Link to="/admin/quizzes/quizzes">Quizzes</Link>
        </li>
      </ul>
    </LayoutDefault>
  );
}

export { Quizzes };
