import React from 'react';
import { Link } from 'react-router-dom';

import { TabBody, TabActions } from '../../../shared/ui';
import { toParagraphs } from '../../../shared/helper';
import { toProfyleSkillsetNames } from '../../../shared/profyle';

interface Params {
  profyle: { report: { text: string }; result: { quiz: { skillsets: { name: string }[] } } };
  nextUrl: string;
}

function TabTextualReport(params: Params) {
  const reportText = params.profyle.report.text;
  const skillsetNames = toProfyleSkillsetNames(params.profyle);

  return (
    <TabBody>
      <p>Here you can discover what Profyle suggests is your preferred {skillsetNames} style.</p>

      {toParagraphs(reportText)}

      <TabActions>
        <Link to={params.nextUrl}>Next</Link>
      </TabActions>
    </TabBody>
  );
}

export { TabTextualReport };
