import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ServerError } from 'apollo-link-http-common';
import { onError, ErrorResponse } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import { RetryLink } from 'apollo-link-retry';
import { createUploadLink } from 'apollo-upload-client';

import { auth } from './app/shared/auth';
import { error } from './app/shared/error';
import { getHttpBaseHeaders, getHttpBaseUrl } from './app';
import { getApiResponseMessage } from './app/shared/graphql';

const parseHeaders = (rawHeaders: any) => {
  const headers = new Headers();
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
  preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
    const parts = line.split(':');
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });
  return headers;
};

export const uploadFetch = (url: string, options: any) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const opts: any = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
      };
      opts.url = 'responseURL' in xhr ? xhr.responseURL : opts.headers.get('X-Request-URL');
      const body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.open(options.method, url, true);

    Object.keys(options.headers).forEach((key) => {
      xhr.setRequestHeader(key, options.headers[key]);
    });

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress;
    }

    options.onAbortPossible(() => {
      xhr.abort();
    });

    xhr.send(options.body);
  });

const customFetch = (uri: any, options: any) => {
  if (options.onProgress) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};

const isServerError = (error: Error): error is ServerError => {
  return typeof (error as ServerError).statusCode !== 'undefined';
};

const isAuthError = (err: ServerError): boolean => {
  return err.statusCode === 403;
};

const retryAttemptsMax = 7;
let failedToFetchCount = 0;
const handleErrorDefault = (message: string, detailedMessage: string, err: ErrorResponse) => {
  if (error.shouldIgnore(err.operation.operationName)) {
    return;
  }

  if (message.includes('Failed to fetch') && failedToFetchCount < retryAttemptsMax - 1) {
    failedToFetchCount++;
    return;
  }
  failedToFetchCount = 0;

  error.error(message);
  console.warn(detailedMessage);
};

const globalWindow: any = window;

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = globalWindow.__APOLLO_STORE__;
// Allow the passed state to be garbage-collected
delete globalWindow.__APOLLO_STORE__;

const client = new ApolloClient({
  ssrMode: true,
  // initialState: {},
  link: ApolloLink.from([
    new RetryLink({
      delay: {
        jitter: false,
      },
      attempts: {
        max: retryAttemptsMax,
      },
    }),
    onError((err) => {
      const { graphQLErrors, networkError } = err;
      if (graphQLErrors) {
        graphQLErrors.forEach((graphQlError) => {
          const { message, locations, path } = graphQlError;
          const logMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
          if (message.startsWith('403: Forbidden')) {
            console.log(logMessage);
            if (auth.isAuthenticated()) {
              client.clearStore();
              if (['UserCurrentGet', 'GetCompanyInfo'].includes(err.operation.operationName)) {
                auth.unauthenticate();
              } else {
                auth.expire();
              }
            }
          } else {
            handleErrorDefault(getApiResponseMessage(err) || message, logMessage, err);
          }
        });
      }
      if (networkError) {
        const logMessage = `[Network error]: ${networkError}`;
        if (isServerError(networkError) && isAuthError(networkError)) {
          console.log(logMessage);
          client.clearStore();
          auth.expire();
        } else {
          handleErrorDefault(networkError.message, logMessage, err);
        }
      }
    }),
    setContext(() => ({
      headers: getHttpBaseHeaders(),
    })),
    (createUploadLink({
      uri: getHttpBaseUrl(),
      credentials: 'same-origin',
      fetch: customFetch as any,
    }) as unknown) as ApolloLink,
  ]),
  cache: new InMemoryCache().restore(preloadedState),
  // cache: preloadedState ? new InMemoryCache().restore(preloadedState) : new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

// Tell react-snap how to save state
globalWindow.snapSaveState = () => ({
  __APOLLO_STORE__: client.extract(),
});

export default client;
