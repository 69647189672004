import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { LayoutDefault, Loading } from '../shared/ui';
import { toLines } from '../shared/helper';

import {
  ProfyleReview360Get,
  ProfyleReview360GetVariables,
  ProfyleReview360Get_profyle360ReviewGet_reviewReportTextFeedbacks,
} from './__generated__/ProfyleReview360Get';
import { GRADE_LABELS, Grade } from '../shared/grade';

const toIndexedFeedbacks = <T extends { paragraphIndex: number }>(feedbacks: T[]): { [index: string]: T } =>
  feedbacks.reduce(
    (a, f) => ({
      ...a,
      [f.paragraphIndex]: f,
    }),
    {}
  );

const Quote = styled.p`
  padding-left: 1rem;
  font-style: italic;
`;

const Feedback = styled.p`
  font-size: 1.05rem;
  word-break: break-word;
`;

const REVIEW_360_GET = gql`
  query ProfyleReview360Get($id: Int!) {
    profyle360ReviewGet(id: $id) {
      id
      reviewer {
        id
        firstName
        lastName
        email
      }
      profyle {
        id
        report {
          text
        }
      }
      reviewReportTextFeedbacks {
        grade
        comment
        paragraphIndex
      }
    }
  }
`;

function Review360() {
  const routeParams = useParams<{ id: string }>();
  const reviewId = parseInt(routeParams.id);
  const { loading, data } = useQuery<ProfyleReview360Get, ProfyleReview360GetVariables>(REVIEW_360_GET, {
    variables: { id: reviewId },
  });
  const review = data?.profyle360ReviewGet;
  const reviewer = review?.reviewer || { firstName: '', lastName: '' };
  const reportTextLines = review ? toLines(review.profyle.report.text) : [];
  const reviewReportTextFeedbacksIndexed = review
    ? toIndexedFeedbacks<ProfyleReview360Get_profyle360ReviewGet_reviewReportTextFeedbacks>(
        review.reviewReportTextFeedbacks
      )
    : {};

  return (
    <LayoutDefault>
      <h2>
        {reviewer.firstName} {reviewer.lastName} Review 360
      </h2>

      {loading ? (
        <Loading />
      ) : (
        (() => {
          return (
            <>
              <h4>Feedback</h4>
              {reportTextLines.map((line, index) => {
                const feedback = reviewReportTextFeedbacksIndexed[index];
                return (
                  <div key={index}>
                    <Quote>{line}</Quote>
                    <Feedback>
                      {reviewer.firstName} {reviewer.lastName} has indicated that they{' '}
                      <strong>{GRADE_LABELS[feedback.grade as Grade]}</strong> on this part of your Profyle{' '}
                      {feedback.comment && `and they also said “${feedback.comment}”.`}
                    </Feedback>
                  </div>
                );
              })}
            </>
          );
        })()
      )}
    </LayoutDefault>
  );
}

export { Review360 };
