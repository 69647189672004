import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';

import { Texts } from './texts';
import { textsEditRoute } from './texts-edit';
import { textsViewRoute } from './texts-view';

function textsRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/texts`}
      render={(routeProps) => (
        <>
          <ProtectedRoute path={routeProps.match.url} exact>
            <Texts />
          </ProtectedRoute>
          {textsEditRoute(routeProps)}
          {textsViewRoute(routeProps)}
        </>
      )}
    />
  );
}

export { textsRoute };
