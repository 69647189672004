import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from '../shared/ui';

export const SmallButton = styled(Button)`
  font-family: var(--profyle-muli-font-family);
  width: 131px;
  position: relative;
  &:disabled {
    background: rgba(101, 105, 126, 0.5);
    border-color: rgba(101, 105, 126, 0.5);
    color: #fff;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonWarning = styled.span`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  color: var(--red);
  font-size: 12px;
  white-space: nowrap;
`;

export const LargeButton = styled(Button)`
  width: 141px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ResponsiveLink = styled(Link)`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonsWrapper = styled.div<{ isAdminSelected: boolean }>`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 45px;
  margin-top: 8px;
  button:first-child {
    margin-right: 16px;
  }
  button:only-child {
    margin-right: 0;
  }
  @media (max-width: 768px) {
    button:first-child {
      margin-right: 0px;
      margin-bottom: ${(props) => (props.isAdminSelected ? '35px' : '8px')};
    }
  }
`;

export const Text = styled.p`
  border-bottom: 2px solid var(--primary);
  text-decoration: none;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  font-family: var(--profyle-headings-font-family);
  color: var(--primary);
  margin-right: 58px;
`;
