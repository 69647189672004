import React from 'react';
import { useFormik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';

import { FormButton } from '../../../shared/ui';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
};

const validationSchema = yup.object().shape({
  firstName: yup.string().required('First Name is a required field'),
  lastName: yup.string().required('Last Name is a required field'),
  email: yup.string().email('Email must be a valid email').required('Email is a required field'),
});

interface Params {
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
}

function ReferralFormLayout(params: Params) {
  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit: params.onSubmit,
  });

  return (
    <Form noValidate onSubmit={form.handleSubmit}>
      <Form.Group controlId="firstName">
        <Form.Label>First name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter first name"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.firstName}
          isInvalid={!!(form.touched.firstName && form.errors.firstName)}
          disabled={form.isSubmitting}
        />
        <Form.Control.Feedback type="invalid">{form.errors.firstName}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="lastName">
        <Form.Label>Last name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter last name"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.lastName}
          isInvalid={!!(form.touched.lastName && form.errors.lastName)}
          disabled={form.isSubmitting}
        />
        <Form.Control.Feedback type="invalid">{form.errors.lastName}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="email">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.email}
          isInvalid={!!(form.touched.email && form.errors.email)}
          disabled={form.isSubmitting}
        />
        <Form.Text className="text-muted">We will never share your contact details</Form.Text>
        <Form.Control.Feedback type="invalid">{form.errors.email}</Form.Control.Feedback>
      </Form.Group>
      <p>Your connection will receive an email from Profyle with your invitation to join</p>
      <FormButton type="submit" form={form}>
        Submit
      </FormButton>
    </Form>
  );
}

export { ReferralFormLayout };
