import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';

import { Quizzes } from './quizzes';
import { quizzesQuizzesRoute } from './quizzes/index';
import { questionsRoute } from './questions';

function quizzesRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/quizzes`}
      render={(routeProps) => (
        <>
          <ProtectedRoute path={routeProps.match.url} exact>
            <Quizzes />
          </ProtectedRoute>
          {quizzesQuizzesRoute(routeProps)}
          {questionsRoute(routeProps)}
        </>
      )}
    />
  );
}

export { quizzesRoute };
