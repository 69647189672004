import { identity, Dictionary } from 'ramda';
import React, { ReactNode } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useQuery } from '@apollo/react-hooks';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';

import { DocumentNode } from 'graphql';

import { LayoutDefault } from '../../layout-default';
import { Loading } from '../../loading';
import { toLabel } from '../../../helper';
import { AdminBreadcrumbs } from '../breadcrumbs';

const LabelCol = styled(Col)`
  font-weight: bold;
`;

const ValueCol = styled(Col).attrs({
  md: 10,
})``;

interface RouteParams {
  id: string;
}

interface Params<D, DP = keyof D> {
  query: DocumentNode;
  dataProp: DP;
  mapping: { key: string; getter?: (value: any) => ReactNode }[];
  breadcrumbs: { path?: string; label: string }[];
}

function AdminView<D = any, DP = keyof D>(params: Params<D, DP>) {
  if (params.breadcrumbs.length < 1) {
    throw new Error('Breadcrumbs params should have at least one item');
  }

  const routeParams = useParams<RouteParams>();
  const { loading, data } = useQuery<D, { id: number }>(params.query, {
    variables: { id: parseInt(routeParams.id) },
  });
  const lastBreadcrumbsIndex = params.breadcrumbs.length - 1;
  const preLastBreadcrumbsIndex = lastBreadcrumbsIndex - 1;

  return (
    <LayoutDefault>
      <h2>{params.breadcrumbs[lastBreadcrumbsIndex].label}</h2>
      <AdminBreadcrumbs breadcrumbs={params.breadcrumbs} />
      <ul>
        <li>
          <Link to={params.breadcrumbs[preLastBreadcrumbsIndex].path!}>
            {params.breadcrumbs[preLastBreadcrumbsIndex].label}
          </Link>
        </li>
      </ul>

      {loading ? (
        <Loading />
      ) : (
        (() => {
          const result = (data as any)[params.dataProp] as Dictionary<any>;
          return (
            <>
              {params.mapping.map((m) => (
                <Row key={m.key}>
                  <LabelCol>{toLabel(m.key)}</LabelCol>
                  <ValueCol>{(m.getter || identity)(result[m.key])}</ValueCol>
                </Row>
              ))}
            </>
          );
        })()
      )}
    </LayoutDefault>
  );
}

export { AdminView };
