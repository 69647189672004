import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const CustomTable = styled(Table)`
  width: 1110px;
  margin-bottom: 0;
  thead {
    th {
      font-weight: 400;
    }
  }
  tbody {
    color: var(--profyle-profyle-color-black);
  }
  td {
    word-break: break-word;
  }
  th {
    width: 10%;
    &:not(:first-child) {
      text-align: center;
    }
    &:first-child {
      width: 24%;
    }
    &:nth-child(2),
    &:nth-child(5) {
      width: 8%;
    }
    &:nth-child(6) {
      width: 12%;
    }
    &:nth-child(7),
    &:nth-child(8) {
      width: 14%;
    }
  }
  td {
    width: 10%;
    &:not(:nth-child(2)) {
      text-align: center;
    }
    &:nth-child(2) {
      text-align: left;
      width: 24%;
    }
    &:nth-child(3),
    &:nth-child(6) {
      width: 8%;
    }
    &:nth-child(7) {
      width: 12%;
    }
    &:nth-child(8),
    &:nth-child(9) {
      width: 14%;
    }
    &[colSpan='8'] {
      border-top: none;
      padding: 0;
    }
  }
`;

export const CustomLink = styled(Link)`
  text-decoration: underline;
  color: var(--profyle-profyle-color-black);
  &:hover {
    color: var(--profyle-profyle-color-main);
  }
`;
