import React from 'react';

import { LayoutDefault } from '../../shared/ui';
import { PasswordForm } from '../../shared/user';

function ResetPassword() {
  return (
    <LayoutDefault>
      <PasswordForm title="Reset Password" landing="/forgot-password/reset-done" />
    </LayoutDefault>
  );
}

export { ResetPassword };
