import React from 'react';

import { OnBoarding } from './on-boarding';
import { HideForUser, ProtectedRoute, ShowForUser } from '../shared/auth';
import { RoleName } from '../shared/auth/role-name';
import { CompanyInfo } from '../company-info';
import { FormMode } from '../shared/from';

const PATH_MY_INFO = '/my-info?redirect=/branding';

function onBoardingRoute() {
  return (
    <ProtectedRoute path="/on-boarding">
      <ShowForUser role={RoleName.ROLE_CORPORATE_ADMIN}>
        <CompanyInfo redirectUrl={PATH_MY_INFO} initialFormMode={FormMode.Edit} />
      </ShowForUser>
      <HideForUser role={RoleName.ROLE_CORPORATE_ADMIN}>
        <OnBoarding />
      </HideForUser>
    </ProtectedRoute>
  );
}

export { onBoardingRoute };
