import React from 'react';
import { useParams, Redirect, useRouteMatch } from 'react-router-dom';
import { LayoutDefault } from '../../shared/ui';
import { toProfyleSkillsetNames } from '../../shared/profyle';
import { ReportTabs } from './report-tabs';

interface Params {
  id: number;
}

function ProfyleChosen(params: Params) {
  const profyleId = params.id;

  const routeParams = useParams<{ section: string }>();
  const tabsActiveKey = routeParams.section || '';

  const routeMatch = useRouteMatch();

  if (!tabsActiveKey) {
    return <Redirect to={`${routeMatch.url}/welcome`} />;
  }

  return (
    <LayoutDefault>
      <ReportTabs
        profyleId={profyleId}
        getHeader={(profyle) => {
          const skillsetNames = toProfyleSkillsetNames(profyle);
          return <h2>Welcome to your {skillsetNames} Profyle</h2>;
        }}
      />
    </LayoutDefault>
  );
}

export { ProfyleChosen };
