import React, { useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { LayoutDefault, Loading, Button } from '../shared/ui';
import { MyInfoForm, MyInfoFormValuesInitial } from '../shared/user';
import { FormMode } from '../shared/from';

import { UserInfoCurrentGet, UserInfoCurrentGet_userInfoCurrentGet } from './__generated__/UserInfoCurrentGet';
import { Link, useHistory } from 'react-router-dom';
import { hasRole } from '../shared/auth/has-role';
import { RoleName } from '../shared/auth/role-name';
import { useQueryParams } from '../shared/router';

const PATH_CHANGE_PASSWORD = '/my-info/password';

export const USER_INFO_GET = gql`
  query UserInfoCurrentGet {
    userInfoCurrentGet {
      id
      email
      firstName
      lastName
      roles {
        role
      }
      gender
      jobTitle
      companyName
      companySize
      companyDepartment
      companyIndustry
      country
      city
    }
  }
`;

export const toValues = (user: UserInfoCurrentGet_userInfoCurrentGet): MyInfoFormValuesInitial => ({
  gender: user.gender,
  jobTitle: user.jobTitle,
  companyName: user.companyName,
  companySize: user.companySize,
  companyDepartment: user.companyDepartment,
  companyIndustry: user.companyIndustry,
  city: { title: user.city || '' },
  country: user.country,
});

function MyInfo() {
  const { loading, data, refetch } = useQuery<UserInfoCurrentGet>(USER_INFO_GET);

  const history = useHistory();
  const queryParams = useQueryParams();
  const redirectPath = queryParams.get('redirect');

  const onSave = useCallback(() => {
    if (redirectPath) {
      history.push(redirectPath);
    } else {
      refetch();
    }
  }, [redirectPath, history, refetch]);

  const getTitle = () => 'My Info';
  const getDescription = (mode: FormMode) => '';

  return (
    <LayoutDefault>
      {loading ? (
        <Loading />
      ) : (
        (() => {
          const user = data!.userInfoCurrentGet;
          const isCorporateUser = hasRole(user, RoleName.ROLE_CORPORATE_CORE);
          const initialValues = toValues(user);

          return (
            <MyInfoForm
              initialStatus={FormMode.Preview}
              initialValues={initialValues}
              submitOnSave={true}
              getTitle={getTitle}
              getDescription={getDescription}
              getAdditionalActions={() => (
                <Button className="btn btn-secondary" as={Link} to={PATH_CHANGE_PASSWORD}>
                  Change password
                </Button>
              )}
              onSave={onSave}
              isCorporateUser={isCorporateUser}
            />
          );
        })()
      )}
    </LayoutDefault>
  );
}

export { MyInfo };
