import React, { useCallback } from 'react';
import { Table } from 'react-bootstrap';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Link, useHistory } from 'react-router-dom';

import { Loading, FormButton } from '../shared/ui';
import { toFormatedDateTime } from '../shared/datetime';
import { toProfyleSkillsetNames } from '../shared/profyle';
import { toLabel } from '../shared/helper';

import {
  Profyle360ReviewRequestRequestedListGet,
  Profyle360ReviewRequestRequestedListGet_profyle360ReviewRequestRequestedListGet,
  Profyle360ReviewRequestRequestedListGet_profyle360ReviewRequestRequestedListGet_requestProfyles,
} from './__generated__/Profyle360ReviewRequestRequestedListGet';

import {
  ProfyleReviewRequestRemindFeedback,
  ProfyleReviewRequestRemindFeedbackVariables,
} from './__generated__/ProfyleReviewRequestRemindFeedback';

import { RequestProfyleWithUser, toRequestProfylesWithReviewer } from '../shared/request';
import { ReviewRequestStatus } from '../../../__generated__/globalTypes';

type RequestProfyleWithReviewer = RequestProfyleWithUser<
  'reviewer',
  Profyle360ReviewRequestRequestedListGet_profyle360ReviewRequestRequestedListGet,
  Profyle360ReviewRequestRequestedListGet_profyle360ReviewRequestRequestedListGet_requestProfyles
>;

const REQUEST_REQUESTED_LIST_GET = gql`
  query Profyle360ReviewRequestRequestedListGet {
    profyle360ReviewRequestRequestedListGet {
      id
      reviewer {
        firstName
        lastName
        email
      }
      requestProfyles {
        id
        status
        acceptedAt
        completedAt
        remindedAt
        review {
          id
        }
        profyle {
          result {
            quiz {
              skillsets {
                name
              }
            }
          }
        }
        createdAt
      }
    }
  }
`;

const REQUEST_PROFYLE_REMIND = gql`
  mutation ProfyleReviewRequestRemindFeedback($id: Int!) {
    reviewRequestProfyleRemind(id: $id) {
      id
    }
  }
`;

function FeedbackAboutYou() {
  const history = useHistory();

  const { loading, data, refetch } = useQuery<Profyle360ReviewRequestRequestedListGet>(REQUEST_REQUESTED_LIST_GET);
  const reviewRequests2 = data?.profyle360ReviewRequestRequestedListGet || [];
  const reviewRequestProfyles2 = toRequestProfylesWithReviewer<RequestProfyleWithReviewer>(reviewRequests2);

  const [reviewRequestProfyleRemind, { loading: loadingRemind }] = useMutation<
    ProfyleReviewRequestRemindFeedback,
    ProfyleReviewRequestRemindFeedbackVariables
  >(REQUEST_PROFYLE_REMIND);

  const onSend = (id: number) => {
    reviewRequestProfyleRemind({ variables: { id } }).then(() => {
      refetch();
    });
  };

  const handleRowViewClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const reviewId = e.currentTarget.dataset.reviewId;
      if (reviewId) {
        history.push(`/review-360/${reviewId}`);
      }
    },
    [history]
  );

  return (
    <>
      <h2>Feedback About You</h2>

      {loading && <Loading />}
      {!loading && !reviewRequestProfyles2.length && <p>You haven't asked anyone to provide Feedback yet.</p>}
      {!loading && !!reviewRequestProfyles2.length && (
        <>
          <p>Click on the Feedback you want to view.</p>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Profyle Role</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {reviewRequestProfyles2.map((request) => {
                const isCompleted = request.status === ReviewRequestStatus.REQUEST_COMPLETED;
                return (
                  <tr
                    key={request.id}
                    onClick={isCompleted ? handleRowViewClick : undefined}
                    data-review-id={request.review?.id}
                    style={{ cursor: isCompleted ? 'pointer' : 'auto' }}
                  >
                    <td>
                      {request.reviewer.firstName} {request.reviewer.lastName}
                    </td>
                    <td>{toProfyleSkillsetNames(request.profyle)}</td>
                    <td>{toLabel(request.status)}</td>
                    <td>
                      {isCompleted ? (
                        <Link to={`/review-360/${request.review!.id}`}>View</Link>
                      ) : request.status === ReviewRequestStatus.REQUEST_REMINDED ? (
                        `Reminder sent ${toFormatedDateTime(request.remindedAt)}`
                      ) : (
                        <FormButton
                          type="submit"
                          onClick={() => onSend(request.id)}
                          disabled={loadingRemind || undefined}
                          loading={loadingRemind || undefined}
                          size="sm"
                        >
                          Send Reminder
                        </FormButton>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
}

export { FeedbackAboutYou };
