import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';

import { Skillsets } from './skillsets';
import { skillsetsAddRoute } from './skillsets-add';
import { skillsetsEditRoute } from './skillsets-edit';
import { skillsetsViewRoute } from './skillsets-view';

function skillsetsRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/skillsets`}
      render={(routeProps) => (
        <>
          <ProtectedRoute path={routeProps.match.url} exact>
            <Skillsets />
          </ProtectedRoute>
          {skillsetsAddRoute(routeProps)}
          {skillsetsEditRoute(routeProps)}
          {skillsetsViewRoute(routeProps)}
        </>
      )}
    />
  );
}

export { skillsetsRoute };
