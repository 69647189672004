import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CompanyContext } from '../../../company/company-context';

import { Logo, LogoType } from '../../logo';

const TextLink = styled(Link)`
  display: inline-block;
`;

interface Props {
  children?: ReactNode;
}

function Footer(props: Props) {
  const { data } = React.useContext(CompanyContext);
  const company = data?.getCompanyInfo;

  return (
    <footer className="container py-5">
      <div className="row">
        <div className="col-6 col-md">
          <Logo logoUrl={company?.companyLanding?.logoUrl} logoType={LogoType.FOOTER}></Logo>
          <small className="d-inline-block ml-1 mb-2 text-muted">&copy; All rights reserved</small>
        </div>
        <div className="col-6 col-md text-right">
          {props.children}

          {/* <Link className="ml-3 text-muted" to="/background">Background</Link> */}
          <TextLink className="ml-3 text-muted" to="/terms">
            Terms &amp; Conditions
          </TextLink>
          <TextLink className="ml-3 text-muted" to="/contacts">
            Contact Us
          </TextLink>

          {/* <h5>Info</h5>
          <ul className="list-unstyled text-small">
            <li><Link className="text-muted" to="/background">Background</Link></li>
            <li><Link className="text-muted" to="/terms">Terms &amp; conditions</Link></li>
          </ul> */}
        </div>
      </div>
    </footer>
  );
}

export { Footer };
