import React, { ReactNode, useState, useEffect } from 'react';

import { ErrorContext } from '../error-context';
import { error } from '../../error';
import { errorValueDefault } from '../../error-value';

interface Props {
  children: ReactNode;
}

function ErrorContextProvider(props: Props) {
  const [message, setMessage] = useState(error.getMessage());
  useEffect(() => {
    function handleChange(message: string | false) {
      setMessage(message);
    }

    error.subscribe(handleChange);
    return () => {
      error.unsubscribe(handleChange);
    };
  });

  const errorValue = {
    ...errorValueDefault,
    message,
  };

  return <ErrorContext.Provider {...props} value={errorValue} />;
}

export { ErrorContextProvider };
