import React from 'react';
import { Table } from 'react-bootstrap';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';

import { LayoutDefault, Loading } from '../../shared/ui';
import { toProfyleSkillsetNames } from '../../shared/profyle';
import { toLabel } from '../../shared/helper';

import {
  ProfyleReviewReviewRequestAssignedListGet,
  ProfyleReviewReviewRequestAssignedListGet_profyleReviewReviewRequestAssignedListGet_requestProfyles,
  ProfyleReviewReviewRequestAssignedListGet_profyleReviewReviewRequestAssignedListGet,
} from './__generated__/ProfyleReviewReviewRequestAssignedListGet';

import { RequestProfyleWithUser, toRequestProfylesWithRequester } from '../../shared/request';

type RequestProfyleWithRequester = RequestProfyleWithUser<
  'requester',
  ProfyleReviewReviewRequestAssignedListGet_profyleReviewReviewRequestAssignedListGet,
  ProfyleReviewReviewRequestAssignedListGet_profyleReviewReviewRequestAssignedListGet_requestProfyles
>;

const REQUEST_LIST_GET = gql`
  query ProfyleReviewReviewRequestAssignedListGet {
    profyleReviewReviewRequestAssignedListGet {
      id
      requester {
        id
        firstName
        lastName
      }
      requestProfyles {
        id
        status
        profyle {
          result {
            quiz {
              skillsets {
                name
              }
            }
          }
        }
      }
    }
  }
`;

function ToReview() {
  const { loading, data } = useQuery<ProfyleReviewReviewRequestAssignedListGet>(REQUEST_LIST_GET);
  const reviewRequests = data?.profyleReviewReviewRequestAssignedListGet || [];
  const reviewRequestProfyles = toRequestProfylesWithRequester<RequestProfyleWithRequester>(reviewRequests);

  return (
    <LayoutDefault>
      <h2>Profyles To Review</h2>

      {loading ? (
        <Loading />
      ) : (
        <>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Profyle Role</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {reviewRequestProfyles.map((request) => {
                return (
                  <tr key={request.id}>
                    <td>
                      {request.requester.firstName} {request.requester.lastName}
                    </td>
                    <td>{toProfyleSkillsetNames(request.profyle)}</td>

                    <td>{toLabel(request.status)}</td>

                    <td>
                      <Link to={`/profyle-review/review/${request.id}`}>Review</Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
    </LayoutDefault>
  );
}

export { ToReview };
