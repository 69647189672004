import React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

export const SpinnerWrapper = styled.div`
  position: fixed;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: '';
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    background: var(--profyle-profyle-color-bg);
    opacity: 0.3;
  }
`;

interface IProps {
  loading: boolean;
}

export const GlobalLoading: React.FC<IProps> = ({ loading }) =>
  loading ? (
    <SpinnerWrapper>
      <Spinner animation="border" variant="primary" />
    </SpinnerWrapper>
  ) : null;
