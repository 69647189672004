import React from 'react';
import gql from 'graphql-tag';

import { AdminList } from '../../shared/ui';

import { AdminUserListGet_adminUserListGet } from './__generated__/AdminUserListGet';

const USER_LIST_GET = gql`
  query AdminUserListGet {
    adminUserListGet {
      id
      firstName
      lastName
      email
      roles {
        role
      }
    }
  }
`;

const USER_DELETE = gql`
  mutation AdminUserDelete($id: Int!) {
    adminUserDelete(id: $id) {
      id
    }
  }
`;

const MAPPING = [
  { label: 'User Id', getter: (value: AdminUserListGet_adminUserListGet) => value.id },
  { label: 'Name', getter: (value: AdminUserListGet_adminUserListGet) => value.firstName + ' ' + value.lastName },
  { label: 'Email', getter: (value: AdminUserListGet_adminUserListGet) => value.email },
  { label: 'Roles', getter: (value: AdminUserListGet_adminUserListGet) => value.roles.map((r) => r.role).join(', ') },
];

const BREADCRUMBS = [{ label: 'Users' }];

function Users() {
  return (
    <AdminList
      title="Users"
      listQuery={USER_LIST_GET}
      deleteMutation={USER_DELETE}
      dataProp="adminUserListGet"
      breadcrumbs={BREADCRUMBS}
      mapping={MAPPING}
    />
  );
}

export { Users };
