import React from 'react';
import { LayoutDefault, Loading } from '../shared/ui';
import { Html } from '../shared/html';
import { Helmet } from 'react-helmet';
import { META_TAGS } from '../shared/constants/metaTags';
import { useSkillsets } from '../shared/skillsets/use-skillsets';

function Skillsets() {
  const { loading, data } = useSkillsets();
  const skillsets = data?.skillsetListGet || [];

  return (
    <LayoutDefault>
      <Helmet>
        <title>{META_TAGS.profyleRoles.title}</title>
        <meta name="description" content={META_TAGS.profyleRoles.description} />
      </Helmet>
      <h2>Profyle Roles</h2>
      {loading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          {skillsets.map((skillset) => (
            <div key={skillset.name}>
              <h3>{skillset.name}</h3>
              <Html code={skillset.description} />
              <br />
              <br />
            </div>
          ))}
        </>
      )}
    </LayoutDefault>
  );
}

export { Skillsets };
