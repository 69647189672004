import { useParams } from 'react-router-dom';

interface RouteParams {
  id: string;
}

const useQuizId = (): number => {
  const routeParams = useParams<RouteParams>();
  return parseInt(routeParams.id);
};

export { useQuizId };
