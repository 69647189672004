import React from 'react';
import { Route } from 'react-router-dom';

import { ProtectedRoute } from '../shared/auth';

import { Collection } from './collection';
import { collectionBaseOnResultRoute } from './collection-base-on-result';
import { collectionQuizRoute } from './collection-quiz';
import { collectionFinishRoute } from './collection-finish';

function collectionRoute() {
  return (
    <ProtectedRoute
      path="/collection"
      render={(routeProps) => (
        <>
          <Route path={routeProps.match.url} exact>
            <Collection />
          </Route>
          {collectionBaseOnResultRoute(routeProps)}
          {collectionQuizRoute(routeProps)}
          {collectionFinishRoute(routeProps)}
        </>
      )}
    />
  );
}

export { collectionRoute };
