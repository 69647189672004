import React from 'react';

import { ProtectedRoute } from '../shared/auth';

import { profyleChosenCompanyRoute } from './profyle-chosen';

function profyleCompanyRoute() {
  return <ProtectedRoute path="/company/profyle" render={(routeProps) => profyleChosenCompanyRoute(routeProps)} />;
}

export { profyleCompanyRoute };
