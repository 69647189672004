import React from 'react';

import { LayoutDefault, AdminBreadcrumbs } from '../../../shared/ui';

import { UsersAddForm } from './users-add-form';

const BREADCRUMBS = [{ path: '/admin/users', label: 'Users' }, { label: 'Add user' }];

function UsersAdd() {
  return (
    <LayoutDefault>
      <h2>Add user</h2>
      <AdminBreadcrumbs breadcrumbs={BREADCRUMBS} />
      <UsersAddForm />
    </LayoutDefault>
  );
}

export { UsersAdd };
