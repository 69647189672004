import Cookies from 'js-cookie';

type Handler = (isAuth: boolean, isExpired: boolean, auth: Auth) => void;

const COOKIES_DOMAIN = '.profyle.me';

class Auth {
  private static STORAGE_KEY = 'PROFYLE_AUTH_TOKEN';
  private storage: Storage = localStorage;
  private expired = false;
  private handler: Handler | undefined;

  isAuthenticated(): boolean {
    return this.getToken() !== false;
  }

  isExpired(): boolean {
    return this.expired;
  }

  authenticate(token: string): Promise<boolean> {
    this.setToken(token);
    this.markExpired(false);
    return this.auth();
  }

  unauthenticate(): Promise<boolean> {
    Cookies.remove('userEmail', { domain: COOKIES_DOMAIN });
    Cookies.remove('userFirstName', { domain: COOKIES_DOMAIN });
    Cookies.remove('userLastName', { domain: COOKIES_DOMAIN });

    if (!this.isAuthenticated()) {
      return this.authWithoutHandler();
    }

    this.removeToken();
    this.markExpired(false);
    return this.auth();
  }

  expire(): Promise<boolean> {
    if (this.isExpired()) {
      return this.authWithoutHandler();
    }

    this.markExpired();
    this.removeToken();
    return this.auth();
  }

  subscribe(handler: Handler): void {
    this.handler = handler;
  }

  unsubscribe(handler: Handler): void {
    if (this.handler !== handler) {
      return;
    }

    this.handler = undefined;
  }

  getToken(): string | false {
    const token = localStorage.getItem(Auth.STORAGE_KEY);
    if (token === null) {
      return false;
    }

    return token;
  }

  private setToken(token: string): void {
    this.storage.setItem(Auth.STORAGE_KEY, token);
  }

  private removeToken(): void {
    this.storage.removeItem(Auth.STORAGE_KEY);
  }

  private markExpired(isExpired = true): void {
    this.expired = isExpired;
  }

  private auth(): Promise<boolean> {
    return this.authWithoutHandler().then((v) => {
      if (this.handler) {
        this.handler(v, this.isExpired(), this);
      }

      return v;
    });
  }

  private authWithoutHandler(): Promise<boolean> {
    return Promise.resolve(this.isAuthenticated());
  }
}

const auth = new Auth();

export { Auth, auth };
