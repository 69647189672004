import React from 'react';

import { LayoutDefault } from '../shared/ui';
import { ForgotPasswordForm } from './forgot-password-form';

function ForgotPassword() {
  return (
    <LayoutDefault>
      <ForgotPasswordForm />
    </LayoutDefault>
  );
}

export { ForgotPassword };
