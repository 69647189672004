import React, { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { LayoutDefault, ResponsiveTable } from '../shared/ui';
import { IObject, ITableMapping } from '../shared/ui/responsive-table/types';
import { REPORT_GET } from '../shared/document-node/reports';
import checkIcon from '../shared/icons/check-icon.svg';
import circleIcon from '../shared/icons/circle-icon.svg';
import { GetUsersReport, ITableData, IUser } from './types';
import { CustomLink, CustomTable } from './components';

const getDefaults = (groupName: string) => ({
  children: [],
  id: groupName,
  isConfirmed: false,
  group: groupName,
  name: groupName,
  assignedProfyles: 0,
  profylesComplited: 0,
  reviewInitiated360: 0,
  responceReceived360: 0,
  reviewCompleted3M: 0,
  reviewCompleted6M: 0,
  isAccordion: true,
});

const renderData = {
  renderIsConfirmed: (data: IObject, index: number) => <td key={`isConfirmed-${index}`}></td>,
};

export const UsersList: React.FC<any> = () => {
  const { loading, data } = useQuery<GetUsersReport>(REPORT_GET, {
    fetchPolicy: 'no-cache',
  });

  const tableData: IUser[] = useMemo(() => {
    let result = {};
    if (data) {
      result = data.getUsersReport.reduce((groups: ITableData, report) => {
        const groupName = report.group;
        if (!groupName) return groups;

        const skillsets = report.assignedProfyles;
        const profiles = report.profylesComplited;
        const initiated = report.reviewInitiated360;
        const received = report.responceReceived360;
        const completedTree = report.reviewCompleted3M;
        const completedSix = report.reviewCompleted6M;

        groups[groupName] = groups[groupName] || getDefaults(groupName);
        groups[groupName]?.children?.push(report);
        groups[groupName].assignedProfyles = groups[groupName].assignedProfyles + skillsets;
        groups[groupName].profylesComplited = groups[groupName].profylesComplited + profiles;
        groups[groupName].reviewInitiated360 = groups[groupName].reviewInitiated360 + initiated;
        groups[groupName].responceReceived360 = groups[groupName].responceReceived360 + received;
        groups[groupName].reviewCompleted3M = groups[groupName].reviewCompleted3M + completedTree;
        groups[groupName].reviewCompleted6M = groups[groupName].reviewCompleted6M + completedSix;
        return groups;
      }, {});
    }

    return Object.values(result);
  }, [data]);

  const mobileRowHeader = (item: IUser) => {
    return item.children || !item.isConfirmed ? (
      item.name
    ) : (
      <CustomLink to={`/company/profyle/user/${item.id}/welcome`}>{item.name}</CustomLink>
    );
  };

  const mapping: ITableMapping<IUser>[] = useMemo(() => {
    return [
      {
        label: 'Core User Name',
        disableOnMobile: true,
        getter: (item) => {
          return mobileRowHeader(item);
        },
      },
      {
        label: 'Account set up',
        getter: (item) => {
          return item.children ? '' : <img src={item.isConfirmed ? checkIcon : circleIcon} />;
        },
      },
      {
        label: 'Assigned profiles',
        getter: (item) => {
          return item.assignedProfyles;
        },
      },
      {
        label: 'Profile completed',
        getter: (item) => {
          return item.profylesComplited;
        },
      },
      {
        label: '360 initiated',
        getter: (item) => {
          return item.reviewInitiated360;
        },
      },
      {
        label: '360 response received',
        getter: (item) => {
          return item.responceReceived360;
        },
      },
      {
        label: '3 months review completed',
        getter: (item) => {
          return item.reviewCompleted3M;
        },
      },
      {
        label: '6 months review completed',
        getter: (item) => {
          return item.reviewCompleted6M;
        },
      },
    ];
  }, []);

  return (
    <LayoutDefault>
      <h1>
        Admin User - Reporting <span className="emphasis">Console</span>
      </h1>
      <ResponsiveTable
        loading={loading}
        mapping={mapping}
        data={tableData}
        CustomTable={CustomTable}
        mobileRowHeader={mobileRowHeader}
      />
    </LayoutDefault>
  );
};
