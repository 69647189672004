export const FAQ_CORE_USER = [
  {
    title: 'What can I use Profyle for?',
    text: [
      'Profyle has a wide range of applications. It can be for curious individuals who want to develop, for HR professionals and talent developers who have responsibility for staff development, or for coaches and trainers looking to add something new and different to their development programmes, either online or face to face.',
    ],
  },
  {
    title: 'Can I use Profyle online?',
    text: [
      'Profyle proves especially effective when added to development programmes delivered over WebEx, MS Teams, Zoom or other internet platforms. It is quick and easy to build into a range of programmes including; Leadership and Management, Influence, Coaching, Team Building, Negotiating or Facilitation.',
    ],
  },
  {
    title: 'How does Profyle work?',
    text: [
      'Profyle uses a unique process to identify your natural strengths and your preferred style before identifying how you can be more effective in your chosen role. Profyle than proposes development actions to help you improve your role.',
    ],
  },
  {
    title: 'How does the Feedback process work?',
    text: [
      'The Profyle Feedback process allows you to share the results of your Profyle with others that you trust to get feedback on your report and to support you in your development. Each person you identify as a reviewer will be able to comment and provide direct and secure feedback to you.',
    ],
  },
  {
    title: 'What do I get from using Profyle?',
    text: [
      'Profyle provides you with a set of tools to help you learn and develop in the role you have chosen to explore. These include a written textual review of your work style, both your strengths and limitations, a summary of areas that you might consider focusing on, a set of immediate actions you can take to start to optimise your style and a structured development path so that you can use to ensure lasting change.',
    ],
  },
  {
    title: 'What are focus areas?',
    text: [
      'Focus areas are specific skills and behaviours that Profyle suggests you might want to adjust in order to help you optimise your role and to ensure that your development is invested in the right areas.',
    ],
  },
  {
    title: 'How would I use Profyle in my company?',
    text: [
      'If you buy Profyle for others then you have a powerful new way to start developing your people that accelerates almost any learning programme. Having access to their Profyles in advance, as a coach or trainer, you get a head start in understanding who you will be working with and how they like to operate both as individuals and collectively.',
    ],
  },
  {
    title: 'How does Profyle differ from other profiling systems?',
    text: [
      'Profyle is designed so that anyone can use it without the need for an intermediary, special qualifications or licence fees. With Profyle you can manage a complete development journey, using trusted others as guides, to ensure you understand yourself better and take control of your own development.',
    ],
  },
  {
    title: 'How will my organisation benefit from using Profyle?',
    text: [
      'A significant saving on the Learning and Development budget. Not only does Profyle do a lot of the ground work for you but it also ensures that each individual has development ideas that are selected for them. Profyle accelerates development accurately, meaning less waste of the development budget.',
    ],
  },
  {
    title: 'Who are the Profyle Partners?',
    text: [
      'The Profyle Partners are a collection of coaches, trainers and educators that have come together to provide an opportunity for you to actively pursue a personal development programme. These partners understand the Profyle process and are able to provide specific assistance to help you address those areas that you feel need some focus.',
    ],
  },
  {
    title: 'How do I purchase Profyle?',
    text: [
      'Profyle can be purchased online by an individual for their own use or by a corporation for use within the workplace. You can see all of the pricing options on this page.',
    ],
  },
  {
    title: 'Is there any research to back up the Profyle approach?',
    text: [
      'Yes, of course. It took us over ten years of testing and thousands of people to validate the Profyle process. The system has been through many upgrades over that time to improve accuracy and ensure that results are easier to understand and interpret.',
      'The four scales that Profyle uses as its base are all recognised psychological concepts taken from the work of Carl Jung and others. The decision pairs within the system are all unique to Profyle.',
      'The Personal Assignment ideas and Instant Actions are a catalogue of practical skill development concepts that we, as a team of coaches and trainers, have been collecting during our work over many years and have been brought together in Profyle for the first time.',
    ],
  },
  {
    title: 'How does Profyle compare to MBTI, Disc, Firo B, 16PF etc?',
    text: [
      'We are big fans of all instruments and we respect the contribution that they have made, not only to our industry, but the way they have also inspired us. But Profyle is very different.',
      'We do not have a four-box model. Neither do we classify people into one of four or sixteen different boxes. Profyle recognises multiple differences in people and variety in styles of work.',
      'Profyle is not focused on numbers, scales and boxes but provides detailed reports, focus areas and practical ideas for skills development. In addition, Profyle has included a unique step – Profyle Feedback - so that everyone can have their results validated by people who know them well. This means that Profyle’s results are not limited to your own perception but are further validated and challenged by how others experience you.',
    ],
  },
  {
    title: 'What is the theory behind Profyle?',
    text: [
      'Inspired by both our competitors and collaborators in Learning and Development and the Psychometric world, the big idea is to go beyond numbers, boxes and theories. Profyle creates an integrated system that takes everyone on their own development path towards being the best version of themselves that they can be.',
      'Profyle provides not just insight and awareness but also 360 validation and practical, often fun, ways of developing.',
      'Are you curious about what Profyle will say about you?',
    ],
  },
  {
    title: 'How much does Profyle cost?',
    text: [
      'Profyle likes to make things as simple as possible and this includes how you pay for the service. So, the first time you create a Profyle it costs £45 for the whole package with nothing else to pay. That includes one role assessment, the full report, access to the development options and Profyle Feedback service.',
      'If you are buying for a company there are discounted enterprise offers that we are able to offer, dependent on your need, so please contact us.',
    ],
  },
];
