import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, RawDraftContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

interface Props {
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  readOnly?: boolean;
  placeholder?: string;
}

function TextEditor(props: Props) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(props.value || '').contentBlocks))
  );
  const onEditorStateChange = (state: EditorState) => {
    setEditorState(state);
  };
  const onContentStateChange = (state: RawDraftContentState) => {
    const html = draftToHtml(state).replace(new RegExp('^(:?<p>)?(.*?)(:?</p>\\r?\\n?)?$'), '$2');
    props.onChange && props.onChange(html);
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      onContentStateChange={onContentStateChange}
      onBlur={props.onBlur}
      readOnly={props.readOnly}
      placeholder={props.placeholder}
    />
  );
}

export { TextEditor };
