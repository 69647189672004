import React, { ChangeEvent } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { FormButton, Loading } from '../../../../../shared/ui';

import { AdminProfyleReportAdd, AdminProfyleReportAddVariables } from './__generated__/AdminProfyleReportAdd';
import classNames from 'classnames';
import { useSkillsets } from '../../../../../shared/skillsets/use-skillsets';

const PATH_ADD_LANDING = '/admin/profyles/reports';

const ADMIN_PROFYLE_REPORT_ADD = gql`
  mutation AdminProfyleReportAdd($input: AdminProfyleReportAddInput!) {
    adminProfyleReportAdd(input: $input) {
      id
    }
  }
`;

const initialValues = {
  skillsetId: '',
  documentFile: undefined,
};

const validationSchema = yup.object().shape({
  skillsetId: yup.string().required('Skillset is a required field'),
  documentFile: yup.mixed().required('Document is a required field'),
});

function ReportsAddForm() {
  const history = useHistory();
  const { loading, data } = useSkillsets();
  const skillsets = data?.skillsetListGet || [];
  const [adminProfyleReportAdd] = useMutation<AdminProfyleReportAdd, AdminProfyleReportAddVariables>(
    ADMIN_PROFYLE_REPORT_ADD
  );

  const onSubmit = (values: { skillsetId: string; documentFile: File | undefined }) => {
    const input = { skillsetIds: [parseInt(values.skillsetId)], documentFile: values.documentFile! };
    adminProfyleReportAdd({ variables: { input } }).then((res) => {
      const newReportId = res.data?.adminProfyleReportAdd.id;
      history.push(`${PATH_ADD_LANDING}#report-${newReportId}`);
    });
  };

  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const createOnChangeFile = (fieldName: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const el = e.currentTarget;
    form.setFieldValue(fieldName, el.files ? el.files[0] : null);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Form noValidate onSubmit={form.handleSubmit}>
      <Form.Group controlId="skillsetId">
        <Form.Label>Skillsets</Form.Label>
        <Form.Control
          as="select"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.skillsetId}
          isInvalid={!!(form.touched.skillsetId && form.errors.skillsetId)}
          disabled={form.isSubmitting}
          custom
          required
        >
          <option disabled value="" key="placeholder">
            Enter skillset
          </option>
          {skillsets.map((skillset) => (
            <option value={skillset.id} key={skillset.id}>
              {skillset.name}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{form.errors.skillsetId}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="documentFile">
        <Form.Label>Document with text report, focus areas and score rules</Form.Label>
        <Form.File
          placeholder="Browse spreadsheet"
          onChange={createOnChangeFile('documentFile')}
          accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          onBlur={form.handleBlur}
          isInvalid={!!(form.touched.documentFile && form.errors.documentFile)}
          disabled={form.isSubmitting}
        />
        <Form.Control.Feedback className={classNames({ 'd-block': form.touched.documentFile })} type="invalid">
          {form.errors.documentFile}
        </Form.Control.Feedback>
      </Form.Group>

      <FormButton type="submit" form={form}>
        Submit
      </FormButton>
    </Form>
  );
}

export { ReportsAddForm };
