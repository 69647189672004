import React, { ChangeEvent, FC } from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop, DropTargetMonitor } from 'react-dnd';
import { FormFile, ProgressBar } from 'react-bootstrap';
import styled from 'styled-components';
import fileIcon from '../../icons/file-icon.svg';

const Wrapper = styled.div`
  position: relative;
  border: 2px dashed var(--dark);
  height: 120px;
  width: 100%;
  padding: 32px;
  background: #f7f7f8;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    margin-left: 0;
    height: auto;
    flex-direction: column;
  }
`;

const UploadButton = styled.label`
  color: var(--primary);
  border-color: var(--primary);
  margin-bottom: 0;
`;

const Description = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: var(--dark);
  @media (max-width: 991px) {
    display: none;
  }
`;

const SupportedFiles = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: rgba(101, 105, 126, 0.5);
  @media (max-width: 991px) {
    padding: 16px 0;
    text-align: center;
  }
`;

const CustomProgressBar = styled(ProgressBar)`
  position: absolute;
  bottom: 8px;
  left: 32px;
  right: 32px;
`;
const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  @media (max-width: 991px) {
    margin-left: 0;
  }
`;
const Content = styled.div`
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
`;

export interface IFileUploadProps {
  onDrop: (item: { files: any[] }) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  accept: string;
  buttonText: string;
  supportedText: string;
  progress: number;
  loading: boolean;
}

export const FileUpload: FC<IFileUploadProps> = (props) => {
  const { onDrop, onChange, accept, buttonText, supportedText, loading, progress } = props;
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        if (!loading && onDrop) {
          onDrop(item);
        }
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [props]
  );

  // const isActive = canDrop && isOver;
  return (
    <Wrapper ref={drop}>
      <Content>
        <img src={fileIcon} />
        <DescriptionWrapper>
          <Description>Drag-and-drop your file or select from your device</Description>
          <SupportedFiles>{supportedText}</SupportedFiles>
          {loading && <CustomProgressBar now={progress} label={`${progress}%`} />}
        </DescriptionWrapper>
      </Content>
      <div>
        <UploadButton className="btn btn-secondary">
          {buttonText}
          <FormFile custom className="d-none" accept={accept} onChange={onChange} disabled={loading} />
        </UploadButton>
      </div>
      {/* {isActive ? 'Release to drop' : 'Drag file here'} */}
    </Wrapper>
  );
};
