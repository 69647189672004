import { Grade } from './grade';

const GRADE_LABELS: Record<Grade, string> = {
  FULLY_DISAGREE: 'Fully disagree',
  GENERALLY_DISAGREE: 'Generally disagree',
  CANT_DECIDE: "Can't decide",
  GENERALLY_AGREE: 'Generally agree',
  FULLY_AGREE: 'Fully agree',
};

export { GRADE_LABELS };
