import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { AdminSkillsetAdd, AdminSkillsetAddVariables } from './__generated__/AdminSkillsetAdd';

import { SkillsetsFormLayout } from '../../skillsets-form-layout';

const PATH_ADD_LANDING = '/admin/skillsets';

const SKILLSET_ADD = gql`
  mutation AdminSkillsetAdd($input: AdminSkillsetAddInput!) {
    adminSkillsetAdd(input: $input) {
      id
    }
  }
`;

function SkillsetsAddForm() {
  const history = useHistory();
  const [adminSkillsetAdd] = useMutation<AdminSkillsetAdd, AdminSkillsetAddVariables>(SKILLSET_ADD);

  const onSubmit = (values: { name: string; description: string }) => {
    const input = { ...values };
    adminSkillsetAdd({ variables: { input } }).then(() => {
      history.push(PATH_ADD_LANDING);
    });
  };

  return <SkillsetsFormLayout onSubmit={onSubmit} />;
}

export { SkillsetsAddForm };
