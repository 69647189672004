import React, { useState } from 'react';
import styled from 'styled-components';
import { Toast } from 'react-bootstrap';

const TIMEOUT_AUTO_HIDE = 15_000;

const MessageToast = styled(Toast)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

const MessageToastBody = styled<any>(Toast.Body)`
  color: ${(props) => (props.type === 'error' ? 'var(--danger)' : 'var(--dark)')};
  font-size: var(--profyle-h5-font-size);
`;

interface Props {
  type: 'error' | 'default';
  message: string;
}

function Message(props: Props) {
  const type = props.type || 'default';
  const [show, setShow] = useState(true);
  const hide = () => {
    setShow(false);
  };

  return (
    <MessageToast onClose={hide} show={show} delay={TIMEOUT_AUTO_HIDE} autohide>
      <MessageToastBody onClick={hide} type={type}>
        {props.message}
      </MessageToastBody>
    </MessageToast>
  );
}

export { Message };
