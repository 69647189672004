import React from 'react';

import { LayoutDefault } from '../../shared/ui';

function MessageSent() {
  return (
    <LayoutDefault>
      <h2>Contact Message</h2>
      Thank you! Your message is sent.
    </LayoutDefault>
  );
}

export { MessageSent };
