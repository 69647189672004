import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { EmailConfirmed } from './email-confirmed';

function emailConfirmedRoute(routeProps: RouteComponentProps) {
  return (
    <Route path={`${routeProps.match.url}/email-confirmed`}>
      <EmailConfirmed />
    </Route>
  );
}

export { emailConfirmedRoute };
