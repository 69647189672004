import { useApolloClient } from '@apollo/react-hooks';

const useResetCurrentUser = () => {
  const apollo = useApolloClient();

  return (): Promise<void> => {
    // Reset current user cache
    apollo.resetStore();

    return new Promise((resolve) => {
      // Wait for cache reseting
      setTimeout(() => {
        resolve();
      }, 250);
    });
  };
};

export { useResetCurrentUser };
