import { Table } from 'react-bootstrap';
import styled from 'styled-components';

export const TableWrapper = styled.div`
  overflow-x: auto;
  border-left: 1px solid white;
  border-top: 1px solid white;
  .table {
    border-bottom-width: 1px;
    vertical-align: middle;
    margin-top: -1px;
    margin-left: -1px;
  }
`;

export const CustomTable = styled(Table)`
  width: 1110px;
  margin-bottom: 0;
  thead,
  tbody {
    font-size: 14px;
    th,
    td {
      border-bottom-width: 1px;
      vertical-align: middle;
      padding: 8px;
      :not(:first-child) {
        width: 8.3%;
        text-align: center;
      }
    }
  }
  thead {
    th {
      font-weight: 400;
      text-align: center;
    }
    tr:last-child {
      th: first-child {
        width: 8.3%;
      }
    }
  }
  tbody {
    color: var(--profyle-profyle-color-black);
  }
`;

export const AccordionTd = styled.td`
  cursor: pointer;
`;

export const ChildTr = styled.tr`
  background: var(--profyle-profyle-color-input-bg);
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Image = styled.img<{ expanded: boolean }>`
  transform: ${(props) => (props.expanded ? 'rotateX(180deg)' : 'rotateX(0)')};
`;

export const Picker = styled.div`
  width: 460px;
  height: 44px;
  background: var(--profyle-profyle-color-input-bg);
  margin-bottom: 36px;
  position: relative;
  & > img {
    position: absolute;
    right: 12px;
    top: 9px;
    pointer-events: none;
  }
  input {
    width: 460px;
    height: 44px;
    background: transparent;
    border: none;
    outline: none;
    padding: 12px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: var(--profyle-profyle-color-black);
  }
  button {
    outline: none;
  }
  .react-datepicker__navigation-icon::before {
    height: 12px;
    top: 12px;
    width: 12px;
  }
  @media (max-width: 767px) {
    width: 100%;
    input {
      width: 100%;
    }
  }
`;
