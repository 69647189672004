import React from 'react';
import styled from 'styled-components';
import { ProgressBar, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { LayoutDefault, FormActions, Loading } from '../../shared/ui';

import {
  ProfyleListGetForCollectionFinish,
  ProfyleListGetForCollectionFinishVariables,
} from './__generated__/ProfyleListGetForCollectionFinish';

const QuestionProgress = styled(ProgressBar)`
  margin-bottom: 3rem;
`;

const PROFYLE_LIST_GET = gql`
  query ProfyleListGetForCollectionFinish($orderBy: OrderByInput = { field: "createdAt", direction: DESC }) {
    profyleListGet(orderBy: $orderBy) {
      id
    }
  }
`;

function CollectionFinish() {
  const { loading, data } = useQuery<ProfyleListGetForCollectionFinish, ProfyleListGetForCollectionFinishVariables>(
    PROFYLE_LIST_GET
  );
  const profyles = data?.profyleListGet || [];

  return (
    <LayoutDefault>
      <h2>That’s it, you’ve completed the first step</h2>
      <QuestionProgress now={100} />
      <p>
        Thank you for making those tough choices. We’re pretty sure that you found some of them much easier than others.
        We suspect that there were some where you were forced to choose between two items where you did not like either.
        And where you had to reject one that you liked a lot. Please don’t worry, it’s all part of the Profyle process.
      </p>
      {loading ? (
        <Loading />
      ) : (
        <FormActions oneAction>
          <Button className="btn btn-primary" as={Link} to={`/profyle/${profyles[0]!.id}`}>
            Discover Yourself
          </Button>
        </FormActions>
      )}
    </LayoutDefault>
  );
}

export { CollectionFinish };
