import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth, ShowForAuth, ShowForUser, CurrentUser, HideForUser } from '../../../auth';
import { Button } from '../../button';
import { Avatar } from '../../avatar';
import { useResetCurrentUserAndHistoryPush } from '../../../user';
import { RoleName } from '../../../auth/role-name';

const AvatarLink = styled(Link)`
  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
  }
`;

const Dropdown = styled(NavDropdown)`
  #collasible-nav-dropdown:after {
    display: none;
  }
  @media (max-width: 991px) {
    display: none;
  }
`;

const Padding = styled.div<{ bottom?: boolean; top?: boolean }>`
  padding: 0 0.5rem;
  ${({ bottom }) => (bottom ? 'padding-bottom: 8px' : '')}
  ${({ top }) => (top ? 'padding-top: 8px' : '')}
`;

const NavAuto = styled.div`
  flex-direction: column;
  button {
    width: 100%;
  }
`;

const ToggleButton = styled.button`
  display: inline-block !important;
`;

const Container = styled.div`
  min-width: 258px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 160%;
  color: #65697e;
  margin-bottom: 0;
  margin-right: 16px;
`;

const CustomButton = () => (
  <ToggleButton className="navbar-toggler">
    <span className="navbar-toggler-icon"></span>
  </ToggleButton>
);

const PATH_UNAUTH_LANDING = '/';

function DesktopNavbar() {
  const auth = useAuth();
  const resetCurrentUserAndHistoryPush = useResetCurrentUserAndHistoryPush();

  const signOut = () => {
    auth.unauthenticate().then(() => {
      resetCurrentUserAndHistoryPush(PATH_UNAUTH_LANDING);
    });
  };

  return (
    <Dropdown title={<CustomButton />} id="collasible-nav-dropdown" alignRight>
      <NavAuto className="me-auto">
        <Container>
          <ShowForAuth>
            <CurrentUser>
              {(user) => (
                <ShowForUser
                  role={[RoleName.ROLE_CORE, RoleName.ROLE_REVIEW_360, RoleName.ROLE_REVIEW_PLAIN]}
                  else={() => (
                    <Padding bottom>
                      <Wrapper>
                        <div>
                          <Text>{user.firstName}</Text>
                          <Text>{user.lastName}</Text>
                        </div>
                        <Avatar firstName={user.firstName} lastName={user.lastName} />
                      </Wrapper>
                    </Padding>
                  )}
                >
                  <Padding bottom>
                    <AvatarLink to="/my-info">
                      <Wrapper>
                        <div>
                          <Text>{user.firstName}</Text>
                          <Text>{user.lastName}</Text>
                        </div>
                        <Avatar firstName={user.firstName} lastName={user.lastName} />
                      </Wrapper>
                    </AvatarLink>
                  </Padding>
                </ShowForUser>
              )}
            </CurrentUser>
          </ShowForAuth>
          <ShowForUser role={RoleName.ROLE_CORPORATE_ADMIN}>
            <Nav.Link to="/company/billing-information" as={Link}>
              Billing Information
            </Nav.Link>
          </ShowForUser>
          <ShowForAuth>
            <Padding top>
              <Button onClick={signOut}>Sign Out</Button>
            </Padding>
          </ShowForAuth>
        </Container>
      </NavAuto>
    </Dropdown>
  );
}

export { DesktopNavbar };
