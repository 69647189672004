import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

function LayoutBody({ children }: Props) {
  return <div className="m-5-resp">{children}</div>;
}

export { LayoutBody };
