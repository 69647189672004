import React from 'react';
import { useFormik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';

import { FormButton, FormGroupText } from '../../../shared/ui';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  message: '',
};

const validationSchema = yup.object().shape({
  firstName: yup.string().required('First Name is a required field'),
  lastName: yup.string().required('Last Name is a required field'),
  email: yup.string().email('Email must be a valid email').required('Email is a required field'),
  message: yup.string().required('Message is a required field'),
});

interface Params {
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
}

function ContactsFormLayout(params: Params) {
  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit: params.onSubmit,
  });

  return (
    <Form noValidate onSubmit={form.handleSubmit}>
      <FormGroupText field="firstName" form={form} />

      <FormGroupText field="lastName" form={form} />

      <FormGroupText field="email" form={form} />

      <FormGroupText field="message" as="textarea" form={form} />

      <FormButton type="submit" form={form}>
        Submit
      </FormButton>
    </Form>
  );
}

export { ContactsFormLayout };
