import React from 'react';
import { Table } from 'react-bootstrap';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';

import { LayoutDefault, Button, Loading, FormButton } from '../../shared/ui';
import { toProfyleSkillsetNames } from '../../shared/profyle';
import { toFormatedDateTime } from '../../shared/datetime';

import { ReviewRequestStatus } from '../../../../__generated__/globalTypes';
import {
  Profyle360ReviewRequestRequestedListGetForStatus,
  Profyle360ReviewRequestRequestedListGetForStatus_profyle360ReviewRequestRequestedListGet,
  Profyle360ReviewRequestRequestedListGetForStatus_profyle360ReviewRequestRequestedListGet_requestProfyles,
} from './__generated__/Profyle360ReviewRequestRequestedListGetForStatus';
import {
  Profyle360ReviewRequestProfyleRemind,
  Profyle360ReviewRequestProfyleRemindVariables,
} from './__generated__/Profyle360ReviewRequestProfyleRemind';
import { RequestProfyleWithUser, toRequestProfylesWithReviewer } from '../../shared/request';

type RequestProfyleWithReviewer = RequestProfyleWithUser<
  'reviewer',
  Profyle360ReviewRequestRequestedListGetForStatus_profyle360ReviewRequestRequestedListGet,
  Profyle360ReviewRequestRequestedListGetForStatus_profyle360ReviewRequestRequestedListGet_requestProfyles
>;

const REQUEST_LIST_GET = gql`
  query Profyle360ReviewRequestRequestedListGetForStatus {
    profyle360ReviewRequestRequestedListGet {
      id
      reviewer {
        firstName
        lastName
        email
      }
      requestProfyles {
        id
        status
        acceptedAt
        completedAt
        remindedAt
        profyle {
          result {
            quiz {
              skillsets {
                name
              }
            }
          }
        }
        createdAt
      }
    }
  }
`;

const REQUEST_PROFYLE_REMIND = gql`
  mutation Profyle360ReviewRequestProfyleRemind($id: Int!) {
    reviewRequestProfyleRemind(id: $id) {
      id
    }
  }
`;

function Status() {
  const { loading, data, refetch } = useQuery<Profyle360ReviewRequestRequestedListGetForStatus>(REQUEST_LIST_GET);
  const reviewRequests = data?.profyle360ReviewRequestRequestedListGet || [];
  const reviewRequestProfyles = toRequestProfylesWithReviewer<RequestProfyleWithReviewer>(reviewRequests);
  const [reviewRequestProfyleRemind, { loading: loadingRemind }] = useMutation<
    Profyle360ReviewRequestProfyleRemind,
    Profyle360ReviewRequestProfyleRemindVariables
  >(REQUEST_PROFYLE_REMIND);
  const onSend = (id: number) => {
    reviewRequestProfyleRemind({ variables: { id } }).then(() => {
      refetch();
    });
  };

  return (
    <LayoutDefault>
      <h2>Profyle 360 Status</h2>

      {loading ? (
        <Loading />
      ) : (
        <>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Profyle Role</th>
                <th>Request Sent</th>
                <th>Review Accepted</th>
                <th>Review Complited</th>
                <th>Send Reminder</th>
                {/* <th>Provide feedback in 3 months and in 6 months</th> */}
              </tr>
            </thead>
            <tbody>
              {reviewRequestProfyles.map((requestProfyle) => {
                return (
                  <tr key={requestProfyle.id}>
                    <td>
                      {requestProfyle.reviewer.firstName} {requestProfyle.reviewer.lastName}
                    </td>
                    <td>{requestProfyle.reviewer.email}</td>
                    <td>{toProfyleSkillsetNames(requestProfyle.profyle)}</td>
                    <td>{toFormatedDateTime(requestProfyle.createdAt)}</td>
                    <td>{toFormatedDateTime(requestProfyle.acceptedAt)}</td>
                    <td>{toFormatedDateTime(requestProfyle.completedAt)}</td>
                    <td>
                      {requestProfyle.status === ReviewRequestStatus.REQUEST_COMPLETED ? (
                        ''
                      ) : requestProfyle.status === ReviewRequestStatus.REQUEST_REMINDED ? (
                        toFormatedDateTime(requestProfyle.remindedAt)
                      ) : (
                        <FormButton
                          type="submit"
                          onClick={() => onSend(requestProfyle.id)}
                          disabled={loadingRemind || undefined}
                          loading={loadingRemind || undefined}
                          size="sm"
                        >
                          Send
                        </FormButton>
                      )}
                    </td>
                    {/* <td>
                    <Form.Check
                      name={`provideFeedbackInMonthes${requestProfyle.id}`}
                      type="checkbox"
                      label="Provide feedback"
                      id={`provide-feedback-in-monthes-${requestProfyle.id}`}
                      custom
                      disabled
                    />
                  </td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <Button className="btn btn-primary" to="/profyle-360" as={Link}>
            Invite more
          </Button>
        </>
      )}
    </LayoutDefault>
  );
}

export { Status };
