import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { LayoutDefault, Loading } from '../shared/ui';
import { BrandingForm } from './branding-form';
import { UpdateLandingInfo, UpdateLandingInfoVariables } from './__generated__/UpdateLandingInfo';
import { UpdateCompanyLandingInfoInput } from '../../../__generated__/globalTypes';
import { useHistory } from 'react-router-dom';
import { CompanyContext } from '../shared/company/company-context';

const PATH_HOME = '/';

const UPDATE_COMPANY_LANDING_INFO = gql`
  mutation UpdateLandingInfo($input: UpdateCompanyLandingInfoInput!) {
    updateLandingInfo(input: $input) {
      logoUrl
    }
  }
`;

export function Branding() {
  const history = useHistory();
  const { getCompany } = React.useContext(CompanyContext);

  const [updateCompanyLanding] = useMutation<UpdateLandingInfo, UpdateLandingInfoVariables>(
    UPDATE_COMPANY_LANDING_INFO
  );

  const handleUpdate = useCallback(
    (values: UpdateCompanyLandingInfoInput) => {
      updateCompanyLanding({ variables: { input: values } }).then(() => {
        getCompany();
        history.push(PATH_HOME);
      });
    },
    [updateCompanyLanding, history, getCompany]
  );

  const handleClose = useCallback(() => {
    history.push(PATH_HOME);
  }, [history]);

  return (
    <LayoutDefault>
      <BrandingForm onUpdate={handleUpdate} onClose={handleClose} />
    </LayoutDefault>
  );
}
