import { compose, prop, map } from 'ramda';
import { list } from 'ramda-adjunct';

const answerToHTMLId: (question: Record<'id', number>, option: Record<'id', number>) => string = compose(
  ([questionId, optionId]: number[]) => `collection-question-${questionId}-option-${optionId}`,
  map(prop<'id', number>('id')),
  list
);

export { answerToHTMLId };
