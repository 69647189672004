import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { SelectControl } from '../../shared/user/my-info-form/my-info-form-layout/my-info-form-layout';
import { Button } from '../../shared/ui';
import { DocumentNode } from 'graphql';
import { useQuery } from '@apollo/react-hooks';
import { CompanyAdminsGet } from './__generated__/CompanyAdminsGet';

const StyledModal = styled(Modal)`
  & > div {
    width: 420px;
    max-width: 420px;
    @media (max-width: 767px) {
      width: auto;
    }
  }
`;

const StyledModalBody = styled(Modal.Body)`
  padding: 48px 64px 64px 64px;
  @media (max-width: 767px) {
    padding: 40px 20px;
  }
`;

const StyledModalHeader = styled(Modal.Header)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  border: 0;
`;

const BodyMainText = styled.p`
  font-size: 20px;
  margin-bottom: 24px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 48px;

  & > button {
    flex: 1;
  }

  & > button:first-child {
    margin-right: 8px;
  }

  & > button:last-child {
    margin-left: 8px;
  }
`;

interface IProps {
  show: boolean;
  adminsQuery: DocumentNode;
  emailToDelete?: string;
  onHide: () => void;
  onRemove: (adminToAssign: string) => void;
}

export const ModalRemoveAdmin: React.FC<IProps> = ({ show, onHide, onRemove, adminsQuery, emailToDelete }) => {
  const { loading, data } = useQuery<CompanyAdminsGet>(adminsQuery);
  const adminsToAssign = data?.getCompanyAdmins.filter((admin) => admin.email !== emailToDelete);

  const [adminToAssign, setAdminToAssign] = useState<string>();

  const handleSelectChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setAdminToAssign(e.target.value);
  }, []);

  const handleRemove = useCallback(() => {
    if (adminToAssign) {
      onRemove(adminToAssign);
    }
  }, [onRemove, adminToAssign]);

  return (
    <StyledModal show={show} onHide={onHide} centered>
      <StyledModalHeader />
      <StyledModalBody>
        <h2>Delete an admin</h2>
        <BodyMainText>Do you really want to delete a user and transfer his users to another admin</BodyMainText>
        <div className="mb-2">Choose an admin</div>
        {adminsToAssign?.length && (
          <SelectControl onChange={handleSelectChange}>
            <option value="">Select an admin</option>
            {adminsToAssign.map((admin) => (
              <option key={admin.id} value={admin.email}>
                {admin.firstName} {admin.lastName}
              </option>
            ))}
          </SelectControl>
        )}
        <ButtonsWrapper>
          <Button onClick={onHide}>No</Button>
          <Button onClick={handleRemove} variant="secondary" disabled={!adminToAssign}>
            Yes
          </Button>
        </ButtonsWrapper>
      </StyledModalBody>
    </StyledModal>
  );
};
