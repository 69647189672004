import React from 'react';
import { Route } from 'react-router-dom';

import { Terms } from './terms';

function termsRoute() {
  return (
    <Route path="/terms">
      <Terms />
    </Route>
  );
}

export { termsRoute };
