import React, { ElementType, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Accordion, AccordionToggleProps } from 'react-bootstrap';
import { ReplaceProps, BsPrefixProps } from 'react-bootstrap/helpers';

const AccordionToggleAs = styled.h6`
  margin-bottom: 0;
  cursor: pointer;
`;

type AccordionToggleProperties = PropsWithChildren<
  ReplaceProps<ElementType, BsPrefixProps<ElementType> & AccordionToggleProps>
>;

const AccordionToggle = (props: AccordionToggleProperties) => {
  return (
    <Accordion.Toggle as={AccordionToggleAs} {...props}>
      {props.children}
    </Accordion.Toggle>
  );
};

export { AccordionToggle };
