import React from 'react';

import { LayoutDefault, AdminBreadcrumbs } from '../../../../shared/ui';

import { ReportsAddForm } from './reports-add-form';

const BREADCRUMBS = [
  { path: '/admin/profyles', label: 'Profyles' },
  { path: '/admin/profyles/reports', label: 'Reports' },
  { label: 'Add report' },
];

function ReportsAdd() {
  return (
    <LayoutDefault>
      <h2>Add report</h2>
      <AdminBreadcrumbs breadcrumbs={BREADCRUMBS} />
      <ReportsAddForm />
    </LayoutDefault>
  );
}

export { ReportsAdd };
