import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';

import { FormButton, FormGroupText, FormGroupSelect } from '../../../../shared/ui';

const SCORE_CATEGORIES = ['consideration', 'assertion', 'emotionalManagement', 'extroversion'];

const defaultInitialValues = {
  optionLabels: ['', ''],
  optionScorePointCategory: ['', ''],
};

const validationSchema = yup.object().shape({
  optionLabels: yup.array().of(yup.string().required('Option text is required')).required(),
  optionScorePointCategory: yup.array().of(yup.string().required('Score point is required')).required(),
});

interface Params {
  onSubmit: (values: any) => void;
  initialValues?: { optionLabels: string[]; optionScorePointCategory: string[] };
}

function QuestionsFormLayout(params: Params) {
  const form = useFormik({
    initialValues: params.initialValues || defaultInitialValues,
    validationSchema,
    onSubmit: params.onSubmit,
  });

  const isFormValid = useMemo(() => {
    const { optionLabels, optionScorePointCategory } = form.values;

    return optionLabels.every(Boolean) && optionScorePointCategory.every(Boolean);
  }, [form]);

  return (
    <Form noValidate onSubmit={form.handleSubmit}>
      {['First option', 'Second option'].map((label, i) => (
        <div key={label}>
          <br />
          <h4>{label}</h4>

          <FormGroupText
            field={`optionLabels[${i}]`}
            valueGetter={(info: { [field: string]: any }) => info.optionLabels[i]}
            infoGetter={(info: { [field: string]: any }) => info.optionLabels?.[i]}
            label="Option text"
            form={form}
          />

          <FormGroupSelect
            field={`optionScorePointCategory[${i}]`}
            valueGetter={(info: { [field: string]: any }) => info.optionScorePointCategory[i]}
            infoGetter={(info: { [field: string]: any }) => info.optionScorePointCategory?.[i]}
            label="Score point"
            options={SCORE_CATEGORIES}
            form={form}
          />
        </div>
      ))}

      <br />
      <FormButton type="submit" form={form} disabled={!isFormValid}>
        Submit
      </FormButton>
    </Form>
  );
}

export { QuestionsFormLayout };
