import React from 'react';

import { LayoutDefault } from '../../shared/ui';

function EmailConfirmation() {
  return (
    <LayoutDefault>
      <h2>Email Confirmation</h2>
      Almost in. Profyle has sent you that usual boring confirmation email just so you’re safe. Hint. It sometimes lands
      in junk or spam.
    </LayoutDefault>
  );
}

export { EmailConfirmation };
