import React, { ReactNode, Component, ErrorInfo, ComponentState } from 'react';

import { error } from '../error';

interface Props {
  children: ReactNode;
}

interface State extends ComponentState {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(state: State) {
    // Update state so the next render will show the fallback UI.
    return { ...state, hasError: true };
  }

  componentDidCatch(err: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error(err, errorInfo);
    error.error(err.message);
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export { ErrorBoundary };
