import React from 'react';

import { LayoutDefault } from '../shared/ui';
import { ReferralForm } from './referral-form';

function Referral() {
  return (
    <LayoutDefault>
      <ReferralForm />
    </LayoutDefault>
  );
}

export { Referral };
