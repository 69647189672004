import React, { useCallback, useState } from 'react';
import { useCompanyGroups } from '../../shared/company/use-company-groups';
import { SelectControl } from '../../shared/user/my-info-form/my-info-form-layout/my-info-form-layout';
import { ModalAddGroup } from './modal-add-group';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { CompanyGroupAdd, CompanyGroupAddVariables } from './__generated__/CompanyGroupAdd';

const COMPANY_GROUPS_ADD = gql`
  mutation CompanyGroupAdd($input: AddCompanyGroupsInput!) {
    addCompanyGroups(input: $input) {
      groupName
    }
  }
`;

interface IProps {
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  value: string | number | string[] | undefined;
  isInvalid?: boolean;
  disabled?: boolean;
  required?: boolean;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  fieldName?: string;
  placeholder?: string;
  placeholderHidden?: boolean;
  custom?: boolean;
}

const ADD_NEW_GROUP_TEXT = '+ New group';

export const SelectGroup: React.FC<IProps> = ({
  onChange,
  onBlur,
  value,
  isInvalid,
  disabled,
  required,
  setFieldValue,
  fieldName,
  placeholder,
  placeholderHidden,
  custom = true,
}) => {
  const { data: groupsData, refetch } = useCompanyGroups();

  const groups = groupsData?.getCompanyGroups;

  const [companyGroupAdd] = useMutation<CompanyGroupAdd, CompanyGroupAddVariables>(COMPANY_GROUPS_ADD);

  const [showModal, setShowModal] = useState(false);

  const handleModalHide = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleCreate = useCallback(
    (value: string) => {
      const input = { groups: [{ groupName: value }] };

      companyGroupAdd({ variables: { input } }).then((res) => {
        refetch();
        if (setFieldValue && fieldName) {
          setFieldValue(fieldName, value);
        }
      });
      setShowModal(false);
    },
    [companyGroupAdd, refetch, setFieldValue, fieldName]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (e.target.value === ADD_NEW_GROUP_TEXT) {
        setShowModal(true);
      } else {
        onChange(e);
      }
    },
    [onChange]
  );

  return (
    <>
      <SelectControl
        onChange={handleChange}
        onBlur={onBlur}
        value={value}
        isInvalid={isInvalid && !showModal}
        disabled={disabled}
        required={required}
        custom={custom}
      >
        <option hidden={placeholderHidden} value="" key="default-group">
          {placeholder}
        </option>
        <option>+ New group</option>
        {groups?.map((group) => (
          <option key={group.groupName} value={group.groupName}>
            {group.groupName}
          </option>
        ))}
      </SelectControl>
      <ModalAddGroup show={showModal} onHide={handleModalHide} onCreate={handleCreate} />
    </>
  );
};
