import { auth } from './auth';

export interface AuthValue {
  isAuthenticated: boolean;
  isExpired: boolean;
  authenticate: typeof auth.authenticate;
  unauthenticate: typeof auth.unauthenticate;
}

export const authValueDefault: AuthValue = {
  isAuthenticated: auth.isAuthenticated(),
  isExpired: auth.isExpired(),
  authenticate: (token: string) => auth.authenticate(token),
  unauthenticate: () => auth.unauthenticate(),
};
