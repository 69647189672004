import React from 'react';

import { LayoutDefault, AdminBreadcrumbs } from '../../../shared/ui';

import { useParamId } from '../../../shared/router';

import { TextsEditForm } from './texts-edit-form';

const BREADCRUMBS = [{ path: '/admin/texts', label: 'Texts' }, { label: 'Edit text' }];

function TextsEdit() {
  const textId = useParamId();

  return (
    <LayoutDefault>
      <h2>Edit text with id {textId}</h2>
      <AdminBreadcrumbs breadcrumbs={BREADCRUMBS} />
      <TextsEditForm />
    </LayoutDefault>
  );
}

export { TextsEdit };
