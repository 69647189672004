import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import React from 'react';

import { AdminList } from '../../shared/ui';
import { AdminCompanyListGet_getAllCompanies } from './__generated__/AdminCompanyListGet';

const BREADCRUMBS = [{ label: 'Companies' }];

const COMPANY_LIST_GET = gql`
  query AdminCompanyListGet {
    getAllCompanies {
      id
      name
    }
  }
`;

const deleteMutationStub: DocumentNode = { kind: 'Document', definitions: [] };

const MAPPING = [
  { label: 'Company ID', getter: (value: AdminCompanyListGet_getAllCompanies) => value?.id },
  { label: 'Company Name', getter: (value: AdminCompanyListGet_getAllCompanies) => value?.name },
];

function Companies() {
  return (
    <AdminList
      listQuery={COMPANY_LIST_GET}
      deleteMutation={deleteMutationStub}
      dataProp="getAllCompanies"
      breadcrumbs={BREADCRUMBS}
      mapping={MAPPING}
      deleteLinkDisable
      addLinkDisable
    />
  );
}

export { Companies };
