import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';

import { ToReview } from './to-review';

function toReviewRoute(routeProps: RouteComponentProps) {
  return (
    <ProtectedRoute path={`${routeProps.match.url}/to-review`}>
      <ToReview />
    </ProtectedRoute>
  );
}

export { toReviewRoute };
