import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';

import { FormButton, FormGroupText, FormGroupSelect } from '../../../shared/ui';

import { FormValues } from './form-values';

const defaultInitialValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
};

const validationSchema = yup.object().shape({
  firstName: yup.string().required('First Name is a required field'),
  lastName: yup.string().required('Last Name is a required field'),
  email: yup.string().email('Email must be a valid email').required('Email is a required field'),
  role: yup.string().required('Role is a required field'),
});

interface Params {
  onSubmit: (values: any) => void;
  initialValues?: FormValues;
  roles: string[];
}

function UsersFormLayout(params: Params) {
  const form = useFormik({
    initialValues: params.initialValues || defaultInitialValues,
    validationSchema,
    onSubmit: params.onSubmit,
  });

  const isFormValid = useMemo(() => {
    const values = Object.values(form.values);
    return values.every(Boolean) && form.isValid;
  }, [form]);

  return (
    <Form noValidate onSubmit={form.handleSubmit}>
      <FormGroupText field="firstName" form={form} />

      <FormGroupText field="lastName" form={form} />

      <FormGroupText field="email" form={form} />

      <FormGroupSelect field="role" options={params.roles} form={form} />

      <br />
      <FormButton type="submit" form={form} disabled={!isFormValid}>
        Submit
      </FormButton>
    </Form>
  );
}

export { UsersFormLayout };
