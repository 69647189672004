import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { LayoutDefault, Loading } from '../shared/ui';
import { GetCompanyOrders } from './__generated__/GetCompanyOrders';
import { CompanyBillingInformationTable } from './company-billing-info-table';
import { CustomLink, SmallButton, Text, Wrapper } from './components';

const GET_COMPANY_ORDERS = gql`
  query GetCompanyOrders {
    getCompanyOrders {
      id
      userAmount
      skillsetAmount
      orderAmount
      isPaid
      createdAt
    }
  }
`;

export const CompanyBillingInformation: React.FC = () => {
  const { data, loading, refetch } = useQuery<GetCompanyOrders>(GET_COMPANY_ORDERS);
  const companyOrders = data?.getCompanyOrders;

  return (
    <LayoutDefault>
      <h1>
        My <span className="emphasis">Orders</span>
      </h1>
      {loading ? <Loading /> : <CompanyBillingInformationTable companyOrders={companyOrders!} refetch={refetch} />}
      <Wrapper>
        <CustomLink to="/consolidated-order">
          <SmallButton size="sm">Generate order</SmallButton>
        </CustomLink>
        <Text>
          It will consolidate all unpaid orders and roles in one new order. All old unpaid orders will be deleted.
        </Text>
      </Wrapper>
    </LayoutDefault>
  );
};
