import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';

import { Users } from './users';
import { usersAddRoute } from './users-add';
import { usersEditRoute } from './users-edit';
import { usersViewRoute } from './users-view';

function usersRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/users`}
      render={(routeProps) => (
        <>
          <ProtectedRoute path={routeProps.match.url} exact>
            <Users />
          </ProtectedRoute>
          {usersAddRoute(routeProps)}
          {usersEditRoute(routeProps)}
          {usersViewRoute(routeProps)}
        </>
      )}
    />
  );
}

export { usersRoute };
