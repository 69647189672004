import React from 'react';
import { Switch, useRouteMatch, useLocation, useHistory, RouteComponentProps } from 'react-router-dom';
import { pathToRegexp } from 'path-to-regexp';

import { trimUrlLastPart } from '../../../shared/helper';

import { feedback360Route } from './feedback-360';

function TabGetFeedbackRouting() {
  const routePropsOrigin: RouteComponentProps<{ section: string }> = {
    history: useHistory(),
    match: useRouteMatch(),
    location: useLocation(),
  };

  const parentPath = trimUrlLastPart(trimUrlLastPart(routePropsOrigin.match.path));
  const parentPathRegExp = pathToRegexp(parentPath, undefined, { end: false });
  const correctUrl = (url: string) => url.match(parentPathRegExp)![0].replace(new RegExp('/?$'), '');
  const routeProps = {
    ...routePropsOrigin,
    match: {
      ...routePropsOrigin.match,
      url: correctUrl(routePropsOrigin.match.url),
    },
    location: {
      ...routePropsOrigin.location,
      pathname: correctUrl(routePropsOrigin.location.pathname),
    },
  };

  if (routeProps.match.params.section !== 'get-feedback') {
    return null;
  }

  return <Switch>{feedback360Route(routeProps)}</Switch>;
}

export { TabGetFeedbackRouting };
