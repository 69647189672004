import React, { ReactNode } from 'react';
import { CookieBanner } from '../cookie-banner';

import { Footer } from './footer';

interface Props {
  children?: ReactNode;
}

function LayoutFooter({ children }: Props) {
  return (
    <>
      <Footer>{children}</Footer>
      <CookieBanner />
    </>
  );
}

export { LayoutFooter };
