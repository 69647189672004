import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { CollectionQuiz } from './collection-quiz';

function collectionQuizRoute(routeProps: RouteComponentProps) {
  return (
    <Route path={`${routeProps.match.url}/quiz/:id`}>
      <CollectionQuiz />
    </Route>
  );
}

export { collectionQuizRoute };
