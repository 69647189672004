import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../../../../shared/auth';

import { InviteSending } from './invite-sending';

function inviteSendingRoute(routeProps: RouteComponentProps) {
  return (
    <ProtectedRoute path={`${routeProps.match.url}/invite-sending`}>
      <InviteSending />
    </ProtectedRoute>
  );
}

export { inviteSendingRoute };
