import React, { useCallback } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

import { LayoutDefault, AdminBreadcrumbs, Loading } from '../../shared/ui';
import { CompanyInfoForm } from '../../company-info/company-info-form';
import { FormMode } from '../../shared/from';
import { AdminCompanyEditGet, AdminCompanyEditGetVariables } from './__generated__/AdminCompanyEditGet';
import { AdminBlockCompany, AdminBlockCompanyVariables } from './__generated__/AdminBlockCompany';
import { AdminUnblockCompany, AdminUnblockCompanyVariables } from './__generated__/AdminUnblockCompany';
import { AdminUpdateCompanyInfo, AdminUpdateCompanyInfoVariables } from './__generated__/AdminUpdateCompanyInfo';
import { UpdateCompanyInfoInput } from '../../../../__generated__/globalTypes';

const BREADCRUMBS = [{ path: '/admin/companies', label: 'Companies' }, { label: 'Edit Company' }];

const COMPANY_GET = gql`
  query AdminCompanyEditGet($id: Int!) {
    getCompanyInfoById(id: $id) {
      id
      name
      companyIndustry
      country
      city
      companySize
      paymentMethod
      companyLanding {
        logoUrl
      }
      companyGroups {
        groupName
      }
      isBlocked
    }
  }
`;

const UPDATE_COMPANY = gql`
  mutation AdminUpdateCompanyInfo($input: UpdateCompanyInfoInput!) {
    updateCompanyInfo(input: $input) {
      name
    }
  }
`;

const BLOCK_COMPANY = gql`
  mutation AdminBlockCompany($input: BlockCompanyInput!) {
    blockCompany(input: $input) {
      id
    }
  }
`;

const UNBLOCK_COMPANY = gql`
  mutation AdminUnblockCompany($input: BlockCompanyInput!) {
    unblockCompany(input: $input) {
      id
    }
  }
`;

interface RouteParams {
  id: string;
}

function CompaniesEdit() {
  const routeParams = useParams<RouteParams>();
  const companyId = parseInt(routeParams.id);

  const { loading, data, refetch } = useQuery<AdminCompanyEditGet, AdminCompanyEditGetVariables>(COMPANY_GET, {
    variables: { id: companyId },
  });

  const [blockCompany] = useMutation<AdminBlockCompany, AdminBlockCompanyVariables>(BLOCK_COMPANY);
  const [unblockCompany] = useMutation<AdminUnblockCompany, AdminUnblockCompanyVariables>(UNBLOCK_COMPANY);
  const [updateCompany] = useMutation<AdminUpdateCompanyInfo, AdminUpdateCompanyInfoVariables>(UPDATE_COMPANY);

  const company = data?.getCompanyInfoById;

  const handleUpdateCompany = useCallback(
    (values: UpdateCompanyInfoInput) => {
      updateCompany({ variables: { input: { ...values, id: companyId } } }).finally(() => {
        refetch();
      });
    },
    [updateCompany, refetch, companyId]
  );

  const handleBlockCompany = useCallback(() => {
    if (company?.isBlocked) {
      unblockCompany({ variables: { input: { id: companyId } } }).finally(() => {
        refetch();
      });
    } else {
      blockCompany({ variables: { input: { id: companyId } } }).finally(() => {
        refetch();
      });
    }
  }, [company, companyId, refetch, blockCompany, unblockCompany]);

  return (
    <LayoutDefault>
      <h2>Company edit</h2>
      <AdminBreadcrumbs breadcrumbs={BREADCRUMBS} />
      {loading ? (
        <Loading />
      ) : (
        <CompanyInfoForm
          headerText={company!.name || `Company #${company!.id}`}
          company={company!}
          initialFormMode={FormMode.Preview}
          hideTabs
          ableToBlock
          onBlockCompany={handleBlockCompany}
          onUpdateCompany={handleUpdateCompany}
        />
      )}
    </LayoutDefault>
  );
}

export { CompaniesEdit };
