import React from 'react';
import { Route } from 'react-router-dom';

import { ProtectedRoute } from '../shared/auth';

import { SignOut } from './sign-out';

const ROUTE_PATH_SIGN_OUT = '/sign-out';

function signOutRoute() {
  return (
    <Route path={ROUTE_PATH_SIGN_OUT}>
      <SignOut />
    </Route>
  );
}

export { signOutRoute, ROUTE_PATH_SIGN_OUT };
