import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';

import { Review } from './review';

function reviewRoute(routeProps: RouteComponentProps) {
  return (
    <ProtectedRoute path={`${routeProps.match.url}/review/:reviewRequestProfyleId`}>
      <Review />
    </ProtectedRoute>
  );
}

export { reviewRoute };
