enum RoleName {
  ROLE_CORE = 'ROLE_CORE',
  ROLE_REVIEW_PLAIN = 'ROLE_REVIEW_PLAIN',
  ROLE_REVIEW_360 = 'ROLE_REVIEW_360',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_CORPORATE_CORE = 'ROLE_CORPORATE_CORE',
  ROLE_CORPORATE_ADMIN = 'ROLE_CORPORATE_ADMIN',
}

export { RoleName };
