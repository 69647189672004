import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';

import { ReviewFinish } from './review-finish';

function reviewFinishRoute(routeProps: RouteComponentProps) {
  return (
    <ProtectedRoute path={`${routeProps.match.url}/review-finish`}>
      <ReviewFinish />
    </ProtectedRoute>
  );
}

export { reviewFinishRoute };
