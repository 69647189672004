import React, { ReactNode } from 'react';
import { AuthContextConsumer } from '../auth-context';

interface Props {
  children: ReactNode;
}

function ShowForNotAuth({ children }: Props) {
  return <AuthContextConsumer>{({ isAuthenticated }) => (!isAuthenticated ? children : null)}</AuthContextConsumer>;
}

export { ShowForNotAuth };
