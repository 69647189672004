import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SmallButton, ResponsiveLink } from './components';

export enum ActiveTabEnum {
  USERS = 'USERS',
  ADD_USERS = 'ADD_USERS',
}

const COMPANY_USERS_PATH = '/company-users';
const COMPANY_ADD_USERS_PATH = '/company-users/add';

interface IProps {
  activeTab: ActiveTabEnum;
}

const TabWrapper = styled.div<{ isActive: boolean }>`
  &:first-child {
    border-bottom: ${(props) => (props.isActive ? '2px solid var(--primary)' : 'none')};
    margin-right: 58px;
    &:hover {
      border-bottom: 2px solid var(--primary);
    }
  }

  & > a {
    text-decoration: none;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    font-family: var(--profyle-headings-font-family);
    color: ${(props) => (props.isActive ? 'var(--primary)' : 'var(--dark)')};

    &:hover {
      color: var(--primary);
    }
  }
`;

export const CompanyUsersTabs: React.FC<IProps> = ({ activeTab }) => {
  return (
    <div className="d-flex mb-5 flex-wrap">
      <TabWrapper isActive={activeTab === ActiveTabEnum.USERS}>
        <Link to={COMPANY_USERS_PATH}>My Users</Link>
      </TabWrapper>
      <TabWrapper isActive={activeTab === ActiveTabEnum.ADD_USERS}>
        <ResponsiveLink to={COMPANY_ADD_USERS_PATH}>
          <SmallButton disabled={activeTab === ActiveTabEnum.ADD_USERS} size="sm">
            Add Users
          </SmallButton>
        </ResponsiveLink>
      </TabWrapper>
    </div>
  );
};
