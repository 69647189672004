import React from 'react';
import { Route } from 'react-router-dom';

import { ProtectedRoute } from '../shared/auth';

import { SignIn } from './sign-in';

const ROUTE_PATH_SIGN_IN = '/sign-in';

function signInRoute() {
  return (
    <ProtectedRoute
      path={ROUTE_PATH_SIGN_IN}
      notAuth
      render={(routeProps) => (
        <>
          <Route path={routeProps.match.url} exact>
            <SignIn />
          </Route>
        </>
      )}
    />
  );
}

export { signInRoute, ROUTE_PATH_SIGN_IN };
