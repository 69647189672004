import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { SkillsetListGet } from './__generated__/SkillsetListGet';

const GET_SKILLSET_LIST = gql`
  query SkillsetListGet {
    skillsetListGet {
      id
      name
      description
    }
  }
`;

const useSkillsets = () => {
  return useQuery<SkillsetListGet>(GET_SKILLSET_LIST, {
    fetchPolicy: 'cache-first',
  });
};

export { useSkillsets };
