import { map, toPairs, fromPairs, range } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Form, ProgressBar } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Wizard, Steps, Step, WizardContext } from 'react-albus';
import { useFormik } from 'formik';
import * as yup from 'yup';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { LayoutDefault, getProgressPercents, isLastStep, isFirstStep, Loading } from '../../shared/ui';
import { toLines } from '../../shared/helper';
import { toProfyleSkillsetNames } from '../../shared/profyle';

import { Profyle360ReviewAddInput, Profyle360ReviewAddFeedbackInput } from '../../../../__generated__/globalTypes';
import { Profyle360ReviewAdd, Profyle360ReviewAddVariables } from './__generated__/Profyle360ReviewAdd';
import {
  ReviewRequestProfyleGetForReview360,
  ReviewRequestProfyleGetForReview360Variables,
} from './__generated__/ReviewRequestProfyleGetForReview360';

import { ReviewItem } from './review-item';
import { Grade } from '../../shared/grade';

const createInitialValues = <T extends unknown>(textLines: string[], value: T): { [id: string]: T } => {
  const ids = range(0, textLines.length);
  const pairs = map<number, [number, T]>((id) => [id, value], ids);
  return fromPairs(pairs);
};

const createValidationSchema = <T extends unknown>(
  textLines: string[],
  validation: yup.Schema<T>
): yup.ObjectSchema<{ [id: string]: T }> => {
  const ids = range(0, textLines.length);
  const pairs = map<number, [number, yup.Schema<T>]>((id) => [id, validation], ids);

  return yup.object(fromPairs(pairs));
};

const AnswerProgress = styled(ProgressBar)`
  margin-bottom: 3rem;
`;

const PATH_REVIEW_LANDING = '/feedback';

const REVIEW_REQUEST_PROFYLE_GET = gql`
  query ReviewRequestProfyleGetForReview360($id: Int!) {
    reviewRequestProfyleGet(id: $id) {
      profyle {
        id
        owner {
          firstName
          lastName
        }
        report {
          text
        }
        result {
          quiz {
            skillsets {
              name
            }
          }
        }
      }
    }
  }
`;

const PROFYLE_360_REVIEW_ADD = gql`
  mutation Profyle360ReviewAdd($input: Profyle360ReviewAddInput!) {
    profyle360ReviewAdd(input: $input) {
      id
    }
  }
`;

interface FeedbackValues {
  [questionId: string]: FeedbackValue | null;
}

interface FeedbackValue {
  grade: Grade;
  comment?: string;
}

const feedbackValuesToFeedbackInput = (values: FeedbackValues): Profyle360ReviewAddFeedbackInput[] => {
  return toPairs<FeedbackValue | null>(values)
    .filter<[string, FeedbackValue]>((p): p is [string, FeedbackValue] => Boolean(p[1]))
    .map<Profyle360ReviewAddFeedbackInput>(([paragraphIndex, feedback]) => ({
      paragraphIndex: parseInt(paragraphIndex),
      grade: feedback.grade,
      comment: feedback.comment || '',
    }));
};

interface RouteParams {
  reviewRequestProfyleId: string;
}

function Review() {
  const history = useHistory();
  const routeParams = useParams<RouteParams>();
  const reviewRequestProfyleId = parseInt(routeParams.reviewRequestProfyleId);

  const { loading, data } = useQuery<ReviewRequestProfyleGetForReview360, ReviewRequestProfyleGetForReview360Variables>(
    REVIEW_REQUEST_PROFYLE_GET,
    {
      variables: { id: reviewRequestProfyleId },
    }
  );
  const profyle = data?.reviewRequestProfyleGet?.profyle;
  const reportTextLines = toLines(profyle?.report.text || '');
  const owner = profyle?.owner || { firstName: '', lastName: '' };
  const [profyle360ReviewAdd] = useMutation<Profyle360ReviewAdd, Profyle360ReviewAddVariables>(PROFYLE_360_REVIEW_ADD);

  const onSubmit = (values: FeedbackValues) => {
    const input: Profyle360ReviewAddInput = {
      reviewRequestProfyleId,
      feedbacks: feedbackValuesToFeedbackInput(values),
    };
    profyle360ReviewAdd({ variables: { input } }).then(() => {
      history.push(PATH_REVIEW_LANDING);
    });
  };

  const form = useFormik({
    initialValues: createInitialValues(reportTextLines, null),
    validationSchema: createValidationSchema(
      reportTextLines,
      yup.object().shape({
        grade: yup.string().required(),
        comment: yup.string().max(200, 'Comment must be at most 200 characters'),
      })
    ),
    onSubmit,
  });

  const onFinish = () => {
    form.submitForm();
  };

  const onNext = (wizard: WizardContext) => {
    if (isLastStep(wizard)) {
      onFinish();
      return;
    }

    wizard.push();
  };

  if (loading) {
    return <Loading />;
  }

  const ownerFullName = `${owner.firstName} ${owner.lastName}`;
  const skillsetNames = toProfyleSkillsetNames(profyle!);
  return (
    <LayoutDefault>
      <h2>
        {ownerFullName} {skillsetNames} Report
      </h2>
      <Form noValidate onSubmit={form.handleSubmit}>
        <Wizard
          onNext={onNext}
          render={(wizard) => (
            <>
              {isFirstStep(wizard) &&
                (() => {
                  return (
                    <>
                      <p>
                        You are now going to start to review {ownerFullName} {skillsetNames} Profyle.
                      </p>
                      <p>
                        You will be presented with a series of short sections of text that relate to {ownerFullName}
                        Profyle and all you need to do is to choose the extent to which you agree or disagree with what
                        Profyle has written about them. Profyle encourages you to also provide some extra feedback that
                        explains and supports your choice in more detail.
                      </p>
                      <br />
                    </>
                  );
                })()}

              <AnswerProgress now={getProgressPercents(wizard)} />

              <Steps>
                {reportTextLines.map((reportTextLine, i) => {
                  const last = i === reportTextLines.length - 1;
                  const reviewAnswerStepId = `step-${i}`;
                  return (
                    <Step
                      render={(wizard) => (
                        <ReviewItem
                          reportTextParagraph={reportTextLine}
                          reportTextIndex={i}
                          form={form}
                          onNext={wizard.next}
                          last={last}
                        />
                      )}
                      id={reviewAnswerStepId}
                      key={reviewAnswerStepId}
                    />
                  );
                })}
              </Steps>
            </>
          )}
        />
      </Form>
    </LayoutDefault>
  );
}

export { Review };
