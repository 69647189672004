import { useHistory } from 'react-router-dom';

import { useResetCurrentUser } from './use-reset-current-user';

const useResetCurrentUserAndHistoryPush = () => {
  const history = useHistory();
  const resetCurrentUser = useResetCurrentUser();

  return <State = {} | null | undefined>(path: string, state?: State) => {
    resetCurrentUser().then(() => {
      history.push(path, state);
    });
  };
};

export { useResetCurrentUserAndHistoryPush };
