import React from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { Button } from '..';

const StyledModal = styled(Modal)`
  & > div {
    width: 420px;
    max-width: 420px;
  }
`;

const StyledModalBody = styled(Modal.Body)`
  padding: 48px 64px 64px 64px;
`;

const StyledModalHeader = styled(Modal.Header)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  border: 0;
`;

const BodyMainText = styled.p`
  font-size: 20px;
  margin-bottom: 24px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 48px;

  & > button {
    flex: 1;
  }

  & > button:first-child {
    margin-right: 8px;
  }

  & > button:last-child {
    margin-left: 8px;
  }

  & > button:only-child {
    margin: 0;
  }
`;

const defaultOnHide = () => {};

interface IProps {
  title: string;
  text?: string;
  confirmText: string;
  cancelText?: string;
  show: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  onHide?: () => void;
  children?: React.ReactNode;
}

export const ModalWindow: React.FC<IProps> = ({
  title,
  text,
  show,
  onConfirm,
  onCancel,
  onHide = defaultOnHide,
  confirmText,
  cancelText,
  children,
}) => {
  return (
    <StyledModal show={show} onHide={onHide} centered>
      <StyledModalHeader />
      <StyledModalBody>
        <h2>{title}</h2>
        {text && <BodyMainText>{text}</BodyMainText>}
        {children}
        <ButtonsWrapper>
          {cancelText && (
            <Button onClick={onCancel} variant="secondary">
              {cancelText}
            </Button>
          )}
          <Button onClick={onConfirm}>{confirmText}</Button>
        </ButtonsWrapper>
      </StyledModalBody>
    </StyledModal>
  );
};
