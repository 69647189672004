import React, { ReactNode } from 'react';
import { ErrorContextConsumer } from '../error-context';
import { Messaging } from '../../ui';

interface Props {
  children?: ReactNode;
}

function ErrorMessage(props: Props) {
  return (
    <ErrorContextConsumer>
      {({ message }) => (
        <>
          {message && <Messaging message={message} />}
          {props.children}
        </>
      )}
    </ErrorContextConsumer>
  );
}

export { ErrorMessage };
