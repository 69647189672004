import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { SkillsetsEdit } from './skillsets-edit';

function skillsetsEditRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/edit/:id`}
      render={() => (
        <>
          <SkillsetsEdit />
        </>
      )}
    />
  );
}

export { skillsetsEditRoute };
