const toRequestProfylesWithRequester = <R extends any>(reviewRequests: any[]): R[] =>
  reviewRequests.flatMap((r) =>
    r.requestProfyles.map((rp: any) => ({
      requester: r.requester,
      ...rp,
    }))
  );

const toRequestProfylesWithReviewer = <R extends any>(reviewRequests: any[]): R[] =>
  reviewRequests.flatMap((r) =>
    r.requestProfyles.map((rp: any) => ({
      reviewer: r.reviewer,
      ...rp,
    }))
  );

export { toRequestProfylesWithRequester, toRequestProfylesWithReviewer };
