import React from 'react';
import { useParams, Redirect, Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { LayoutDefault, Loading, Button } from '../../shared/ui';
import { useResetCurrentUserAndHistoryPush } from '../../shared/user';
import { toFormatedDate } from '../../shared/datetime';

import {
  QuizResultSimilarQuestionsListGet,
  QuizResultSimilarQuestionsListGetVariables,
} from './__generated__/QuizResultSimilarQuestionsListGet';
import {
  QuizResultAddBaseOnExistent,
  QuizResultAddBaseOnExistentVariables,
} from './__generated__/QuizResultAddBaseOnExistent';
import { toSkillsetNames } from '../../shared/profyle';
import styled from 'styled-components';

const MobileButton = styled(Button)`
  @media (max-width: 767px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const PATH_COLLECTION_BASE_ON_EXISTENT_LANDING = '/collection/finish';

const QUIZ_RESULT_SIMILAR_QUESTIONS_LIST_GET = gql`
  query QuizResultSimilarQuestionsListGet($quizId: Int!) {
    quizResultSimilarQuestionsListGet(quizId: $quizId) {
      id
      quiz {
        skillsets {
          name
        }
      }
      createdAt
    }
  }
`;

const QUIZ_RESULT_ADD_BASE_ON_EXISTENT = gql`
  mutation QuizResultAddBaseOnExistent($input: QuizResultAddBaseOnExistentInput!) {
    quizResultAddBaseOnExistent(input: $input) {
      id
    }
  }
`;

interface RouteParams {
  quizId: string;
}

function CollectionBaseOnResult() {
  const routeParams = useParams<RouteParams>();
  const quizId = routeParams.quizId;
  const quizPath = `/collection/quiz/${quizId}`;

  const { loading, data } = useQuery<QuizResultSimilarQuestionsListGet, QuizResultSimilarQuestionsListGetVariables>(
    QUIZ_RESULT_SIMILAR_QUESTIONS_LIST_GET,
    {
      variables: { quizId: parseInt(quizId) },
    }
  );
  const results = data?.quizResultSimilarQuestionsListGet || [];

  const [quizResultAddBaseOnExistent] = useMutation<QuizResultAddBaseOnExistent, QuizResultAddBaseOnExistentVariables>(
    QUIZ_RESULT_ADD_BASE_ON_EXISTENT
  );
  const resetCurrentUserAndHistoryPush = useResetCurrentUserAndHistoryPush();

  const onUse = () => {
    quizResultAddBaseOnExistent({ variables: { input: { quizId: parseInt(quizId) } } }).then(() => {
      resetCurrentUserAndHistoryPush(PATH_COLLECTION_BASE_ON_EXISTENT_LANDING);
    });
  };

  return (
    <LayoutDefault>
      {loading ? (
        <Loading />
      ) : (
        <>
          {results.length !== 0 ? (
            (() => {
              const latestResult = results[0];
              const latestResultSkillsetNames = toSkillsetNames(latestResult.quiz.skillsets);
              return (
                <>
                  <h2>Answer reusing</h2>
                  <p>Do you want use previous answers to generate profyle?</p>

                  <MobileButton className="btn btn-secondary" as={Link} to={quizPath}>
                    Create a New Profyle
                  </MobileButton>
                  <MobileButton onClick={onUse}>
                    Use My latest Profyle - {latestResultSkillsetNames} ({toFormatedDate(latestResult.createdAt)})
                  </MobileButton>
                </>
              );
            })()
          ) : (
            <Redirect to={quizPath} />
          )}
        </>
      )}
    </LayoutDefault>
  );
}

export { CollectionBaseOnResult };
