const calcMaxScoreDiff = (
  assertion: number,
  consideration: number,
  emotionalManagement: number,
  extroversion: number
): number => {
  const scores = [assertion, consideration, emotionalManagement, extroversion];
  const maxScore = Math.max(...scores);
  const minScore = Math.min(...scores);
  return maxScore - minScore;
};

export { calcMaxScoreDiff };
