import React from 'react';
import { Route } from 'react-router-dom';

import { Contacts } from './contacts';
import { messageSentRoute } from './message-sent';

function contactsRoute() {
  return (
    <Route
      path="/contacts"
      render={(routeProps) => (
        <>
          <Route path={routeProps.match.url} exact>
            <Contacts />
          </Route>
          {messageSentRoute(routeProps)}
        </>
      )}
    />
  );
}

export { contactsRoute };
