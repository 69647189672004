import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';

import { FormButton, FormGroupText, FormEditor } from '../../../shared/ui';

const defaultInitialValues = {
  name: '',
  description: '',
};

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  description: yup.string().required('Description is a required field'),
});

interface Params {
  onSubmit: (values: any) => void;
  initialValues?: { name: string; description: string };
}

function SkillsetsFormLayout(params: Params) {
  const form = useFormik({
    initialValues: params.initialValues || defaultInitialValues,
    validationSchema,
    onSubmit: params.onSubmit,
  });

  const isFormValid = useMemo(() => {
    const values = Object.values(form.values);
    return values.every(Boolean) && form.isValid;
  }, [form]);

  return (
    <Form noValidate onSubmit={form.handleSubmit}>
      <FormGroupText field="name" form={form} />

      <FormGroupText field="description" form={form} as={FormEditor} />

      <br />
      <FormButton type="submit" form={form} disabled={!isFormValid}>
        Submit
      </FormButton>
    </Form>
  );
}

export { SkillsetsFormLayout };
