import React, { useCallback } from 'react';
import { Nav, Container, Col, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';

import { Navigation, Loading, LayoutFooter, VideoPlayer, Button, BlockDescription } from '../shared/ui';
import { TextListGetHomesForHome } from './__generated__/TextListGetHomesForHome';
import discoverYourselfVideoImage from './images/discover-yourself-video-2x.png';
import discoverYourselfVideo from './images/discover-yourself-video.mp4';
import { toTaggedTexts } from '../shared/helper';
import { Html } from '../shared/html';
import useMediaQuery from '../shared/ui/use-media-query/use-media-query';
import { ModalCreateAccount } from '../shared/sign-in-up';
import { HOME_TEXT_LIST_GET } from './home';
import { BlockFigureMain } from '../shared/block-figure-main';
import { StagesOfYourJourney } from './stages-of-your-journey';
import profyleReportImage from './images/profyle-report.jpg';
import profyleProcessImage from './images/profyle-process.jpg';

const PATH_PROFYLE = '/profyle';

const headingsBaseStyles = `
  margin-bottom: 3rem;
`;

const H1 = styled.h1`
  ${headingsBaseStyles}
`;

const H2 = styled.h2`
  ${headingsBaseStyles}
`;

const H3 = styled.h3`
  ${headingsBaseStyles}
`;

const BlockContainer = styled(Container)`
  margin-top: 88px;
  margin-bottom: 10px;
`;

const BlockRow = styled(Row)`
  margin-bottom: 128px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const CreateAccountButtonMain = styled(Button)`
  margin-top: 48px;
`;

const BlockFigure = styled.div`
  min-width: 320px;
`;

const StyledBlockFigureMain = styled(BlockFigureMain)`
  min-width: 320px;
  padding: 0px 10px;
  text-align: left;
`;

const STAGES_MAP = {
  DECIDE: 'guestHomeStagesOfYourJourneyDecide',
  GENERATE: 'guestHomeStagesOfYourJourneyGenerate',
  DISCOVER: 'guestHomeStagesOfYourJourneyDiscover',
  CHOOSE: 'guestHomeStagesOfYourJourneyChoose',
  SHARE: 'guestHomeStagesOfYourJourneyShare',
};

export const HomeNotAuth = () => {
  const history = useHistory();
  const { loading, data } = useQuery<TextListGetHomesForHome>(HOME_TEXT_LIST_GET, {
    fetchPolicy: 'cache-first',
  });
  const homeTexts = data?.textListGet || [];
  const homeTaggedTexts = toTaggedTexts(homeTexts);

  const isSmall = useMediaQuery('(max-width: 767px)');

  const handleGetStartedRedirect = useCallback(async () => {
    history.push(PATH_PROFYLE);
  }, [history]);

  if (loading || !homeTexts.length) {
    return <Loading center />;
  }

  return (
    <>
      <Navigation>
        <Nav.Link to="/skillsets" as={Link}>
          Profyle Roles
        </Nav.Link>
        <Nav.Link to="/faq" as={Link}>
          FAQ
        </Nav.Link>
      </Navigation>

      <BlockContainer>
        <BlockRow>
          <Col>
            <H1>
              <Html code={homeTaggedTexts['corpHomeDiscoverEmployees'].title} />
            </H1>
            <BlockDescription>
              <Html code={homeTaggedTexts['corpHomeDiscoverEmployees'].body} />
            </BlockDescription>
            <ModalCreateAccount
              render={({ showModal }) => (
                <CreateAccountButtonMain onClick={showModal}>Get Started</CreateAccountButtonMain>
              )}
            />
          </Col>
          <Col className="text-right">
            <StyledBlockFigureMain>
              <VideoPlayer
                width={isSmall ? '100%' : '520'}
                height={isSmall ? '100%' : '312'}
                src={discoverYourselfVideo}
                poster={discoverYourselfVideoImage}
              />
            </StyledBlockFigureMain>
          </Col>
        </BlockRow>

        <H2>
          <Html code={homeTaggedTexts['corpHomeProfyleProcess'].title} />
        </H2>
        <BlockRow>
          <Col>
            <BlockFigure>
              <img className="img-fluid" src={profyleProcessImage} alt="The Profyle Process" />
            </BlockFigure>
          </Col>
          <Col>
            <BlockDescription>
              <Html code={homeTaggedTexts['corpHomeProfyleProcess'].body} />
            </BlockDescription>
          </Col>
        </BlockRow>

        <BlockRow>
          <Col>
            <H3>
              <Html code={homeTaggedTexts['corpHomeWhatProfyleProvide'].title} />
            </H3>
            <BlockDescription>
              <Html code={homeTaggedTexts['corpHomeWhatProfyleProvide'].body} />
            </BlockDescription>
          </Col>
          <Col className="text-right">
            <BlockFigure>
              <img className="img-fluid" src={profyleReportImage} alt="The Profyle Report" />
            </BlockFigure>
          </Col>
        </BlockRow>

        <H2>
          <Html code={homeTaggedTexts['homeStagesOfYourJourney'].title} />
        </H2>
        <StagesOfYourJourney taggedTexts={homeTaggedTexts} stagesMap={STAGES_MAP} />
      </BlockContainer>

      <LayoutFooter />
    </>
  );
};
