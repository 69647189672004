import React from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { useParamId } from '../../../../shared/router';

import {
  AdminSettingGet,
  AdminSettingGetVariables,
  AdminSettingGet_adminSettingGet,
} from './__generated__/AdminSettingGet';
import { AdminSettingUpdate, AdminSettingUpdateVariables } from './__generated__/AdminSettingUpdate';

import { SettingsFormLayout } from '../../settings-form-layout';
import { Loading } from '../../../../shared/ui';

const toInitialValues = (setting: AdminSettingGet_adminSettingGet): { title: string; value: string } => ({
  title: setting.title,
  value: setting.value,
});

const PATH_UPDATE_LANDING = '/admin/settings';

const SETTING_GET = gql`
  query AdminSettingGet($id: Int!) {
    adminSettingGet(id: $id) {
      id
      title
      value
    }
  }
`;

const SETTING_UPDATE = gql`
  mutation AdminSettingUpdate($input: AdminSettingUpdateInput!) {
    adminSettingUpdate(input: $input) {
      id
    }
  }
`;

function SettingsEditForm() {
  const settingId = useParamId();
  const history = useHistory();
  const { loading, data } = useQuery<AdminSettingGet, AdminSettingGetVariables>(SETTING_GET, {
    variables: { id: settingId },
  });
  const setting = data?.adminSettingGet;
  const [adminSettingUpdate] = useMutation<AdminSettingUpdate, AdminSettingUpdateVariables>(SETTING_UPDATE);

  const onSubmit = (values: { title: string; value: string }) => {
    const input = { id: settingId, ...values };
    adminSettingUpdate({ variables: { input } }).then(() => {
      history.push(PATH_UPDATE_LANDING);
    });
  };

  if (loading) {
    return <Loading />;
  }

  return <SettingsFormLayout initialValues={toInitialValues(setting!)} onSubmit={onSubmit} />;
}

export { SettingsEditForm };
