import React, { ReactNode, useState, useRef, useEffect } from 'react';

import { ModalSignInLayout } from './modal-sign-in-layout';

interface RenderProps {
  showModal: () => void;
}

interface Props {
  render: (renderProps: RenderProps) => ReactNode;
}

function ModalSignIn(props: Props) {
  const mounted = useRef(true);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const [show, setShow] = useState(false);
  const hideModal = () => mounted.current && setShow(false);
  const showModal = () => mounted.current && setShow(true);

  const signIn = () => {
    hideModal();
  };

  return (
    <>
      {props.render({ showModal })}
      <ModalSignInLayout show={show} onHide={hideModal} onSignIn={signIn} />
    </>
  );
}

export { ModalSignIn };
