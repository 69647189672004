import React from 'react';

import { LayoutDefault } from '../../shared/ui';

function ResetDone() {
  return (
    <LayoutDefault>
      <h2>Password Reset Done</h2>
      Password is changed.
    </LayoutDefault>
  );
}

export { ResetDone };
