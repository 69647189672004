import React from 'react';
import { Route } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';
import { RoleName } from '../../shared/auth/role-name';

import { CompanyOrderInvoice } from './company-order-invoice';

function companyOrderInvoiceRoute() {
  return (
    <ProtectedRoute
      path="/company-order-invoice/:id"
      role={RoleName.ROLE_CORPORATE_ADMIN}
      render={(routeProps) => (
        <>
          <Route notAuth path={routeProps.match.url} exact>
            <CompanyOrderInvoice orderId={Number(routeProps.match.params.id)} />
          </Route>
        </>
      )}
    />
  );
}

export { companyOrderInvoiceRoute };
