import React, { useRef, useEffect } from 'react';
import { select, arc, easeElasticOut, interpolate } from 'd3';
import styled from 'styled-components';
import { prop } from 'ramda';

import { degreesToRadians } from '../../../../shared/graph';
import { Unpacked } from '../../../../shared/ts';

import { GraphicFilterInnerShadow } from '../graphic-filter-inner-shadow';
import { Score } from '../../shared/score';

const GRAPHIC_SIZE_DEGREES = 180;
const ARC_WIDTH = 30;
const ARC_INNER_RADIUS_FIRST = 140;
const ARC_OUTER_RADIUS_FIRST = ARC_INNER_RADIUS_FIRST + ARC_WIDTH;
const ARC_CORNER_RADIUS = ARC_WIDTH / 2;
const ARC_SECTOR_MAX = 15;
const ARCS: { color: string; scoreName: keyof Score; label: string }[] = [
  {
    color: '#4f8bcf',
    scoreName: 'assertion',
    label: 'Assertion',
  },
  {
    color: '#83bc43',
    scoreName: 'consideration',
    label: 'Consideration',
  },
  {
    color: '#8735d4',
    scoreName: 'emotionalManagement',
    label: 'Emotional Management',
  },
  {
    color: '#212121',
    scoreName: 'extroversion',
    label: 'Extroversion',
  },
];
const ARC_SECTOR_ONE_DEGREES = GRAPHIC_SIZE_DEGREES / ARC_SECTOR_MAX;
const LEGEND_ITEM_LINE_HEIGHT = 40;
const LEGEND_ITEM_POINT_RADIUS = LEGEND_ITEM_LINE_HEIGHT / 6;

const ArcG = styled.g``;

const LegendG = styled.g`
  & text,
  & circle {
    cursor: pointer;
  }

  & text {
    font-family: var(--profyle-headings-font-family);
    font-size: var(--profyle-h4-font-size);
    font-weight: var(--profyle-headings-font-weight);
    fill: var(--dark);
  }
`;

interface Props extends Score {
  onDetails: () => void;
}

function GraphicScoreAreas(props: Props) {
  const refArcGroup = useRef<SVGSVGElement>(null);
  const refLegendGroup = useRef<SVGSVGElement>(null);

  useEffect(() => {
    // Arcs

    const arcData = ARCS.map((a, i) => {
      return {
        color: a.color,
        innerRadius: ARC_INNER_RADIUS_FIRST - i * ARC_WIDTH,
        outerRadius: ARC_OUTER_RADIUS_FIRST - i * ARC_WIDTH,
        startAngle: 0,
        endAngle: degreesToRadians(props[a.scoreName] * ARC_SECTOR_ONE_DEGREES),
      };
    });
    type ArcData = Unpacked<typeof arcData>;

    const createArcGenerator = (data: ArcData) =>
      arc().cornerRadius(ARC_CORNER_RADIUS).innerRadius(data.innerRadius).outerRadius(data.outerRadius);

    const createDataStartEndAngle = <A extends 'startAngle' | 'endAngle'>(angleName: A, data: ArcData) => ({
      ...data,
      [angleName]: degreesToRadians(10),
    });

    const arcs = select(refArcGroup.current)
      .selectAll('path')
      .data(arcData)
      .enter()
      .append('path')
      .attr('d', (data) => createArcGenerator(data)(createDataStartEndAngle('endAngle', data)))
      .attr('fill', prop('color'))
      .attr('style', 'filter:url(#graphic-score-areas-inner-shadow)');

    // Arcs animation

    const arcTween = (data: ArcData) => {
      const generator = createArcGenerator(data);
      const angleInterpolation = interpolate(
        generator.startAngle()(createDataStartEndAngle('startAngle', data)),
        generator.endAngle()(data)
      );

      const originalEnd = data.endAngle;

      return (t: number) => {
        const currentAngle = angleInterpolation(t);
        if (currentAngle < data.startAngle) {
          return '';
        }

        data.endAngle = Math.min(currentAngle, originalEnd);

        return createArcGenerator(data)(data) || '';
      };
    };

    setTimeout(() => {
      arcs.transition().ease(easeElasticOut.amplitude(1).period(0.5)).duration(1500).attrTween('d', arcTween);
    }, 500);

    // Legend

    const legendData = ARCS.map((a, i) => {
      return {
        color: a.color,
        label: `${a.label} / ${props[a.scoreName]}`,
        x: LEGEND_ITEM_POINT_RADIUS * 2.5,
        y: i * LEGEND_ITEM_LINE_HEIGHT,
        cy: i * LEGEND_ITEM_LINE_HEIGHT - LEGEND_ITEM_POINT_RADIUS / 1.2,
      };
    });

    select(refLegendGroup.current)
      .selectAll('circle')
      .data(legendData)
      .enter()
      .append('circle')
      .on('click', props.onDetails)
      .attr('cy', prop('cy'))
      .attr('r', LEGEND_ITEM_POINT_RADIUS)
      .attr('fill', prop('color'))
      .attr('style', 'filter:url(#graphic-score-areas-inner-shadow)');

    select(refLegendGroup.current)
      .selectAll('text')
      .data(legendData)
      .enter()
      .append('text')
      .on('click', props.onDetails)
      .attr('x', prop('x'))
      .attr('y', prop('y'))
      .text(prop('label'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <svg viewBox="0 0 335 585">
        <ArcG ref={refArcGroup} transform="translate(30, 190)" />
        <LegendG ref={refLegendGroup} transform="translate(45, 460)" />

        <defs>
          <GraphicFilterInnerShadow id="graphic-score-areas-inner-shadow" opacity={0.9} />
        </defs>
      </svg>
    </div>
  );
}

export { GraphicScoreAreas };
