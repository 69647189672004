import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { FormikHelpers } from 'formik';

import { ContactsFormLayout } from './contacts-form-layout';

import { ContactMessageSendInput } from '../../../../__generated__/globalTypes';
import { ContactMessageSend, ContactMessageSendVariables } from './__generated__/ContactMessageSend';

const PATH_CONTACTS_LANDING = '/contacts/message-sent';

const CONTACT_MESSAGE_SEND = gql`
  mutation ContactMessageSend($input: ContactMessageSendInput!) {
    contactMessageSend(input: $input) {
      id
    }
  }
`;

function ContactsForm() {
  const history = useHistory();

  const [contactMessageSend] = useMutation<ContactMessageSend, ContactMessageSendVariables>(CONTACT_MESSAGE_SEND);

  const onSubmit = (values: ContactMessageSendInput, formikHelpers: FormikHelpers<ContactMessageSendInput>) => {
    contactMessageSend({ variables: { input: values } })
      .then(() => {
        history.push(PATH_CONTACTS_LANDING);
      })
      .catch(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <div className="w-50-resp m-auto">
      <h3 className="text-center">Get in Touch</h3>
      <ContactsFormLayout onSubmit={onSubmit} />
    </div>
  );
}

export { ContactsForm };
