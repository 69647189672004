import React, { useEffect, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

interface Params {
  children: ReactNode;
}

function ScrollToTop(params: Params) {
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      window.scrollTo(0, 0);
    });
  });

  return <>{params.children}</>;
}

export { ScrollToTop };
