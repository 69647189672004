import React, { ReactNode } from 'react';

import { CurrentUser } from '../current-user';
import { UserCurrentGet_userCurrentGet } from '../__generated__/UserCurrentGet';

import { RoleName } from '../role-name';
import { hasRole } from '../has-role';

interface Props {
  role: RoleName | RoleName[];
  invert?: boolean;
  children?: ReactNode;
  else?: () => ReactNode;
}

function ShowForUser({ role, invert, children, else: elseGetter }: Props) {
  const elseChildren = elseGetter ? elseGetter() : null;
  const resultMatch = invert ? elseChildren : children;
  const resultNotMatch = invert ? children : elseChildren;

  return (
    <CurrentUser>
      {(user: UserCurrentGet_userCurrentGet) => (hasRole(user, role) ? resultMatch : resultNotMatch)}
    </CurrentUser>
  );
}

export { ShowForUser };
