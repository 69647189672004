import gql from 'graphql-tag';

export const REPORT_GET = gql`
  query GetUsersReport {
    getUsersReport {
      id
      isConfirmed
      group
      name
      assignedProfyles
      profylesComplited
      reviewInitiated360
      responceReceived360
      reviewCompleted3M
      reviewCompleted6M
    }
  }
`;
