import React, { useCallback, useState } from 'react';
import { Table } from 'react-bootstrap';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Link, useRouteMatch } from 'react-router-dom';

import { Button, FormButton, Loading } from '../../../../../shared/ui';
import { toProfyleSkillsetNames } from '../../../../../shared/profyle';
import { toFormatedDateTime } from '../../../../../shared/datetime';
import { RequestProfyleWithUser, toRequestProfylesWithReviewer } from '../../../../../shared/request';

import { ReviewRequestStatus } from '../../../../../../../__generated__/globalTypes';
import {
  Profyle360ReviewRequestRequestedListGetForStatusForFeedback,
  Profyle360ReviewRequestRequestedListGetForStatusForFeedback_profyle360ReviewRequestRequestedListGet,
  Profyle360ReviewRequestRequestedListGetForStatusForFeedback_profyle360ReviewRequestRequestedListGet_requestProfyles,
} from './__generated__/Profyle360ReviewRequestRequestedListGetForStatusForFeedback';
import {
  Profyle360ReviewRequestProfyleRemindForFeedback,
  Profyle360ReviewRequestProfyleRemindForFeedbackVariables,
} from './__generated__/Profyle360ReviewRequestProfyleRemindForFeedback';
import { trimUrlLastPart } from '../../../../../shared/helper';
import expandIcon from './images/expand.svg';
import styled from 'styled-components';

type RequestProfyleWithReviewer = RequestProfyleWithUser<
  'reviewer',
  Profyle360ReviewRequestRequestedListGetForStatusForFeedback_profyle360ReviewRequestRequestedListGet,
  Profyle360ReviewRequestRequestedListGetForStatusForFeedback_profyle360ReviewRequestRequestedListGet_requestProfyles
>;

const ResponsiveTable = styled(Table)`
  @media (max-width: 991px) {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    td {
      font-size: 14px;
      position: relative;
      border-top: none;
      border-bottom: 1px solid var(--light);
      padding-left: 0;
      z-index: 0;
      word-break: break-word;
    }

    td:not(:first-child) {
      /* Behave  like a "row" */
      padding-left: 50%;
      display: none;
      min-height: 47px;
    }

    td:not(:first-child):before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      content: attr(data-label);
      font-weight: bold;
    }

    td:first-child:after {
      content: url(${expandIcon});
      position: absolute;
      right: 20px;
      height: 14px;
      width: 7px;
    }
  }
`;

interface RowProps {
  isExpanded: boolean;
}
const ExpandableRow = styled.tr<RowProps>`
  ${(props) =>
    props.isExpanded &&
    `
    @media (max-width: 991px) {
        td:not(:first-child) {
          display: flex;
          justify-content: flex-end;
        }
        td:first-child:after {
          transform: rotate(180deg);
          top: 20px;
          right: 13px;
        }
      }
    `}
`;

const WideBackground = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
    background: var(--white);
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: -20px;
    right: -20px;
    z-index: -1;
  }
`;

const LoadingWrapper = styled.div`
  @media (max-width: 767px) {
    padding-top: 20px;
  }
`;

const REQUEST_LIST_GET = gql`
  query Profyle360ReviewRequestRequestedListGetForStatusForFeedback {
    profyle360ReviewRequestRequestedListGet {
      id
      reviewer {
        firstName
        lastName
        email
      }
      requestProfyles {
        id
        status
        acceptedAt
        completedAt
        remindedAt
        profyle {
          result {
            quiz {
              skillsets {
                name
              }
            }
          }
        }
        review {
          id
        }
        createdAt
      }
    }
  }
`;

const REQUEST_PROFYLE_REMIND = gql`
  mutation Profyle360ReviewRequestProfyleRemindForFeedback($id: Int!) {
    reviewRequestProfyleRemind(id: $id) {
      id
    }
  }
`;

function Status() {
  const routeMatch = useRouteMatch();
  const { loading, data, refetch } = useQuery<Profyle360ReviewRequestRequestedListGetForStatusForFeedback>(
    REQUEST_LIST_GET
  );
  const reviewRequests = data?.profyle360ReviewRequestRequestedListGet || [];
  const reviewRequestProfyles = toRequestProfylesWithReviewer<RequestProfyleWithReviewer>(reviewRequests);
  const [reviewRequestProfyleRemind, { loading: loadingRemind }] = useMutation<
    Profyle360ReviewRequestProfyleRemindForFeedback,
    Profyle360ReviewRequestProfyleRemindForFeedbackVariables
  >(REQUEST_PROFYLE_REMIND);
  const onSend = (id: number) => {
    reviewRequestProfyleRemind({ variables: { id } }).then(() => {
      refetch();
    });
  };

  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const handleItemExpand = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const rowId = Number(e.currentTarget.dataset.rowId);

      if (expandedRows.includes(rowId)) {
        setExpandedRows(expandedRows.filter((id) => id !== rowId));
      } else {
        setExpandedRows([...expandedRows, rowId]);
      }
    },
    [expandedRows]
  );

  return (
    <>
      {loading ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        <>
          <ResponsiveTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Profyle Role</th>
                <th>Request Sent</th>
                <th>Review Accepted</th>
                <th>Review Complete</th>
                <th>Actions</th>
                {/* <th>Provide feedback in 3 months and in 6 months</th> */}
              </tr>
            </thead>
            <tbody>
              {reviewRequestProfyles.map((requestProfyle) => {
                return (
                  <ExpandableRow key={requestProfyle.id} isExpanded={expandedRows.includes(requestProfyle.id)}>
                    <td data-label="Name" data-row-id={requestProfyle.id} onClick={handleItemExpand}>
                      {requestProfyle.reviewer.firstName} {requestProfyle.reviewer.lastName}
                      <WideBackground />
                    </td>
                    <td data-label="Email">{requestProfyle.reviewer.email}</td>
                    <td data-label="Profyle Role">{toProfyleSkillsetNames(requestProfyle.profyle)}</td>
                    <td data-label="Request Sent">{toFormatedDateTime(requestProfyle.createdAt)}</td>
                    <td data-label="Review Accepted">{toFormatedDateTime(requestProfyle.acceptedAt)}</td>
                    <td data-label="Review Complete">{toFormatedDateTime(requestProfyle.completedAt)}</td>
                    <td data-label="Actions">
                      {requestProfyle.status === ReviewRequestStatus.REQUEST_COMPLETED ? (
                        <Link to={`/review-360/${requestProfyle.review!.id}`}>View</Link>
                      ) : requestProfyle.status === ReviewRequestStatus.REQUEST_REMINDED ? (
                        toFormatedDateTime(requestProfyle.remindedAt)
                      ) : (
                        <FormButton
                          type="submit"
                          onClick={() => onSend(requestProfyle.id)}
                          disabled={loadingRemind || undefined}
                          loading={loadingRemind || undefined}
                          size="sm"
                        >
                          Send Reminder
                        </FormButton>
                      )}
                    </td>
                    {/* <td>
                    <Form.Check
                      name={`provideFeedbackInMonthes${requestProfyle.id}`}
                      type="checkbox"
                      label="Provide feedback"
                      id={`provide-feedback-in-monthes-${requestProfyle.id}`}
                      custom
                      disabled
                    />
                  </td> */}
                  </ExpandableRow>
                );
              })}
            </tbody>
          </ResponsiveTable>

          <Button className="btn btn-primary" to={`${trimUrlLastPart(routeMatch.url)}/home`} as={Link}>
            Invite more
          </Button>
        </>
      )}
    </>
  );
}

export { Status };
