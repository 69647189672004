import React, { SyntheticEvent } from 'react';

import { Button } from '../button';
import { ButtonSpinner } from '../button-spinner';

import { FormButtonProps } from './form-button-props';

const FormButton = (props: FormButtonProps) => {
  const onClick = (e: SyntheticEvent) => {
    if (props.onClick) {
      return props.onClick(e);
    }

    props.form && props.form.submitForm();
  };

  return (
    <Button {...props} disabled={props.disabled || props.form?.isSubmitting || undefined} onClick={onClick}>
      {props.children || 'Submit'}
      {(props.loading || props.form?.isSubmitting) && <ButtonSpinner />}
    </Button>
  );
};

export { FormButton };
