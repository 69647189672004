import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

import { Message } from './message';

const Wrapper = styled(Container)`
  position: relative;
  min-height: 0;
  z-index: 999;
`;

interface Props {
  message: string;
}

function Messaging(props: Props) {
  return (
    <Wrapper>
      <Message type="error" message={props.message} />
    </Wrapper>
  );
}

export { Messaging };
