import React from 'react';

import { LayoutDefault, AdminBreadcrumbs } from '../../../../shared/ui';
import { useParamId } from '../../../../shared/router';

import { QuestionsEditForm } from './questions-edit-form';

const BREADCRUMBS = [
  { path: '/admin/quizzes', label: 'Quizzes & Questions' },
  { path: '/admin/quizzes/quizzes', label: 'Quizzes' },
  { label: 'Edit question' },
];

function QuestionsEdit() {
  const questionId = useParamId();

  return (
    <LayoutDefault>
      <h2>Edit question with id {questionId}</h2>
      <AdminBreadcrumbs breadcrumbs={BREADCRUMBS} />
      <QuestionsEditForm />
    </LayoutDefault>
  );
}

export { QuestionsEdit };
