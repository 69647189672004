import React, { useCallback, useState, useMemo, ReactNode } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Button, ResponsiveTable } from '../shared/ui';
import { useHistory, Link } from 'react-router-dom';
import { GetCompanyOrders, GetCompanyOrders_getCompanyOrders } from './__generated__/GetCompanyOrders';
import { toFormatedDateTime } from '../shared/datetime';
import { SelectControl } from '../shared/user/my-info-form/my-info-form-layout/my-info-form-layout';
import styled from 'styled-components';
import { ConfirmModal } from '../shared/confirm-modal';
import { DeleteCompanyOrderById, DeleteCompanyOrderByIdVariables } from './__generated__/deleteCompanyOrderById';
import { ApolloQueryResult } from 'apollo-client';
import { NotificationManager } from 'react-notifications';
import { ITableMapping } from '../shared/ui/responsive-table/types';
import checkIcon from '../profyle/images/check.svg';

const DELETE_COMPANY_ORDER_BY_ID = gql`
  mutation DeleteCompanyOrderById($input: DeleteCompanyOrderByIdInput!) {
    deleteCompanyOrderById(input: $input) {
      id
    }
  }
`;

const RowButton = styled(Button)`
  padding: 6.3px 20.5px;
`;

const Row = styled.tr`
  & > td {
    vertical-align: middle;
  }
`;

const SELECT_ACTION_PAY = 'Pay';
const SELECT_ACTION_DELETE = 'Delete';
const SELECT_ACTIONS = [SELECT_ACTION_PAY, SELECT_ACTION_DELETE];

interface IProps {
  companyOrders: GetCompanyOrders_getCompanyOrders[];
  refetch: () => Promise<ApolloQueryResult<GetCompanyOrders>>;
}

const MobileHeader = styled.div`
  display: flex;
`;

const MobileHeaderIdCell = styled.div`
  width: 30px;
  min-width: 30px;
  display: inline-block;
`;

const MobileHeaderDateCell = styled.div`
  display: inline-block;
`;

export const CompanyBillingInformationTable: React.FC<IProps> = ({ companyOrders, refetch }) => {
  const [deleteOrderById, { loading }] = useMutation<DeleteCompanyOrderById, DeleteCompanyOrderByIdVariables>(
    DELETE_COMPANY_ORDER_BY_ID
  );

  const [selectedAction, setSelectedAction] = useState<{ [key: string]: string }>(
    companyOrders.reduce((acc, order) => {
      return { ...acc, [order.id]: SELECT_ACTION_PAY };
    }, {})
  );

  const [orderIdToGo, setOrderIdToGo] = useState<number>();

  const history = useHistory();
  const handleSelectChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const orderId = Number(e.currentTarget.dataset.id);
      setSelectedAction({ ...selectedAction, [orderId]: e.target.value });
    },
    [selectedAction]
  );

  const [showModal, setShowModal] = useState(false);

  const handleHideModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleConfirmModal = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const orderId = orderIdToGo!;
      deleteOrderById({ variables: { input: { orderId } } }).then(() => {
        refetch();
        NotificationManager.success(`Order #${orderId} deleted successfully`);
      });
      setShowModal(false);
    },
    [deleteOrderById, refetch, orderIdToGo]
  );

  const handleGoClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const orderId = Number(e.currentTarget.dataset.id);
      setOrderIdToGo(orderId);

      if (selectedAction[orderId] === SELECT_ACTION_PAY) {
        history.push(`/company-order/${orderId}`);
      } else if (selectedAction[orderId] === SELECT_ACTION_DELETE) {
        setShowModal(true);
      }
    },
    [selectedAction, history]
  );

  const mapping: ITableMapping<GetCompanyOrders_getCompanyOrders>[] = useMemo(() => {
    return [
      {
        label: '#',
        getter: (item) => {
          return <Link to={`/company-order-details/${item.id}`}>{item.id}</Link>;
        },
        disableOnMobile: true,
      },
      {
        label: 'Date',
        getter: (item) => {
          return toFormatedDateTime(item.createdAt);
        },
        disableOnMobile: true,
      },
      {
        label: 'Users',
        getter: (item) => {
          return item.userAmount;
        },
      },
      {
        label: 'Skillset',
        getter: (item) => {
          return item.skillsetAmount;
        },
      },
      {
        label: 'Total cost',
        getter: (item) => {
          return `${item.orderAmount / 100} GBP`;
        },
      },
      {
        label: 'Status',
        getter: (item) => {
          return item.isPaid ? 'Paid' : 'Created';
        },
      },
      {
        label: 'Action',
        getter: (item) => {
          if (item.isPaid) {
            return <img src={checkIcon} alt="Purchased" />;
          }
          return (
            <SelectControl
              onChange={handleSelectChange}
              value={selectedAction[item.id]}
              disabled={item.isPaid}
              data-id={item.id}
            >
              {SELECT_ACTIONS?.map((action) => (
                <option key={action} value={action}>
                  {action}
                </option>
              ))}
            </SelectControl>
          );
        },
      },
      {
        label: '',
        getter: (item) => {
          return (
            !item.isPaid && (
              <RowButton onClick={handleGoClick} disabled={item.isPaid} data-id={item.id}>
                Go
              </RowButton>
            )
          );
        },
      },
    ];
  }, [handleGoClick, handleSelectChange, selectedAction]);

  const mobileHeader = useMemo(() => {
    return (
      <MobileHeader>
        <MobileHeaderIdCell>{mapping[0].label}</MobileHeaderIdCell>
        <MobileHeaderDateCell>{mapping[1].label}</MobileHeaderDateCell>
      </MobileHeader>
    );
  }, [mapping]);

  const mobileRowHeader = (item: GetCompanyOrders_getCompanyOrders): ReactNode => {
    return (
      <MobileHeader>
        <MobileHeaderIdCell>
          <Link to={`/company-order-details/${item.id}`}>{item.id}</Link>
        </MobileHeaderIdCell>
        <MobileHeaderDateCell>{toFormatedDateTime(item.createdAt)}</MobileHeaderDateCell>
      </MobileHeader>
    );
  };

  return (
    <>
      <ResponsiveTable
        data={companyOrders}
        mapping={mapping}
        mobileHeader={mobileHeader}
        mobileRowHeader={mobileRowHeader}
      />
      <ConfirmModal
        show={showModal}
        headerText="Delete order"
        bodyText={`Do you really want to delete order #${orderIdToGo}`}
        onHide={handleHideModal}
        onConfirm={handleConfirmModal}
      />
    </>
  );
};
