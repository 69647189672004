import React, { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { LayoutDefault, Loading } from '../shared/ui';
import { CompanyInfoForm } from './company-info-form';
import { UpdateCompanyInfo, UpdateCompanyInfoVariables } from './__generated__/UpdateCompanyInfo';
import { UpdateCompanyInfoInput } from '../../../__generated__/globalTypes';
import { useHistory } from 'react-router-dom';
import { FormMode } from '../shared/from';
import { CompanyContext } from '../shared/company/company-context';

const UPDATE_COMPANY_INFO = gql`
  mutation UpdateCompanyInfo($input: UpdateCompanyInfoInput!) {
    updateCompanyInfo(input: $input) {
      name
    }
  }
`;

interface IProps {
  redirectUrl?: string;
  initialFormMode?: FormMode;
}

export const CompanyInfo: React.FC<IProps> = ({ redirectUrl, initialFormMode }) => {
  const { loading, data, getCompany } = React.useContext(CompanyContext);
  const company = data?.getCompanyInfo;
  const history = useHistory();

  const [updateCompany] = useMutation<UpdateCompanyInfo, UpdateCompanyInfoVariables>(UPDATE_COMPANY_INFO);

  const handleUpdateCompany = useCallback(
    (values: UpdateCompanyInfoInput) => {
      updateCompany({ variables: { input: values } }).then(() => {
        getCompany();
        if (redirectUrl) {
          history.push(redirectUrl);
        }
      });
    },
    [updateCompany, redirectUrl, history, getCompany]
  );

  return (
    <LayoutDefault>
      {loading ? (
        <Loading />
      ) : (
        <CompanyInfoForm company={company!} initialFormMode={initialFormMode} onUpdateCompany={handleUpdateCompany} />
      )}
    </LayoutDefault>
  );
};
