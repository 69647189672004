import React from 'react';

import { Rating } from './rating';
import { ProtectedRoute } from '../shared/auth';

function ratingRoute() {
  return (
    <ProtectedRoute path="/rating">
      <Rating />
    </ProtectedRoute>
  );
}

export { ratingRoute };
