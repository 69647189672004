import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { AdminList, FormButton } from '../../shared/ui';
import { Form, FormControlProps } from 'react-bootstrap';

import {
  getPaymentLogPageAndSize,
  getPaymentLogPageAndSize_getPaymentLogPageAndSize_paymentLog,
} from './__generated__/getPaymentLogPageAndSize';
import { toFormatedDateTime } from '../../shared/datetime';
import { AdminPaymentLogPageAndSizeInput } from '../../../../__generated__/globalTypes';
import styled from 'styled-components';

const StyledGroup = styled(Form.Group)`
  margin-right: 10px;
`;

const PAYMENT_LOGS_GET = gql`
  query getPaymentLogPageAndSize($input: AdminPaymentLogPageAndSizeInput) {
    getPaymentLogPageAndSize(input: $input) {
      currentPage
      totalItems
      totalPages
      paymentLog {
        id
        createdAt
        updatedAt
        paymentType
        paymentId
        paymentStatus
        userId
      }
    }
  }
`;

const MAPPING = [
  { label: 'id', getter: (value: getPaymentLogPageAndSize_getPaymentLogPageAndSize_paymentLog) => value.id },
  {
    label: 'createdAt',
    getter: (value: getPaymentLogPageAndSize_getPaymentLogPageAndSize_paymentLog) =>
      toFormatedDateTime(value.createdAt),
  },
  {
    label: 'updatedAt',
    getter: (value: getPaymentLogPageAndSize_getPaymentLogPageAndSize_paymentLog) =>
      toFormatedDateTime(value.updatedAt),
  },
  {
    label: 'paymentType',
    getter: (value: getPaymentLogPageAndSize_getPaymentLogPageAndSize_paymentLog) => value.paymentType,
  },
  {
    label: 'paymentId',
    getter: (value: getPaymentLogPageAndSize_getPaymentLogPageAndSize_paymentLog) => (
      <div
        style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        title={value.paymentId}
      >
        {value.paymentId}
      </div>
    ),
  },
  {
    label: 'paymentStatus',
    getter: (value: getPaymentLogPageAndSize_getPaymentLogPageAndSize_paymentLog) => value.paymentStatus,
  },
  {
    label: 'userId',
    getter: (value: getPaymentLogPageAndSize_getPaymentLogPageAndSize_paymentLog) => (
      <Link to={`/admin/users/view/${value.userId}`}>{value.userId}</Link>
    ),
  },
];

const BREADCRUMBS = [{ label: 'Payment Logs' }];

const PAYMENT_STATUSES = ['NEW', 'SENT', 'CANCELLED', 'SUCCESS'];
const PAGE_SIZES = [20, 50, 100];

const validationSchema = yup.object().shape<AdminPaymentLogPageAndSizeInput>({
  paymentStatus: yup.string().required(),
  page: yup.number().required(),
  size: yup.number().required(),
});

function PaymentLogs() {
  const form = useFormik({
    initialValues: {
      page: 0,
      size: 20,
      paymentStatus: 'SUCCESS',
    },
    validationSchema,
    onSubmit: (values, actions) => {},
  });

  const listQueryVariables = useMemo(
    () => ({
      input: {
        page: Number(form.values.page),
        size: Number(form.values.size),
        paymentStatus: form.values.paymentStatus,
      },
    }),
    [form.values.page, form.values.size, form.values.paymentStatus]
  );

  const resetPageOnChange = useCallback(
    (fieldName: string) => (e: ChangeEvent<HTMLSelectElement>) => {
      console.log(e.target.value);
      form.setValues({
        ...form.values,
        page: 0,
        [fieldName]: e.target.value,
      });
    },
    [form]
  );

  const deleteMutationStub: DocumentNode = { kind: 'Document', definitions: [] };
  return (
    <AdminList
      title="Payment Logs"
      listQuery={PAYMENT_LOGS_GET}
      listQueryVariables={listQueryVariables}
      deleteMutation={deleteMutationStub}
      dataProp={['getPaymentLogPageAndSize', 'paymentLog']}
      breadcrumbs={BREADCRUMBS}
      mapping={MAPPING}
      deleteLinkDisable
      addLinkDisable
      viewLinkDisable
      editLinkDisable
      renderHeader={(data: getPaymentLogPageAndSize) => {
        return (
          <Form noValidate onSubmit={form.handleSubmit}>
            <Form.Row>
              <StyledGroup controlId="page">
                <Form.Label>Page</Form.Label>
                <Form.Control
                  as="select"
                  value={form.values.page}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                >
                  {Array(data.getPaymentLogPageAndSize.totalPages || 1)
                    .fill('')
                    .map((_, index) => (
                      <option value={index} key={index}>
                        {index + 1}
                      </option>
                    ))}
                </Form.Control>
              </StyledGroup>

              <StyledGroup controlId="size">
                <Form.Label>Size</Form.Label>
                <Form.Control
                  as="select"
                  value={form.values.size}
                  onChange={resetPageOnChange('size')}
                  onBlur={form.handleBlur}
                >
                  {PAGE_SIZES.map((size) => (
                    <option value={size} key={size}>
                      {size}
                    </option>
                  ))}
                </Form.Control>
              </StyledGroup>
              <StyledGroup controlId="paymentStatus">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  value={form.values.paymentStatus}
                  onChange={resetPageOnChange('paymentStatus')}
                  onBlur={form.handleBlur}
                >
                  {PAYMENT_STATUSES.map((status) => (
                    <option value={status} key={status}>
                      {status}
                    </option>
                  ))}
                </Form.Control>
              </StyledGroup>
              <StyledGroup>
                <Form.Label>Total</Form.Label>
                <Form.Control
                  readOnly
                  plaintext
                  disabled
                  value={data.getPaymentLogPageAndSize.totalItems}
                  className="font-weight-normal"
                />
              </StyledGroup>
            </Form.Row>
          </Form>
        );
      }}
    />
  );
}

export { PaymentLogs };
