import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { MessageSent } from './message-sent';

function messageSentRoute(routeProps: RouteComponentProps) {
  return (
    <Route path={`${routeProps.match.url}/message-sent`}>
      <MessageSent />
    </Route>
  );
}

export { messageSentRoute };
