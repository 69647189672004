import React, { useMemo, useState, Fragment } from 'react';
import { format } from 'date-fns';

import arrowIcon from '../../shared/icons/arrow-icon.svg';
import { CustomTable, TableWrapper, AccordionTd, Wrapper, ChildTr, Image } from './components';
import { IExpanded, IParams, ITableData, GetSalesReport_getSalesReport } from './types';

const getReportsDefaultObject = (months: Date[]) => months.map(() => ({ users: 0, skillsets: 0, amount: 0 }));

function SalesTable(params: IParams) {
  const [expanded, setExpanded] = useState<IExpanded>({});
  const { data = [], months } = params;

  const onClick = (e: any) => {
    const element = e.currentTarget as HTMLInputElement;
    const key = String(element.id);
    setExpanded((state) => ({
      ...state,
      [key]: !state[key],
    }));
  };

  const tableData = useMemo(
    () =>
      data.reduce(
        (newObj: ITableData, item: GetSalesReport_getSalesReport) => {
          const { companyName, month, skillsets, amount, firstName, lastName } = item;
          const name = `${firstName} ${lastName}`;
          const monthIndex = months.findIndex((m) => m.getMonth() + 1 === month);

          if (monthIndex < 0) return newObj;

          const { companies, admins } = newObj;

          companies[companyName] = companies[companyName] || getReportsDefaultObject(months);
          companies[companyName][monthIndex].users += 1;
          companies[companyName][monthIndex].skillsets += skillsets;
          companies[companyName][monthIndex].amount += amount;

          admins[companyName] = admins[companyName] || {};
          admins[companyName][name] = admins[companyName][name] || getReportsDefaultObject(months);
          admins[companyName][name][monthIndex].users += 1;
          admins[companyName][name][monthIndex].skillsets += skillsets;
          admins[companyName][name][monthIndex].amount += amount;
          return newObj;
        },
        { companies: {}, admins: {} }
      ),
    [data, months]
  );

  return (
    <TableWrapper>
      <CustomTable bordered responsive>
        <thead>
          <tr>
            <td rowSpan={2}></td>
            {months.map((month) => (
              <Fragment key={`header-${month.toString()}`}>
                <th colSpan={3}>{format(month, 'MMMM, yyyy')}</th>
              </Fragment>
            ))}
          </tr>
          <tr>
            {months.map((month) => (
              <Fragment key={month.toString()}>
                <th>Users</th>
                <th>Skillsets</th>
                <th>Amount</th>
              </Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(tableData.companies).map((key, index) => {
            const monthlyData = tableData.companies[key];
            const adminsData = tableData.admins[key];

            return (
              <Fragment key={key}>
                <tr>
                  <AccordionTd id={key} onClick={onClick}>
                    <Wrapper>
                      <span>{key}</span>
                      <Image src={arrowIcon} expanded={expanded[key]} />
                    </Wrapper>
                  </AccordionTd>
                  {monthlyData.map((month, index) => (
                    <Fragment key={`${key}-${index}`}>
                      <td>{month.users}</td>
                      <td>{month.skillsets}</td>
                      <td>{month.amount / 100} GBP</td>
                    </Fragment>
                  ))}
                </tr>
                {expanded[key] &&
                  Object.keys(adminsData).map((name) => (
                    <ChildTr className={`multi-collapse${index}`} id={`multiCollapse${index}`} key={`${name}-${key}`}>
                      <td>{name}</td>
                      {adminsData[name].map((month, index) => (
                        <Fragment key={`${name}-${key}-${index}`}>
                          <td>{month.users}</td>
                          <td>{month.skillsets}</td>
                          <td>{month.amount / 100} GBP</td>
                        </Fragment>
                      ))}
                    </ChildTr>
                  ))}
              </Fragment>
            );
          })}
        </tbody>
      </CustomTable>
    </TableWrapper>
  );
}

export { SalesTable };
