import React from 'react';
import { Route } from 'react-router-dom';

import { Background } from './background';
// import { assertionRoute } from './assertion';
// import { considerationRoute } from './consideration';
// import { emotionalManagementRoute } from './emotional-management';
// import { extroversionRoute } from './extroversion';
// import { scoreImplicationsRoute } from './score-implications';

function backgroundRoute() {
  return (
    <Route path="/background/:section?">
      <Background />
    </Route>
  );
  // return (
  //   <Route path="/background" render={(routeProps) => (<>
  //     <Route path={routeProps.match.url} exact>
  //       <Background />
  //     </Route>
  //     {assertionRoute(routeProps)}
  //     {considerationRoute(routeProps)}
  //     {emotionalManagementRoute(routeProps)}
  //     {extroversionRoute(routeProps)}
  //     {scoreImplicationsRoute(routeProps)}
  //   </>)} />
  // );
}

export { backgroundRoute };
