import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { UsersEdit } from './users-edit';

function usersEditRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/edit/:id`}
      render={() => (
        <>
          <UsersEdit />
        </>
      )}
    />
  );
}

export { usersEditRoute };
