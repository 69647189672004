import React from 'react';
import { Route } from 'react-router-dom';

import { Home } from './home';

const ROUTE_PATH_HOME = '/';

function homeRoute() {
  return (
    <Route path={ROUTE_PATH_HOME} exact>
      <Home />
    </Route>
  );
}

export { homeRoute, ROUTE_PATH_HOME };
