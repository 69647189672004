import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ReportsView } from './reports-view';

function reportsViewRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/view/:id`}
      render={() => (
        <>
          <ReportsView />
        </>
      )}
    />
  );
}

export { reportsViewRoute };
