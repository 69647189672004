import React, { ConsumerProps } from 'react';

import { ErrorContext } from '../error-context';
import { ErrorValue } from '../../error-value';

type Props = ConsumerProps<ErrorValue>;

function ErrorContextConsumer(props: Props) {
  return <ErrorContext.Consumer {...props} />;
}

export { ErrorContextConsumer };
