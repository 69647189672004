import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { SkillsetsView } from './skillsets-view';

function skillsetsViewRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/view/:id`}
      render={() => (
        <>
          <SkillsetsView />
        </>
      )}
    />
  );
}

export { skillsetsViewRoute };
