import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { GetCompanyInfo } from './__generated__/GetCompanyInfo';
import { error } from '../error';

interface ICompanyContext {
  data: GetCompanyInfo | undefined;
  error: ApolloError | undefined;
  loading: boolean;
  getCompany: () => Promise<ApolloQueryResult<GetCompanyInfo>>;
}

export const CompanyContext = React.createContext<ICompanyContext>({
  data: undefined,
  error: undefined,
  loading: false,
  getCompany: () => Promise.resolve(null as any),
});

const GET_COMPANY_INFO = gql`
  query GetCompanyInfo {
    getCompanyInfo {
      id
      name
      companyIndustry
      country
      city
      companySize
      paymentMethod
      companyLanding {
        logoUrl
      }
      companyGroups {
        groupName
      }
      isBlocked
    }
  }
`;

error.registerIgnoreOperation('GetCompanyInfo');

const CompanyContextProvider = ({ children }: { children: React.ReactElement }) => {
  const { data, error, loading, refetch: getCompany } = useQuery<GetCompanyInfo>(GET_COMPANY_INFO);

  return <CompanyContext.Provider value={{ data, error, loading, getCompany }}>{children}</CompanyContext.Provider>;
};

export { CompanyContextProvider };
