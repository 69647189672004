import React, { ReactNode, KeyboardEvent } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { UserSignIn, UserSignInVariables } from './__generated__/UserSignIn';
import { UserSignInInput } from '../../../../../__generated__/globalTypes';
import { FormButton, FormButtonProps } from '../../ui';
import { auth } from '../../auth';
import { getApiResponseMessage } from '../../graphql';
import { error } from '../../error';
import { useQueryParams } from '../../router';
import { useResetCurrentUserAndHistoryPush } from '../../user';

const PATH_SIGN_IN_LANDING_DEFAULT = '/';

const SignInButtonModal = styled((props) => <FormButton {...props} />)`
  display: block;
  margin: 48px auto 0;
`;

interface SignInButtonProps extends FormButtonProps {
  modal?: boolean;
  children?: ReactNode;
}

const SignInButton = ({ modal, ...props }: SignInButtonProps) =>
  modal ? <SignInButtonModal {...props} /> : <FormButton {...props} />;

const USER_SIGN_IN = gql`
  mutation UserSignIn($input: UserSignInInput!) {
    userSignIn(input: $input) {
      token
      user {
        roles {
          role
        }
      }
    }
  }
`;

error.registerIgnoreOperation('UserSignIn');

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = yup.object().shape({
  email: yup.string().email('Email must be a valid email').required('Email is a required field'),
  password: yup.string().required('Password is a required field'),
});

interface Props {
  modal?: boolean;
  onSignIn?: () => void;
}

function FormSignIn(props: Props) {
  const queryParams = useQueryParams();
  const redirect = queryParams.get('redirect') || PATH_SIGN_IN_LANDING_DEFAULT;
  const resetCurrentUserAndHistoryPush = useResetCurrentUserAndHistoryPush();
  const [userSignIn] = useMutation<UserSignIn, UserSignInVariables>(USER_SIGN_IN);

  const onSubmit = (values: UserSignInInput, formikHelpers: FormikHelpers<UserSignInInput>) => {
    userSignIn({ variables: { input: values } })
      .then((result) => {
        return auth.authenticate(result.data!.userSignIn!.token).then(() => result.data!.userSignIn!.user);
      })
      .then((user) => {
        props.onSignIn && props.onSignIn();
        resetCurrentUserAndHistoryPush(redirect);
      })
      .catch((e) => {
        formikHelpers.setErrors({ password: getApiResponseMessage(e) });
        formikHelpers.setSubmitting(false);
      });
  };

  const form = useFormik<UserSignInInput>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      form.submitForm();
    }
  };

  return (
    <Form noValidate onSubmit={form.handleSubmit}>
      <Form.Group controlId="email">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.email}
          isInvalid={!!(form.touched.email && form.errors.email)}
          disabled={form.isSubmitting}
          onKeyPress={onKeyPress}
        />
        <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
        <Form.Control.Feedback type="invalid">{form.errors.email}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Enter password"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.password}
          isInvalid={!!(form.touched.password && form.errors.password)}
          disabled={form.isSubmitting}
          onKeyPress={onKeyPress}
        />
        <Form.Text>
          <Link to="/forgot-password">Forgot Password?</Link>
        </Form.Text>
        <Form.Control.Feedback type="invalid">{form.errors.password}</Form.Control.Feedback>
      </Form.Group>
      <SignInButton type="submit" modal={props.modal} form={form}>
        Sign In
      </SignInButton>
    </Form>
  );
}

export { FormSignIn };
