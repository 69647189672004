import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { LayoutDefault, Loading } from '../shared/ui';
import { Html } from '../shared/html';

import { TextGetTermsForTerms } from './__generated__/TextGetTermsForTerms';

const TERMS_TEXT_GET = gql`
  query TextGetTermsForTerms {
    textGet(tag: "terms") {
      title
      body
    }
  }
`;

function Terms() {
  const { loading, data } = useQuery<TextGetTermsForTerms>(TERMS_TEXT_GET);
  const text = data?.textGet;

  return (
    <LayoutDefault>
      {loading ? (
        <Loading />
      ) : (
        (() => {
          return (
            !!text && (
              <>
                <h2>{text.title}</h2>
                <Html code={text.body} />
              </>
            )
          );
        })()
      )}
    </LayoutDefault>
  );
}

export { Terms };
