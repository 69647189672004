import gql from 'graphql-tag';

export const SALES_REPORT_GET = gql`
  mutation GetSalesReport($input: MonthListInput!) {
    getSalesReport(input: $input) {
      companyId
      companyName
      firstName
      lastName
      email
      userCorporateAdminId
      year
      month
      user
      skillsets
      amount
    }
  }
`;
