import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { Invite } from './invite';

function inviteRoute(routeProps: RouteComponentProps) {
  return (
    <Route path={`${routeProps.match.url}/invite`}>
      <Invite />
    </Route>
  );
}

export { inviteRoute };
