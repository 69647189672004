import React from 'react';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { AdminList } from '../../shared/ui';

import { getAllOrders_getAllOrders } from './__generated__/getAllOrders';

const ALL_ORDERS_ADMIN_GET = gql`
  query getAllOrders {
    getAllOrders {
      id
      userAmount
      skillsetAmount
      orderAmount
      isPaid
    }
  }
`;

const MAPPING = [
  { label: 'id', getter: (value: getAllOrders_getAllOrders) => value.id },
  {
    label: 'isPaid',
    getter: (value: getAllOrders_getAllOrders) => `${value.isPaid}`,
  },
  {
    label: 'orderAmount',
    getter: (value: getAllOrders_getAllOrders) => `${value.orderAmount / 100} GBP`,
  },
  {
    label: 'skillsetAmount',
    getter: (value: getAllOrders_getAllOrders) => value.skillsetAmount,
  },
  {
    label: 'userAmount',
    getter: (value: getAllOrders_getAllOrders) => value.userAmount,
  },
];

const BREADCRUMBS = [{ label: 'Payment Logs' }];

function Orders() {
  const deleteMutationStub: DocumentNode = { kind: 'Document', definitions: [] };
  return (
    <AdminList
      title="Orders"
      listQuery={ALL_ORDERS_ADMIN_GET}
      deleteMutation={deleteMutationStub}
      dataProp="getAllOrders"
      breadcrumbs={BREADCRUMBS}
      mapping={MAPPING}
      deleteLinkDisable
      addLinkDisable
      viewLinkDisable
      editLinkDisable
    />
  );
}

export { Orders };
