import { Route } from 'react-router-dom';
import React from 'react';

import { ProtectedRoute } from '../shared/auth';
import { RoleName } from '../shared/auth/role-name';
import { CompanyUsersList } from './';
import { AddUser } from './add-user';

function companyUsersRoute() {
  return (
    <ProtectedRoute
      path="/company-users"
      role={RoleName.ROLE_CORPORATE_ADMIN}
      render={(routeProps: any) => (
        <>
          <Route path={routeProps.match.url} exact>
            <CompanyUsersList />
          </Route>
          <Route path={`${routeProps.match.url}/add`} exact>
            <AddUser {...routeProps.location?.state} />
          </Route>
        </>
      )}
    />
  );
}

export { companyUsersRoute };
