import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { useQuizIdQueryParam } from '../../use-quiz-id-query-param';

import { AdminQuizQuestionAdd, AdminQuizQuestionAddVariables } from './__generated__/AdminQuizQuestionAdd';

import { QuestionsFormLayout } from '../../questions-form-layout';

interface Score {
  consideration: number;
  assertion: number;
  emotionalManagement: number;
  extroversion: number;
}

const toScore = (scorePointCategory: string): Score => ({
  ...{
    consideration: 0,
    assertion: 0,
    emotionalManagement: 0,
    extroversion: 0,
  },
  [scorePointCategory]: 1,
});

const toOptions = (labels: string[], categories: string[]): { text: string; score: Score }[] =>
  labels.map((label, i) => ({
    text: label,
    score: toScore(categories[i]),
  }));

const PATH_ADD_LANDING = '/admin/quizzes/questions';

const QUESTION_ADD = gql`
  mutation AdminQuizQuestionAdd($input: AdminQuizQuestionAddInput!) {
    adminQuizQuestionAdd(input: $input) {
      id
    }
  }
`;

function QuestionsAddForm() {
  const quizId = useQuizIdQueryParam();
  const history = useHistory();
  const [adminQuizQuestionAdd] = useMutation<AdminQuizQuestionAdd, AdminQuizQuestionAddVariables>(QUESTION_ADD);

  const onSubmit = (values: { optionLabels: string[]; optionScorePointCategory: string[] }) => {
    const input = { quizId, options: toOptions(values.optionLabels, values.optionScorePointCategory) };
    adminQuizQuestionAdd({ variables: { input } }).then(() => {
      history.push(`${PATH_ADD_LANDING}?quizId=${quizId}`);
    });
  };

  return <QuestionsFormLayout onSubmit={onSubmit} />;
}

export { QuestionsAddForm };
