import styled from 'styled-components';
import { Tabs as BootstrapTabs } from 'react-bootstrap';

const Tabs = styled(BootstrapTabs)`
  border-bottom: none;

  & .nav-link.active {
    background-color: var(--profyle-profyle-color-bg);
    border-bottom-color: #dee2e6;
  }

  @media (min-width: 1270px) {
    & .nav-link.active {
      border-bottom-color: var(--profyle-profyle-color-bg);
    }
  }
`;

export { Tabs };
