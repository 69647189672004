import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';

import { Sales } from './sales';

function salesRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/sales-report`}
      render={(routeProps) => (
        <>
          <ProtectedRoute path={routeProps.match.url} exact>
            <Sales />
          </ProtectedRoute>
        </>
      )}
    />
  );
}

export { salesRoute };
