import React, { useCallback } from 'react';
import styled from 'styled-components';

const CustomCheckbox = styled.div<{ checked?: boolean; reset?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 2px;
  border-color: ${({ reset, checked }) => (reset || !checked ? '#d4d6db' : '#fff')};
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    width: ${({ reset, checked }) => {
      if (reset) return '8px';
      if (checked) return '6px';
      return '0px';
    }};
    border-bottom: ${({ reset, checked }) => {
      if (reset || checked) return '2px solid var(--profyle-profyle-color-main)';

      return 'none';
    }};
    position: ${({ checked }) => (checked ? 'absolute' : 'static')};
    left: 4px;
    top: 0px;
    height: ${({ checked }) => (checked ? '12px;' : '0px')};
    border-right: ${({ checked }) => (checked ? '2px solid var(--profyle-profyle-color-main);' : 'none')};
    transform: ${({ checked }) => (checked ? 'rotate(45deg)' : 'none')};
  }
`;

interface IProps {
  checked?: boolean;
  reset?: boolean;
  id: number | string;
  onChange: (id: number | string, checked: boolean) => void;
}

export const Checkbox: React.FC<IProps> = ({ id, checked, reset, onChange }) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      onChange(id, !!checked);
    },
    [checked, id, onChange]
  );
  return <CustomCheckbox checked={checked} reset={reset} onClick={handleClick} />;
};
