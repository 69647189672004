import React, { useEffect, useState, useRef, useCallback } from 'react';
// eslint-disable-next-line import/named
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Form, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';

import { Button, FormActions, Loading } from '../../../shared/ui';
import { useResetCurrentUserAndHistoryPush } from '../../../shared/user';

import {
  PaymentStripeCheckoutSessionCreate,
  PaymentStripeCheckoutSessionCreateVariables,
  PaymentStripeCheckoutSessionCreate_paymentStripeCheckoutSessionCreate,
} from './__generated__/PaymentStripeCheckoutSessionCreate';
import { PaymentStripePublishableKeyGet } from './__generated__/PaymentStripePublishableKeyGet';
import { equals } from 'ramda';
import { useQueryParams } from '../../../shared/router';
import styled from 'styled-components';
import { error } from '../../../shared/error';

const MobileCol = styled(Col)<{ hasError?: boolean }>`
  @media (min-width: 768px) {
    margin-top: ${(props) => (props.hasError ? '40px' : '20px')};
  }
  @media (max-width: 767px) {
    display: flex;
    width: 100%;
    margin-top: 10px;
    flex-direction: column;
  }
`;

export const PUBLISHABLE_KEY_GET = gql`
  query PaymentStripePublishableKeyGet {
    paymentStripePublishableKeyGet {
      publishableKey
    }
  }
`;

const CHECKOUT_SESSION_CREATE = gql`
  mutation PaymentStripeCheckoutSessionCreate($input: PaymentStripeCheckoutSessionCreateInput!) {
    paymentStripeCheckoutSessionCreate(input: $input) {
      id
      amountTotal
      currency
    }
  }
`;

error.registerIgnoreOperation('PaymentStripeCheckoutSessionCreate');

interface Props {
  skillsetIds: number[];
  redirect?: string;
  onApplyCouponSuccess: () => void;
}

function PaymentProfyleRoleForm(props: Props) {
  const queryParams = useQueryParams();
  const redirect = props.redirect || queryParams.get('redirect');
  if (!redirect) {
    throw new Error('Redirect is not found in query params');
  }
  const [isPaymentAllowed, setIsPaymentAllowed] = useState(true);
  const [isCheckoutSessionCreated, setIsCheckoutSessionCreated] = useState(false);
  const lastCheckoutSessionCreateSkillsetIdsRef = useRef<number[]>();
  const skillsetIdsRef = useRef<number[]>(props.skillsetIds);
  skillsetIdsRef.current = props.skillsetIds;
  const { onApplyCouponSuccess } = props;

  // Load publishable key
  const { loading: publishableKeyLoading, data: publishableKeyData } = useQuery<PaymentStripePublishableKeyGet>(
    PUBLISHABLE_KEY_GET
  );

  // Load stripe
  const stripeRef = useRef<Stripe | null>();
  useEffect(() => {
    if (!publishableKeyData) {
      return;
    }
    const publishableKey = publishableKeyData.paymentStripePublishableKeyGet.publishableKey;
    loadStripe(publishableKey).then((s) => {
      stripeRef.current = s;
    });
  }, [publishableKeyData]);

  // Create checkout session
  const stripeCheckoutSessionRef = useRef<PaymentStripeCheckoutSessionCreate_paymentStripeCheckoutSessionCreate>();
  const [paymentStripeCheckoutSessionCreate] = useMutation<
    PaymentStripeCheckoutSessionCreate,
    PaymentStripeCheckoutSessionCreateVariables
  >(CHECKOUT_SESSION_CREATE);
  useEffect(() => {
    if (equals(lastCheckoutSessionCreateSkillsetIdsRef.current, props.skillsetIds)) {
      return;
    }
    stripeCheckoutSessionRef.current = undefined;
    setIsCheckoutSessionCreated(false);
    const { skillsetIds } = props;
    lastCheckoutSessionCreateSkillsetIdsRef.current = skillsetIds;
    paymentStripeCheckoutSessionCreate({ variables: { input: { skillsetIds, redirect } } }).then((result) => {
      if (!equals(skillsetIds, skillsetIdsRef.current)) {
        return;
      }

      stripeCheckoutSessionRef.current = result.data!.paymentStripeCheckoutSessionCreate;
      setIsCheckoutSessionCreated(false);
      setTimeout(() => {
        if (stripeCheckoutSessionRef.current) {
          setIsCheckoutSessionCreated(true);
        }
      }, 500);
    });
  }, [paymentStripeCheckoutSessionCreate, props, redirect]);

  const resetCurrentUserAndHistoryPush = useResetCurrentUserAndHistoryPush();

  const [coupon, setCoupon] = useState('');
  const [couponErr, setCouponErr] = useState('');

  // Pay
  const onClickPay = () => {
    setIsPaymentAllowed(false);
    stripeRef.current!.redirectToCheckout({ sessionId: stripeCheckoutSessionRef.current!.id });
  };

  // Coupon
  const handleCouponChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCoupon(e.target.value);
      if (couponErr) {
        setCouponErr('');
      }
    },
    [couponErr]
  );

  const handleApplyCoupon = useCallback(() => {
    paymentStripeCheckoutSessionCreate({
      variables: { input: { skillsetIds: props.skillsetIds, redirect, coupon } },
    })
      .then((result) => {
        if (result.data?.paymentStripeCheckoutSessionCreate.amountTotal !== 0) {
          throw new Error('Invalid Coupon');
        }
        onApplyCouponSuccess();
        resetCurrentUserAndHistoryPush(redirect);
      })
      .catch((e) => {
        setCouponErr('Invalid Coupon');
      });
  }, [
    coupon,
    paymentStripeCheckoutSessionCreate,
    props.skillsetIds,
    redirect,
    resetCurrentUserAndHistoryPush,
    onApplyCouponSuccess,
  ]);

  const handleCouponKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleApplyCoupon();
      }
    },
    [handleApplyCoupon]
  );

  const loading = publishableKeyLoading || !stripeRef.current || !isCheckoutSessionCreated;
  const session = stripeCheckoutSessionRef.current!;

  return (
    <>
      <FormActions oneAction>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Button onClick={onClickPay} disabled={isPaymentAllowed ? undefined : true}>
              Pay {session.amountTotal / 100} {session.currency.toUpperCase()}
            </Button>
          </>
        )}
      </FormActions>

      <Form.Group>
        <Form.Row className="justify-content-sm-center align-items-sm-center">
          <MobileCol sm="auto" xs={12}>
            <div>Coupon Code:</div>
          </MobileCol>
          <MobileCol lg={3} md={4} sm={12} xs={12} hasError={!!couponErr}>
            <Form.Control
              isInvalid={!!couponErr}
              type="text"
              placeholder="Enter Coupon Code"
              onChange={handleCouponChange}
              onKeyDown={handleCouponKeyDown}
            />
            <Form.Control.Feedback type="invalid" className={classNames({ 'd-block': couponErr })}>
              {couponErr}
            </Form.Control.Feedback>
          </MobileCol>
          <MobileCol xs="auto">
            <Button disabled={!coupon} onClick={handleApplyCoupon}>
              Apply Coupon
            </Button>
          </MobileCol>
        </Form.Row>
      </Form.Group>
    </>
  );
}

export { PaymentProfyleRoleForm };
