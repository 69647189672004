import React, { useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router';

import { Button, LayoutDefault, Loading } from '../shared/ui';
import { MyOrderForm } from '../my-order-enterprise/my-order-form';
import { CONSOLIDATED_ORDER } from '../shared/document-node/order';
import { ConsolidatedOrder as OrderType } from '../shared/document-node/__generated__/ConsolidatedOrder';

function ConsolidatedOrder() {
  const { loading, data } = useQuery<OrderType>(CONSOLIDATED_ORDER);
  const history = useHistory();
  const handleGoBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <LayoutDefault>
      {loading && <Loading />}
      {!loading && data && <MyOrderForm order={data!.consolidatedOrder} />}
      {!loading && !data && (
        <div>
          <p>You have nothing to pay</p>
          <Button onClick={handleGoBackClick}>Go back</Button>
        </div>
      )}
    </LayoutDefault>
  );
}

export { ConsolidatedOrder };
