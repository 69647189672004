import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { LayoutDefault, Loading } from '../../shared/ui';
import { useQueryParams } from '../../shared/router';
import { auth } from '../../shared/auth';
import {
  UserForgotPasswordConfirm,
  UserForgotPasswordConfirmVariables,
} from './__generated__/UserForgotPasswordConfirm';
import { useResetCurrentUserAndHistoryPush } from '../../shared/user';

const PATH_FORGOT_PASSWORD_CONFIRM_LANDING = '/forgot-password/reset';

const USER_FORGOT_PASSWORD_CONFIRM = gql`
  mutation UserForgotPasswordConfirm($input: ConfirmInput!) {
    userForgotPasswordConfirm(input: $input) {
      token
    }
  }
`;

function EmailConfirmed() {
  const queryParams = useQueryParams();
  const code = queryParams.get('code');
  if (!code) {
    throw new Error('Code is not found in query params');
  }
  const resetCurrentUserAndHistoryPush = useResetCurrentUserAndHistoryPush();
  const [userForgotPasswordConfirm] = useMutation<UserForgotPasswordConfirm, UserForgotPasswordConfirmVariables>(
    USER_FORGOT_PASSWORD_CONFIRM
  );
  const ref = useRef<boolean>();

  // Run only on first render
  if (!ref.current) {
    ref.current = true;

    const input = { code };
    userForgotPasswordConfirm({ variables: { input: input } }).then((result) => {
      return auth.authenticate(result.data!.userForgotPasswordConfirm!.token).then(() => {
        resetCurrentUserAndHistoryPush(PATH_FORGOT_PASSWORD_CONFIRM_LANDING);
      });
    });
  }

  return (
    <LayoutDefault>
      <h2>Email confirm</h2>
      <Loading />
    </LayoutDefault>
  );
}

export { EmailConfirmed };
