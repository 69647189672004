import { omit } from 'ramda';
import React from 'react';
import { useFormik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { FormButton } from '../../shared/ui';
import { UserForgotPassword, UserForgotPasswordVariables } from './__generated__/UserForgotPassword';
import { UserForgotPasswordInput } from '../../../../__generated__/globalTypes';
import { error } from '../../shared/error';
import { getApiResponseMessage } from '../../shared/graphql';

const PATH_FORGOT_PASSWORD_LANDING = '/forgot-password/email-confirmation';

const USER_FORGOT_PASSWORD = gql`
  mutation UserForgotPassword($input: UserForgotPasswordInput!) {
    userForgotPassword(input: $input) {
      id
    }
  }
`;

error.registerIgnoreOperation('UserForgotPassword');

const initialValues: UserForgotPasswordInput = {
  email: '',
};

const validationSchema = yup.object().shape<UserForgotPasswordInput>({
  email: yup.string().required('Email is a required field'),
});

function ForgotPasswordForm() {
  const history = useHistory();
  const [userForgotPassword] = useMutation<UserForgotPassword, UserForgotPasswordVariables>(USER_FORGOT_PASSWORD);
  const onSubmit = (values: UserForgotPasswordInput, formikHelpers: FormikHelpers<UserForgotPasswordInput>) => {
    userForgotPassword({ variables: { input: omit(['passwordConfirm'], values) } })
      .then(() => {
        history.push(PATH_FORGOT_PASSWORD_LANDING);
      })
      .catch((e) => {
        formikHelpers.setErrors({ email: getApiResponseMessage(e) });
        formikHelpers.setSubmitting(false);
      });
  };
  const form = useFormik<UserForgotPasswordInput>({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <div className="w-50-resp m-auto">
      <h2>Forgot Password</h2>
      <Form noValidate onSubmit={form.handleSubmit}>
        <Form.Group controlId="email">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.email}
            isInvalid={!!(form.touched.email && form.errors.email)}
            disabled={form.isSubmitting}
          />
          <Form.Control.Feedback type="invalid">{form.errors.email}</Form.Control.Feedback>
        </Form.Group>
        <FormButton form={form} />
      </Form>
    </div>
  );
}

export { ForgotPasswordForm };
