import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form } from 'react-bootstrap';

import countries from './countries.json';

interface CountriesByCode {
  [code: string]: string;
}

const countriesByCode: CountriesByCode = countries;

interface Option {
  code: string;
  title: string;
}

const options: Option[] = Object.entries(countriesByCode).map(([code, title]) => ({
  code,
  title,
}));

interface OptionsByCode {
  [code: string]: Option;
}

const optionsByCode: OptionsByCode = options.reduce(
  (a, o) => ({
    ...a,
    [o.code]: o,
  }),
  {}
);

interface OptionsByTitle {
  [title: string]: Option;
}

const optionsByTitle: OptionsByTitle = options.reduce<OptionsByTitle>(
  (a, o) => ({
    ...a,
    [o.title]: a[o.title] || o,
  }),
  {}
);

interface Props {
  id: string;
  value?: string;
  isInvalid?: boolean;
  plaintextAndReadOnly?: boolean;
  onChange?: (countryCode: string) => void;
  onBlur?: () => void;
}

function FormSelectCountry(props: Props) {
  const handleChange = (selected: Option[]) => {
    const oneCode = selected.length ? selected[0].code : '';
    props.onChange && props.onChange(oneCode);
  };

  const handleInputChange = (text: string) => {
    const selectedOne = optionsByTitle[text];
    if (!selectedOne) {
      return;
    }

    handleChange([selectedOne]);
  };

  const selected = props.value ? [optionsByCode[props.value]] : [];
  const placeholder = 'Choose country...';

  if (props.plaintextAndReadOnly) {
    return (
      <Form.Control
        type="text"
        placeholder={placeholder}
        value={optionsByCode[props.value || '']?.title}
        isInvalid={props.isInvalid}
        plaintext
        readOnly
      />
    );
  }

  return (
    <Typeahead
      id={props.id}
      options={options}
      labelKey="title"
      onChange={handleChange}
      onInputChange={handleInputChange}
      onBlur={props.onBlur}
      selected={selected}
      isInvalid={props.isInvalid}
      placeholder={placeholder}
      highlightOnlyResult
    />
  );
}

export { FormSelectCountry };
