import React, { useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { Button, FormActions, LayoutDefault, LayoutContainer, Loading, ResponsiveTable } from '../../shared/ui';
import { useCurrentUser } from '../../shared/auth';
import { ActiveTabEnum, CompanyTabs } from '../company-tabs';
import { ModalRemoveAdmin } from './modal-remove-admin';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CompanyAdminsDelete, CompanyAdminsDeleteVariables } from './__generated__/CompanyAdminsDelete';
import { CompanyAdminsGet } from './__generated__/CompanyAdminsGet';
import styled from 'styled-components';
import removeIcon from '../../shared/icons/remove-icon.svg';

const IconImage = styled.img`
  cursor: pointer;
`;

const ADD_ADMINS_PATH = '/company-info/add-admins';

const ADMINS_LIST_GET = gql`
  query CompanyAdminsGet {
    getCompanyAdmins {
      id
      firstName
      lastName
      email
      # roles {
      #   role
      # }
      # company {
      #   name
      # }
    }
  }
`;

const ADMINS_DELETE = gql`
  mutation CompanyAdminsDelete($input: DeleteCompanyAdminsInput!) {
    deleteCompanyAdmins(input: $input) {
      id
    }
  }
`;

export const ListOfAdmins: React.FC<any> = () => {
  const [showModal, setShowModal] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState<string>();
  const history = useHistory();
  const { data: user } = useCurrentUser();

  const { loading: adminsLoading, data: adminsData, refetch: adminsRefetch } = useQuery<CompanyAdminsGet>(
    ADMINS_LIST_GET
  );
  const [deleteAdmin, { loading: deleteAdminLoading }] = useMutation<CompanyAdminsDelete, CompanyAdminsDeleteVariables>(
    ADMINS_DELETE
  );

  const handleAdd = useCallback(() => {
    history.push(ADD_ADMINS_PATH);
  }, [history]);

  const handleModalHide = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleDeleteClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const itemEmail = e.currentTarget.dataset.itemEmail;
    setEmailToDelete(itemEmail);
    setShowModal(true);
  }, []);

  const handleRemove = useCallback(
    (adminToAssign: string) => {
      if (emailToDelete) {
        deleteAdmin({
          variables: { input: { users: [{ email: emailToDelete, substituteAdminEmail: adminToAssign }] } },
        }).finally(() => {
          adminsRefetch();
        });
      }
      setShowModal(false);
    },
    [deleteAdmin, emailToDelete, adminsRefetch]
  );

  const MAPPING = useMemo(
    () => [
      { label: 'Name', getter: (item: any) => `${item.firstName} ${item.lastName}` },
      {
        label: 'Email',
        getter: (item: any) => item.email,
      },
      {
        label: 'Delete',
        getter: (item: any) =>
          user?.userCurrentGet.id !== item.id ? (
            <div>
              <IconImage src={removeIcon} onClick={handleDeleteClick} data-item-email={item.email} />
            </div>
          ) : null,
      },
    ],
    [handleDeleteClick, user]
  );

  return (
    <LayoutDefault>
      <LayoutContainer>
        <h2>
          List of <strong className="emphasis">admins</strong>
        </h2>
        <CompanyTabs activeTab={ActiveTabEnum.ADMINS} />
        {deleteAdminLoading || adminsLoading ? (
          <Loading />
        ) : (
          <ResponsiveTable mapping={MAPPING} data={adminsData!.getCompanyAdmins} mobileRowHeader={MAPPING[0].getter} />
        )}
        <ModalRemoveAdmin
          show={showModal}
          onHide={handleModalHide}
          onRemove={handleRemove}
          adminsQuery={ADMINS_LIST_GET}
          emailToDelete={emailToDelete}
        />
        <FormActions>
          <Button onClick={handleAdd} variant="primary">
            Add new
          </Button>
        </FormActions>
      </LayoutContainer>
    </LayoutDefault>
  );
};
