import React, { KeyboardEvent } from 'react';
import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';

import { UseFormikReturnType } from '../../../shared/formik';
import { answerToHTMLId } from '../data';
import { QuizGet_quizGet_questions } from '../__generated__/QuizGet';

import './question-item.scss';
import { ButtonSpinner } from '../../../shared/ui';

const QuestionItemWrapper = styled.div.attrs(() => ({
  className: 'question-item',
}))``;

const QuestionFormGroup = styled(Form.Group)`
  margin-bottom: 3rem;
`;

const QuestionText = styled.div`
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
`;

const QuestionFormCheck = styled(Form.Check)`
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

interface Props {
  question: QuizGet_quizGet_questions;
  form: UseFormikReturnType;
  onNext: () => void;
  last: boolean;
}

function QuestionItem({ question, form, onNext, last }: Props) {
  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13) {
      onNext();
    }
  };
  const submitButonText = last ? 'Complete!' : 'Bring on the next one!';
  return (
    <QuestionItemWrapper>
      <QuestionFormGroup>
        {/* <QuestionText>{question.text}</QuestionText> */}
        <QuestionText>Click on the one that feels most like you</QuestionText>
        {question.options.map((option) => {
          const answerHtmlId = answerToHTMLId(question, option);
          return (
            <QuestionFormCheck
              id={answerHtmlId}
              key={answerHtmlId}
              type="radio"
              name={String(question.id)}
              value={option.id}
              label={option.text}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              custom
              onKeyPress={onKeyPress}
            />
          );
        })}
      </QuestionFormGroup>
      <Button disabled={!form.values[question.id] || form.isSubmitting} onClick={onNext}>
        {submitButonText}
        {form.isSubmitting && <ButtonSpinner />}
      </Button>
    </QuestionItemWrapper>
  );
}

export { QuestionItem };
