import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { CollectionFinish } from './collection-finish';

function collectionFinishRoute(routeProps: RouteComponentProps) {
  return (
    <Route path={`${routeProps.match.url}/finish`}>
      <CollectionFinish />
    </Route>
  );
}

export { collectionFinishRoute };
