import React from 'react';
import gql from 'graphql-tag';
import { Loading, Logo } from '../../shared/ui';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { InvoiceGet, InvoiceGetVariables } from './__generated__/InvoiceGet';

const Wrapper = styled.div`
  width: 800px;
  margin: 0 auto;
`;

const PrintButton = styled.button`
  @media print {
    visibility: hidden;
  }
`;

const INVOICE_GET = gql`
  query InvoiceGet($id: Int!) {
    getCompanyInvoiceDetails(id: $id) {
      descriotion
      quantity
      vat
      taxInvoiceDetails
      invoiceNumber
      vatNumber
      profyleCompanyDetail
      submitPaymentTo
      totalAmountWithVat
      totalAmount
      unitPrice
      vatAmount
    }
  }
`;

export const CompanyOrderInvoice: React.FC<{
  orderId: number;
}> = ({ orderId }) => {
  const { loading, data } = useQuery<InvoiceGet, InvoiceGetVariables>(INVOICE_GET, {
    variables: { id: orderId },
  });

  const invoice = data?.getCompanyInvoiceDetails;

  const handlePrint = () => {
    window.print();
  };

  if (loading) {
    return <Loading />;
  }

  if (!invoice) {
    return <div>Something went wrong</div>;
  }

  return (
    <Wrapper>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <PrintButton onClick={handlePrint}>Print the document</PrintButton>
        <Logo />
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <h1 className="m-0">TAX INVOICE</h1>
          <div className="ml-5">
            <div>Urban Intelligence</div>
            <div>Pendyris St</div>
            <div>Cardiff</div>
            <div>CF11 6BH</div>
            <div>WALES</div>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex flex-column mr-4">
            <div className="d-flex flex-column mb-1">
              <strong>Invoice Date</strong>
              <span>??? 7 Nov 2021</span>
            </div>
            <div className="d-flex flex-column mb-1">
              <strong>Invoice Number</strong>
              <span>{invoice.invoiceNumber}</span>
            </div>
            <div className="d-flex flex-column mb-1">
              <strong>Reference</strong>
              <span>??? 2021 Cons</span>
            </div>
            <div className="d-flex flex-column mb-1">
              <strong>VAT Number</strong>
              <span>{invoice.vatNumber}</span>
            </div>
          </div>
          <div>
            <div>The Applied Knowledge</div>
            <div>Network Limited</div>
            <div>Walnut Barn</div>
            <div>8 Hollang Walk</div>
            <div>Longstanton</div>
            <div>Cambridgeshire</div>
            <div>CB24 3EY</div>
          </div>
        </div>
      </div>
      <table className="w-100 mt-5">
        <thead>
          <tr style={{ borderBottom: '2px solid black' }}>
            <th>Description</th>
            <th style={{ width: '15%' }} className="text-right">
              Quantity
            </th>
            <th style={{ width: '15%' }} className="text-right">
              Unit Price
            </th>
            <th style={{ width: '15%' }} className="text-right">
              VAT
            </th>
            <th style={{ width: '15%' }} className="text-right">
              Amount GBP
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-bottom">
            <td>{invoice?.descriotion}</td>
            <td className="text-right">{invoice?.quantity}</td>
            <td className="text-right">{(invoice?.unitPrice / 100).toFixed(2)}</td>
            <td className="text-right">{invoice?.vat}</td>
            <td className="text-right">{(invoice?.totalAmount / 100).toFixed(2)}</td>
          </tr>
          <tr>
            <td></td>
            <td colSpan={3} className="text-right">
              Subtotal
            </td>
            <td className="text-right">{(invoice.totalAmount / 100).toFixed(2)}</td>
          </tr>
          <tr>
            <td></td>
            <td colSpan={3} className="text-right" style={{ borderBottom: '2px solid black' }}>
              TOTAL VAT {invoice.vat}
            </td>
            <td className="text-right" style={{ borderBottom: '2px solid black' }}>
              {(invoice.vatAmount / 100).toFixed(2)}
            </td>
          </tr>
          <tr>
            <td></td>
            <td colSpan={3} className="text-right">
              <strong>TOTAL GBP</strong>
            </td>
            <td className="text-right">
              <strong>{(invoice.totalAmountWithVat / 100).toFixed(2)}</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <h2 className="text-left mt-5 mb-1" style={{ fontSize: '1.5rem' }}>
          Due date: ??? 7 Dec 2021
        </h2>
        <p>"An investment in knowledge pays the best interest" Be</p>
        <p>Please submit payment to: The Applied Knowledge Network Limited</p>
        <p>Santander - Account Number - 74058583 Sort Code - 09-01-27</p>
        <p>Terms - 30 days</p>
      </div>

      <div className="d-flex justify-content-between mt-4 mb-4 pt-4" style={{ borderTop: '4px dashed black' }}>
        <div>
          <h2 className="text-left">PAYMENT ADVICE</h2>
          <div className="d-flex justify-content-between">
            <div className="mr-2">To: </div>
            <div>
              <p>??? (Profyle data) The Applied Knowledge Network Limited</p>
              <p>Walnut Barn</p>
              <p>8 Holland Walk</p>
              <p>Longstanton</p>
              <p>Cambrodgeshire</p>
              <p>CB24 3EY</p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <p className="font-weight-bold">Customer</p>
            <p className="font-weight-bold border-bottom">Invoice Number</p>
            <p className="font-weight-bold">Amount Due</p>
            <p className="font-weight-bold border-bottom">Due Date</p>
            <p className="font-weight-bold">Amount Enclosed</p>
          </div>
          <div>
            <p>??? Customer Name</p>
            <p className="border-bottom">{invoice.invoiceNumber}</p>
            <p>{(invoice.totalAmountWithVat / 100).toFixed(2)}</p>
            <p className="border-bottom">??? 7 Dec 2021</p>
            <p className="mt-5" style={{ borderTop: '2px solid black' }}>
              <small>Enter the amount yoy are paying above</small>
            </p>
          </div>
        </div>
      </div>

      <small>
        ??? Company Registration No: 07197679. Registered Office: 8 Holland Walk, Longstanton, Cambridgeshire, CB24 3EY,
        UK
      </small>
    </Wrapper>
  );
};
