import React from 'react';
import { Route } from 'react-router-dom';

import { ProtectedRoute } from '../shared/auth';

import { SignUp } from './sign-up';
import { emailConfirmationRoute } from './email-confirmation';
import { emailConfirmedRoute } from './email-confirmed';
import { passwordRoute } from './password';

function signUpRoute() {
  return (
    <Route
      path="/sign-up"
      render={(routeProps) => (
        <>
          <ProtectedRoute notAuth path={routeProps.match.url} exact>
            <SignUp />
          </ProtectedRoute>
          {emailConfirmationRoute(routeProps)}
          {emailConfirmedRoute(routeProps)}
          {passwordRoute(routeProps)}
        </>
      )}
    />
  );
}

export { signUpRoute };
