import React from 'react';
import { Route } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';
import { RoleName } from '../../shared/auth/role-name';

import { CompanyOrder } from './company-order';

function companyOrderRoute() {
  return (
    <ProtectedRoute
      path="/company-order/:id"
      role={RoleName.ROLE_CORPORATE_ADMIN}
      render={(routeProps) => (
        <>
          <Route notAuth path={routeProps.match.url} exact>
            <CompanyOrder orderId={Number(routeProps.match.params.id)} />
          </Route>
        </>
      )}
    />
  );
}

export { companyOrderRoute };
