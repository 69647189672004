import React from 'react';
import { Route } from 'react-router-dom';

import { Faq } from './faq';

function faqRoute() {
  return (
    <Route path="/faq">
      <Faq />
    </Route>
  );
}

export { faqRoute };
