import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { PaymentCancel } from './payment-cancel';

function paymentCancelRoute(routeProps: RouteComponentProps) {
  return (
    <>
      <Route path={`${routeProps.match.url}/cancel`} exact>
        <PaymentCancel />
      </Route>
      <Route path={`${routeProps.match.url}/company/cancel`} exact>
        <PaymentCancel />
      </Route>
    </>
  );
}

export { paymentCancelRoute };
