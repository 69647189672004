import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { LayoutDefault, Loading } from '../../shared/ui';
import { useQueryParams } from '../../shared/router';
import { auth } from '../../shared/auth';
import { useResetCurrentUserAndHistoryPush } from '../../shared/user';
import {
  UserCorporateSignUpConfirm,
  UserCorporateSignUpConfirmVariables,
} from './__generated__/UserCorporateSignUpConfirm';
const PATH_SIGN_UP_CONFIRM_LANDING = '/sign-up/password';

const USER_CORPORATE_SIGN_UP_CONFIRM = gql`
  mutation UserCorporateSignUpConfirm($input: UserCorporateSignUpConfirmInput!) {
    userCorporateSignUpConfirm(input: $input) {
      token
    }
  }
`;

function EmailConfirmed() {
  const queryParams = useQueryParams();
  const code = queryParams.get('code');
  if (!code) {
    throw new Error('Code is not found in query params');
  }
  const resetCurrentUserAndHistoryPush = useResetCurrentUserAndHistoryPush();
  const [userCorporateSignUpConfirm] = useMutation<UserCorporateSignUpConfirm, UserCorporateSignUpConfirmVariables>(
    USER_CORPORATE_SIGN_UP_CONFIRM
  );
  const ref = useRef<boolean>();

  // Run only on first render
  if (!ref.current) {
    ref.current = true;

    const input = { code };
    userCorporateSignUpConfirm({ variables: { input: input } }).then((result) => {
      return auth.authenticate(result.data!.userCorporateSignUpConfirm!.token).then(() => {
        resetCurrentUserAndHistoryPush(PATH_SIGN_UP_CONFIRM_LANDING);
      });
    });
  }

  return (
    <LayoutDefault>
      <h2>Email confirm</h2>
      <Loading />
    </LayoutDefault>
  );
}

export { EmailConfirmed };
