import React from 'react';

import { LayoutDefault, AdminBreadcrumbs } from '../../../../shared/ui';

import { QuizzesAddForm } from './quizzes-add-form';

const BREADCRUMBS = [
  { path: '/admin/quizzes', label: 'Quizzes & Questions' },
  { path: '/admin/quizzes/quizzes', label: 'Quizzes' },
  { label: 'Add quiz' },
];

function QuizzesAdd() {
  return (
    <LayoutDefault>
      <h2>Add quiz</h2>
      <AdminBreadcrumbs breadcrumbs={BREADCRUMBS} />
      <QuizzesAddForm />
    </LayoutDefault>
  );
}

export { QuizzesAdd };
