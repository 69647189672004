import React from 'react';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

import { AdminList } from '../../shared/ui';

import { SettingListGetForAdmin_settingListGet } from './__generated__/SettingListGetForAdmin';

const SETTING_LIST_GET = gql`
  query SettingListGetForAdmin {
    settingListGet(tagQuery: "") {
      id
      tag
      title
      value
    }
  }
`;

const MAPPING = [
  { label: 'Setting Id', getter: (value: SettingListGetForAdmin_settingListGet) => value.id },
  { label: 'Tag', getter: (value: SettingListGetForAdmin_settingListGet) => value.tag },
  { label: 'Title', getter: (value: SettingListGetForAdmin_settingListGet) => value.title },
  { label: 'Value', getter: (value: SettingListGetForAdmin_settingListGet) => value.value },
];

const BREADCRUMBS = [{ label: 'Settings' }];

function Settings() {
  const deleteMutationStub: DocumentNode = { kind: 'Document', definitions: [] };
  return (
    <AdminList
      title="Settings"
      listQuery={SETTING_LIST_GET}
      deleteMutation={deleteMutationStub}
      dataProp="settingListGet"
      breadcrumbs={BREADCRUMBS}
      mapping={MAPPING}
      deleteLinkDisable={true}
      addLinkDisable={true}
    />
  );
}

export { Settings };
