import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { UsersAdd } from './users-add';

function usersAddRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/add`}
      render={() => (
        <>
          <UsersAdd />
        </>
      )}
    />
  );
}

export { usersAddRoute };
