import React from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { useParamId } from '../../../../shared/router';

import {
  AdminSkillsetGet,
  AdminSkillsetGetVariables,
  AdminSkillsetGet_adminSkillsetGet,
} from './__generated__/AdminSkillsetGet';
import { AdminSkillsetUpdate, AdminSkillsetUpdateVariables } from './__generated__/AdminSkillsetUpdate';

import { SkillsetsFormLayout } from '../../skillsets-form-layout';
import { Loading } from '../../../../shared/ui';

const toInitialValues = (skillset: AdminSkillsetGet_adminSkillsetGet): { name: string; description: string } => ({
  name: skillset.name,
  description: skillset.description,
});

const PATH_UPDATE_LANDING = '/admin/skillsets';

const SKILLSET_GET = gql`
  query AdminSkillsetGet($id: Int!) {
    adminSkillsetGet(id: $id) {
      id
      name
      description
    }
  }
`;

const SKILLSET_UPDATE = gql`
  mutation AdminSkillsetUpdate($input: AdminSkillsetUpdateInput!) {
    adminSkillsetUpdate(input: $input) {
      id
    }
  }
`;

function SkillsetsEditForm() {
  const skillsetId = useParamId();
  const history = useHistory();
  const { loading, data } = useQuery<AdminSkillsetGet, AdminSkillsetGetVariables>(SKILLSET_GET, {
    variables: { id: skillsetId },
  });
  const skillset = data?.adminSkillsetGet;
  const [adminSkillsetUpdate] = useMutation<AdminSkillsetUpdate, AdminSkillsetUpdateVariables>(SKILLSET_UPDATE);

  const onSubmit = (values: { name: string; description: string }) => {
    const input = { id: skillsetId, ...values };
    adminSkillsetUpdate({ variables: { input } }).then(() => {
      history.push(PATH_UPDATE_LANDING);
    });
  };

  if (loading) {
    return <Loading />;
  }

  return <SkillsetsFormLayout initialValues={toInitialValues(skillset!)} onSubmit={onSubmit} />;
}

export { SkillsetsEditForm };
