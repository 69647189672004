import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';

import { ProfyleChosenCompany } from './profyle-chosen-company';
import { ScrollToTop } from '../../shared/router';

function profyleChosenCompanyRoute(routeProps: RouteComponentProps) {
  return (
    <ProtectedRoute
      path={`${routeProps.match.url}/user/:id`}
      render={(routeProps) => (
        <>
          <ScrollToTop>
            <Route path={`${routeProps.match.url}/:section?/:subSection?/:subSubSection?`} exact>
              <ProfyleChosenCompany id={parseInt(routeProps.match.params.id)} />
            </Route>
          </ScrollToTop>
        </>
      )}
    />
  );
}

export { profyleChosenCompanyRoute };
