import React from 'react';
import { Route } from 'react-router-dom';

import { Wsa } from './wsa';

function wsaRoute() {
  return (
    <Route path="/wsa">
      <Wsa />
    </Route>
  );
}

export { wsaRoute };
