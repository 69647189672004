import React, { useCallback } from 'react';
import { Nav, Container, Col, Row } from 'react-bootstrap';
import { Link, Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Helmet } from 'react-helmet';

import { Navigation, BlockDescription, Loading, LayoutFooter, VideoPlayer, Button } from '../shared/ui';
import { RoleName } from '../shared/auth/role-name';
import { ShowForAuth, ShowForUser, ShowForNotAuth, HideForUser, useAuth } from '../shared/auth';

import { TextListGetHomesForHome } from './__generated__/TextListGetHomesForHome';

import { ClientThoughtsCarousel } from './client-thoughts-carousel';
import { StagesOfYourJourney } from './stages-of-your-journey';

import discoverYourselfVideoImage from './images/discover-yourself-video-2x.png';
import discoverYourselfVideo from './images/discover-yourself-video.mp4';
import profyleProcessImage from './images/profyle-process.jpg';
import profyleReportImage from './images/profyle-report.jpg';
import { CreateYourProfyle } from './create-your-profyle';
import { toTaggedTexts } from '../shared/helper';
import { Html } from '../shared/html';
import useMediaQuery from '../shared/ui/use-media-query/use-media-query';
import { ModalCreateAccount } from '../shared/sign-in-up';
import { useHasRole } from '../shared/auth/use-has-role';
import { HomeCorporateAdmin } from './homeCorporateAdmin';
import { HomeNotAuth } from './homeNotAuth';
import { META_TAGS } from '../shared/constants/metaTags';
import { BlockFigureMain } from '../shared/block-figure-main';

const PATH_PROFYLE = '/profyle';

const headingsBaseStyles = `
  margin-bottom: 3rem;
`;

const H1 = styled.h1`
  ${headingsBaseStyles}
`;

const H2 = styled.h2`
  ${headingsBaseStyles}
`;

const H3 = styled.h3`
  ${headingsBaseStyles}
`;

const BlockContainer = styled(Container)`
  margin-top: 88px;
  margin-bottom: 10px;
`;

const BlockRow = styled(Row)`
  margin-bottom: 96px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const BlockFigure = styled.div`
  min-width: 320px;
`;

const StyledBlockFigureMain = styled(BlockFigureMain)`
  min-width: 320px;
  padding: 0 10px;
  text-align: left;
`;

const CreateAccountButtonMain = styled(Button)`
  margin-top: 48px;
`;

export const HOME_TEXT_LIST_GET = gql`
  query TextListGetHomesForHome {
    textListGet(tagQuery: "home") {
      tag
      title
      body
    }
  }
`;

const STAGES_MAP = {
  DECIDE: 'homeStagesOfYourJourneyDecide',
  GENERATE: 'homeStagesOfYourJourneyGenerate',
  DISCOVER: 'homeStagesOfYourJourneyDiscover',
  CHOOSE: 'homeStagesOfYourJourneyChoose',
  SHARE: 'homeStagesOfYourJourneyShare',
};

const HomeDefault = () => {
  const history = useHistory();
  const auth = useAuth();

  const isAdmin = useHasRole(RoleName.ROLE_ADMIN);

  const { loading, data } = useQuery<TextListGetHomesForHome>(HOME_TEXT_LIST_GET, {
    fetchPolicy: 'cache-first',
  });
  const homeTexts = data?.textListGet || [];
  const homeTaggedTexts = toTaggedTexts(homeTexts);

  const isSmall = useMediaQuery('(max-width: 767px)');

  const handleGetStartedRedirect = useCallback(async () => {
    history.push(PATH_PROFYLE);
  }, [history]);

  if (loading || !homeTexts.length) {
    return <Loading center />;
  }

  return (
    <>
      <Navigation>
        <Nav.Link to="/skillsets" as={Link}>
          Profyle Roles
        </Nav.Link>
        {/* <Nav.Link to="/background" as={Link}>Background</Nav.Link> */}
        <Nav.Link to="/faq" as={Link}>
          FAQ
        </Nav.Link>
      </Navigation>

      <BlockContainer>
        <BlockRow>
          <Col>
            <H1>
              <Html code={homeTaggedTexts['homeDiscoverYourself'].title} />
            </H1>
            <BlockDescription>
              <Html code={homeTaggedTexts['homeDiscoverYourself'].body} />
            </BlockDescription>
            <ModalCreateAccount
              render={({ showModal }) => (
                <CreateAccountButtonMain
                  disabled={isAdmin}
                  onClick={auth.isAuthenticated ? handleGetStartedRedirect : showModal}
                >
                  Get Started
                </CreateAccountButtonMain>
              )}
            />
          </Col>
          <Col className="text-right">
            <StyledBlockFigureMain>
              <VideoPlayer
                width={isSmall ? '100%' : '520'}
                height={isSmall ? '100%' : '312'}
                src={discoverYourselfVideo}
                poster={discoverYourselfVideoImage}
              />
            </StyledBlockFigureMain>
          </Col>
        </BlockRow>

        <H2>
          <Html code={homeTaggedTexts['homeProfyleProcess'].title} />
        </H2>

        {/* <BlockRow>
        <Col>
          <H3>Developing Skills</H3>
          <BlockDescription>Everyone in the world is born with a set of skills which we each develop and nurture as we age. Despite having these skills, it is probably fair to say that few of us are completely optimised in terms of using them in the right context to always generate the best results.</BlockDescription>
        </Col>
        <Col className="text-right">
          <BlockFigure>
            <img src={developingSkillsImage} alt="Discover Yourself Video" />
          </BlockFigure>
        </Col>
      </BlockRow> */}

        <BlockRow>
          <Col>
            <BlockFigure>
              <img className="img-fluid" src={profyleProcessImage} alt="The Profyle Process" />
            </BlockFigure>
          </Col>
          <Col>
            {/* <H3>The Profyle Process</H3> */}

            <BlockDescription>
              <Html code={homeTaggedTexts['homeProfyleProcess'].body} />
            </BlockDescription>
          </Col>
        </BlockRow>

        <BlockRow>
          <Col>
            <H3>
              <Html code={homeTaggedTexts['homeWhatYouGet'].title} />
            </H3>
            <BlockDescription>
              <Html code={homeTaggedTexts['homeWhatYouGet'].body} />
            </BlockDescription>
          </Col>
          <Col className="text-right">
            <BlockFigure>
              <img className="img-fluid" src={profyleReportImage} alt="The Profyle Report" />
            </BlockFigure>
          </Col>
        </BlockRow>

        <H2>
          <Html code={homeTaggedTexts['homeStagesOfYourJourney'].title} />
        </H2>
        <StagesOfYourJourney taggedTexts={homeTaggedTexts} stagesMap={STAGES_MAP} />

        <H2>
          <Html code={homeTaggedTexts['homeWhatOurClientsThink'].title} />
        </H2>
        <ClientThoughtsCarousel taggedTexts={homeTaggedTexts} />

        <CreateYourProfyle
          onClick={auth.isAuthenticated ? handleGetStartedRedirect : undefined}
          taggedTexts={homeTaggedTexts}
          disabled={isAdmin}
        />
      </BlockContainer>

      <LayoutFooter />
    </>
  );
};

function Home() {
  return (
    <>
      <Helmet>
        <title>{META_TAGS.home.title}</title>
        <meta name="description" content={META_TAGS.home.description} />
      </Helmet>
      <ShowForAuth>
        <ShowForUser role={RoleName.ROLE_CORPORATE_ADMIN}>
          <HomeCorporateAdmin />
        </ShowForUser>
        <HideForUser role={RoleName.ROLE_CORPORATE_ADMIN}>
          <HomeDefault />
        </HideForUser>
      </ShowForAuth>
      <ShowForNotAuth>
        <HomeNotAuth />
      </ShowForNotAuth>
    </>
  );
}

export { Home };
