import React, { ConsumerProps } from 'react';

import { AuthContext } from '../auth-context';
import { AuthValue } from '../../auth-value';

type Props = ConsumerProps<AuthValue>;

function AuthContextConsumer(props: Props) {
  return <AuthContext.Consumer {...props} />;
}

export { AuthContextConsumer };
