import React from 'react';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

import { AdminList } from '../../shared/ui';

import { TextListGetForAdmin_textListGet } from './__generated__/TextListGetForAdmin';

const TEXT_LIST_GET = gql`
  query TextListGetForAdmin {
    textListGet(tagQuery: "") {
      id
      tag
      title
      body
    }
  }
`;

const MAPPING = [
  { label: 'Text Id', getter: (value: TextListGetForAdmin_textListGet) => value.id },
  { label: 'Tag', getter: (value: TextListGetForAdmin_textListGet) => value.tag },
  { label: 'Title', getter: (value: TextListGetForAdmin_textListGet) => value.title },
];

const BREADCRUMBS = [{ label: 'Texts' }];

function Texts() {
  const deleteMutationStub: DocumentNode = { kind: 'Document', definitions: [] };
  return (
    <AdminList
      title="Texts"
      listQuery={TEXT_LIST_GET}
      deleteMutation={deleteMutationStub}
      dataProp="textListGet"
      breadcrumbs={BREADCRUMBS}
      mapping={MAPPING}
      deleteLinkDisable={true}
      addLinkDisable={true}
    />
  );
}

export { Texts };
