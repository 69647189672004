import React from 'react';
import { Route } from 'react-router-dom';

import { ProtectedRoute } from '../shared/auth';

import { ProfyleReview } from './profyle-review';
import { statusRoute } from './status';
import { inviteRoute } from './invite';
import { inviteSendingRoute } from './invite-sending';
import { toReviewRoute } from './to-review';
import { reviewRoute } from './review';

function profyleReviewRoute() {
  return (
    <Route
      path="/profyle-review"
      render={(routeProps) => (
        <>
          <ProtectedRoute path={routeProps.match.url} exact>
            <ProfyleReview />
          </ProtectedRoute>
          {statusRoute(routeProps)}
          {inviteRoute(routeProps)}
          {inviteSendingRoute(routeProps)}
          {toReviewRoute(routeProps)}
          {reviewRoute(routeProps)}
        </>
      )}
    />
  );
}

export { profyleReviewRoute };
