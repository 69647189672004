import React from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import styled from 'styled-components';

import { ProfyleScoreDiffListGet } from '../__generated__/ProfyleScoreDiffListGet';

import { TabBody, TabActions, Text, Loading } from '../../../shared/ui';
import { calcMaxScoreDiff } from '../shared/calc-max-score-diff';
import { toParagraphs } from '../../../shared/helper';

import { GraphicScoreAreas } from './graphic-score-areas';
import { GraphicScoreDiff } from './graphic-score-diff';
import { createHistoryPushTab } from '../shared/create-history-push-tab';
import { Score } from '../shared/score';
import { Col, Row } from 'react-bootstrap';

const GraphicScoreAreasWrapper = styled.div`
  margin-bottom: 30px;
`;

const GraphicScoreDiffImage = styled(GraphicScoreDiff)`
  margin: 0 30% 25%;
`;

const GraphicScoreDiffWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const GraphicScoreDiffDesc = styled.div`
  margin-left: 24px;
  margin-right: 48px;

  font-size: 0.875rem;
`;

const GraphicScoreDiffHeader = styled.h3`
  margin-bottom: 20px;

  color: var(--profyle-profyle-color-main-lighter);
`;

const findProfyleScoreDiff = (
  diffs: { description: string; scoreDiffMin: number }[],
  score: number
): { description: string } | undefined => {
  const revertedDiffs = [...diffs]
    .sort((d1, d2) => (d1.scoreDiffMin > d2.scoreDiffMin ? 1 : d1.scoreDiffMin === d2.scoreDiffMin ? 0 : -1))
    .reverse();

  return revertedDiffs.find((d) => score >= d.scoreDiffMin);
};

const DIFF_SCORE_LIST_GET = gql`
  query ProfyleScoreDiffListGet {
    profyleScoreDiffListGet {
      id
      scoreDiffMin
      description
    }
  }
`;

interface Params {
  profyle: { result: { score: Score } };
  nextUrl: string;
}

function TabDiscoverYourself(params: Params) {
  const history = useHistory();
  const routeMatch = useRouteMatch();

  const historyPushTab = createHistoryPushTab(history, routeMatch);
  const graphicScoreAreasOnDetails = () => {
    historyPushTab('background');
  };

  const resultScore = params.profyle.result.score;

  const { loading: loadingProfyleScoreDiffList, data: dataProfyleScoreDiffList } = useQuery<ProfyleScoreDiffListGet>(
    DIFF_SCORE_LIST_GET
  );
  const scoreDiffList = dataProfyleScoreDiffList?.profyleScoreDiffListGet;

  const maxDistanceScore = calcMaxScoreDiff(
    resultScore.assertion,
    resultScore.consideration,
    resultScore.emotionalManagement,
    resultScore.extroversion
  );

  if (loadingProfyleScoreDiffList) {
    return <Loading />;
  }

  const scoreDiff = findProfyleScoreDiff(scoreDiffList!, maxDistanceScore);
  const scoreDiffDesc = scoreDiff?.description;

  return (
    <TabBody>
      <p>
        Profyle has done the number crunching thing and has calculated how you scored against each of the scales below.
        To understand more just hover or click on the scale that takes your curiosity. Profyle has also generated your
        Span Score.
      </p>
      <p>
        Below you can see your Profyle results. Each element of your Profyle identifies your preferred behaviour in that
        area and you can see from the graphic which ones rate higher than others.
      </p>

      <Row>
        <Col md="5">
          <GraphicScoreAreasWrapper>
            <GraphicScoreAreas
              assertion={resultScore.assertion}
              consideration={resultScore.consideration}
              emotionalManagement={resultScore.emotionalManagement}
              extroversion={resultScore.extroversion}
              onDetails={graphicScoreAreasOnDetails}
            />
          </GraphicScoreAreasWrapper>
        </Col>
        <Col md="7">
          <GraphicScoreDiffWrapper>
            <GraphicScoreDiffImage
              assertion={resultScore.assertion}
              consideration={resultScore.consideration}
              emotionalManagement={resultScore.emotionalManagement}
              extroversion={resultScore.extroversion}
            />
            <GraphicScoreDiffDesc>
              <GraphicScoreDiffHeader>Your Score is {maxDistanceScore}</GraphicScoreDiffHeader>
              <Text lines={5} showMoreBackground="var(--profyle-profyle-color-bg)">
                {toParagraphs(scoreDiffDesc, 'div')}
              </Text>
            </GraphicScoreDiffDesc>
          </GraphicScoreDiffWrapper>
        </Col>
      </Row>

      <TabActions>
        <Link to={params.nextUrl}>Next</Link>
      </TabActions>
    </TabBody>
  );
}

export { TabDiscoverYourself };
