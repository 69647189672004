import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

const ButtonSpinner = styled(Spinner).attrs({
  animation: 'border',
  size: 'sm',
})`
  margin-left: 1ex;
`;

export { ButtonSpinner };
