import React from 'react';
import { useFormik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { Form, Button, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { UserCorporateSignUp, UserCorporateSignUpVariables } from './__generated__/UserCorporateSignUp';
import { UserCorporateSignUpInput } from '../../../../__generated__/globalTypes';
import { ButtonSpinner } from '../../shared/ui';
import { getApiResponseMessage } from '../../shared/graphql';
import { error } from '../../shared/error';

const PATH_SIGN_UP_LANDING = '/sign-up/email-confirmation';

const USER_CORPORATE_SIGN_UP = gql`
  mutation UserCorporateSignUp($input: UserCorporateSignUpInput!) {
    userCorporateSignUp(input: $input) {
      id
    }
  }
`;

error.registerIgnoreOperation('UserCorporateSignUp');

const initialValues: UserCorporateSignUpInput = {
  companyName: '',
  firstName: '',
  lastName: '',
  email: '',
};

const validationSchema = yup.object().shape<UserCorporateSignUpInput | any>({
  companyName: yup.string().required('Company Name is a required field'),
  firstName: yup.string().required('Admin First Name is a required field'),
  lastName: yup.string().required('Admin Last Name is a required field'),
  email: yup.string().email('Admin Email must be a valid email').required('Admin Email is a required field'),
});

function SignUpForm() {
  const history = useHistory();
  const [userCorporateSignUp] = useMutation<UserCorporateSignUp, UserCorporateSignUpVariables>(USER_CORPORATE_SIGN_UP);
  const onSubmit = (values: UserCorporateSignUpInput, formikHelpers: FormikHelpers<UserCorporateSignUpInput>) => {
    userCorporateSignUp({ variables: { input: values } })
      .then(() => {
        history.push(PATH_SIGN_UP_LANDING);
      })
      .catch((e) => {
        formikHelpers.setErrors({ email: getApiResponseMessage(e) });
        formikHelpers.setSubmitting(false);
      });
  };
  const form = useFormik<UserCorporateSignUpInput>({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <div className="w-50-resp m-auto">
      <h2>Info Collection Admin user</h2>
      <Form noValidate onSubmit={form.handleSubmit}>
        <Form.Group controlId="companyName">
          <Form.Label>Company name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter company name"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.companyName}
            isInvalid={!!(form.touched.companyName && form.errors.companyName)}
            disabled={form.isSubmitting}
          />
          <Form.Control.Feedback type="invalid">{form.errors.companyName}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="firstName">
          <Form.Label>Admin First Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter first name"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.firstName}
            isInvalid={!!(form.touched.firstName && form.errors.firstName)}
            disabled={form.isSubmitting}
          />
          <Form.Control.Feedback type="invalid">{form.errors.firstName}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="lastName">
          <Form.Label>Admin Last Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter last name"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.lastName}
            isInvalid={!!(form.touched.lastName && form.errors.lastName)}
            disabled={form.isSubmitting}
          />
          <Form.Control.Feedback type="invalid">{form.errors.lastName}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Admin email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.email}
            isInvalid={!!(form.touched.email && form.errors.email)}
            disabled={form.isSubmitting}
          />
          <Form.Control.Feedback type="invalid">{form.errors.email}</Form.Control.Feedback>
        </Form.Group>
        <Row className="m-0 justify-content-end">
          <Button type="submit" disabled={form.isSubmitting}>
            Submit
            {form.isSubmitting && <ButtonSpinner />}
          </Button>
        </Row>
      </Form>
    </div>
  );
}

export { SignUpForm };
