import React from 'react';
import { Table } from 'react-bootstrap';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';

import { LayoutDefault, Button, FormButton, Loading } from '../../shared/ui';
import { toProfyleSkillsetNames } from '../../shared/profyle';
import { RequestProfyleWithUser, toRequestProfylesWithReviewer } from '../../shared/request';
import { toFormatedDateTime } from '../../shared/datetime';

import { ReviewRequestStatus } from '../../../../__generated__/globalTypes';
import {
  ProfyleReviewReviewRequestRequestedListGetForStatus,
  ProfyleReviewReviewRequestRequestedListGetForStatus_profyleReviewReviewRequestRequestedListGet,
  ProfyleReviewReviewRequestRequestedListGetForStatus_profyleReviewReviewRequestRequestedListGet_requestProfyles,
} from './__generated__/ProfyleReviewReviewRequestRequestedListGetForStatus';
import {
  ProfyleReviewReviewRequestProfyleRemind,
  ProfyleReviewReviewRequestProfyleRemindVariables,
} from './__generated__/ProfyleReviewReviewRequestProfyleRemind';

type RequestProfyleWithReviewer = RequestProfyleWithUser<
  'reviewer',
  ProfyleReviewReviewRequestRequestedListGetForStatus_profyleReviewReviewRequestRequestedListGet,
  ProfyleReviewReviewRequestRequestedListGetForStatus_profyleReviewReviewRequestRequestedListGet_requestProfyles
>;

const REQUEST_LIST_GET = gql`
  query ProfyleReviewReviewRequestRequestedListGetForStatus {
    profyleReviewReviewRequestRequestedListGet {
      id
      reviewer {
        firstName
        lastName
        email
      }
      requestProfyles {
        id
        status
        acceptedAt
        completedAt
        remindedAt
        profyle {
          result {
            quiz {
              skillsets {
                name
              }
            }
          }
        }
        createdAt
      }
    }
  }
`;

const REQUEST_PROFYLE_REMIND = gql`
  mutation ProfyleReviewReviewRequestProfyleRemind($id: Int!) {
    reviewRequestProfyleRemind(id: $id) {
      id
    }
  }
`;

function Status() {
  const { loading, data, refetch } = useQuery<ProfyleReviewReviewRequestRequestedListGetForStatus>(REQUEST_LIST_GET);
  const reviewRequests = data?.profyleReviewReviewRequestRequestedListGet || [];
  const [reviewRequestProfyleRemind, { loading: loadingRemind }] = useMutation<
    ProfyleReviewReviewRequestProfyleRemind,
    ProfyleReviewReviewRequestProfyleRemindVariables
  >(REQUEST_PROFYLE_REMIND);
  const onSend = (id: number) => {
    reviewRequestProfyleRemind({ variables: { id } }).then(() => {
      refetch();
    });
  };
  const reviewRequestProfyles = toRequestProfylesWithReviewer<RequestProfyleWithReviewer>(reviewRequests);

  return (
    <LayoutDefault>
      <h2>Profyle Review Status</h2>

      {loading ? (
        <Loading />
      ) : (
        <>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Profyle Role</th>
                <th>Request Sent</th>
                <th>Review Accepted</th>
                <th>Review Complited</th>
                <th>Send Reminder</th>
              </tr>
            </thead>
            <tbody>
              {reviewRequestProfyles.map((requestProfyle) => {
                return (
                  <tr key={requestProfyle.id}>
                    <td>
                      {requestProfyle.reviewer.firstName} {requestProfyle.reviewer.lastName}
                    </td>
                    <td>{requestProfyle.reviewer.email}</td>
                    <td>{toProfyleSkillsetNames(requestProfyle.profyle)}</td>
                    <td>{toFormatedDateTime(requestProfyle.createdAt)}</td>
                    <td>{toFormatedDateTime(requestProfyle.acceptedAt)}</td>
                    <td>{toFormatedDateTime(requestProfyle.completedAt)}</td>
                    <td>
                      {requestProfyle.status === ReviewRequestStatus.REQUEST_COMPLETED ? (
                        ''
                      ) : requestProfyle.status === ReviewRequestStatus.REQUEST_REMINDED ? (
                        toFormatedDateTime(requestProfyle.remindedAt)
                      ) : (
                        <FormButton
                          type="submit"
                          onClick={() => onSend(requestProfyle.id)}
                          disabled={loadingRemind || undefined}
                          loading={loadingRemind || undefined}
                          size="sm"
                        >
                          Send
                        </FormButton>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <Button className="btn btn-primary" to="/profyle-review" as={Link}>
            Invite more
          </Button>
        </>
      )}
    </LayoutDefault>
  );
}

export { Status };
