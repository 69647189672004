import React from 'react';
import { Link } from 'react-router-dom';

import { LayoutDefault } from '../../shared/ui';

function InviteSending() {
  return (
    <LayoutDefault>
      <h2>Invitation Sent</h2>
      Thank you for invite people for review. Invites will come to email addresses. You can get information about you
      invites on <Link to="/profyle-review/status">Profyle Review Status</Link>.
    </LayoutDefault>
  );
}

export { InviteSending };
