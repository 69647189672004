import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';

import { ProfyleChosen } from './profyle-chosen';
import { ScrollToTop } from '../../shared/router';

function profyleChosenRoute(routeProps: RouteComponentProps) {
  return (
    <ProtectedRoute
      path={`${routeProps.match.url}/:id`}
      render={(routeProps) => (
        <>
          <ScrollToTop>
            <Route path={`${routeProps.match.url}/:section?/:subSection?/:subSubSection?`} exact>
              <ProfyleChosen id={parseInt(routeProps.match.params.id)} />
            </Route>
          </ScrollToTop>
        </>
      )}
    />
  );
}

export { profyleChosenRoute };
