import React from 'react';
import gql from 'graphql-tag';

import { AdminUserGetForView_adminUserGet_roles } from './__generated__/AdminUserGetForView';
import { AdminView } from '../../../shared/ui';

const USER_GET = gql`
  query AdminUserGetForView($id: Int!) {
    adminUserGet(id: $id) {
      id
      firstName
      lastName
      email
      roles {
        role
      }
    }
  }
`;

const BREADCRUMBS = [{ path: '/admin/users', label: 'Users' }, { label: 'View user' }];

const MAPPING = [
  { key: 'id' },
  { key: 'firstName' },
  { key: 'lastName' },
  { key: 'email' },
  {
    key: 'roles',
    getter: (value: AdminUserGetForView_adminUserGet_roles[]) => <div>{value.map((r) => r.role).join(', ')}</div>,
  },
];

function UsersView() {
  return <AdminView query={USER_GET} dataProp="adminUserGet" breadcrumbs={BREADCRUMBS} mapping={MAPPING} />;
}

export { UsersView };
