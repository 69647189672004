import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { GetCompanyGroups } from './__generated__/GetCompanyGroups';

const GROUPS_GET = gql`
  query GetCompanyGroups {
    getCompanyGroups {
      groupName
    }
  }
`;

export const useCompanyGroups = () => {
  return useQuery<GetCompanyGroups>(GROUPS_GET);
};
