import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { PaymentSuccess } from './payment-success';
import { SESSION_SUCCESS, COMPANY_SESSION_SUCCESS, PATH_ON_BOARDING, PATH_BILLING_INFO } from './constants';

function paymentSuccessRoute(routeProps: RouteComponentProps) {
  return (
    <>
      <Route path={`${routeProps.match.url}/success`} exact>
        <PaymentSuccess paymentSuccessMutation={SESSION_SUCCESS} defaultRedirect={PATH_ON_BOARDING} />
      </Route>
      <Route path={`${routeProps.match.url}/company/success`} exact>
        <PaymentSuccess paymentSuccessMutation={COMPANY_SESSION_SUCCESS} defaultRedirect={PATH_BILLING_INFO} />
      </Route>
    </>
  );
}

export { paymentSuccessRoute };
