import React, { SyntheticEvent, ReactNode, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';

import { LayoutDefault } from '../../layout-default';
import { Loading } from '../../loading';

import { AdminBreadcrumbs } from '../breadcrumbs';

interface ListItem {
  id: number;
}

interface ParamsDelete {
  item: { id: number };
  mutation: DocumentNode;
  onDelete: () => void;
}

function AdminListDelete(params: ParamsDelete) {
  const [deleteItem] = useMutation<any, { id: number }>(params.mutation);
  const createOnDelete = (item: ListItem) => (e: SyntheticEvent) => {
    e.preventDefault();

    if (!window.confirm(`Do you really want to delete item?`)) {
      return;
    }

    deleteItem({ variables: { id: item.id } })
      .then(() => {
        params.onDelete();
      })
      .catch(() => {
        alert('Item cannot be deleted');
      });
  };

  return (
    <div>
      <a href="#/" onClick={createOnDelete(params.item)}>
        Delete
      </a>
    </div>
  );
}

const TableWrapper = styled.div`
  @media (max-width: 767px) {
    overflow-x: auto;
  }
`;

const ColContentWrapper = styled.div`
  /* @media (max-width: 767px) {
    min-width: 75px;
    word-break: break-word;
  } */

  @media (min-width: 768px) {
    max-width: 300px;
    word-break: break-word;
  }
`;

interface Params<D, DP = keyof D> {
  title?: string;
  listQuery: DocumentNode;
  listQueryVariables?: any;
  deleteMutation: DocumentNode;
  dataProp: DP | string[];
  mapping: { label: string; getter: (value: any) => ReactNode }[];
  breadcrumbs: { path?: string; label: string }[];
  addLinkDisable?: boolean;
  addLinkPath?: string;
  viewLinkDisable?: boolean;
  deleteLinkDisable?: boolean;
  editLinkDisable?: boolean;
  editLinkPathGetter?: (value: any) => string;
  additionalActions?: (value: any) => ReactNode;
  renderHeader?: (data: D) => ReactNode;
}

function AdminList<D = any, DP = keyof D>(params: Params<D, DP>) {
  const routeMatch = useRouteMatch();
  const { loading, data, refetch } = useQuery<D, any>(params.listQuery, {
    variables: { ...(params.listQueryVariables || {}) },
  });
  const result = useMemo(() => {
    if (Array.isArray(params.dataProp)) {
      return params.dataProp.reduce<ListItem[]>((acc: any, cur) => {
        return (acc?.[cur] as ListItem[] | undefined) || [];
      }, data as any);
    }
    return (((data as any) || {})[params.dataProp] as ListItem[] | undefined) || [];
  }, [params.dataProp, data]);

  const lastBreadcrumbsIndex = params.breadcrumbs.length - 1;
  const onDelete = () => {
    refetch();
  };

  const location = useLocation();

  useEffect(() => {
    if (!loading && location.hash) {
      const reportId = location.hash.slice(1);
      const element = document.getElementById(reportId);
      element?.scrollIntoView();
    }
  }, [loading, location]);

  return (
    <LayoutDefault>
      <h2>{params.title || params.breadcrumbs[lastBreadcrumbsIndex].label}</h2>
      <AdminBreadcrumbs breadcrumbs={params.breadcrumbs} />
      {params.addLinkDisable || (
        <ul>
          <li>
            <Link to={params.addLinkPath || `${routeMatch.path}/add`}>Add item</Link>
          </li>
        </ul>
      )}

      {loading ? (
        <Loading />
      ) : (
        <TableWrapper>
          {params.renderHeader && data && params.renderHeader(data)}
          <Table>
            <thead>
              <tr>
                {params.mapping.map((m, i) => (
                  <th key={i}>{m.label}</th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {result.map((item) => {
                return (
                  <tr key={item.id} id={`report-${item.id}`}>
                    {params.mapping.map((m, i) => (
                      <td key={i}>
                        <ColContentWrapper>{m.getter(item)}</ColContentWrapper>
                      </td>
                    ))}

                    <td>
                      {params.viewLinkDisable || (
                        <div>
                          <Link to={`${routeMatch.path}/view/${item.id}`}>View</Link>
                        </div>
                      )}
                      {params.editLinkDisable || (
                        <div>
                          <Link
                            to={
                              params.editLinkPathGetter
                                ? params.editLinkPathGetter(item)
                                : `${routeMatch.path}/edit/${item.id}`
                            }
                          >
                            Edit
                          </Link>
                        </div>
                      )}
                      {params.additionalActions && params.additionalActions(item)}
                      {params.deleteLinkDisable || (
                        <AdminListDelete item={item} mutation={params.deleteMutation} onDelete={onDelete} />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </TableWrapper>
      )}
    </LayoutDefault>
  );
}

export { AdminList };
