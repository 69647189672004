import { useQueryParams } from '../../../shared/router';

const useQuizIdQueryParam = (): number => {
  const queryParams = useQueryParams();
  const quizId = parseInt(queryParams.get('quizId') || '');
  if (!quizId) {
    throw new Error('"quizId" query param is required');
  }

  return quizId;
};

export { useQuizIdQueryParam };
