import React from 'react';

import { LayoutDefault, AdminBreadcrumbs } from '../../../shared/ui';

import { useParamId } from '../../../shared/router';

import { UsersEditForm } from './users-edit-form';

const BREADCRUMBS = [{ path: '/admin/users', label: 'Users' }, { label: 'Edit user' }];

function UsersEdit() {
  const userId = useParamId();

  return (
    <LayoutDefault>
      <h2>Edit user with id {userId}</h2>
      <AdminBreadcrumbs breadcrumbs={BREADCRUMBS} />
      <UsersEditForm />
    </LayoutDefault>
  );
}

export { UsersEdit };
