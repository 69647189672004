import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { LayoutDefault, Loading } from '../shared/ui';
import { useQueryParams } from '../shared/router';
import { auth } from '../shared/auth';

import {
  UserSignInByConfirmForReview,
  UserSignInByConfirmForReviewVariables,
} from './__generated__/UserSignInByConfirmForReview';
import { useResetCurrentUserAndHistoryPush } from '../shared/user';

const SIGN_IN_BY_CONFIRM = gql`
  mutation UserSignInByConfirmForReview($input: ConfirmInput!) {
    userSignInByConfirm(input: $input) {
      token
    }
  }
`;

function SignInByConfirm() {
  const queryParams = useQueryParams();
  const code = queryParams.get('code');
  const redirect = queryParams.get('redirect') || '/';
  if (!code) {
    throw new Error('Code is not found in query params');
  }
  const resetCurrentUserAndHistoryPush = useResetCurrentUserAndHistoryPush();
  const [signInByConfirm] = useMutation<UserSignInByConfirmForReview, UserSignInByConfirmForReviewVariables>(
    SIGN_IN_BY_CONFIRM
  );
  const ref = useRef<boolean>();

  // Run only on first render
  if (!ref.current) {
    ref.current = true;

    const input = { code };
    signInByConfirm({ variables: { input: input } }).then((result) => {
      return auth.authenticate(result.data!.userSignInByConfirm!.token).then(() => {
        resetCurrentUserAndHistoryPush(redirect);
      });
    });
  }

  return (
    <LayoutDefault>
      <h2>Sign In</h2>
      <Loading />
    </LayoutDefault>
  );
}

export { SignInByConfirm };
