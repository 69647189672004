import React, { useCallback, useState } from 'react';
import gql from 'graphql-tag';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FormActions, LayoutDefault, FormButton, LayoutContainer } from '../../shared/ui';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { SelectControl } from '../../shared/user/my-info-form/my-info-form-layout/my-info-form-layout';
import { useSkillsets } from '../../shared/skillsets/use-skillsets';
import { AddCompanyUsers, AddCompanyUsersVariables } from './__generated__/AddCompanyUsers';
import { AddBulkCompanyUsers, AddBulkCompanyUsersVariables } from './__generated__/AddBulkCompanyUsers';
import { ImportUsers } from './import-users';
import { ModalSent } from '../modal-sent/modal-sent';
import { SelectGroup } from '../select-group';
import { ActiveTabEnum, CompanyUsersTabs } from '../company-users-tabs';
import { getApiResponseMessage } from '../../shared/graphql';
import { NotificationManager } from 'react-notifications';

const USER_ADD = gql`
  mutation AddCompanyUsers($input: AddCompanyUsersInput!) {
    addCompanyUsers(input: $input) {
      id
    }
  }
`;
const BULK_USER_ADD = gql`
  mutation AddBulkCompanyUsers($input: AddBulkCompanyUsersInput!) {
    addBulkCompanyUsers(input: $input) {
      id
    }
  }
`;

const validationSchema = yup.object().shape<any>({
  firstName: yup.string().required('First name is a required field'),
  lastName: yup.string().required('Last name is a required field'),
  email: yup.string().email().required('Email is a required field'),
  companyDepartment: yup.string().required('Company department is a required field'),
  skillsetIds: yup.string(), // .required('Skillsets is a required field'),
  jobTitle: yup.string().required('Job title is a required field'),
  groupName: yup.string().required('Group name is a required field'),
});

const DefaultTitle = () => (
  <h1>
    Add <span className="emphasis">User</span>
  </h1>
);

const ListTitle = () => (
  <h1>
    Admin User - Set Up - Single <span className="emphasis">Skillset</span>
  </h1>
);

const TEXT_TEMPLATE = 'Invitation email to {{name}} was sent successfully!';
const COMPANY_USERS_PATH = '/company-users';
interface IProps {
  listTitle: boolean | undefined;
}

export const AddUser: React.FC<IProps> = ({ listTitle }) => {
  const [addUser, { loading }] = useMutation<AddCompanyUsers, AddCompanyUsersVariables>(USER_ADD);
  const [addBulkUser, { loading: bulkUserLoading }] = useMutation<AddBulkCompanyUsers, AddBulkCompanyUsersVariables>(
    BULK_USER_ADD
  );
  const { loading: loadingSkillsets, data: dataSkillsets } = useSkillsets();
  const skillsets = dataSkillsets?.skillsetListGet || [];
  const history = useHistory();
  const [modalText, setModalText] = useState('');

  const form = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      companyDepartment: '',
      skillsetIds: '',
      jobTitle: '',
      groupName: '',
    },
    validationSchema,
    onSubmit: (values, actions) => {
      const input = {
        users: [
          {
            ...values,
            skillsetIds: [Number(values.skillsetIds)],
          },
        ],
      };
      addUser({ variables: { input } })
        .then(() => {
          const { firstName, lastName } = values;
          setModalText(TEXT_TEMPLATE.replace('{{name}}', `${firstName} ${lastName}`));
        })
        .catch((err) => {
          const errorMessage = getApiResponseMessage(err);
          NotificationManager.error(errorMessage);
        });
      actions.setSubmitting(false);
    },
    validateOnBlur: true,
    validateOnMount: true,
  });

  const onConfirm = useCallback(() => {
    history.push(COMPANY_USERS_PATH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutDefault>
      <LayoutContainer>
        {listTitle ? <ListTitle /> : <DefaultTitle />}
        <CompanyUsersTabs activeTab={ActiveTabEnum.ADD_USERS} />

        <h2>Upload the list of employees</h2>
        <ImportUsers mutate={addBulkUser} onEnd={onConfirm} loading={bulkUserLoading} />

        <Form noValidate onSubmit={form.handleSubmit}>
          <h2>Choose one skillset</h2>
          <p>Please choose one skillset from list</p>
          <Form.Group controlId="skillsetIds">
            <SelectControl
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.skillsetIds}
              isInvalid={!!(form.touched.skillsetIds && form.errors.skillsetIds)}
              disabled={form.isSubmitting}
              custom
              required
            >
              <option key="blank-skillset" hidden>
                Enter skillset
              </option>
              {skillsets.map((s) => (
                <option key={s.id} value={s.id}>
                  {s.name}
                </option>
              ))}
            </SelectControl>
            <Form.Control.Feedback type="invalid">{form.errors.skillsetIds}</Form.Control.Feedback>
          </Form.Group>
          <h2>User information</h2>
          <div className="row">
            <div className="col-sm-12">
              <Form.Group controlId="jobTitle">
                <Form.Label>Job Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter job title"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.jobTitle}
                  isInvalid={!!(form.touched.jobTitle && form.errors.jobTitle)}
                  disabled={form.isSubmitting}
                />
                <Form.Control.Feedback type="invalid">{form.errors.jobTitle}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.firstName}
                  isInvalid={!!(form.touched.firstName && form.errors.firstName)}
                  disabled={form.isSubmitting}
                />
                <Form.Control.Feedback type="invalid">{form.errors.firstName}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-sm-6">
              <Form.Group controlId="companyDepartment">
                <Form.Label>Department</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter department"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.companyDepartment}
                  isInvalid={!!(form.touched.companyDepartment && form.errors.companyDepartment)}
                  disabled={form.isSubmitting}
                />
                <Form.Control.Feedback type="invalid">{form.errors.companyDepartment}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.lastName}
                  isInvalid={!!(form.touched.lastName && form.errors.lastName)}
                  disabled={form.isSubmitting}
                />
                <Form.Control.Feedback type="invalid">{form.errors.lastName}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col-sm-6">
              <Form.Group controlId="email">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter email address"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.email}
                  isInvalid={!!(form.touched.email && form.errors.email)}
                  disabled={form.isSubmitting}
                />
                <Form.Control.Feedback type="invalid">{form.errors.email}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <Form.Group controlId="groupName">
                <Form.Label>You can add a user to one or more groups</Form.Label>
                <SelectGroup
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.groupName}
                  isInvalid={!!(form.touched.groupName && form.errors.groupName)}
                  disabled={form.isSubmitting}
                  setFieldValue={form.setFieldValue}
                  fieldName="groupName"
                  placeholder="Enter group name"
                  required
                />
                <Form.Control.Feedback type="invalid">{form.errors.groupName}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <FormActions>
            <FormButton type="submit" form={form} disabled={!form.isValid}>
              Send Invite
            </FormButton>
          </FormActions>
        </Form>
      </LayoutContainer>
      <ModalSent onConfirm={onConfirm} show={!!modalText} text={modalText} />
    </LayoutDefault>
  );
};
