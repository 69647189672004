import React, { useCallback, useState } from 'react';
import gql from 'graphql-tag';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import { FormActions, LayoutDefault, FormButton } from '../../shared/ui';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { CompanyAdminsAdd, CompanyAdminsAddVariables } from './__generated__/CompanyAdminsAdd';
import { ModalSent } from '../../company-users/modal-sent/modal-sent';
import { useHistory } from 'react-router-dom';

const ADMINS_ADD = gql`
  mutation CompanyAdminsAdd($input: AddCompanyAdminsInput!) {
    addCompanyAdmins(input: $input) {
      id
    }
  }
`;

const validationSchema = yup.object().shape<any>({
  email: yup.string().email().required('Email is a required field'),
});

const LIST_OF_ADMINS_PATH = '/company-info/list-of-admins';

export const AddAdmins: React.FC<any> = () => {
  const [addAdmin, { loading }] = useMutation<CompanyAdminsAdd, CompanyAdminsAddVariables>(ADMINS_ADD);
  const [modalText, setModalText] = useState('');
  const history = useHistory();

  const form = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit: (values, actions) => {
      addAdmin({ variables: { input: { users: [{ email: form.values.email }] } } }).then(() => {
        setModalText(`Invitation email to ${form.values.email} was sent successfully!`);
      });
      actions.setSubmitting(false);
    },
    validateOnBlur: true,
    validateOnMount: true,
  });

  const handleConfirm = useCallback(() => {
    history.push(LIST_OF_ADMINS_PATH);
  }, [history]);

  return (
    <LayoutDefault>
      <div className="w-75 m-auto">
        <h2>
          Add <strong className="emphasis">admins</strong>
        </h2>
        <Form noValidate onSubmit={form.handleSubmit}>
          <div className="row">
            <div className="col-sm-12">
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter email"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.email}
                  isInvalid={!!(form.touched.email && form.errors.email)}
                  disabled={form.isSubmitting}
                />
                <Form.Control.Feedback type="invalid">{form.errors.email}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <FormActions>
            <FormButton type="submit" form={form} disabled={!form.isValid}>
              Send an invitation
            </FormButton>
          </FormActions>
        </Form>
        <ModalSent onConfirm={handleConfirm} show={!!modalText} text={modalText} />
      </div>
    </LayoutDefault>
  );
};
