import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { UserCurrentGet } from './__generated__/UserCurrentGet';

const USER_GET = gql`
  query UserCurrentGet {
    userCurrentGet {
      id
      email
      firstName
      lastName
      roles {
        role
      }
      skillsets {
        id
      }
    }
  }
`;

const useCurrentUser = () => {
  return useQuery<UserCurrentGet>(USER_GET, {
    fetchPolicy: 'cache-first',
  });
};

export { useCurrentUser };
