import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { ReferralFormLayout } from './referral-form-layout';

import { UserInviteUserInput } from '../../../../__generated__/globalTypes';
import { UserCoreInvite, UserCoreInviteVariables } from './__generated__/UserCoreInvite';
import { FormikHelpers } from 'formik';
import { getApiResponseMessage } from '../../shared/graphql';
import { error } from '../../shared/error';

const PATH_REFERRAL_LANDING = '/share/invite-sending';

const USER_CORE_INVITE = gql`
  mutation UserCoreInvite($input: UserInviteInput!) {
    userCoreInvite(input: $input) {
      id
    }
  }
`;

error.registerIgnoreOperation('UserCoreInvite');

function ReferralForm() {
  const history = useHistory();

  const [userCoreInvite] = useMutation<UserCoreInvite, UserCoreInviteVariables>(USER_CORE_INVITE);

  const onSubmit = (values: UserInviteUserInput, formikHelpers: FormikHelpers<UserInviteUserInput>) => {
    userCoreInvite({ variables: { input: { users: [values] } } })
      .then(() => {
        history.push(PATH_REFERRAL_LANDING);
      })
      .catch((e) => {
        formikHelpers.setErrors({ email: getApiResponseMessage(e) });
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <div className="w-50-resp m-auto">
      <h2>Share Profyle with others</h2>
      <p>Invite others to experience Profyle</p>
      <ReferralFormLayout onSubmit={onSubmit} />
    </div>
  );
}

export { ReferralForm };
