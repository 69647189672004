import { toLower } from 'ramda';
import { toUpperFirst } from '../../../with-types/ramda-extension';

const toLabelWith = (key: string | undefined, regExp: RegExp): string =>
  key ? key.replace(regExp, (match: string, g1: string) => ' ' + toUpperFirst(toLower(g1))).trim() : '';

const toLabelCamelCase = (key: string | undefined): string => toLabelWith(key, new RegExp('((?:^|[A-Z])[a-z]+)', 'g'));

const toLabelUnderscore = (key: string | undefined): string =>
  toLabelWith(key?.replace(new RegExp('_', 'g'), ' '), new RegExp('([a-z]+)', 'gi'));

export const toLabel = (key: string | undefined): string =>
  key ? (key.includes('_') ? toLabelUnderscore(key) : toLabelCamelCase(key)) : '';
