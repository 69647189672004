import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';

import { Html } from '../../shared/html';
import { ModalCreateAccount } from '../../shared/sign-in-up';

import background from './images/background.svg';

const CreateBlock = styled.div`
  min-height: 360px;
  margin: 128px 10% 0;
  padding-top: 80px;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: 160px 40px;
  background-color: var(--profyle-profyle-color-bg);
  text-align: center;
`;

const CreateBlockTitle = styled.h2`
  max-width: 500px;
  margin: 0 auto 60px;
`;

const CreateAccountButton = styled(Button)`
  margin-bottom: 30px;
`;

interface Props {
  taggedTexts: { [tag: string]: { title: string; body: string } };
  onClick?: () => void;
  disabled?: boolean;
}

function CreateYourProfyle(props: Props) {
  const { taggedTexts: texts, onClick, disabled } = props;

  return (
    <CreateBlock>
      <CreateBlockTitle>
        <Html code={texts['homeCreateYourProfyle'].title} />
      </CreateBlockTitle>
      <ModalCreateAccount
        render={({ showModal }) => (
          <CreateAccountButton disabled={disabled} onClick={onClick || showModal}>
            Let’s Go
          </CreateAccountButton>
        )}
      />
    </CreateBlock>
  );
}

export { CreateYourProfyle };
