import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import { LayoutDefault, Loading } from '../../shared/ui';
import { useQueryParams } from '../../shared/router';

import {
  PaymentStripeCheckoutSessionSuccess,
  PaymentStripeCheckoutSessionSuccessVariables,
} from './__generated__/PaymentStripeCheckoutSessionSuccess';

const TIMEOUT_LANDING_PAYMENT_SUCCESS = 5000;

interface IProps {
  paymentSuccessMutation: any;
  defaultRedirect: string;
}

function PaymentSuccess(props: IProps) {
  const { paymentSuccessMutation, defaultRedirect } = props;

  const queryParams = useQueryParams();
  const redirect = queryParams.get('redirect') || defaultRedirect;
  const sessionId = queryParams.get('sessionId');
  if (!sessionId) {
    throw new Error('Session id is not found in query params');
  }
  const ref = useRef<boolean>();
  const history = useHistory();
  const [paymentSuccess] = useMutation<
    PaymentStripeCheckoutSessionSuccess,
    PaymentStripeCheckoutSessionSuccessVariables
  >(paymentSuccessMutation);

  // Run only on first render
  if (!ref.current) {
    ref.current = true;

    paymentSuccess({ variables: { sessionId } }).then(() => {
      setTimeout(() => {
        history.push(redirect);
      }, TIMEOUT_LANDING_PAYMENT_SUCCESS);
    });
  }

  return (
    <LayoutDefault>
      <h2>Payment Success</h2>
      <Loading />
    </LayoutDefault>
  );
}

export { PaymentSuccess };
