import React from 'react';
import { Route } from 'react-router-dom';

import { ProtectedRoute } from '../shared/auth';
import { RoleName } from '../shared/auth/role-name';

import { MyOrderEnterprise } from './my-order-enterprise';

function myOrderEnterpriseRoute() {
  return (
    <ProtectedRoute
      path="/my-order-enterprise"
      role={RoleName.ROLE_CORPORATE_CORE}
      render={(routeProps) => (
        <>
          <Route notAuth path={routeProps.match.url} exact>
            <MyOrderEnterprise />
          </Route>
        </>
      )}
    />
  );
}

export { myOrderEnterpriseRoute };
