import styled from 'styled-components';
import expandIcon from './images/expand.svg';

export const TableWrapper = styled.div`
  overflow-x: auto;
  .table {
    border-bottom-width: 1px;
    vertical-align: middle;
    thead,
    tbody {
      th,
      td {
        border-bottom-width: 1px;
        vertical-align: middle;
      }
    }
    .table {
      margin: 0;
      tr {
        background: var(--profyle-profyle-color-input-bg);
      }
      @media (max-width: 767px) {
        td {
          padding-left: 12px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .table {
      width: 100%;
      tbody,
      th,
      td,
      tr {
        display: block;
      }

      thead {
        display: none;
      }
    }

    td {
      font-size: 14px;
      position: relative;
      padding-left: 0;
      min-width: 100%;
      &:not(:first-child) {
        display: none;
        background: var(--profyle-profyle-color-bg);
      }
    }
    td:not(:first-child) {
      display: none;
      background: var(--profyle-profyle-color-bg);
    }
    td:not(:first-child):before {
      padding-right: 10px;
      content: attr(data-label);
    }
  }
`;

export const MobileRow = styled.tr`
  display: none;
  @media (max-width: 767px) {
    display: block;
    color: #65697e;
    font-weight: 400;
    font-size: 16px;
    border-top: 1px solid rgb(222, 226, 230);

    td {
      display: table-cell !important;
      border-top: none;
    }
  }
`;

export const ExpandableRow = styled.tr<{ expanded: boolean }>`
  ${(props) =>
    props.expanded &&
    `
    @media (max-width: 767px) {
        td:not(:first-child) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 12px;
        }
      }
    `}
`;

export const Accordion = styled.div<{ hasChildren: boolean; expanded: boolean }>`
  display: flex;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) => {
    if (props.hasChildren)
      return `
        &:after {
          content: url(${expandIcon});
          transform: ${props.expanded ? 'rotate(-180deg)' : 'rotate(0deg)'};
        }
      `;

    return `
      @media (max-width: 767px) {
        &:after {
          content: url(${expandIcon});
          transform: ${props.expanded ? 'rotate(-180deg)' : 'rotate(0deg)'};
        }
      }
    `;
  }}
  cursor: pointer;
`;

export const AccordionWrapper = styled.div<{ expanded: boolean }>`
  max-height: ${(props) => (props.expanded ? 'auto' : '0px')};
  overflow: hidden;
`;

export const MobileRowHeader = styled.td`
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const MobileCell = styled.td<{ disableOnMobile?: boolean }>`
  @media (max-width: 767px) {
    ${(props) => {
      if (props.disableOnMobile)
        return `
          display: none !important;
        `;
    }}
  }
`;
