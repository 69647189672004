import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import { Button } from '../../shared/ui';

export const TableWrapper = styled.div`
  overflow-x: auto;
  .table {
    border-bottom-width: 1px;
    vertical-align: middle;

    @media (min-width: 768px) {
      width: 1110px;
      thead,
      tbody {
        th {
          border-bottom-width: 1px;
          vertical-align: middle;
          padding: 12px 16px;
          white-space: nowrap;
          &:not(:last-child) {
            width: 21%;
          }
          &:last-child {
            padding-right: 0;
          }
          &:nth-child(2) {
            width: 22%;
          }
        }
        td:nth-child(2),
        th:first-child {
          padding-left: 2px;
          width: 2%;
        }
        tr:last-child {
          td {
            border-top: 1px solid var(--profyle-gray-300);
          }
        }
      }
    }
    @media (max-width: 767px) {
      td:first-child > div > div > div,
      td:first-child > div > div {
        margin-right: 5px;
      }
    }
  }
`;

export const AdminLabel = styled.span`
  background-color: var(--primary);
  color: var(--white);
  font-size: 12px;
  padding: 2px;
  border-radius: 4px;
`;

export const SmallButton = styled(Button)`
  font-family: var(--profyle-muli-font-family);
  width: 131px;
  font-weight: 600;
  &:disabled {
    background: rgba(101, 105, 126, 0.5);
    border-color: rgba(101, 105, 126, 0.5);
    color: #fff;
  }
`;

export const CustomTr = styled.tr<{ isDisabled: boolean }>`
  td {
    color: ${({ isDisabled }) => (isDisabled ? 'var(--profyle-color-gray-for-text)' : 'var(--profyle-headings-color)')};
  }
`;
