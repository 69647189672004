import { auth } from '../auth';

const getHttpBaseHeaders = () => ({
  ...(auth.isAuthenticated()
    ? {
        Authorization: `Bearer ${auth.getToken()}`,
      }
    : {}),
});

const getHttpBaseUrl = () => process.env.REACT_APP_PROFYLE_APIGATEWAY_URL || 'http://localhost:4000/graphql';

export { getHttpBaseHeaders, getHttpBaseUrl };
