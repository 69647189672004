import React from 'react';

import { UseFormikReturnType } from '../../formik';

import { TextEditor } from './text-editor';

interface FormTextEditorProps {
  value?: string;
  readOnly?: boolean;
  placeholder?: string;
  id: string;
  form: UseFormikReturnType;
}

function FormEditor(props: FormTextEditorProps) {
  return (
    <TextEditor
      value={props.value}
      onChange={(value) => props.form.setFieldValue(props.id, value)}
      onBlur={() => {
        props.form.handleBlur(props.id);
        props.form.setFieldTouched(props.id);
      }}
      readOnly={props.readOnly}
      placeholder={props.placeholder}
    />
  );
}

export { FormEditor };
