import React from 'react';
import { Route } from 'react-router-dom';

import { ProtectedRoute } from '../shared/auth';

import { SignUpEnterprise } from './sign-up-enterprise';
import { emailConfirmedRoute } from './email-confirmed';

function signUpEnterpriseRoute() {
  return (
    <Route
      path="/sign-up-enterprise"
      render={(routeProps) => (
        <>
          <ProtectedRoute notAuth path={routeProps.match.url} exact>
            <SignUpEnterprise />
          </ProtectedRoute>
          {emailConfirmedRoute(routeProps)}
        </>
      )}
    />
  );
}

export { signUpEnterpriseRoute };
