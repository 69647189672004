import React from 'react';
import styled from 'styled-components';

const AvatarBlock = styled.div`
  width: var(--profyle-navbar-height);
  height: var(--profyle-navbar-height);
  line-height: var(--profyle-navbar-height);
  border: 1px solid var(--dark);
  text-align: center;
  color: var(--dark);
  user-select: none;
`;

interface Props {
  firstName: string;
  lastName: string;
}

function Avatar(props: Props) {
  // TODO: Check multilingual support of this uppercase code
  const abbr = props.firstName[0].toUpperCase() + props.lastName[0].toUpperCase();
  return <AvatarBlock title={`${props.firstName} ${props.lastName}`}>{abbr}</AvatarBlock>;
}

export { Avatar };
