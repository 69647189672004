import React from 'react';
import { Link } from 'react-router-dom';

import { LayoutDefault } from '../shared/ui';

function Admin() {
  return (
    <LayoutDefault>
      <h2>Admin Console</h2>
      <ul>
        <li>
          <Link to="/admin/quizzes">Quizzes &amp; Questions</Link>
        </li>
        <li>
          <Link to="/admin/profyles">Profyles</Link>
        </li>
        <li>
          <Link to="/admin/users">Users</Link>
        </li>
        <li>
          <Link to="/admin/companies">Companies</Link>
        </li>
        <li>
          <Link to="/admin/skillsets">Skillsets</Link>
        </li>
        <li>
          <Link to="/admin/texts">Texts</Link>
        </li>
        <li>
          <Link to="/admin/payment-logs">Payment Logs</Link>
        </li>
        <li>
          <Link to="/admin/orders">Orders</Link>
        </li>
        <li>
          <Link to="/admin/settings">Settings</Link>
        </li>
        <li>
          <Link to="/admin/sales-report">Sales report</Link>
        </li>
      </ul>
    </LayoutDefault>
  );
}

export { Admin };
