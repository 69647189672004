import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../../shared/auth';

import { Questions } from './questions';
import { questionsAddRoute } from './questions-add';
import { questionsEditRoute } from './questions-edit';

function questionsRoute(routeProps: RouteComponentProps) {
  return (
    <Route
      path={`${routeProps.match.path}/questions`}
      render={(routeProps) => (
        <>
          <ProtectedRoute path={routeProps.match.url} exact>
            <Questions />
          </ProtectedRoute>
          {questionsAddRoute(routeProps)}
          {questionsEditRoute(routeProps)}
        </>
      )}
    />
  );
}

export { questionsRoute };
