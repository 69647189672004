import React, { ReactNode } from 'react';

import { ShowForUser } from '../show-for-user';
import { RoleName } from '../role-name';

interface Props {
  role: RoleName | RoleName[];
  children: ReactNode;
}

function HideForUser({ role, children }: Props) {
  return (
    <ShowForUser role={role} invert>
      {children}
    </ShowForUser>
  );
}

export { HideForUser };
