import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { AuthContextProvider, useCurrentUser } from './shared/auth';
import { ErrorBoundary, ErrorMessage, ErrorContextProvider } from './shared/error';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Routing } from './routing';

const COOKIES_DOMAIN = '.profyle.me';

function App() {
  const { loading: userLoading, data: user } = useCurrentUser();

  useEffect(() => {
    if (user?.userCurrentGet) {
      const {
        userCurrentGet: { email, firstName, lastName, roles },
      } = user;

      const rolesString = roles.reduce((arr: string[], { role }) => [...arr, role], []).join();
      Cookies.set('roles', rolesString, { domain: COOKIES_DOMAIN });
      Cookies.set('userEmail', email, { domain: COOKIES_DOMAIN });
      Cookies.set('userFirstName', firstName, { domain: COOKIES_DOMAIN });
      Cookies.set('userLastName', lastName, { domain: COOKIES_DOMAIN });
    } else {
      Cookies.remove('roles', { domain: COOKIES_DOMAIN });
      Cookies.remove('userEmail', { domain: COOKIES_DOMAIN });
      Cookies.remove('userFirstName', { domain: COOKIES_DOMAIN });
      Cookies.remove('userLastName', { domain: COOKIES_DOMAIN });
    }
  }, [user]);

  return (
    <ErrorContextProvider>
      <ErrorBoundary>
        <ErrorMessage>
          <AuthContextProvider>
            <Routing />
            <NotificationContainer />
          </AuthContextProvider>
        </ErrorMessage>
      </ErrorBoundary>
    </ErrorContextProvider>
  );
}

export { App };
