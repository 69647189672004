import React from 'react';
import { LayoutDefault } from '../shared/ui';
import { FeedbackAboutYou } from './feedback-about-you';
import { YourFeedbackAboutOthers } from './your-feedback-about-others';

function Feedback() {
  return (
    <LayoutDefault>
      <FeedbackAboutYou />
      <YourFeedbackAboutOthers />
    </LayoutDefault>
  );
}

export { Feedback };
