import React from 'react';
import styled from 'styled-components';
import figureMainBackground from './images/figure-main-background.svg';
import figureMainBackground2 from './images/figure-main-background-2.svg';

export const BlockFigureMain = styled.div`
  background-image: url(${figureMainBackground}), url(${figureMainBackground2});
  background-repeat: no-repeat;
  background-position: bottom left, top right;
`;
