import React from 'react';

import { LayoutDefault } from '../../shared/ui';
import { PasswordForm } from '../../shared/user';

function Password() {
  return (
    <LayoutDefault>
      <PasswordForm title="Setup Password" landing="/my-info" />
    </LayoutDefault>
  );
}

export { Password };
