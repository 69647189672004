import React from 'react';
import gql from 'graphql-tag';

import { AdminList } from '../../shared/ui';

import { AdminSkillsetListGet_adminSkillsetListGet } from './__generated__/AdminSkillsetListGet';

const SKILLSET_LIST_GET = gql`
  query AdminSkillsetListGet {
    adminSkillsetListGet {
      id
      name
    }
  }
`;

const SKILLSET_DELETE = gql`
  mutation AdminSkillsetDelete($id: Int!) {
    adminSkillsetDelete(id: $id) {
      id
    }
  }
`;

const MAPPING = [
  { label: 'Skillset Id', getter: (value: AdminSkillsetListGet_adminSkillsetListGet) => value.id },
  { label: 'Name', getter: (value: AdminSkillsetListGet_adminSkillsetListGet) => value.name },
];

const BREADCRUMBS = [{ label: 'Skillsets' }];

function Skillsets() {
  return (
    <AdminList
      title="Skillsets"
      listQuery={SKILLSET_LIST_GET}
      deleteMutation={SKILLSET_DELETE}
      dataProp="adminSkillsetListGet"
      breadcrumbs={BREADCRUMBS}
      mapping={MAPPING}
    />
  );
}

export { Skillsets };
