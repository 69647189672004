import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from '../../shared/auth';

import { Password } from './password';

function passwordRoute(routeProps: RouteComponentProps) {
  return (
    <ProtectedRoute path={`${routeProps.match.url}/password`}>
      <Password />
    </ProtectedRoute>
  );
}

export { passwordRoute };
