import { Route } from 'react-router-dom';
import React from 'react';

import { ProtectedRoute } from '../shared/auth';

import { Branding } from './branding';
import { RoleName } from '../shared/auth/role-name';

function brandingRoute() {
  return (
    <ProtectedRoute
      path="/branding"
      role={RoleName.ROLE_CORPORATE_ADMIN}
      render={(routeProps) => (
        <>
          <Route path={routeProps.match.url} exact>
            <Branding />
          </Route>
        </>
      )}
    />
  );
}

export { brandingRoute };
