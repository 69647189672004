import React from 'react';
import { Route } from 'react-router-dom';

import { Skillsets } from './skillsets';

function skillsetsRoute() {
  return (
    <Route path="/skillsets">
      <Skillsets />
    </Route>
  );
}

export { skillsetsRoute };
