import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { Button } from '../button';

interface ButtonContainerProps {
  oneAction?: boolean;
  children: ReactNode;
}

const ButtonWrapper = styled.div.attrs<ButtonContainerProps>((props) => ({
  className: classNames('mt-4', {
    'text-right': !props.oneAction,
    'text-center': props.oneAction,
  }),
}))<ButtonContainerProps>`
  // ${Button} {
  //   margin-right: 20px;
  // }

  // ${Button}:last-child {
  //   margin-right: 0;
  // }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
  }
`;

interface Props {
  oneAction?: boolean;
  children: ReactNode;
}

function FormActions(props: Props) {
  return <ButtonWrapper oneAction={props.oneAction}>{props.children}</ButtonWrapper>;
}

export { FormActions };
